import Web3 from "web3";
//@ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import { client, CreateWalletConnectClient } from "./connectWallet";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { getNonceData, userLogout } from "../../action/Api.action";
import Url from "../../utils/appUrls";
import axios from "axios";
import { getSdkError } from "@walletconnect/utils";
import { useDispatch } from "react-redux";
import { setLoginCheck, setProfile } from "../../slices/auth";
import { useHistory } from "react-router-dom";

const getNonce = async (address) => {
  // const res = await axios.get(
  //
  // );
  // console.log("res", res);
  // if (res?.data?.data[0]) {
  //   return res.data.data[0].sequence.nonce;
  // } else {
  //   return 0;
  // }
  const info = await fetch(
    // `https://service-ccx-dev.prosofo.com/api/v2/accounts?publicKey=${address}`
    // `https://service-rivet.clymene.zeeve.net/api/v2/accounts?publicKey=${address}`
    `https://service-truss.clymene.network/api/v2/accounts?publicKey=${address}`
  )
    .then((res) => {
      if (res?.data?.data[0]) {
        return res.data.data[0].sequence.nonce;
      } else {
        return 0;
      }
    })
    .catch((err) => {
      return 0;
    });
  return info;
};
export const WalletConnectMetamask = async () => {
  if (!client) {
    await CreateWalletConnectClient();
  }
  try {
    const { uri, approval } = await client.connect({
      requiredNamespaces: {
        eip155: {
          methods: [
            "personal_sign",
            "custom_sendTransaction",
            "request_acknowledgement",
          ],
          chains: ["eip155:42"],
          events: ["chainChanged", "accountsChanged"],
        },
      },
    });
    if (uri) {
      QRCodeModal.open(
        uri,
        () => {
          console.log("EVENT", "QR Code Modal closed");
        },
        { desktopLinks: [], mobileLinks: [] }
      );
    }

    const session = await approval();

    QRCodeModal.close();
    const body = {
      session,
      account: session.namespaces.eip155.accounts[0].split(":")[2],
      status: true,
    };
    return body;
  } catch (error) {
    //console.log("WalletConnect Error", error);
    QRCodeModal.close();
    return {
      status: false,
      message: error.message,
    };
  }
};

export const getSignMessage = async (msg, account, session) => {
  try {
    if (!client) {
      await CreateWalletConnectClient();
    }
    const signature = await client.request({
      topic: session.topic,
      chainId: "eip155:42",
      request: {
        method: "personal_sign",
        params: [msg, account],
      },
    });
    return signature;
  } catch (error) {
    return error;
  }
};
export const createResellTransaction = async (data) => {
  if (!client) {
    await CreateWalletConnectClient();
  }
  try {
    let msg = "Phaeton CCX - Acknowledgement for Resell Transaction";
    const currentDate = Date.now();
    const storedSession = localStorage.getItem("wc@2:client:0.3//session");
    if (storedSession !== []) {
      const latestSession = JSON.parse(storedSession).length;
      const session = JSON.parse(storedSession)[latestSession - 1];
      const accountAddress =
        session.namespaces.eip155.accounts[0].split(":")[2];

      // const arr = [];
      // data?.certificateIDs.forEach((data) => arr.push({ certificateID: data }));
      const nonce = await getNonce(accountAddress);
      const payLoad = {
        sellerPublicKey: window.phaeton.hexToBuffer(
          data?.sellerPublicKey,
          "hex"
        ),
        platformSignature: window.phaeton.hexToBuffer(
          data?.platformSignature,
          "hex"
        ),
        certificates: data?.certificates,
      };
      const result = await client.request({
        topic: session.topic,
        chainId: "eip155:42",
        request: {
          id: 1,
          jsonrpc: "2.0",
          method: "custom_sendTransaction",
          params: [
            JSON.stringify({
              moduleID: data?.moduleID,
              assetID: data?.assetID,
              fee: 220000,
              nonce: nonce,
              senderPublicKey: window.phaeton.hexToBuffer(
                data?.sellerPublicKey,
                "hex"
              ),
              asset: payLoad,
              signatures: [],
            }),
            Url.SERVICE_URL,
          ],
        },
      });
      // let resp = JSON.parse(result);
      console.log("AFTER RESELL", typeof result, result);
      let resp = result;
      if (result && typeof result == "string") {
        resp = JSON.parse(result);
      }
      await sendAcknowledgement(
        msg,
        resp.transactionHash,
        currentDate,
        session,
        accountAddress,
        "custom_sendTransaction",
        resp.status
      );
      return resp;
      // console.log("RESULT", result);
    }
  } catch (error) {
    //console.log("Resell Transaction Error", error);
    return {
      status: false,
      message: error.message,
    };
  }
};
export const sendAcknowledgement = async (
  new_msg,
  signature,
  currentDate,
  session,
  accountAddress,
  method,
  status
) => {
  try {
    const acknowledgement = {
      status: status,
      message: new_msg,
      data: signature, //encrypt it? signature or transaction hash
      method: "request_acknowledgement", //method used for this request
      acknowledgementFor: method, //dynamic depending on type of request personal_sign or custom_sendTransaction
      ack_id: currentDate, //pick from previous response body
    };

    const ackData = {
      topic: session.topic,
      chainId: "eip155:42",
      request: {
        method: "request_acknowledgement",
        params: [JSON.stringify(acknowledgement), accountAddress],
      },
    };
    console.log(ackData, "KYC:ackData");

    const ack = await client.request(ackData);
  } catch (err) {
    console.log("Erron in Send Acknowledge", err);
  }
};

export const buyCTNFTTransaction = async (data, info) => {
  if (!client) {
    await CreateWalletConnectClient();
  }
  try {
    const storedSession = localStorage.getItem("wc@2:client:0.3//session");
    if (storedSession !== []) {
      let msg = "Phaeton CCX - Acknowledgement for Resell Transaction";
      const currentDate = Date.now();
      const latestSession = JSON.parse(storedSession).length;
      const session = JSON.parse(storedSession)[latestSession - 1];
      const accountAddress =
        session.namespaces.eip155.accounts[0].split(":")[2];

      const nonce = await getNonce(accountAddress);
      const payLoad = info;
      console.log("before Buy Tansaction", payLoad);
      const result = await client.request({
        topic: session.topic,
        chainId: "eip155:42",
        request: {
          id: 1,
          jsonrpc: "2.0",
          method: "custom_sendTransaction",
          params: [
            JSON.stringify({
              moduleID: data?.moduleID,
              assetID: data?.assetID,
              fee: 220000,
              senderPublicKey: window.phaeton.hexToBuffer(
                accountAddress,
                "hex"
              ),
              nonce: nonce,
              asset: payLoad,
              signatures: [],
            }),
            Url.SERVICE_URL,
          ],
        },
      });
      console.log("AFTER BUY", typeof result, result);
      let resp = result;
      if (result && typeof result == "string") {
        resp = JSON.parse(result);
      }
      sendAcknowledgement(
        msg,
        resp.transactionHash,
        currentDate,
        session,
        accountAddress,
        "custom_sendTransaction",
        resp.status
      );
      return resp;
    }
  } catch (error) {
    console.log("Buy Transaction Error", error);
    return {
      status: false,
      message: error.message,
    };
  }
};

export const createCTNFT = async (meta_data) => {
  if (!client) {
    await CreateWalletConnectClient();
  }
  try {
    // console.log("meta_data", meta_data);
    const currentDate = Date.now();
    const storedSession = localStorage.getItem("wc@2:client:0.3//session");
    if (storedSession !== []) {
      const latestSession = JSON.parse(storedSession).length;
      // console.log("latestSession", latestSession);
      const session = JSON.parse(storedSession)[latestSession - 1];
      const accountAddress =
        session.namespaces.eip155.accounts[0].split(":")[2];
      // console.log("accountAddress", accountAddress);

      const result = await client.request({
        topic: session.topic,
        chainId: "eip155:42",
        request: {
          method: "personal_sign",
          params: [JSON.stringify(meta_data), accountAddress],
        },
      });
      let msg = "Phaeton CCX - Acknowledgement for Create Transaction";
      if (result) {
        await sendAcknowledgement(
          msg,
          result,
          currentDate,
          session,
          accountAddress,
          "personal_sign",
          true
        );
      }
      return result;
    }
  } catch (error) {
    console.log("Create CTNFT Error", error);
    return {
      status: false,
      message: error.message,
    };
  }
};

export const wallectConnectTransaction = async (to, amount) => {
  if (typeof window !== "undefined") {
    let wallet_address = localStorage.getItem("wallet_address");
    try {
      const provider = new WalletConnectProvider({
        rpc: {
          1: "https://cloudflare-eth.com/",
          137: "https://polygon-rpc.com/",
          80001: "https://rpc-mumbai.matic.today",
          3: "https://api.infura.io/v1/jsonrpc/ropsten",
        },
      });
      await provider.enable();
      const web3 = new Web3(provider);
      //@ts-ignore
      //debugger
      let accounts = await web3.eth.getAccounts();
      let address;
      if (accounts) {
        address = accounts[0];
      } else {
        address = wallet_address;
      }
      //@ts-ignore
      const val = web3.utils.toWei(amount);
      const trans = await web3.eth.sendTransaction({
        from: accounts[0],
        to: to,
        value: val,
      });
      return trans;
    } catch (error) {
      //
      return {
        status: false,
        message: error?.message,
      };
    }
  }
};

export const WalletDisconnect = async () => {
  if (!client) {
    await CreateWalletConnectClient();
  }
  try {
    const storedSession = localStorage.getItem("wc@2:client:0.3//session");
    if (storedSession !== null) {
      const latestSession = JSON.parse(storedSession).length;
      const session = JSON.parse(storedSession)[latestSession - 1];

      await client.disconnect({
        topic: session.topic,
        reason: getSdkError("USER_DISCONNECTED"),
      });
    }
  } catch (err) {
    //console.log("wallectConnectTransaction Error", err);
    return {
      status: false,
      message: err?.message,
    };
  }
};

export const createBurnTransaction = async (data) => {
  if (!client) {
    await CreateWalletConnectClient();
  }
  try {
    const storedSession = localStorage.getItem("wc@2:client:0.3//session");
    if (storedSession !== []) {
      const msg = "Phaeton CCX - Acknowledgement for Burn Transaction";
      const currentDate = Date.now();
      const latestSession = JSON.parse(storedSession).length;
      const session = JSON.parse(storedSession)[latestSession - 1];
      const accountAddress =
        session.namespaces.eip155.accounts[0].split(":")[2];

      // const arr = [];
      // data?.certificateIDs.forEach((data) => arr.push({ certificateID: data }));
      const nonce = await getNonce(accountAddress);
      const payLoad = {
        certificatesID: data?.certificatesID,
      };
      console.log("before burn PAYLOAD", payLoad);
      const result = await client.request({
        topic: session.topic,
        chainId: "eip155:42",
        request: {
          id: 1,
          jsonrpc: "2.0",
          method: "custom_sendTransaction",
          params: [
            JSON.stringify({
              moduleID: data?.moduleID,
              assetID: data?.assetID,
              fee: 220000,
              nonce: nonce,
              senderPublicKey: window.phaeton.hexToBuffer(
                accountAddress,
                "hex"
              ),
              asset: payLoad,
              signatures: [],
            }),
            Url.SERVICE_URL,
          ],
        },
      });
      console.log("AFTER BURN", result);
      let resp = result;
      if (result && typeof result == "string") {
        resp = JSON.parse(result);
      }
      // const resp = result;
      await sendAcknowledgement(
        msg,
        resp.transactionHash,
        currentDate,
        session,
        accountAddress,
        "custom_sendTransaction",
        resp.status
      );
      return resp;
      // console.log("RESULT", result);
    }
  } catch (error) {
    //console.log("Resell Transaction Error", error);
    return {
      status: false,
      message: error?.message,
    };
  }
};

const fetchLogout = async (request) => {
  await userLogout(request);
};

export const walletConnectSessionTimeout = async () => {
  // const dispatch = useDispatch();
  // const history = useHistory()
  const user_id = localStorage.getItem("userId");
  console.log("Ping for user Id ", user_id)
  const request = {
    user_id: parseInt(user_id),
  };
  await fetchLogout(request);
  if (!client) {
    await CreateWalletConnectClient();
  }
  try {
    client.on("session_delete", () => {
      console.log("EVENT", "session_delete");
      // Session was deleted -> reset the dapp state, clean up from user session, etc.
    });
    // It will removed existing session from wallet
    await WalletDisconnect();
    localStorage.clear();
    // dispatch(setProfile({}));
    // dispatch(setLoginCheck(false));
    // history.push(`/`);
  } catch (error) {
    console.log("wallet connect session delete", error);
  }
  // dispatch(setProfile({}));
  // dispatch(setLoginCheck(false));
  localStorage.clear();
  // history.push(`/`);
};
