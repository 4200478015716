import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { setSuccessData } from '../slices/home';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import {userRole, kycDocumentList, message} from '../common/Constant';

import {updateCompanyInfoAction} from '../action/Api.action';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Companyinfo = ({ history }) => {
	
	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({});
	const [formErrors, setFormErrors] = useState({});
	const [isUpdate, setUPdate] = useState(false);
	const [isDisabled, setDisabled] = useState(false);

	const dispatch = useDispatch();

	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	  });
	  
	const { vertical, horizontal, open } = state;


	const handleClick = (newState) => () => {
		setState({ open: true, ...newState });
	};

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	const [kycList, setKycList] = useState([{ documentType: "", image: "", imgPath:"", _id:"0" }]);
	
	const formHandler = (e, field, index='') => {
		let data = formData;
		let err = formErrors;
		if(field === 'documentType'){
			
			let kyc = kycList;
			kyc[index].documentType = e.target.value;
			
			setKycList(kyc);
			
		}else if(field === 'image'){
			fileChangedHandler(e, field, index);
			
		}else{
			data[field] = e.target.value;
		}
		
		if(err[field]){
			delete err[field];
		}
		setFormErrors(err);
		setFormData(data);
		setUPdate(!isUpdate);	
		
	}

	const fileChangedHandler=(event, elename, index)=>{ 
		event.preventDefault();
		let formErrorsData = formErrors;      
	
		let kycData = kycList;	  
		let file = event.target.files[0];
		  if(!file && file===undefined){
			//formDataData['preview'] = '';
			//formDataData[elename] = '';
			//setFormData({...formData,...formDataData});
			return false;
		  }
		  var fileName = (file && file.name?file.name:'');
		  let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
		  
		if(file.size>31457280){
			//formErrorsData[elename] = "File size not greater then 30MB.";
		}else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg'){
		  
			//formErrorsData[elename] = "";
			//formErrorsData["preview"] = "";
			//formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
			//formDataData['fileType'] = extensions;
			kycData[index].image = event.target.files[0];
			kycData[index].imgPath = event.target.files[0].name;
			
			//setFormData({...formData,...formDataData});
		}else {          
			 // formErrorsData[elename] = "File extensions doesn't match.";
					 
		}
		//setFormErrors({...formErrors,...formErrorsData});

		setKycList(kycData);
	}

	// handle click event of the Remove button
	const handleRemoveClick = (index) => {
		const list = [...kycList];
		
		list.splice(index, 1);
		
		setKycList(list);
	
	  };
	   
	  // handle click event of the Add button
	  const handleAddClick = () => {
		  if(kycList.length<5){
			 setKycList([...kycList, { documentType: "", image: "", imgPath:"", _id:"0" }]);
		  }   
	  };
	const handleSubmit = async(event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
	
			setValidated(true);
		}else{
			event.preventDefault();
			event.stopPropagation();
			setDisabled(true);
			//setValidated(true);
			
			let formDataData = formData;
			if(formDataData.documentType){
				delete formDataData['documentType'];
			}			

			let postData = new FormData();
			for(let x in formDataData){
	
				let item = formDataData[x];       
				postData.append(x, item);

			}

			let kycData = kycList;

			let y = 0;

			for(let x in kycData){
	
				let item = kycData[x];   
				if(item.image && item.image !== '' && item.image !== 'undefined'){ 
					
					postData.append('documentType', item.documentType);
					postData.append('image', item.image?item.image:'');
					
					postData.append('kyc_id', item._id);
					
				}
			}

			for(let x in kycData){
	
				let item = kycData[x];    
				if(!item.image || item.image === ''){    
					postData.append('documentType', item.documentType);
					//postData.append('image', item.image?item.image:'');
					
					postData.append('kyc_id', item._id);
					
				}
			}

			let response = await updateCompanyInfoAction(postData);
		
			if(response.code === 200){
				
				//setState({ open: true, vertical: 'top', horizontal: 'center' });
				setFormData({});
				localStorage.setItem('authData', JSON.stringify(response.data));
				dispatch(setSuccessData(message['companyCreated']));
				
				history.push({pathname:"/success"});				

			}else if(response && response.errors){
				
				setDisabled(false);
				let errorsList = response.errors;
				let err = {};
				for(let i in errorsList){
					err[errorsList[i].param] = errorsList[i].msg;
				}
				
				setFormErrors(err);

			}
		}		
	};

	/* Api Calling */
	useEffect(() => {
		let userData = JSON.parse(localStorage.getItem('authData'));
		userData.contactName = userData.contactName !== ''?userData.contactName:userData.fullName;
		userData.contactPhone = userData.contactPhone !== ''?userData.contactPhone:userData.phone;
		userData.contactEmail = userData.contactEmail !== ''?userData.contactEmail:userData.email;

		delete userData['approvalArr'];
		delete userData['axiaStatus'];
		delete userData['bio'];
		delete userData['contractSend'];
		delete userData['contractSign'];
		delete userData['coverImg'];
		delete userData['createdAt'];
		delete userData['deleted'];
		delete userData['email'];
		delete userData['enabled'];
		delete userData['firstName'];
		delete userData['followers'];
		delete userData['following'];
		delete userData['fullName'];
		delete userData['kycVerify'];
		delete userData['lastName'];
		delete userData['metaId'];
		delete userData['otp'];
		delete userData['phone'];
		delete userData['phoneCode'];
		delete userData['porfolioUrl'];
		delete userData['profileImg'];
		delete userData['temp_str'];
		delete userData['token'];
		delete userData['twitterUrl'];
		delete userData['updatedAt'];
		delete userData['customUrl'];
		delete userData['salt'];
		delete userData['password'];
		delete userData['steps'];
		delete userData['__v'];
		
		setFormData(userData);
		setUPdate(!isUpdate);	
		//console.log(userData);
		let kycDocs = [];
		if(userData.kycDocs && userData.kycDocs.length>0){

			for(let i in userData.kycDocs){
				let item = userData.kycDocs[i];
				item.documentType = item.type;
				kycDocs.push(item);
			}
			setKycList(kycDocs);
		}
		
	},[]);

	return (
		<Container>

			<Snackbar
			 		autoHideDuration={2000}
					anchorOrigin={{ vertical, horizontal }}
					open={open}
					onClose={handleClose}
					//message="I love snacks"
					key={vertical + horizontal}
					//severity="error"
				>
				<Alert onClose={handleClose} severity="success">Successfully company information added.</Alert>
			</Snackbar>
			
			<div className="moreInfoSec signUpForm boxShaddow burger">
				<Row className="mb-4">
					<Col lg={6}>
						<div className="heading">
							<h2>Company Information</h2>
							<p>Enter your legal entity details here</p>
						</div>
					</Col>
				</Row>
				<Form className="formContent"  noValidate validated={validated} onSubmit={e=>handleSubmit(e)}>
					<Row className="border-bottom mb-4 pb-4">
					
						<Col xs={12}><h4>Company Details</h4></Col>
						
						<Col lg={6}>							
							<Form.Group className="mb-3" controlId="companyName">
								<Form.Control type="text" placeholder="Organization Name" defaultValue={formData.companyName} name="companyName" onChange={e => formHandler(e, 'companyName')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>							
						</Col>
						<Col lg={6}>
							
							<Form.Group className="mb-3" controlId="companyRegisterNumber">
								<Form.Control type="text" placeholder="Organization Registration Number" defaultValue={formData.companyRegisterNumber} name="companyRegisterNumber" onChange={e => formHandler(e, 'companyRegisterNumber')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>

						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="userType">
								
								<Form.Control as="select" custom  className="form-control" value={formData.userType} name="userType" onChange={e => formHandler(e, 'userType')} required>
									<option value="">Select Type</option>
									{userRole.map((row, index)=>{
										return <option value={row.value} key={index}>{row.label}</option>
									})}
								</Form.Control>								
							</Form.Group>
							
						</Col>	
						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="companyTaxationNumber">
								<Form.Control type="number" placeholder="Taxation Number" defaultValue={formData.companyTaxationNumber} name="companyTaxationNumber" onChange={e => formHandler(e, 'companyTaxationNumber')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>
						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="companyEntity">
								<Form.Control type="text" placeholder="Type of Company Entity" defaultValue={formData.companyEntity} name="companyEntity" onChange={e => formHandler(e, 'companyEntity')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>
					</Row>

					<Row className="border-bottom mb-4 pb-4">
						<Col xs={12}><h4>Business Address</h4></Col>

						<Col lg={6}>
							
							<Form.Group className="mb-3" controlId="Address">
								<Form.Control type="text" placeholder="Address" defaultValue={formData.address} name="address" onChange={e => formHandler(e, 'address')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>
						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="zipCode">
								<Form.Control type="number" placeholder="Zip Code" defaultValue={formData.zipCode} name="zipCode" onChange={e => formHandler(e, 'zipCode')} minLength="2" maxLength="8" autoComplete="none" required />
							</Form.Group>
							
						</Col>	
						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="formBasicCountry">
								<Form.Control type="text" placeholder="Country" defaultValue={formData.country} name="country" onChange={e => formHandler(e, 'country')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>

						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="state">
								<Form.Control type="text" placeholder="State/ Provins" defaultValue={formData.state} name="state" onChange={e => formHandler(e, 'state')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>
						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="city">
								<Form.Control type="text" placeholder="City" defaultValue={formData.city} name="city" onChange={e => formHandler(e, 'city')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>
					</Row>

					<Row className="border-bottom mb-4 pb-4">
						<Col xs={12}><h4>Contact Person Details</h4></Col>

						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="contactName">
								<Form.Control type="text" placeholder="Full Name" defaultValue={formData.contactName} name="contactName" onChange={e => formHandler(e, 'contactName')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>	
						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="contactPhone">
								{/* <Form.Control type="tel" placeholder="Mobile" defaultValue={formData.contactPhone} name="contactPhone" onChange={e => formHandler(e, 'contactPhone')} minLength="10" maxLength="12" autoComplete="none" required/> */}
								<PhoneInput
										//country={'us'}
										placeholder="Mobile"
										value={formData.contactPhone}
										onChange={phone => formHandler(phone, 'contactPhone')}
										inputProps={{
											name: 'contactPhone',
											required: true
										  }}
										inputStyle={{width:"100%"}}
										isValid={(value) => {
											if (value.length<10 || value.length>12) {
												return false;
											} else {
												return true;
											}
										}}										
									/>
							</Form.Group>
							
						</Col>

						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="contactEmail">
								<Form.Control type="email" placeholder="Email Address" defaultValue={formData.contactEmail} name="contactEmail" onChange={e => formHandler(e, 'contactEmail')} minLength="2" maxLength="128" autoComplete="none" required/>
							</Form.Group>
							
						</Col>
						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="contactDesignation">
								<Form.Control type="text" placeholder="Designation" defaultValue={formData.contactDesignation} name="contactDesignation" onChange={e => formHandler(e, 'contactDesignation')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>
					</Row>

					<Row className="border-bottom mb-4 pb-4">
						<Col xs={12}><h4>Banking  Details</h4></Col>

						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="accountName">
								<Form.Control type="text" placeholder="Account Name" defaultValue={formData.accountName} name="accountName" onChange={e => formHandler(e, 'accountName')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>	
						<Col lg={3}>
							
							<Form.Group className="mb-3" controlId="accountNumber">
								<Form.Control type="number" placeholder="Account Number" defaultValue={formData.accountNumber} name="accountNumber" onChange={e => formHandler(e, 'accountNumber')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
							
						</Col>

						<Col lg={3}>
							<Form.Group className="mb-3" controlId="accountBranch">
								<Form.Control type="text" placeholder="Bank Name & Branch" defaultValue={formData.accountBranch} name="accountBranch" onChange={e => formHandler(e, 'accountBranch')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>							
						</Col>
						<Col lg={3}>							
							<Form.Group className="mb-3" controlId="accountIFSCCode">
								<Form.Control type="text" placeholder="IFSC Code" defaultValue={formData.accountIFSCCode} name="accountIFSCCode" onChange={e => formHandler(e, 'accountIFSCCode')} minLength="2" maxLength="64" autoComplete="none" required/>
							</Form.Group>
						</Col>
					</Row>

					<Row>
						<h4>KYC Detail Information</h4>
						<Col xs={12}>
							{kycList.length>0 && kycList.map((item, index)=>{
								
								return <div className="documentType" key={index}>								
											<Form.Group className="" controlId="formBasicCompanyType">											
												<Form.Control as="select" custom  className="form-control" value={item.documentType} name="documentType" onChange={e => formHandler(e, 'documentType', index)} required>
													<option value="">Select Ducument Type</option>
													{kycDocumentList.map((row, index)=>{
														return <option value={row.value} key={index}>{row.label}</option>
													})}
												</Form.Control>	
											</Form.Group>								
											<Form.Group controlId="formFile" className="selectFileOption mt-2">
												<Form.Label>Upload Document
													
												</Form.Label>
												<span><img src="assets/img/mountain.png" /></span>
												{item._id && item._id !== ''?<Form.Control type="file" accept="image/*" onChange={e => formHandler(e, 'image', index)} />:<Form.Control type="file" accept="image/*" onChange={e => formHandler(e, 'image', index)} required/>}
												
												
											</Form.Group>
											
											<Form.Group className="" controlId="formBasicCompanyType">&nbsp;&nbsp;
												{ kycList.length !== 1 && 
														<button type="button" onClick={() => handleRemoveClick(index)} className="btn-fill btn-wd btn btn-info">
															<i className="fa fa-minus" aria-hidden="true"></i>
														</button>
												}
												&nbsp;
												{ kycList.length - 1 === index && kycList.length < kycDocumentList.length && 
														<button type="button" onClick={handleAddClick} className="btn-fill btn-wd btn btn-info">
															<i className="fa fa-plus" aria-hidden="true"></i>
														</button>
												}                                                                           
                                      		</Form.Group>
											  {item.imgPath && item.imgPath !== '' &&
														<><br/><a href={item.imgPath} target="_blank">{item.imgPath}</a></>
													}	
										</div>
									})}
							
							
						</Col>
						
						<Col xs={12} md={6}>
							<Button className="secondaryBtn" variant="" type="submit" disabled={isDisabled}>Add Company Details</Button>
						</Col>
					</Row>
				</Form>
			</div>

		</Container>
	);
};

export default Companyinfo;
