import React,{useEffect, useState} from 'react';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import {getCTNFTListAction} from '../action/Api.action';
import SingleCTNFT from '../component/SingleCTNFT';

const CTNFT = ({ history }) => {

	const [ctnftList, setCTNFTList] = useState([]);
	const [ctnftFrom, setCTNFTFrom] = useState({page:0, limit:8});

	const getCtnftList = async()=>{
		
		let data = await getCTNFTListAction(ctnftFrom);
		
		if(data && data.code === 200){					
			
			setCTNFTList(data.data); 
		}  				
	}

	/* Api Calling */
	useEffect(() => {

		getCtnftList();

	},[]);

	return (
		<div>
			<Container>
				
				<div className="homeBanner burger">
					<Row>
						<Carousel>
							<Carousel.Item>
							    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="First slide" />
							    <Carousel.Caption>
							      <h2>Access the World's <br /> Carbon Credit Markets</h2>
							      <p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
							      <div className="bannerBtns">
								      <Button variant="" className="secondaryBtn">Contact us</Button>
								      <Button variant="" className="primaryBtn">Join Now</Button>
							    	</div>
							    </Carousel.Caption>
							</Carousel.Item>

							<Carousel.Item>
							    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="Second slide" />
							    <Carousel.Caption>
						    		<h2>Access the World's <br /> Carbon Credit Markets</h2>
							    	<p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
							    	<div className="bannerBtns">
								    	<Button variant="" className="secondaryBtn">Contact us</Button>
								    	<Button variant="" className="primaryBtn">Join Now</Button>
							    	</div>
							    </Carousel.Caption>
							</Carousel.Item>
							
							<Carousel.Item>
							    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="Third slide" />
							    <Carousel.Caption>
							    	<h2>Access the World's <br /> Carbon Credit Markets</h2>
							    	<p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
							    	<div className="bannerBtns">
								    	<Button variant="" className="secondaryBtn">Contact us</Button>
								    	<Button variant="" className="primaryBtn">Join Now</Button>
							    	</div>
							    </Carousel.Caption>
							</Carousel.Item>
						</Carousel>
					</Row>
				</div>

				<div className="producersTradersSec burger">
					<Row>
						<Col xs={12}>
							<h5 className="themeClr1">Top Trending CTNFT</h5>
							<h1>Most Trending CTNFT's from Producers/ Traders</h1>
						</Col>

						{ ctnftList.length>0 && ctnftList.map((item, index)=>{
							
							return (<SingleCTNFT data={item} key={index}/>)

						})}
						

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="requestsContent more">
								<img src="assets/img/lines.png" alt="First slide" />
								<p>+6 <br /> More</p>
								<div className="moreContent">
									<span>Top trending CTNFT's</span>
									<span className="fa fa-chevron-right"></span>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="liveAuctionSec burger">
					<Row>
						<Col xs={12}>
							<h5 className="themeClr1">Live Auction</h5>
							<h1>Recent & trending CTNFT's available for live auction</h1>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo">
									<img src="assets/img/live-auction.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-live-auction.png" alt="First slide" className="whiteBG" />
									<div><span>CCX-123ab-1232</span></div>
								</div>
								<p className="credit">100 Carbon credits</p>
								<p className="aud">10043.00PHAe AUD $ 10,000.00</p>
								<div className="bidInfo">
									<span>Total Bids: 109</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/live-auction.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-live-auction.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">92 Carbon credits</p>
								<p className="aud">9200.97 PHAe AUD $ 9,109.00</p>
								<div className="bidInfo">
									<span>Total bids: 106</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/live-auction.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-live-auction.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">106 Carbon credits</p>
								<p className="aud">10743.00 PHAe AUD $ 10,700.11</p>
								<div className="bidInfo">
									<span>Total bids: 105</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/live-auction.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-live-auction.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">200 Carbon credits</p>
								<p className="aud">21043.00 PHAe AUD $ 20,900.11</p>
								<div className="bidInfo">
									<span>Total bids: 101</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/live-auction.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-live-auction.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">110 Carbon credits</p>
								<p className="aud">11200.97 PHAe AUD $ 11,149.60</p>
								<div className="bidInfo">
									<span>Total bids: 101</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/live-auction.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-live-auction.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">103 Carbon credits</p>
								<p className="aud">10200.97 PHAe AUD $ 10,149.60</p>
								<div className="bidInfo">
									<span>Total bids: 100</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/live-auction.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-live-auction.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">134 Carbon credits</p>
								<p className="aud">13500.97 PHAe AUD $ 13,149.10</p>
								<div className="bidInfo">
									<span>Total bids: 99</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="requestsContent more">
								<img src="assets/img/lines.png" alt="First slide" />
								<p>+6 <br /> More</p>
								<div className="moreContent">
									<span>Top trending CTNFT's</span>
									<span className="fa fa-chevron-right"></span>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="directBuySec burger">
					<Row>
						<Col xs={12}>
							<h5 className="themeClr1">Fixed Price</h5>
							<h1>Recent & trending CTNFT's available for direct buy</h1>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo">
									<img src="assets/img/fixed-price.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-fixed-price.png" alt="First slide" className="whiteBG" />
									<div><span>CCX-123ab-1232</span></div>
								</div>
								<p className="credit">100 Carbon credits</p>
								<p className="aud">10043.00PHAe AUD $ 10,000.00</p>
								<div className="bidInfo">
									<span>Total Bids: 109</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/fixed-price.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-fixed-price.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">92 Carbon credits</p>
								<p className="aud">9200.97 PHAe AUD $ 9,109.00</p>
								<div className="bidInfo">
									<span>Total bids: 106</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/fixed-price.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-fixed-price.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">106 Carbon credits</p>
								<p className="aud">10743.00 PHAe AUD $ 10,700.11</p>
								<div className="bidInfo">
									<span>Total bids: 105</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/fixed-price.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-fixed-price.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">200 Carbon credits</p>
								<p className="aud">21043.00 PHAe AUD $ 20,900.11</p>
								<div className="bidInfo">
									<span>Total bids: 101</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/fixed-price.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-fixed-price.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">110 Carbon credits</p>
								<p className="aud">11200.97 PHAe AUD $ 11,149.60</p>
								<div className="bidInfo">
									<span>Total bids: 101</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/fixed-price.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-fixed-price.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">103 Carbon credits</p>
								<p className="aud">10200.97 PHAe AUD $ 10,149.60</p>
								<div className="bidInfo">
									<span>Total bids: 100</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="ctnftBox">
								<p className="likeShare">
									<span><i className="fa fa-heart"></i>72</span>
									<span className="share"><img src="assets/img/share.png" /></span>
								</p>
								<div className="ccxInfo timeInfo">
									<img src="assets/img/fixed-price.png" alt="First slide" className="colorBG" />
									<img src="assets/img/mouseover-fixed-price.png" alt="First slide" className="whiteBG" />
									<div className="timer">
										<ul>
											<li>03</li>
											<li>D</li>
										</ul>
										<ul>
											<li>23</li>
											<li>H</li>
										</ul>
										<ul>
											<li>59</li>
											<li>M</li>
										</ul>
										<ul>
											<li>09</li>
											<li>S</li>
										</ul>
									</div>
								</div>
								<p className="credit">134 Carbon credits</p>
								<p className="aud">13500.97 PHAe AUD $ 13,149.10</p>
								<div className="bidInfo">
									<span>Total bids: 99</span>
									<Button variant="" className="placeBidBtn">Place Bid</Button>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} lg={4} xl={3}>
							<div className="requestsContent more">
								<img src="assets/img/lines.png" alt="First slide" />
								<p>+6 <br /> More</p>
								<div className="moreContent">
									<span>Top trending CTNFT's</span>
									<span className="fa fa-chevron-right"></span>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="latestRequestSec burger">
					<Row>
						<Col xs={12}>
							<h5 className="themeClr1">Request for quotes</h5>
							<h1>Latest requests for quote from enterprises</h1>
						</Col>

						<Col xs={12} md={4} xl={3}>
							<div className="requestsContent">
								<img src="assets/img/top-trending.png" alt="First slide" />
								<p>100 Carbon <br /> credits require</p>
								<span>Posted on 30-07-2021, 01:45</span>
							</div>
						</Col>

						<Col xs={12} md={4} xl={3}>
							<div className="requestsContent">
								<img src="assets/img/live-auction.png" alt="First slide" />
								<p>92 Carbon <br /> credits require</p>
								<span>Posted on 30-07-2021, 01:45</span>
							</div>
						</Col>

						<Col xs={12} md={4} xl={3}>
							<div className="requestsContent">
								<img src="assets/img/live-auction.png" alt="First slide" />
								<p>106 Carbon <br /> credits require</p>
								<span>Posted on 30-07-2021, 01:45</span>
							</div>
						</Col>

						<Col xs={12} md={4} xl={3}>
							<div className="requestsContent">
								<img src="assets/img/top-trending.png" alt="First slide" />
								<p>200 Carbon <br /> credits require</p>
								<span>Posted on 30-07-2021, 01:45</span>
							</div>
						</Col>

						<Col xs={12} md={4} xl={3}>
							<div className="requestsContent">
								<img src="assets/img/live-auction.png" alt="First slide" />
								<p>110 Carbon <br /> credits require</p>
								<span>Posted on 30-07-2021, 01:45</span>
							</div>
						</Col>

						<Col xs={12} md={4} xl={3}>
							<div className="requestsContent">
								<img src="assets/img/top-trending.png" alt="First slide" />
								<p>109 Carbon <br /> credits require</p>
								<span>Posted on 30-07-2021, 01:45</span>
							</div>
						</Col>

						<Col xs={12} md={4} xl={3}>
							<div className="requestsContent">
								<img src="assets/img/fixed-price.png" alt="First slide" />
								<p>123 Carbon <br /> credits require</p>
								<span>Posted on 30-07-2021, 01:45</span>
							</div>
						</Col>

						<Col xs={12} md={4} xl={3}>
							<div className="requestsContent more">
								<img src="assets/img/lines.png" alt="First slide" />
								<p>+24 <br /> More</p>
								<div className="moreContent">
									<span>New requests listed</span>
									<span className="fa fa-chevron-right"></span>
								</div>
							</div>
						</Col>
					</Row>
				</div>

				<div className="postRequestSec burger">
					<Row>
						<Col xs={12}>
							<div className="postRequestContent">
								<img src="assets/img/cta-bottom.png" className="img-fluid" />
								<div className="img-caption">
									<Button variant="" className="requestBtn">Request for Quote</Button>
									<h2>Post Your Request With Us?</h2>
									<p>A Wonderful serenity has taken possession far away, behind the word mountains.</p>
									<Button variant="" className="secondaryBtn">Start Now</Button>
								</div>
							</div>
						</Col>
					</Row>
				</div>

			</Container>
		</div>
	);
};

export default CTNFT;
