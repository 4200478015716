import  { useEffect, useState } from "react";
import SubHeader from "../Subheader";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {  useParams, useHistory } from 'react-router-dom';
import { getParticularListing, createMintnftAction } from "../../action/walletApiAction";
import { useToast } from "../../context/Toast/useToast";
import { convertCurrency } from "../../utils/convertCurrency";
import StatusDialog from "./StatusDialog";
function MintNFT(props) {
  const history = useHistory()
  const [minttype, setMinttype] = useState('fixed')
  const [price, setPrice] = useState(0);
  const [convertedPrice, setConvertedPrice] = useState(0);
  const [symbol, setSymbol] = useState("USDC");
  const [currency, setCurrency] = useState("USD")
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [flagForModal, setFlagForModal] = useState("Minting")
  const [disablesubmit, setDisablesubmit] = useState(false);
  const [auction_length, setAuction_length] = useState(7);
  const [arrayauction, setArrayauction] = useState([7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]);
  const walletAddress = localStorage.getItem("wallet_address");
  const [noNftFound, setNoNftFound] = useState(false)

  const [fields, setFields] = useState({
    name: "",
    price: 0,
    current_price: 0,
    address: "",
    registry: "",
    project_type: "",
    total_credit: 0,
    saleType: minttype,
    creator_wallet: localStorage.getItem("wallet_address"),
    currentowner_wallet: "",
    certificate_id: "",
    accu_number: "",
    issale_active: false,
    isburn: false,
    isFreeze: false,
    image_url: "",
    discount: false,
    description: "",
    blockchain: "polygon",
    timezone: "",
    user_id: 0,
    pin: "",
    city: "",
    country: "",
    currency: ""
  });
  const mintType = (e) => {
    if (minttype !== e) {
      setConvertedPrice(0)
      setPrice(0)
    }
    setMinttype(e)
    setCurrency("USD")
    setSymbol("USDC")
  }
  useEffect(() => {
    setFields((p) => ({ ...p, saleType: minttype }))
  }, [minttype])

  const params = useParams()
  const { addToast } = useToast();
  const [nftDetails, setNftDetails] = useState({})
  const callGetParticularListing = async () => {
    let body = {}

    body.accu = params.accu
    const resp = await getParticularListing(body);
    if (resp.status === "success") {
      let res = resp.data.nftDetails;
      setFields((p) => ({
        ...p,
        name: res?.name,
        address: res?.address,
        registry: res?.issuing_body,
        project_type: res?.project_type,
        saleType: minttype,
        creator_wallet: walletAddress,
        currentowner_wallet: walletAddress,
        certificate_id: res?.certificate_id,
        accu_number: "" + res?.accu,
        create_date: res?.create_date,
        description: res?.description,
        total_credit: res?.credits,
        user_id: res?.user_id,
        id: res?.id,
        pin: "" + res?.postal_code,
        city: res?.city,
        state: res?.state,
        country: res?.country,
        image_url: "",
        currency: res?.currency,

      })
      );
      setNftDetails(resp.data.nftDetails)
    } else {
      setNoNftFound(true)
      addToast({
        message: resp.message,
        type: "error",
      });
    }
  }

  useEffect(() => {
    callGetParticularListing()

  }, [params])
  const getCurrency = async (symbol = "USDC", currency = "USD",) => {
    setCurrency(currency);
    const res = await convertCurrency(symbol, currency);
    if (price !== null) {
      for (const key in res) {
        setConvertedPrice(res[key] * price)
      }
    }
  }

  useEffect(() => {
    getCurrency(symbol, currency);

  }, [price, symbol,])

  useEffect(() => {
    setFields((pre) => ({ ...pre, price: price, current_price: price }))
  }, [price, symbol, convertedPrice])

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setDisablesubmit(true)
    if ((fields?.price) <= 0) {
      setDisablesubmit(false)
      return addToast({
        message: "Price should be greater than zero.",
        type: "error",
      });
    }
    setWalletModalOpen(true)
    try {
      let datanft = {
        ...fields,
        saleType: minttype,
        current_bidder: "",
        start_timestamp: "",
        end_timestamp: "",
        no_of_bids: "0",
        auction_duration: auction_length,
        current_price: price
      }
      let saledata = {};

      setFlagForModal("Market")
      await createMintnftAction(datanft).then(async (res1) => {
        setWalletModalOpen(false)
        setDisablesubmit(false)
        if (res1.status == "success") {
          history.push("/marketplace")
          addToast({
            message: "Your NFT has minted successfully!",
            type: "success",
          });
        }
        else {
          addToast({
            message: res1.message,
            type: "error",
          });
        }
      })

      saledata.auctionDuration = auction_length;
    } catch (err) {
      setWalletModalOpen(false);
      setDisablesubmit(false)
      console.log("nft page error", err)
      return addToast({
        message: err.message,
        type: "error",
      });
    }


  }

  const selectWalletConnect = () => {
    setWalletModalOpen(!walletModalOpen);
  };

  const flagForModalFunc = () => {
    setFlagForModal("Market")
  }

  return (
    <>
      <SubHeader />
      {noNftFound === false ?
        <Container fluid>
          <div className="quoteRequestSec CreateCTNFTSec burger">
            <Container>
              <Row className="justify-content-md-center">
                <Col sm={12}>
                  <Form
                    className="moreInfoSec formContent mintNFT boxShaddow"
                    noValidate
                  // validated={validated}
                  // onSubmit={(e) => handleSubmit(e)}
                  // onSubmit={(e) => handleSubmit2(e)}
                  >
                    <h1> Listing</h1>
                    <h2>Assets ID : <span>{nftDetails?.accu}</span>  </h2>

                    <hr className="w-100" />
                    <div className="mintpriceBox">
                      <h1>Type</h1>
                      <Row>
                        <Col lg={6}>
                          <div className="choosemintBox">
                            <button type="button" className={minttype === 'fixed' ? 'active' : ''} onClick={() => mintType('fixed')}> <div className="circle"></div> Fixed Price</button>
                            <button type="button" className={minttype === 'auction' ? 'active' : ''} onClick={() => mintType('auction')}><div className="circle"></div> Time Auction</button>
                          </div>
                        </Col>

                      </Row>
                      <h1>Price</h1>
                      {minttype === 'fixed' && (
                        <>
                          <Row>

                            <Col lg={6}>
                              <div className="enterAmountbox">
                                <div className="mainePrice">
                                  <Form.Control
                                    type="number"
                                    id="proj_title"
                                    placeholder="Price"
                                    name="project_title" className="pe-5"
                                    onChange={(e) => { setPrice(e.target.value !== "" ? e.target.value : 0) }}
                                    minLength="2"
                                    maxLength="64"
                                    autoComplete="off"
                                  />
                                  <img src="/images/svg/usdcIcon.svg" alt="" />
                                </div>
                                <div>
                                  <img src="/images/svg/exchangeArrow.svg" alt="" />

                                </div>
                                <div className="d-flex align-item-center gap-2">
                                  <Form.Control
                                    type="text" readOnly
                                    id="proj_title"
                                    placeholder="Price "
                                    name="project_title"
                                    value={Number(convertedPrice).toFixed(5)}

                                    minLength="2"
                                    maxLength="64"
                                    autoComplete="off"
                                  />
                                  <Form.Control
                                    as="select"
                                    className="form-select"
                                    id="proj_type"
                                    name="project_type"
                                    onChange={(e) => getCurrency("USDC", e.target.value)}
                                  >
                                    <option value="USD">USD</option>
                                    <option value="AUD">AUD</option>
                                    <option value="INR">INR</option>

                                  </Form.Control>
                                </div>
                              </div>
                              <p className="notetext">Service Fee 3.25%</p>
                              <p className="notetext">You Will Receive {(Number(convertedPrice) - Number(convertedPrice) * 0.0325).toFixed(5)} {currency}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="unloackBox">
                                <div>
                                  <h1>Unlock once purchased</h1>
                                  <p>Carbon Credit Certificates will be unlocked after successful transaction</p>
                                </div>
                                <div className="customeSwitch">
                                  <input type="checkbox" id="unlock" />
                                  <label htmlFor="unlock"></label>
                                </div>
                              </div>
                            </Col>

                          </Row>
                        </>
                      )}
                      {minttype === 'auction' && (
                        <>
                          <Row>
                            <Col lg={6}>
                              <Row>
                                <Col className="mb-3" lg={12}>
                                  <div className="enterAmountbox">
                                    <div className="mainePrice">
                                      <Form.Control
                                        type="number"
                                        id="proj_title"
                                        placeholder="Price per CTNFT"
                                        name="project_title"
                                        onChange={(e) => setPrice(e.target.value)}
                                        minLength="2"
                                        maxLength="64"
                                        autoComplete="off"
                                      />
                                      <img src="/images/svg/usdcIcon.svg" alt="" />
                                    </div>
                                    <div>
                                      <img src="/images/svg/exchangeArrow.svg" alt="" />

                                    </div>
                                    <div className="d-flex align-item-center gap-2">
                                      <Form.Control
                                        type="text" readOnly
                                        id="proj_title"
                                        placeholder="Price "
                                        name="project_title"
                                        value={Number(convertedPrice).toFixed(5)}
                                        minLength="2"
                                        maxLength="64"
                                        autoComplete="off"
                                      />
                                      <Form.Control
                                        as="select"
                                        className="form-select"
                                        id="proj_type"
                                        name="project_type"
                                        onChange={(e) => getCurrency("USDC", e.target.value)}
                                      >
                                        <option value="USD">USD</option>
                                        <option value="AUD">AUD</option>
                                        <option value="INR">INR</option>


                                      </Form.Control>
                                    </div>
                                  </div>
                                  <p className="notetext">Service Fee 3.25%</p>

                                </Col>
                                <Col lg={6}>
                                  <label>Auction length</label>
                                  <Form.Control
                                    as="select"
                                    className="form-select"
                                    id="proj_type"
                                    name="project_type"
                                    onChange={(e) => setAuction_length(e.target.value)}
                                  >
                                    {
                                      arrayauction.map((res) =>
                                        <option value={res}>{res} Days</option>
                                      )
                                    }
                                  </Form.Control>


                                </Col>
                              </Row>
                            </Col>



                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="unloackBox">
                                <div>
                                  <h1>Unlock once purchased</h1>
                                  <p>Carbon Credit Certificates will be unlocked after successful transaction</p>
                                </div>
                                <div className="customeSwitch">
                                  <input type="checkbox" id="unlock" />
                                  <label htmlFor="unlock"></label>
                                </div>
                              </div>
                            </Col>

                          </Row>
                        </>
                      )}
                      <Row>
                        <Col lg={6}>
                          <div className="moreInfoBtns">
                            <Button
                              className="secondaryBtn submitBtn"
                              variant=""
                              type="submit"
                              onClick={onSubmit}
                              disabled={disablesubmit}
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
        :
        "No NFT Found!!"
      }
      <StatusDialog flagForModalParam={flagForModalFunc} flagForModal={flagForModal} walletshow={walletModalOpen} walletFunc={selectWalletConnect}></StatusDialog>
    </>
  );
}

export default MintNFT;