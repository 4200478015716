import React, { useEffect, useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'

function StatusDialog(props) {
  const [showWallet, setShowWallet] = useState(false);
  const [flag, setFlag] = useState("Minting");
  useEffect(() => {
    // console.log("flag", flag)
    setShowWallet(props.walletshow);
    setFlag(props.flagForModal)
    
    // setShowFucn(props.walletFunc)
 
  }, [props, props.flagForModal]);
  const showFucn = () => {
    props.walletFunc();
  };

  return (
    <>
      <Modal className="walletBox" centered show={showWallet}>
        <div className="WalletDesign">
          {/* <button
            className="closeBtn"
            onClick={() => showFucn()}
            variant="secondary"
          >
            <img src="/images/svg/closeBtn.svg" alt="close" />
          </button> */}
          {flag !== "Market" && <>
          <div className="walletInner">
          <Spinner animation="border" />


            
              <h1>Minting NFT...</h1>
              <p> Your NFT is being minted. Please be patient while we create your unique digital asset.</p>
           

            {/* <Button
                  className="btn walletBtn"
                
                >
                  Create a New Wallet
                </Button> */}
          </div>
          </>
            }
            {flag === "Market" && <>
          <div className="walletInner">
          <Spinner animation="border" />
            {/* <img src="/images/png/Success.png" width={150} alt="Wallet" /> */}
            {/* <img src="/images/png/failed.png" width={150} alt="Wallet" /> */}
          
              <h1>Processing NFT</h1>
              <p> We're making your NFT live on the marketplace. Hang tight while we finalize the process.</p>
         

            {/* <Button
                  className="btn walletBtn"
                
                >
                  Create a New Wallet
                </Button> */}
          </div>
          </>
            }

        </div>
      </Modal>
    </>
  )
}

export default StatusDialog