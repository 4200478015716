import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
const Ourmission = ({ data }) => {
    const images = ["/images/png/producer.png", "/images/png/trader.png", "/images/png/consumer.png"]
    return (
        <div className="container-fluid" >

            <div className=' aboutBannerDiv' >
                
                        <div className='aboutBannerBox' >
                            <div className=' aboutTitleBox'> <p className='aboutBannerTitle centerTitle'>Our Mission</p></div>
                           
                            <p className='aboutBannerSubTitle '>At Phaeton, we are committed to empowering the future by harnessing hyper-scalable technology to achieve a Zero-Carbon footprint network. We believe in a sustainable world where carbon trading is accessible, transparent, and efficient for all participants. Our mission is to provide a decentralized and secure platform for trading carbon credits, promoting global efforts to reduce greenhouse gas emissions.</p>
                            </div>
                       
               
                 
            </div>
            
                        <div className="row aboutMissionImgBox">
                               <div className="col-md-2  ourMissionImgBox">
                                 <img className="aboutMissionDivImg" src="/images/png/Rectangle26807.png" />
                               </div>
                               <div className="col-md-2  ourMissionImgBox">
                               <img className="aboutMissionDivImg" src="/images/png/Rectangle26808.png" />

                               </div>
                               <div className="col-md-2  ourMissionImgBox">
                                 <img className="aboutMissionDivImg" src="/images/png/Rectangle26809.png" />
                               </div>
                               <div className="col-md-2  ourMissionImgBox">
                               <img className="aboutMissionDivImg" src="/images/png/Rectangle26810.png" />

                               </div>
                               <div className="col-md-2  ourMissionImgBox">
                               <img className="aboutMissionDivImg" src="/images/png/Rectangle26811.png" />

                               </div>
                             </div>
                        
        </div>
    );
};

export default Ourmission;
