import React,{useEffect, useState} from 'react';

const Timer = ({data}) => {
	//console.log(data);

	const [ctnft, setCTNFT] = useState({});

	/* Api Calling */
	useEffect(() => {

		let nftData = data;
		
		setCTNFT(nftData);
		if(data.auctionStatus === "timed"){
			timer(data.endTime, "ctnft-"+data._id);
		}

	},[data]);

	const timer = (endDate, id, type='live')=>{
        // Get today's date and time
        let now = new Date().getTime();
        let countDownDate = new Date(endDate).getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));

        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

        let seconds = Math.floor((distance % (1000 * 60)) / 1000);    
        let html = ``;
        // Display the result in the element with id="demo"
        if(document.getElementById(id)){
                             
			if(days>0){
				html += `<ul>
							<li>`+(days<10?'0':'')+days+`</li>
							<li>D</li>
						</ul>`;
			}

				html += `<ul>
							<li>`+(hours<10?'0':'')+hours+`</li>
							<li>H</li>
						</ul>
						<ul>
							<li>`+(minutes<10?'0':'')+minutes+`</li>
							<li>M</li>
						</ul>
						<ul>
							<li>`+(seconds<10?'0':'')+seconds+`</li>
							<li>S</li>
						</ul>`;
			
            document.getElementById(id).innerHTML = html;
     
      	}

        // If the count down is finished, write some text
        if (distance < 0) {
           
            // if(type === 'highBid'){
            //     getHomeSingleProductData();
            // }else{
                
            //    // get_ProductList();
            // }
            
        }else{
            
            setTimeout(()=>{
            timer(endDate, id, type);
            //console.log(endDate);
            //console.log(id);
            },1000);
        }
    }

	return (<div className="timer" id={"ctnft-"+ctnft._id}></div>);
};

export default Timer;
