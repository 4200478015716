import React from "react";
import BannerSec from "../component/HomeNew/Banner";
import AboutSec from "../component/HomeNew/About";
import FeatureSec from "../component/HomeNew/Features";
import Howitworks from "../component/HomeNew/Howitworks";
import Explore from "../component/HomeNew/Explore";
import Contact from "../component/HomeNew/Contact";
import Footer from "../component/HomeNew/Footer";
import Contactnew from "../component/HomeNew/Contactnew";

const Home = ({ history }) => {


  
 return (
  <>
   <BannerSec />
  <AboutSec /> 
   <FeatureSec /> 
   <Howitworks/> 
   <Explore/> 
   {/* <Contact/>  */}
  <Contactnew/>
  <Footer/>
     
  </>
  );
};

export default Home;
