import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  HashRouter,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import history from "./api/history";
// import Home from "./pages/Home";
import Home from "./pages/HomeNew";
import About from "./pages/Aboutus";
import Login from "./pages/Login";
import Explore from "./pages/Explore";
import CTNFT from "./pages/CTNFT";
import Projects from "./pages/Projects";
import Company from "./pages/Company";
import Newmedia from "./pages/Newmedia";
import Contact from "./pages/Contact";
import SignUp from "./pages/SignUp";
import Verification from "./pages/Verification";
import Verifysuccessfully from "./pages/Verifysuccessfully";
import Successfully from "./pages/Successfully";
import Companyinfo from "./pages/Companyinfo";
import Polluteragreement from "./pages/Polluteragreement";
import Quoterequest from "./pages/Quoterequest";
import CreateCTNFT from "./pages/CreateCTNFT";
import ListingCTNFT from "./pages/ListingCTNFT";
import PendingCTNFT from "./pages/ListingCTNFT";
import Placebid from "./pages/Placebid";
import CtnftDetail from "./pages/CtnftDetail";
import CreatemarketplaceListing from "./pages/CreatemarketplaceListing";
import ForgotPassword from "./common/ForgotPassword";
import ResetPassword from "./common/ResetPassword";
import Polluterdashboard from "./pages/Polluterdashboard";
import Order from "./pages/Order";
import EditProfile from "./pages/EditPage";
import MyCollection from "./pages/MyCollection";
import Resell from "./pages/ReSell";
import BurnOrder from "./pages/BurnOrder";
import Withdraw from "./pages/Withdraw";
import EditCTNFT from "./pages/EditCTNFT";
import Dashboard from "./pages/Dashboard";
import "./App.css";

// import Dashboard from "./admin/pages/Dashboard";
import ManageProducers from "./admin/pages/ManageProducers";
import AddNewProducers from "./admin/pages/AddNewProducers";
import VerifyCompanyDetails from "./admin/pages/VerifyCompanyDetails";
import ManageDocument from "./admin/pages/ManageDocument";
import BusinessEntity from "./admin/pages/BusinessEntity";
import TransactionFees from "./admin/pages/TransactionFees";
import Users from "./admin/pages/Users";
import SaleReport from "./admin/pages/SaleReport";
import Ctnftlisting from "./admin/pages/Ctnftlisting";
import Settings from "./admin/pages/Settings";
import ChangePassword from "./admin/pages/ChangePassword";
import Profile from "./admin/pages/Settings";
import Ledger from "./admin/pages/Ledger";
import Registration from "./pages/Registration";
import Layout from "./Layout";
import { ToastProvider } from "./context/Toast";
import { LoaderProvider } from "./context/Loader";
import Cart from "./pages/Cart";
import MyOrder from "./pages/MyOrder";
import Cms from "./pages/Cms";
import DeList from "./pages/De-ListCTNFT";
import Page404 from "./pages/page-404";
import WalletMaine from './component/Walletmodule'
import WalletDashboard from './component/Walletmodule/Dashboard'
import WalletDeposit from './component/Walletmodule/Deposit'
import WalletWithdrawal from './component/Walletmodule/Withdrawal'
import WalletTransectionHistory from './component/Walletmodule/TrasectionHistory'
import WalletChanagePassword from './component/Walletmodule/ChangePassword'
import WalletNotFound from "./component/WalletNotFound";
import OfferMade from "./component/SellerActivity/Offermade";
import MintNFT from "./component/MintNFT";
import Marketplace from "./pages/Marketplace";
import ApprovedCTNFT from "./pages/ApprovedCTNFT";
import ViewNFT from "./pages/ViewNFT";
import ViewProfile from "./pages/ViewProfile";
import ViewCTNFTByList from "./pages/ViewCTNFTByList";
import ComingSoon from "./pages/ComingSoon";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFail from "./pages/PaymentFail";
import Contactus from "./pages/Contactus";



//let persistor = persistStore(store);

function App() {
  return (
    <div>
      <Provider store={store}>
        {/* <HashRouter history={history}> */}
        <Router>
          <Switch>
            <ToastProvider>
              <LoaderProvider>
                <Layout>
                  <Route
                    exact
                    path="/"
                    render={(props) => <Home {...props} />}
                  />
                  {/* <Route
                    exact
                    path="/"
                    render={(props) => <CreateCTNFT {...props} />}
                  /> */}
                   <Route
                    exact
                    path="/about"
                    render={(props) => <About {...props} />}
                  />
                   <Route
                    exact
                    path="/offermade"
                    render={(props) => <OfferMade {...props} />}
                  />
                  {/* <Route
                    exact
                    path="/:pageType"
                    render={(props) => <Home {...props} />}
                  /> */}
                 
                 
                  <Route
                    path="/page/:pageId"
                    render={(props) => <Cms {...props} />}
                  />
                  <Route
                    exact
                    path="/comingsoon"
                    render={(props) => <ComingSoon {...props} />}
                  />
                  <Route
                    exact
                    path="/login"
                    render={(props) => <Login {...props} />}
                  />
                   <Route
                    exact
                    path="/create-wallet"
                    render={(props) => <WalletNotFound {...props} />}
                  />
                     <Route
                    exact
                    path="/payment-success"
                    render={(props) => <PaymentSuccess {...props} />}
                  />
                      <Route
                    exact
                    path="/payment-fail"
                    render={(props) => <PaymentFail {...props} />}
                  />
                   {/* <Route index  path='/'   render={(props) =><WalletMaine  {...props}/>}></Route> */}
                   <Route path="/wallet/dashboard"   render={(props) =><WalletDashboard  page={'dashboard'} {...props}/>} />
                   <Route path="/wallet/deposit"   render={(props) =><WalletDeposit page={'deposit'}   {...props}/>} />
                   <Route path="/wallet/withdrawal"   render={(props) =><WalletWithdrawal page={'withdrawal'}   {...props}/>} />
                   <Route path="/wallet/transection"   render={(props) =><WalletTransectionHistory page={'transection'}   {...props}/>} />
                   <Route path="/wallet/changePassord"   render={(props) =><WalletChanagePassword page={'changePassord'}   {...props}/>} />


                  {/* <Route
                    exact
                    path="/wallets_new"
                    render={(props)=><Wallet {...props} />}
                  /> */}
                  <Route
                    exact
                    path="/my-collection"
                    render={(props) => <MyCollection {...props} />}
                  />
                  <Route
                    exact
                    path="/resell"
                    render={(props) => <Resell {...props} />}
                  />
                  <Route
                    exact
                    path="/de-listingCTNFT"
                    render={(props) => <DeList {...props} />}
                  />
                  <Route
                    exact
                    path="/burn-orders"
                    render={(props) => <BurnOrder {...props} />}
                  />
                  <Route
                    exact
                    path="/edit-ctnft"
                    render={(props) => <EditCTNFT {...props} />}
                  />
                  <Route
                    exact
                    path="/my-orders"
                    render={(props) => <MyOrder {...props} />}
                  />
                  <Route
                    exact
                    path="/withdraw"
                    render={(props) => <Withdraw {...props} />}
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    render={(props) => <ForgotPassword {...props} />}
                  />
                  <Route
                    exact
                    path="/reset-password"
                    render={(props) => <ResetPassword {...props} />}
                  />
                  <Route
                    exact
                    path="/edit-profile"
                    render={(props) => <EditProfile {...props} />}
                  />
                    <Route
                    exact
                    path="/view-profile"
                    render={(props) => <ViewProfile {...props} />}
                  />

                  <Route
                    exact
                    path="/ctnft"
                    render={(props) => <CTNFT {...props} />}
                  />
                  <Route
                    exact
                    path="/projects"
                    render={(props) => <Projects {...props} />}
                  />
                  <Route
                    exact
                    path="/company"
                    render={(props) => <Company {...props} />}
                  />
                  <Route
                    exact
                    path="/newmedia"
                    render={(props) => <Newmedia {...props} />}
                  />
                  <Route
                    exact
                    path="/contact"
                    render={(props) => <Contact {...props} />}
                  />
                                    <Route
                    exact
                    path="/contactus"
                    render={(props) => <Contactus {...props} />}
                  />

                  <Route
                    exact
                    path="/SignUp"
                    render={(props) => <SignUp {...props} />}
                  />
                  <Route
                    exact
                    path="/verification"
                    render={(props) => <Verification {...props} />}
                  />
                  <Route
                    exact
                    path="/success"
                    render={(props) => <Successfully {...props} />}
                  />
                  <Route
                    exact
                    path="/verifysuccessfully"
                    render={(props) => <Verifysuccessfully {...props} />}
                  />
                  <Route
                    exact
                    path="/companyinfo"
                    render={(props) => <Companyinfo {...props} />}
                  />

                  <Route
                    exact
                    path="/agreement"
                    render={(props) => <Polluteragreement {...props} />}
                  />
                  <Route
                    exact
                    path="/dashboard"
                    render={(props) => <Dashboard {...props} />}
                  />
                  <Route
                    exact
                    path="/marketplace"
                    render={(props) => <Marketplace {...props} />}
                  />
                   <Route
                    exact
                    path="/viewctnft/:nft_id"
                    render={(props) => <ViewNFT {...props} />}
                  />
                  
                  <Route
                    exact
                    path="/cart"
                    render={(props) => <Cart {...props} />}
                  />
                  <Route
                    exact
                    path="/quoterequest"
                    render={(props) => <Quoterequest {...props} />}
                  />
                  <Route
                    exact
                    path="/createCNFT"
                    render={(props) => <CreateCTNFT {...props} />}
                  />
                  <Route
                    exact
                    path="/approvedCNFT"
                    render={(props) => (
                      <ApprovedCTNFT {...props} status="active" />
                    )}
                  />
                  <Route
                    exact
                    path="/listedCNFT"
                    render={(props) => (
                      <ApprovedCTNFT {...props} status="listed"  />
                    )}
                  />
                  <Route
                    exact
                    path="/soldCNFT"
                    render={(props) => (
                      <ApprovedCTNFT {...props} status="sold" />
                    )}
                  />
                  <Route
                    exact
                    path="/boughtCNFT"
                    render={(props) => (
                      <ApprovedCTNFT {...props} status="bought" />
                    )}
                  />
                  <Route
                    exact
                    path="/ownedCNFT"
                    render={(props) => (
                      <ApprovedCTNFT {...props} status="owned" />
                    )}
                  />
                  
                   <Route
                    exact
                    path="/retireCNFT"
                    render={(props) => (
                      <ApprovedCTNFT {...props} status="retire" />
                    )}
                  />
                   <Route
                    exact
                    path="/listingCNFT"
                    render={(props) => (
                      <ListingCTNFT {...props} status="active" />
                    )}
                  />
                    <Route
                    exact
                    path="/mintCNFT/:accu"
                    render={(props) => (  <MintNFT {...props}  /> )}
                  />


                  <Route
                    exact
                    path="/pendingCNFT"
                    render={(props) => (
                      <ListingCTNFT {...props} status="inactive" />
                    )}
                  />
                
                  <Route
                    exact
                    path="/placebid"
                    render={(props) => <Placebid {...props} />}
                  />
                   <Route
                    exact
                    path="/view-CTNFT"
                    render={(props) => <ViewCTNFTByList {...props} />}
                  />

                  <Route
                    exact
                    path="/ctnft-detail"
                    render={(props) => <CtnftDetail {...props} />}
                  />
                  <Route
                    exact
                    path="/CreatemarketplaceListing"
                    render={(props) => <CreatemarketplaceListing {...props} />}
                  />
                  <Route
                    exact
                    path="/explore"
                    render={(props) => <Explore {...props} />}
                  />

                  <Route
                    exact
                    path="/buy-cnft"
                    render={(props) => <Order {...props} />}
                  />
                  <Route
                    exact
                    path="/registration"
                    render={(props) => <Registration {...props} />}
                  />

                  {/* start admin routing */}
                  <Route
                    exact
                    path="/admin/dashboard"
                    render={(props) => <Dashboard {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/manageProducers"
                    render={(props) => <ManageProducers {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/addNewProducers"
                    render={(props) => <AddNewProducers {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/verifyCompanyDetails"
                    render={(props) => <VerifyCompanyDetails {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/manageDocument"
                    render={(props) => <ManageDocument {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/businessEntity"
                    render={(props) => <BusinessEntity {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/transactionFees"
                    render={(props) => <TransactionFees {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/users"
                    render={(props) => <Users {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/report"
                    render={(props) => <SaleReport {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/ctnftListing"
                    render={(props) => <Ctnftlisting {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/settings"
                    render={(props) => <Settings {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/ledger"
                    render={(props) => <Ledger {...props} />}
                  />

                  <Route
                    exact
                    path="/admin/changePassword"
                    render={(props) => <ChangePassword {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/profile"
                    render={(props) => <Profile {...props} />}
                  />
                  {/* <Route
                    // exact
                    path="*"
                    // component={Page404}
                    // render={(props) => <Page404 {...props} />}
                  >
                    <Page404 />
                  </Route> */}
                </Layout>
              </LoaderProvider>
            </ToastProvider>
          </Switch>
        </Router>
        {/* </HashRouter> */}
      </Provider>
    </div>
  );
}
export default App;
