import  { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap'
import { burnNftAction } from "../../action/walletApiAction";
import { useToast } from "../../context/Toast/useToast";
import { useHistory } from 'react-router-dom';
import CustomLoader from "../../common/customLoader";
function RetireNFT(props) {
  const [showWallet, setShowWallet] = useState(true);
  const [gasFee, setGasFee] = useState(0.549)
  const [nft, setNft] = useState()
  const { addToast } = useToast();
  const [IsLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const [fields, setFields] = useState({
    wallet_address: localStorage.getItem("wallet_address"),
    tokenId: '',
    gas_fee: gasFee,
    certificate_id: "",
    user_id:localStorage.getItem("userId"), 
    nft_id:"",
    sale_type:""
  })
  useEffect(() => {
    console.log("fields", fields)
  }, [fields])
  useEffect(() => {
    console.log("dwdasds", nft)
    setFields((p) => ({ ...p, certificate_id: nft?.certificate_id, tokenId: nft?.token_id, nft_id:nft?.id, sale_type:nft?.saletype }))
  }, [nft])
  useEffect(() => {
    setShowWallet(props.statusshow);
    setNft(props.nftDet)
    // setShowFucn(props.walletFunc)
    console.log('deepak', props);
  }, [props]);
  const showFucn = () => {
    props.StatusFunc();
  };

  const handleRetireNft = async () => {
    setIsLoading(true)
    const burnRes = await burnNftAction(fields)
    setIsLoading(false)
    if (burnRes.Status === "Error") {

      addToast({
        message: burnRes.message,
        type: "error",
      });
    } else {
      props.StatusFunc();
      addToast({
        message: "NFT Burn Successfully.",
        type: "success",
      });
      history.push({pathname:`/viewctnft/${nft?.id}`, state:{"view":"retire"}})
      // // history.push({pathname:`/viewctnft/${nft?.id}`, state:{"view":"retire"}});
      return (history.push({pathname:`/viewctnft/${nft?.id}`, state:{"view":"retire"}})
          // <Nav.Link as={Link} to={{ pathname: "/retireCNFT", state: { page: "NFTs", pagename: 'Retired NFTs' } }}>Retired NFTs</Nav.Link>
      )
    }
  }
  return (
    <>{
      IsLoading && (
        <div>
          <CustomLoader />
        </div>)}

      <Modal className="kycstatusdialog " size="lg" centered show={showWallet}>

        <div className="kycstatusBox position-relative pt-5">
          <button
            className="closeBtn btn position-absolute top-0 end-0"
            onClick={() => showFucn()}
            variant="secondary"
          >
            <img src="/images/svg/closeBtn.svg" alt="close" />
          </button>
          <img src="/images/svg/retireImage.svg" alt="" width={150} />
          <h1 className="mt-4">Please Confirm</h1>
          <p>That you intend to retire the NFT and download all carbon certificates</p>
          <button onClick={handleRetireNft} className="primaryBtnNew  mx-auto w-auto btn px-5">Retired NFT</button>
        </div>
      </Modal>
    </>
  )
}


export default RetireNFT