import CustomLoader from "../../common/customLoader";
import React from "react";

export interface LoaderContextProps {
  setLoading: (isLoading: boolean, text?: string) => void;
}

const LoaderContext = React.createContext<LoaderContextProps>({
  setLoading: () => {},
});

const LoaderProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState("Loading...");

  return (
    <LoaderContext.Provider
      value={{
        setLoading: (isLoading: boolean, text?: string) => {
          setIsLoading(isLoading);
          setLoadingText(text ?? "Loading...");
          console.trace("setLoading", isLoading, text);
        },
      }}>
      {isLoading && <CustomLoader title={loadingText} />}
      {children}
    </LoaderContext.Provider>
  );
};

export { LoaderContext, LoaderProvider };
