import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    homeData: [],
    popularTopic: [],
    recentTopics: [],
    posts: [],
    byTopic: {},
    successData: {},
    adminPathName: "",
    masterDetail: null,
    quoteData: null,
    cartData: [],
    dapp: 0,
    resubmission: false,
    headerList: [],
    previous: false,
    footer: true,
  },
  reducers: {
    setTopCategorie: (state, action) => {
      state.homeData = action.payload;
    },
    setPopularTopic: (state, action) => {
      state.popularTopic = action.payload;
    },
    setRecentTopics: (state, action) => {
      state.recentTopics = action.payload;
    },
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setByTopicCategory: (state, action) => {
      state.byTopic = action.payload;
    },
    setSuccessData: (state, action) => {
      state.successData = action.payload;
    },
    setadminPathName: (state, action) => {
      state.adminPathName = action.payload;
    },
    setMasterData: (state, action) => {
      state.masterDetail = action.payload;
    },
    setQuoteData: (state, action) => {
      state.quoteData = action.payload;
    },
    setCartData: (state, action) => {
      state.cartData = action.payload;
    },
    setDapp: (state, action) => {
      state.dapp = action.payload;
    },
    setResubmission: (state, action) => {
      state.resubmission = action.payload;
    },
    setHeaderList: (state, action) => {
      state.headerList = action.payload;
    },
    setPrevious: (state, action) => {
      state.previous = action.payload;
    },
    setFooter: (state, action) => {
      state.footer = action.payload;
    },
  },
});

export const {
  setTopCategorie,
  setPopularTopic,
  setRecentTopics,
  setPosts,
  setByTopicCategory,
  setSuccessData,
  setadminPathName,
  setMasterData,
  setQuoteData,
  setCartData,
  setDapp,
  setHeaderList,
  setEditDapp,
  setPrevious,
  setFooter,
  setResubmission,
} = homeSlice.actions;

export default homeSlice.reducer;
