import React, {useEffect} from 'react';
import { Container, Row, Col, Carousel, Image, Navbar, Form, Select, DropdownButton, Dropdown, Button, Card } from 'react-bootstrap';

import Header from '../component/Header';
import Footer from '../component/Footer';

const Polluterdashboard = ({ history }) => {


	/* Api Calling */
	useEffect(() => {},[]);

	return (
		<Container>
			
			<div className="PolluterdashboardSec burger">
				<Row>
					<Col xs={12}>
						<div className="dashboardHead">

						</div>
					</Col>
					
					<Col xs={12} md={4}>
						<div className="dashboardLiveStatus">

						</div>
					</Col>
					<Col xs={12} md={8}>
						<div className="dashboardLiveStatus">

						</div>
					</Col>

					<Col xs={12} md={4}>
						<div className="dashboardLiveStatus">

						</div>
					</Col>
					<Col xs={12} md={8}>
						<div className="dashboardLiveStatus">

						</div>
					</Col>

					<Col xs={12} md={4}>
						<div className="dashboardLiveStatus">

						</div>
					</Col>
					<Col xs={12} md={4}>
						<div className="dashboardLiveStatus">

						</div>
					</Col>
					<Col xs={12} md={4}>
						<div className="dashboardLiveStatus">

						</div>
					</Col>
				</Row>
			</div>

			<div className="CTNFTrequestQuote tableListing boxShaddow burger">
				<h4>CTNFT Request For Quote</h4>

				<div className="tableOpton">
				</div>

				<div className="listing">
					<ul className="tableHead">
						<li className="srNo">Sr. No</li>
						<li className="qtyReq">Quantity Require</li>
						<li className="date">Start Date</li>
						<li className="date">End Date</li>
						<li className="PlluterBuyer">Polluter/Buyer</li>
						<li className="numeric buyPrice">Proposed Buying Price ($)</li>
						<li className="numeric buyPrice">Price as per last 30 days ($)</li>
						<li className="listingStatus">Listing Status</li>
					</ul>
					<ul className="tableBody">
						<li className="srNo">1</li>
						<li className="qtyReq">10 CTNFT</li>
						<li className="date">16-Jun-2021</li>
						<li className="date">16-Jun-2021</li>
						<li className="PlluterBuyer">Boston Trading Company</li>
						<li className="numeric buyPrice">$ 130, 000</li>
						<li className="numeric buyPrice">$ 135, 000</li>
						<li className="listingStatus"><Button className="primaryBtn" variant="" type="submit">Select Status</Button></li>
					</ul>
					<ul className="tableBody">
						<li className="srNo">2</li>
						<li className="qtyReq">10 CTNFT</li>
						<li className="date">16-Jun-2021</li>
						<li className="date">16-Jun-2021</li>
						<li className="PlluterBuyer">Boston Trading Company</li>
						<li className="numeric buyPrice">$ 130, 000</li>
						<li className="numeric buyPrice">$ 135, 000</li>
						<li className="listingStatus"><Button className="primaryBtn" variant="" type="submit">Select Status</Button></li>
					</ul>
					<ul className="tableBody">
						<li className="srNo">3</li>
						<li className="qtyReq">10 CTNFT</li>
						<li className="date">16-Jun-2021</li>
						<li className="date">16-Jun-2021</li>
						<li className="PlluterBuyer">Boston Trading Company</li>
						<li className="numeric buyPrice">$ 130, 000</li>
						<li className="numeric buyPrice">$ 135, 000</li>
						<li className="listingStatus"><Button className="primaryBtn" variant="" type="submit">Select Status</Button></li>
					</ul>
					<ul className="tableBody">
						<li className="srNo">4</li>
						<li className="qtyReq">10 CTNFT</li>
						<li className="date">16-Jun-2021</li>
						<li className="date">16-Jun-2021</li>
						<li className="PlluterBuyer">Boston Trading Company</li>
						<li className="numeric buyPrice">$ 130, 000</li>
						<li className="numeric buyPrice">$ 135, 000</li>
						<li className="listingStatus"><Button className="primaryBtn" variant="" type="submit">Select Status</Button></li>
					</ul>
					<ul className="tableBody">
						<li className="srNo">5</li>
						<li className="qtyReq">10 CTNFT</li>
						<li className="date">16-Jun-2021</li>
						<li className="date">16-Jun-2021</li>
						<li className="PlluterBuyer">Boston Trading Company</li>
						<li className="numeric buyPrice">$ 130, 000</li>
						<li className="numeric buyPrice">$ 135, 000</li>
						<li className="listingStatus"><Button className="primaryBtn" variant="" type="submit">Select Status</Button></li>
					</ul>
				</div>
			</div>

			<div className="CTNFTrequestQuote tableListing boxShaddow burger">
				<h4>My CTNFT Listing</h4>

				<div className="tableOpton">
				</div>

				<div className="listing">
					<ul className="tableHead">
						<li className="srNo">Sr. No</li>
						<li className="qtyReq">Quantity Require</li>
						<li className="date">Start Date</li>
						<li className="date">End Date</li>
						<li className="PlluterBuyer">Producers/Traders</li>
						<li className="assetsId">Assets ID ($)</li>
						<li className="numeric sellPrice">Min. Selling Price</li>
						<li className="numeric sellPrice">Avg. Price 30 days</li>
						<li className="listingStatus">Listing Status</li>
						<li className="action">Action</li>
					</ul>
					<ul className="tableBody">
						<li className="srNo">1</li>
						<li className="qtyReq">10 CTNFT</li>
						<li className="date">16-Jun-2021</li>
						<li className="date">16-Jun-2021</li>
						<li className="PlluterBuyer">ABC Green Energy Inc.</li>
						<li className="assetsId">ERF-98575-2021-3421</li>
						<li className="numeric sellPrice">$ 130, 000</li>
						<li className="numeric sellPrice">$ 135, 000</li>
						<li className="listingStatus">Approval Awaited</li>
						<li className="action">
							<Button className="primaryBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>
						</li>
					</ul>
				</div>
			</div>

		</Container>
	);
};

export default Polluterdashboard;
