import React, { useEffect, useState } from 'react'
import { useHistory, Link } from "react-router-dom";
import { convertCurrency } from '../../utils/convertCurrency';
import { Country} from 'country-state-city';
import ReactCountryFlag from "react-country-flag";

function NFTcard(props) {
    const history = useHistory();
    const [timeZone, setTimeZone] = useState('')
    const [dollarExchangeRate, setDollarExchangeRate] = useState("USD")
    const [nft, SetNft] = useState()


    useEffect(() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeZone(timeZone)
        console.log(props);
        SetNft(props.NFTType)
        return () => {
            SetNft([]);
        };
    }, [props])

    const getCountry =  (countryCode) => {
            console.log('Ashmin Code to fetch country')
            var country = Country.getCountryByCode(countryCode)
            if (country )
            return country.name
            else return '' 
    }
    const getCurrency = async () => {
        const res = await convertCurrency("USDC", "USD");
        setDollarExchangeRate(res.USD)
    }

    useEffect(() => {
        getCurrency();
    }, [])
    const formatDate = (nft) => {
        if (nft?.end_timestamp) {
            // console.log("end_timestamp", nft?.end_timestamp)
            const endDate = new Date(parseInt(nft?.end_timestamp, 10));
            // console.log("end_timestamp", endDate)
            const diffMillis = endDate - Date.now();
            const days = Math.floor(diffMillis / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diffMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); const minutes = Math.floor((diffMillis % (1000 * 60 * 60)) / (1000 * 60));
            // console.log("days", days, "hours", hours, "minutes", minutes)
            return `${days} days ${hours} hours ${minutes} min`;
        }
        
    }
    return (
        <>
            <div className='NFTCardBox'>
                <div className='imageBox'>
                    <img src={nft?.nftImages?.length > 0 ? nft?.nftImages[0]?.image : ""} alt='' />

                    {nft?.sell_type === 'fixed' ? (
                        ''
                    ) : (<div className='auctionLabel'>Ends in&nbsp;
                            {/* {new Date(Number(nft?.end_timestamp)).toLocaleString('en-US', { timeZone: timeZone })} */}
                            
                            {/* {`${Math.floor((nft?.end_timestamp - Date.now()) / (1000 * 60 * 60 * 24))} days ${new Date(nft?.end_timestamp - Date.now()).getUTCHours()} hours ${new Date(nft?.end_timestamp - Date.now()).getMinutes()} min`} */}
                            {/* {`${Math.floor((nft?.end_timestamp - Date.now()) / (1000 * 60 * 60 * 24))} days ${new Date(nft?.end_timestamp - Date.now()).getUTCHours()} hours ${new Date(nft?.end_timestamp - Date.now()).getMinutes()} min`} */}
                        {formatDate(nft)}
                        </div>)}
                </div>
                <div className='NFTDetails'>
                    
                    <div style={{display : 'flex', flexDirection: 'row'}}>
                    <h1 style={{display : 'inline',textAlign:'left'}}>{nft?.name}</h1>
                    <ReactCountryFlag countryCode={nft?.country} style={{display : 'inline' ,fontSize: "2em",marginLeft : 'auto'}} />
                    </div>
                    <p>{new Date(nft?.create_date).toLocaleDateString('en-GB', { timeZone })}, {getCountry(nft?.country)}</p>
                
                    <table>
                        <tr>
                            <td>Price</td>
                            <td><img width={18} src="/images/svg/usdcIcon.svg" alt="" />  {Number(nft?.current_price).toFixed(5) + "   "}  (${(dollarExchangeRate * nft?.current_price).toFixed(5)})</td>
                        </tr>
                        <tr>
                            <td>Project Type</td>
                            <td>{nft?.project_type}</td>
                        </tr><tr>
                            <td>Total Credit</td>
                            <td>{nft?.total_credit}</td>
                        </tr>
                        <tr>
                            <td>Fixed/Auction</td>
                            <td>{nft?.sell_type === 'fixed' ? 'Fixed' : 'Auction'}</td>
                        </tr>
                    </table>
                    <Link to={{ pathname: `/viewctnft/${nft?.id}`, state: { "view": "Market Place" } }}> {nft?.sell_type === 'fixed' ? "Buy" : "Bid"}
                    </Link>

                </div>
            </div>
        </>
    )
}

export default NFTcard