export const numFormatter = (value) => {
  const num = Number(value);
  if (num > 999 && num < 1e6) {
    return (num / 1e3).toFixed(0) + "k"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1e6) {
    return (num / 1e6).toFixed(0) + "m"; // convert to M for number from > 1 million
  } else if (num <= 999) {
    return num; // if value < 1000, nothing to do
  }
};

export function validateEmail(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
}

export const calculateDays = (value) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(value);
  const secondDate = new Date();

  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};

export function saveStorage(key, data) {
  if (typeof data === "string" || data instanceof String) {
    localStorage.setItem(key, data);
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }

  return true;
}

export function getStorageData(key, type = false) {
  let data = "";
  if (type === true) {
    data = JSON.parse(localStorage.getItem(key));
  } else {
    data = localStorage.getItem(key);
  }

  return data;
}

export function setAdminNavName() {
  let name = window.location.href.split("/");

  let linkName = name && name.length ? name[name.length - 1] : "";

  if (linkName === "dashboard") {
    linkName = "Dashboard";
  } else if (linkName === "manageProducers") {
    linkName = "Manage Producers";
  } else if (linkName === "ctnftListing") {
    linkName = "CTNFT Listing";
  } else if (linkName === "manageDocument") {
    linkName = "Manage Document";
  } else if (linkName === "businessEntity") {
    linkName = "Business Entity";
  } else if (linkName === "report") {
    linkName = "Report";
  } else if (linkName === "ledger") {
    linkName = "Ledger";
  } else if (linkName === "settings") {
    linkName = "Settings";
  } else {
    linkName = "AMIT";
  }

  return linkName;
  //return window.location.href;
}
