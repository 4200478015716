import { getCartData, updateCartApi } from "../../action/Api.action";
import { setCartData } from "../../slices/home";
const CartItem = async (quantData, qnty, addToast, dispatch, show = "true") => {
  if (quantData) {
    const body = {
      order_id: quantData,
      add_qty: parseFloat(qnty),
    };

    const res = await updateCartApi(body);
    if (res.status) {
      addToast({
        message: res?.message,
        type: "success",
        duration: 3000,
      });
      const body = {
        order_id: quantData,
      };
      const result = await getCartData(body);
      if (result.status) {
        dispatch(setCartData(result?.cartDetail));
        return { status: true };
      } else {
        addToast({
          message: res?.message,
          type: "error",
          duration: 3000,
        });
        return { status: false };
      }
    } else {
      if (show) {
        addToast({
          message: res?.message,
          type: "error",
          duration: 3000,
        });
        return { status: false };
      }
      return { status: false };
    }
    return true;
  } else {
    addToast({
      message: "Please Login to add Certificate to Cart",
      type: "error",
      duration: 3000,
    });
  }
};
export default CartItem;
