
import { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap'
import {  Col } from 'react-bootstrap';
const Pagination2 = (props) => {

  const [afterPagedData, setAfterPagedData] = useState([])
  const [itemPerPage, setItemPerPage] = useState(10)
  const [totalCountArr, setTotalCountArr] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [sno, setSno] = useState(1)
  const [data, setData] = useState([])

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(props?.data)
    }
    const x = Math.ceil(Number(props?.data?.length) / itemPerPage)
    const totalCountArr = Array.from(Array(x), (_, index) => index + 1);
    setTotalCountArr(totalCountArr)
    setItemPerPage(props?.itemPerPage)
    setSno(1)
  }, [props])
  useEffect(() => {
    let arr = []
    for (let i = 0; i < itemPerPage; i++) {
      if (data[i]) {
        arr.push(data[i])
      }
    }
    setAfterPagedData(arr)
  }, [data])

  const handlePage = (pageNo) => {
    setCurrentPage(pageNo)
    const startIndex = (pageNo - 1) * itemPerPage
    const endIndex = startIndex + itemPerPage
    let arr = []
    setSno(startIndex+1)
    for (let i = startIndex; i < endIndex; i++) {
      if (data[i]) {
        arr.push(data[i])
      }
    }
    setAfterPagedData(arr)
  }
  useEffect(()=>{
    props.getPagedData(afterPagedData, sno)
  } ,[afterPagedData ])
  return (
    <>
      {afterPagedData.length > 0 &&
        <Col className='text-end' lg={12}>
          <Pagination className='justify-content-end mt-3'>
            {(currentPage > 1 && afterPagedData.length > 1) && <>
              <Pagination.First onClick={() => handlePage(1)} />
              <Pagination.Prev onClick={() => handlePage(currentPage - 1)} />
            </>}
            {totalCountArr.map((item) =>
              <Pagination.Item active={item === currentPage ? true : false} onClick={() => handlePage(item)}>{item}</Pagination.Item>
            )}
            {(currentPage < Math.ceil((data.length) / itemPerPage) && afterPagedData.length > 1) && <>
              <Pagination.Next onClick={() => handlePage(currentPage + 1)} />
              <Pagination.Last onClick={() => handlePage(Math.ceil((data.length) / itemPerPage))} />
            </>}
          </Pagination>
        </Col>
      }
    </>
  )
}

export default Pagination2