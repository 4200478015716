import React, { Component, useState, useEffect } from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import SideBar from '../component/SideBar';
import Select from 'react-select';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { getBusinessEntityAction, addEditBusinessEntityAction, tableListStatusAction } from "../../action/Admin.action";


import { statusOptions } from "../../common/Constant.js";
import AdminLayout from "../../Layout/AdminLayout";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ManageDocument = ({ history }) => {

	const options = [
		{ value: 'chocolate', label: 'Chocolate' },
		{ value: 'strawberry', label: 'Strawberry' },
		{ value: 'vanilla', label: 'Vanilla' }
	];

	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [isUpdate, setUPdate] = useState(false);
	const [isDisabled, setDisabled] = useState(false);

	const [dataList, setDataList] = useState([]);
	const [spinner, setSpinner] = useState(true);
	const [show, setShow] = React.useState(false);

	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);

	const [addEditMsg, setAddEditMsg] = useState("Added Successfully.");

	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});

	const handleCloseAlert = () => {
		setState({ ...state, open: false });
	};

	const getListData = async (e) => {
		let res = await getBusinessEntityAction();
		if (res.code === 200) {
			setDataList(res.data);
			setSpinner(false);
		}
	}

	/* Api Calling */
	useEffect(() => {
		getListData();
	}, []);

	function indexN(cell, row, enumObject, index) {
		return (<div>{index + 1}</div>)
	}

	const actionFunction = (cell, row) => {

		return (<div className="action multiBtnOptn">
			<Button className="editBtn" variant="" type="submit" onClick={e => editActionFunction(row)}><i className="fa fa-edit"></i></Button>
			{/* <Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button> */}
		</div>)
	}

	const editActionFunction = (row) => {
		setFormData(row);
		setShow(true);
		setAddEditMsg('Updated Successfully.');
	}

	const handleSubmit = async (event) => {
		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();

			setValidated(true);
		} else {
			event.preventDefault();
			event.stopPropagation();
			setDisabled(true);
			//setValidated(true);

			let response = await addEditBusinessEntityAction(formData);

			if (response.code === 200) {

				setState({ open: true, vertical: 'top', horizontal: 'center' });
				setFormData({});
				setDataList(response.data);

				handleClose();

			} else if (response && response.errors) {

				setDisabled(false);
				let errorsList = response.errors;
				let err = {};
				for (let i in errorsList) {
					err[errorsList[i].param] = errorsList[i].msg;
				}

				setErrors(err);

			}
		}

	};

	const formHandler = (e, field) => {

		let data = { ...formData };
		let err = errors;

		if (e.target) {
			data[field] = e.target.value;
		} else {
			data[field] = e.value;
		}

		if (err[field]) {
			delete err[field];
		}
		setErrors(err);
		setFormData(data);
		setUPdate(!isUpdate);

	}


	const { vertical, horizontal, open } = state;

	return (
		<>
			<Snackbar
				autoHideDuration={2000}
				anchorOrigin={{ vertical, horizontal }}
				open={open}
				onClose={handleCloseAlert}
				key={vertical + horizontal}
			>
				<Alert onClose={handleCloseAlert} severity="success">{addEditMsg}</Alert>
			</Snackbar>

			<div className="spinner" style={{ display: spinner ? 'block' : 'none' }}>
				<img src="../assets/img/spinner.gif" alt='loader' />
			</div>

			<Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="tableSelection">
						<Form className="formContent" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
							<Row>
								<Col sm={6}>
									<Form.Group className="mb-3" controlId="exampleForm.ControlDocName">
										<Form.Control type="text" placeholder="Enter Name" defaultValue={formData.name} onChange={e => formHandler(e, 'name')} required />
									</Form.Group>
								</Col>

								<Col sm={6}>
									<Form.Group className="mb-3" controlId="userType">
										<Form.Control as="select" custom className="form-control" defaultValue={formData.enabled} onChange={e => formHandler(e, 'enabled')} required>
											<option value="" disabled selected>Select Status</option>
											{statusOptions.map((row, index) => {
												return <option value={row.value} key={index}>{row.label}</option>
											})}
										</Form.Control>
									</Form.Group>
								</Col>

								<Col xs={12}>
									<Button className="primaryBtn addNewDocBtn mb-3 px-4" variant="" type="submit">Submit</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</Modal.Body>

			</Modal>

			<AdminLayout>
				<Row>
					<Col xs={12}>
						<div className="CTNFTrequestQuote tableListing boxShaddow burger">


							<Button className="primaryBtn addNewDocBtn mb-3" variant="" onClick={handleShow}><span>+</span> Add</Button>
							<BootstrapTable data={dataList} pagination={true} search={true} bordered={false}>
								<TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='_id' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>
								<TableHeaderColumn dataField="any" width='58' dataFormat={indexN}>Sr. No</TableHeaderColumn>
								<TableHeaderColumn width='400' dataField='name'>Name</TableHeaderColumn>
								<TableHeaderColumn width='200' dataField='enabled'>Status</TableHeaderColumn>
								<TableHeaderColumn width='200' dataFormat={actionFunction}>Action</TableHeaderColumn>
							</BootstrapTable>

						</div>
					</Col>
				</Row>
			</AdminLayout>
		</>
	);
};

export default ManageDocument;
