import React, { useEffect, useState, useSyncExternalStore } from "react";
import QRCode from "react-qr-code"
import WalletMenu from "../WalletMenu";
import { ButtonGroup, Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { convertCurrency } from "../../../utils/convertCurrency"
import { useToast } from "../../../context/Toast/useToast.ts";
import { addMoneyAction, getUserBalance } from "../../../action/walletApiAction";
import CustomLoader from "../../../common/customLoader";
function Index(props) {
  const [depositMethos, setdepositMethos] = useState('wallet')
  const [convertedPrice, setConvertedPrice] = useState(0)
  const [price, setPrice] = useState('')
  const { addToast } = useToast();
  const [currency, setCurrency] = useState("USD")
  const { page } = props;
  const [walletAddress, setWalletAddress] = useState('')
  const [usdcBalance, setUsdcbalance] = useState(0);
  const [maticBalance, setMaticbalance] = useState(0);
  const [loading, setLoading] = useState(false)
  const [eventKey, setEventKey] = useState('USDC')
  const [fields, setfileds] = useState({
    email: localStorage.getItem("email"),
    amount: "",
    user_name: localStorage.getItem("userName"),
    user_id: localStorage.getItem("userId"),
    currency: "",
    wallet_address: localStorage.getItem("wallet_address")
  })

  useEffect(() => {
    setWalletAddress(localStorage.getItem("wallet_address"))
  }, []);
  const handleCurrencyChange = (value) => {
    setCurrency(value)
  }
  const handleChangePrice = (value) => {
    value = value.replace(/^[-+]/, '');
    if ( /^\d*$/.test(value)) {
      setPrice(value)
    }

  }
  const getCurrency = async (symbol = "MATIC", currency = "USD",) => {
    const res = await convertCurrency(symbol, currency);
    if (price !== null) {
      for (const key in res) {
        setConvertedPrice(res[key] * Number(price))
      }
    }
  }
  useEffect(() => {
    getCurrency("USDC", currency)
    getCurrency("USDC", currency)
  }, [price, currency]);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress)
    addToast({
      message: "Wallet Address Copied Successfully",
      type: "success",
    });
  }
  const getWalletBalance = async () => {
    let body = {}
    body.wallet_address = localStorage.getItem("wallet_address")
    body.wallet_address = localStorage.getItem("wallet_address")
    const balanceRes = await getUserBalance(body)
    if (balanceRes.status === "Error") {
      addToast({
        message: balanceRes.message,
        type: "error",
      });
    }
    setUsdcbalance(balanceRes.data.balance.usdc_balance)
    setMaticbalance(balanceRes.data.balance.matic_balance)
    if (balanceRes.status === "Error") {
      addToast({
        message: balanceRes.message,
        type: "error",
      });
    }
    setUsdcbalance(balanceRes.data.balance.usdc_balance)
    setMaticbalance(balanceRes.data.balance.matic_balance)
  }
  useEffect(() => {
    getWalletBalance()
    const togglebtn = document.getElementById('dropdown-button-drop-end')
    togglebtn.classList.remove('btn-primary')
  }, [])

  const changeHandler = (name, value) => {
    setfileds((p) => ({ ...p, [name]: value }))
  }
  const handleAddMoney = async (e) => {
    e.preventDefault()
    try {
      if (fields?.amount === "") {
        return addToast({
          message: "Please enter the amount",
          type: "error",
        });
      }
      if (fields?.amount === 0) {
        return addToast({
          message: "Amount should be greater than 0",
          type: "error",
        });
      }
      if (fields?.currency === "") {
        return addToast({
          message: "Currency is required.",
          type: "error",
        });
      }
      setLoading(true)
      const AddMoneyRes = await addMoneyAction(fields)
      setLoading(false)
      if (AddMoneyRes.Status === "success") {
        localStorage.setItem("stripeSessionId", AddMoneyRes.data.id)
        localStorage.setItem("stripeSessionId", AddMoneyRes.data.id)
        window.location.href = AddMoneyRes.data.url
      } else {

        return addToast({
          message: AddMoneyRes.message,
          type: "error",
        });
      }

    } catch (error) {
      setLoading(false)
    }


  }
  return (
    <>
      {loading && (
        <div>
          <CustomLoader />
        </div>
      )}

      <div className="walletMaineBox">
        <WalletMenu pagename={page} />
        <div className="walletRanderBox">
          <div className="walletDeposit">
            <Row>
              <Col lg={6}>
                <div className="depositLeft">
                  <h2>Current Balance </h2>
                  <div className="depositBallance">
                    <div className="Currncy">
                      <img
                        width={45}
                        height={45}
                        src={eventKey === "USDC" ? `/images/svg/usdcIcon.svg` : `/images/svg/maticIcon.svg`}
                        alt=""
                      />
                      <div className="w-100">
                        <div className="w-100 dropdownwallet">

                          <DropdownButton
                            as={ButtonGroup} className="btn"
                            key={'end'}
                            id={`dropdown-button-drop-end`}
                            drop={'end'}
                            varient={'link'}
                            title={eventKey}
                          >
                            <Dropdown.Item eventKey="USDC" onClick={() => setEventKey("USDC")}>USDC</Dropdown.Item>
                            <Dropdown.Item eventKey="Matic" onClick={() => setEventKey("Matic")}>Matic</Dropdown.Item>

                          </DropdownButton>


                        </div>
                        <p className="text-start">Polygon Mainnet </p>
                      </div>
                    </div>
                    <div className="balanceBx">
                      <img
                        width={24}
                        height={24}
                        src="/images/svg/walletIcon.svg"
                        alt="wallet"
                      />
                      <h3>{eventKey === "Matic" ? Number(maticBalance).toFixed(5) : Number(usdcBalance).toFixed(5)}</h3>

                    </div>
                  </div>
                  <div className="addMoneyinp">
                    <div className="addMoney">
                      {/* <p>Enter amount</p> */}
                      <div className="addmoneyInpInn">
                        <input type="text" placeholder="Enter Amount" value={price} pattern="[0-9]*"  onChange={(e) => handleChangePrice(e.target.value)} />
                        <img
                          width={35}
                          height={35}
                          src="/images/svg/usdcIcon.svg"
                          alt=""
                        />
                      </div>

                    </div>
                    {/* <div className="exchnageArrow">
                      <img src="/images/svg/exchangeArrow.svg" alt="arrow" />
                    </div> */}
                    <div className="convertMoney">
                      {/* <p>Convert amount to currency</p> */}
                      <div className="selectcurrentMain">
                        <div className="currencySelect">
                          <div className="currenncyNameIcon">
                            <img
                              width={35}
                              height={35}
                              src={currency === 'USD' ? "/images/png/doller.png" : currency === 'INR' ? "/images/png/INR.png" : currency === 'AUD' ? "/images/png/AUD.png" : ''}
                              alt=""
                            /></div>
                          <div className="currencyValue">{convertedPrice.toFixed(2)} </div>
                          <button className="btn">
                            <i class="fa-solid fa-chevron-down"></i>
                          </button>
                        </div>
                        <div className="selectcurreny customecheckBox">
                          <ul>
                            <li>
                              <input type="radio" name="currency" checked={currency === 'USD' ? true : false} id='usd' onClick={(e) => handleCurrencyChange("USD")} />
                              <label htmlFor='usd'> <img
                                width={18}
                                height={18}
                                src="/images/png/doller.png"
                                alt=""
                              /> USD</label>

                            </li>
                            <li>
                              <input type="radio" name="currency" checked={currency === 'INR' ? true : false} id='inr' onClick={(e) => handleCurrencyChange("INR")} />
                              <label htmlFor='inr'> <img
                                width={18}
                                height={18}
                                src="/images/png/INR.png"
                                alt=""
                              /> INR</label>
                            </li>
                            <li>
                              <input type="radio" name="currency" checked={currency === 'AUD' ? true : false} id='aud' onClick={(e) => handleCurrencyChange("AUD")} />
                              <label htmlFor='aud'> <img
                                width={18}
                                height={18}
                                src="/images/png/AUD.png"
                                alt=""
                              /> AUD</label>
                            </li>

                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="depositoptions">
                    <ul>
                      <li className={depositMethos === 'wallet' ? 'active' : ''} onClick={() => setdepositMethos('wallet')}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.5 13.75C15.5 13.3358 15.8358 13 16.25 13H18.25C18.6642 13 19 13.3358 19 13.75C19 14.1642 18.6642 14.5 18.25 14.5H16.25C15.8358 14.5 15.5 14.1642 15.5 13.75ZM3 5H3.01373C3.13809 3.87501 4.09186 3 5.25 3H16.75C17.9926 3 19 4.00736 19 5.25V6.08697C20.4333 6.42555 21.5 7.71321 21.5 9.25V17.75C21.5 19.5449 20.0449 21 18.25 21H6.25C4.45508 21 3 19.5449 3 17.75V5ZM18.25 7.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H18.25C19.2165 19.5 20 18.7165 20 17.75V9.25C20 8.2835 19.2165 7.5 18.25 7.5ZM17.5 6V5.25C17.5 4.83579 17.1642 4.5 16.75 4.5H5.25C4.83579 4.5 4.5 4.83579 4.5 5.25C4.5 5.66421 4.83579 6 5.25 6H17.5Z"
                            fill="#212121"
                          />
                        </svg>
                        <h5>Deposit by Wallet</h5>
                      </li>
                      <li className={depositMethos === 'Cards' ? 'active' : ''} onClick={() => setdepositMethos('Cards')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.25 4C3.45507 4 2 5.45507 2 7.25V16.75C2 18.5449 3.45507 20 5.25 20H18.75C20.5449 20 22 18.5449 22 16.75V7.25C22 5.45507 20.5449 4 18.75 4H5.25ZM3.5 7.25C3.5 6.2835 4.2835 5.5 5.25 5.5H18.75C19.7165 5.5 20.5 6.2835 20.5 7.25V16.75C20.5 17.7165 19.7165 18.5 18.75 18.5H5.25C4.2835 18.5 3.5 17.7165 3.5 16.75V7.25ZM5 7.75C5 7.33579 5.33579 7 5.75 7H11.25C11.6642 7 12 7.33579 12 7.75C12 8.16421 11.6642 8.5 11.25 8.5H5.75C5.33579 8.5 5 8.16421 5 7.75ZM6 13C5.44772 13 5 13.4477 5 14V16C5 16.5523 5.44772 17 6 17H13C13.5523 17 14 16.5523 14 16V14C14 13.4477 13.5523 13 13 13H6ZM5 10.25C5 9.83579 5.33579 9.5 5.75 9.5H16.25C16.6642 9.5 17 9.83579 17 10.25C17 10.6642 16.6642 11 16.25 11H5.75C5.33579 11 5 10.6642 5 10.25Z" fill="#212121" />
                        </svg>

                        <h5>Debit or Credit</h5>
                      </li>
                      {/* <li className={depositMethos === 'Saved' ? 'active' : ''} onClick={() => setdepositMethos('Saved')}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 5.75C3 4.23122 4.23122 3 5.75 3H18.25C19.7688 3 21 4.23122 21 5.75V10.3542C20.5925 9.89247 20.08 9.52556 19.5 9.29076V5.75C19.5 5.05964 18.9404 4.5 18.25 4.5H5.75C5.05964 4.5 4.5 5.05964 4.5 5.75V18.25C4.5 18.9404 5.05964 19.5 5.75 19.5H12.0036C12.0263 20.0358 12.1547 20.5386 12.3714 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75ZM18 14.5C17.1716 14.5 16.5 13.8284 16.5 13C16.5 12.1716 17.1716 11.5 18 11.5V12H20.8293C20.6976 11.6274 20.4944 11.2885 20.2361 11H18V10C16.3431 10 15 11.3431 15 13C15 14.6569 16.3431 16 18 16C18.8885 16 19.6868 15.6137 20.2361 15H18V14.5ZM14.9375 17C14.3563 17 13.8688 17.2822 13.5423 17.6347C13.2184 17.9845 13 18.4582 13 18.944V19.328C13 21.2893 15.0414 23 18 23V22H21.4726C21.881 21.7117 22.2176 21.373 22.4698 21H18V21.5C15.4707 21.5 14.5 20.1114 14.5 19.328V18.944C14.5 18.8976 14.5307 18.7751 14.6429 18.654C14.7497 18.5386 14.8601 18.5 14.9375 18.5H18V18H22.7296C22.6519 17.8682 22.5601 17.7453 22.4577 17.6347C22.1312 17.2822 21.6437 17 21.0625 17H14.9375ZM18 14V13H21C21 13.3506 20.9398 13.6872 20.8293 14H18ZM22.9189 20H18V19H23V19.328C23 19.5561 22.9724 19.7809 22.9189 20Z" fill="#212121" />
                        </svg>

                        <h5>Save Accounts</h5>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                {depositMethos === 'wallet' && <div className="depositBoxMethod">
                  <div className="d-flex align-items-center selectcard">
                    <button className="active">Deposit by wallet</button>

                  </div>
                  <Form className="cardForm">
                    <Row>
                      <Col lg={12}>
                        <div className="walletIDbox">

                          <Form.Group className="mb-3 " >
                            <Form.Label>Wallet ID</Form.Label>
                            <div className="position-relative">
                              <button className='btn position-absolute top-0 bottom-0 end-0 my-auto' onClick={(e) => { e.preventDefault(); handleCopyToClipboard() }}><i class="fa-regular fa-copy" ></i></button>
                              <Form.Control type="text" placeholder="Enter ID" value={walletAddress} readOnly />
                            </div>


                          </Form.Group>
                        </div>


                      </Col>
                      <Col lg={12}>
                        <div className="walletnoteBox">
                          <div className="notebxMaine">
                            <h4>Note on Adding/Withdraw Funds to Your Wallet</h4>
                            <ul>
                              <li>Network: Ensure you are using the Polygon network.</li>
                              <li>Deposit Address: Double-check the address before sending:</li>
                              <li>Minimum Deposit: The minimum deposit is 0.00000001 USDC.</li>
                              <li>Contract Information: Contract address ends with ***xxxxx.</li>
                            </ul>
                          </div>
                          <div className="barcodeBox">
                            <QRCode value={walletAddress} className="img-fluid" />
                          </div>
                        </div>
                        <div className="walletnoteBox">
                          <div className="notebxMaine">
                            <h4>Important Notes:</h4>
                            <ul>
                              <li>Do not send NFTs to this address.</li>
                              <li>Please confirm before sending that you are using the MATIC token on the MATIC chain.</li>
                              <li>If you use a different chain, we are not responsible for any loss of funds.</li>
                            </ul>
                          </div>
                        </div>
                      </Col>

                      <Col className="mx-auto mt-4" lg={8}>

                        {/* <button className="btn btn-primary w-100">Add Money</button> */}

                      </Col>
                    </Row>
                  </Form>
                </div>}
                {depositMethos === 'Cards' && <div className="depositBoxMethod">
                  <div className="d-flex align-items-center selectcard">
                    <button className="active">Debit Card/Credit Card</button>

                  </div>
                  <Form className="cardForm">
                    <Row>
                      {/* <Col lg={12}>
                      {/* <Col lg={12}>

                        <Form.Group className="mb-3" >
                          <Form.Label>Name of Card</Form.Label>
                          <Form.Control type="text" placeholder="Enter Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" >
                          <Form.Label>Card Number</Form.Label>
                          <div className="position-relative cardicon">
                            <Form.Control type="number" placeholder="Enter Card Number" />
                          </div>
                        </Form.Group>

                      </Col> */}
                      {/* <Col lg={6}>
                      </Col> */}
                      {/* <Col lg={6}>
                        <Form.Group className="mb-3" >
                          <Form.Label>Expire</Form.Label>
                          <Form.Control type="text" placeholder="MM/YY" />
                        </Form.Group>
                      </Col> */}
                      {/* <Col lg={6}>
                      </Col> */}
                      {/* <Col lg={6}>
                        <Form.Group className="mb-3" >
                          <Form.Label>CVV</Form.Label>
                          <Form.Control type="number" placeholder="000" />
                        </Form.Group>
                     
                      </Col> */}
                      {/* {depositMethos === "Cards" && */}
                      <Form.Group className="mb-3" >
                        <Form.Label>Amount</Form.Label>
                        {/* <div className="position-relative cardicon"> */}
                        <Form.Control type="number" placeholder="Enter Amount" onChange={(e) => { changeHandler("amount", e.target.value) }} />
                        {/* </div> */}
                      </Form.Group>
                      <Form.Group className="mb-3" >
                        <Form.Label>Currency</Form.Label>
                        {/* <div className="position-relative cardicon"> */}
                        <Form.Control className="form-control form-select" placeholder="Choose Currency" as="select" onChange={(e) => { changeHandler("currency", e.target.value) }} >
                          <option value="">Choose Currency</option>
                          <option value="USD">USD</option>
                          <option value="INR">INR</option>
                          <option value="AUD">AUD</option>
                        </Form.Control>

                      </Form.Group>
                      {/* } */}

                      {/* <Col className="mx-auto mt-4" lg={8}> */}
                      <Col className="mx-auto mt-4" lg={8}>
                        {/* <Form.Check // prettier-ignore
                          className="checkwallet"
                          type="checkbox"
                          id={`default-$checkbox`}
                          label={`Save this account`}
                        />  */}
                        <button className="btn btn-primary w-100" onClick={(e) => handleAddMoney(e)}>Add Money</button>

                      </Col>
                      {/* </Col> */}
                    </Row>
                  </Form>
                </div>}
                {depositMethos === 'Saved' && <div className="depositBoxMethod">
                  <div className="d-flex align-items-center selectcard">
                    <button className="active">Save Accounts</button>
                  </div>
                  {/* <Form className="cardForm">
                  {/* <Form className="cardForm">
                    <Row>
                      <Col lg={12}>
                        <div className="savedAccount">
                          <ul>
                            <li>
                              <div>
                                <h2>Rohit</h2>
                                <div className="cardDetails">
                                  <h3>Card Number - 2564859847596325</h3>
                                  <h3>Expire - 04/25</h3>
                                  <h3>CVV - 365</h3>
                                </div>
                              </div>
                              <Form.Check

                                type={'radio'}

                                id={`selectCard`}
                              />
                            </li>
                          </ul>
                        </div>
                      </Col>

                      <Col className="mx-auto mt-4" lg={8}>

                        <button className="btn btn-primary w-100">Add Money</button>

                      </Col>
                    </Row>
                
                  </Form> */}


                </div>}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
