import styles from "./modal.module.scss";
import React, { useEffect } from "react";
export default function ProducerStatus({ modalOpen, handleClose }) {
  useEffect(() => {
    // Close qr code model on esc button
    const handleEscapeButton = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };
    document.addEventListener("keydown", handleEscapeButton, false);
    return () => {
      document.removeEventListener("keydown", handleEscapeButton, false);
    };
  }, []);
  return (
    <div>
      {modalOpen && (
        <div className={styles.modalWrapper}>
          <div className={styles.paymentModal}>
            <div className={styles.paymentModalHeader} onClick={handleClose}>
              <img src="/images/common/close-modal.svg" />
            </div>
            <div className={styles.modalBody} handleClose={handleClose}>
              <div className={styles.imageCenterAlignment}>
                <img src="/images/common/error-icon.svg" />
              </div>
              <p>No Access !</p>
              <p>
                We are reviewing your profile. Please try again in some time.
              </p>
              <div className={styles.buttonCenterAlignment}>
                <button onClick={handleClose}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
