import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { getDashboardData } from "../action/Api.action";
import PolluterDashboard from "../component/Dashboard";
import NoRecordsFound from "../component/NoRecordFound";
import SubHeader from "../component/Subheader";
import { useLoader } from "../context/Loader/useLoader";
import CreateCTNFT from "./CreateCTNFT";
import WalletNotFound from "../component/WalletNotFound";
import EditPage from "./EditPage";
import ComingSoon from "./ComingSoon";
import { useLocation } from "react-router-dom";
import BuyerDashboard from "./BuyerDashboard";

const Dashboard = ({ history }) => {
  const { state } = useLocation();
  const [subPagination, SetPagination] = useState();
  const { setLoading } = useLoader();
  const [data, setData] = useState();
  useEffect(()=>{
 
    SetPagination(state)
  },[state])
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getDashboardData();
      console.log("TEst for response : ", res);
      setLoading(false);
      console.log(
        "wallet connected :",
        localStorage.getItem("wallet_connect") ? "Connected" : "disconnected"
      );
      if (res) {
        setData(res);
        console.log("Response for polluter dashboard :", res);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {

     if(localStorage.getItem('userState') !==  "approved")
     history.push("/edit-profile")
    if(!localStorage.getItem('userId'))
    history.push('/')

    if (localStorage.getItem("userType") === "polluter") 
    fetchData();
  }, []);

  console.log(localStorage);
  console.log("wallet status : ", localStorage.getItem("wallet_connect"));
  return (
    <>
      {/* {localStorage.getItem('userState') == "draft" && localStorage.getItem('userType') == 'producer' && <EditPage></EditPage>}
        {(localStorage.getItem('userState') == "approved" || localStorage.getItem('userType') == 'polluter')  && (localStorage.getItem('wallet_connect') === "true"  ?
        (localStorage.getItem('userType') !== 'producer' ? <><SubHeader></SubHeader><Container> <PolluterDashboard data={data} /></Container></> : <CreateCTNFT/>)
          : <WalletNotFound/>)
      } */}

      {localStorage.getItem("wallet_connect") == "true" ? (
        localStorage.getItem("userType") != "producer" ? (
          <>
            <SubHeader subpagi={subPagination}></SubHeader>
         
              {" "}
              <BuyerDashboard data={data} />
          
          </>
        ) : (
          <>
            <SubHeader subpagi={subPagination}></SubHeader>
            <BuyerDashboard />
            {/* <ComingSoon /> */}
          </>
        )
      ) : (
        <WalletNotFound />
      )}

      {/**
       * 
      <SubHeader />
      <Container>
        {localStorage.getItem('userType') != 'producer' ? <PolluterDashboard data={data} /> : <NoRecordsFound />}
      </Container>
       */}
    </>
  );
};

export default Dashboard;
