import { uniqueId } from "lodash";
import React, { createContext, useEffect, useState } from "react";
import ToastContainer from "./ToastContainer";

export type ToastType = "success" | "error"; // | "info" | "warning";

export interface ToastProps {
  message: string;
  id: string;
  type?: ToastType;
  duration?: number;
}

export interface ToastContextProps {
  addToast: (toast: Omit<ToastProps, "id">) => string;
  removeToast: (id: string) => void;
}

const ToastContext = createContext<ToastContextProps>({
  addToast: () => "",
  removeToast: () => {},
});

const ToastProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [Toasts, setToasts] = useState<ToastProps[]>([]);
  const addToast = (toast: Omit<ToastProps, "id">) => {
    const id = uniqueId();
    const Type = toast.type || "success";
    const Duration = toast.duration || 3000;
    setToasts(Toasts.concat({ ...toast, type: Type, duration: Duration, id }));
    return id;
  };
  const removeToast = (id: string) => {
    setToasts(Toasts.filter((Toast) => Toast.id !== id));
  };
  useEffect(() => {
    const toast = Toasts[0];
    if (toast) {
      setTimeout(() => {
        removeToast(toast.id);
      }, toast.duration);
    }
  }, [Toasts]);

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}>
      {children}
      <ToastContainer removeToast={removeToast} toasts={Toasts.slice(0, 3)} />
    </ToastContext.Provider>
  );
};

export { ToastProvider, ToastContext };
