import {postRequest, getRequest} from "./helper"


export const adminLoginAction = (data) => {
    return postRequest('admin/login',data).then(res=>{return res.data});
}

export const getDashboardDataAction = (data) => {
    return getRequest('admin/getDashboardCount', data).then(res=>{return res.data});
}

export const getAdminUserListAction = (data) => {
    return postRequest('admin/userList',data).then(res=>{return res.data});
}

export const getAdminCategooryListAction = (data) => {
    return postRequest('admin/categoryList',data).then(res=>{return res.data});
}

export const getAdminProductListAction = (data) => {
    return postRequest('admin/productList',data).then(res=>{return res.data});
}

export const tableListStatusAction = (data) => {
    return getRequest('admin/userStatusAction', data).then(res=>{return res.data});
}

export const adminLogoutAction = (data) => {
    return getRequest('admin/logout', data).then(res=>{return res.data});
}

export const getAdminOrderListAction = (data) => {
    return postRequest('admin/orderList', data).then(res=>{return res.data});
}

export const getNewsletterListAction = (data) => {
    return getRequest('admin/newsLetterList', data).then(res=>{return res.data});
}

export const getManageDocumentAction = (data) => {
    return getRequest('admin/getManageDocument', data).then(res=>{return res.data});
}

export const getBusinessEntityAction = (data) => {
    return getRequest('admin/getBusinessEntity', data).then(res=>{return res.data});
}

export const addEditManageDocumentAction = (data) => {
    return postRequest('admin/addEditManageDocument', data).then(res=>{return res.data});
}

export const addEditBusinessEntityAction = (data) => {
    return postRequest('admin/addEditBusinessEntity', data).then(res=>{return res.data});
}


export const getAdminNftListAction = (data) => {
    return getRequest('admin/getBusinessEntity', data).then(res=>{return res.data});
}



