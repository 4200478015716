import React from "react";
import styles from "./customLoader.module.scss";
export default function CustomLoader({ title }) {
  const Title = title ? title : "Loading...";
  return (
    <div>
      <div className={styles.CustomLoaderBlur}>
        <div className={styles.spinnerContentAlignment}>
          <div className={styles.spinnerCenterAlignment}>
            <div className={styles.spinner}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={styles.loadingTextStyle}>
            <p>{Title}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
