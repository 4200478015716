import React from "react";
import styles from "./styles.module.scss";
const NoRecordsFound = ({ removeSpacing }) => {
  return (
    <div className={!removeSpacing ? styles.NoBondsFound : styles.noMarginBond}>
      <div className={styles.icon}>
        <i className="fa-solid fa-triangle-exclamation"></i>
      </div>
      <div className={styles.text}>No Records Found.</div>
    </div>
  );
};

export default NoRecordsFound;
