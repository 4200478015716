import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountryData } from "../../action/Api.action";
import { setMasterData } from "../../slices/home";
import { useHistory } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const masterData = useSelector((state) => state?.home?.masterDetail);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(async () => {
    if (masterData == null) {
      const result = await fetchCountryData();
      if (result.status) {
        dispatch(setMasterData(result.result[0]));
      } else {
        localStorage.clear();
        history.push("/");
      }
    }
  }, [masterData]);

  //   if (!isLoggedIn) {
  //     return <p>Loading...</p>;
  //   }

  return children;
}
