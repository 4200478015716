import React,{useEffect, useState} from 'react';
import { Container, Row, Col, Form, Button} from 'react-bootstrap';
import Subheader from '../component/Subheader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { setSuccessData } from '../slices/home';
import { message } from '../common/Constant';

import { addProductAction } from '../action/Api.action';

const CreatemarketplaceListing = ({history}) => {

	const [nftDetail, setNFTDetail] = useState({});
	const [formData, setFormData] = useState({'currency':'PHAe', "auctionStatus":"fixed", "unlockStatus":false});
  	const [formErrors, setFormErrors] = useState({});
  	const [spinner,setSpinner] = useState(false);
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [isDisabled, setDisabled] = useState(false);

	const [validated, setValidated] = useState(false);	
	const [isUpdate, setUPdate] = useState(false);

	const dispatch = useDispatch();

	/* Api Calling */
	useEffect(() => {
		//console.log(history);
		if(history.location.state && history.location.state._id){
			setNFTDetail(history.location.state);
			let from = formData;
			from['_id'] = history.location.state._id;
			setFormData(from);
		}else{
			// history.push({pathname:"/dashboard"});
		}

	},[]);

	const inputHandler = async(e,field)=>{
		let formDataData = formData;	  
		let formErrorsData = formErrors; 
		
		if(field !== 'startDate' && field !== 'endDate' && e.target.value.length>0){
			formErrorsData[field] = '';
		}else if((field === 'startDate' || field === 'endDate') && e.length>0){
			formErrorsData[field] = '';
		}

		if(field === 'startDate'){
			setStartDate(e);
			formDataData['startTime'] = e;
		}else if(field === 'endDate'){
			setEndDate(e);
			formDataData['endTime'] = e;
		}else{
			if(field === 'price'){
  
				let index = e.target.value.lastIndexOf(".");
				let price = e.target.value.substring(index + 1);
  
				if((price.length === 4 && (index+1)) || (price.length === 7 && (index === 0 || index))){
					
				}else{
					formDataData[field] = e.target.value;
					//formDataData['doller'] = await getDollerValue(e.target.value, formData.currency);  
				}
  
			}else{
				formDataData[field] = e.target.value;
			}          
		}

		setFormErrors({...formErrors,...formErrorsData});
		setFormData({...formData,...formDataData});
	}

	const onFormSubmit = async(event)=>{

		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
	
			setValidated(true);
		}else{
			event.preventDefault();
			event.stopPropagation();
			setDisabled(true);

			setSpinner(true); 
			let response = await addProductAction(formData);
		
			if(response.code === 200){
				
				//setState({ open: true, vertical: 'top', horizontal: 'center' });
				setFormData({});
				dispatch(setSuccessData(message['CTNFTCreated']));
				
				history.push({pathname:"/success"});				

			}else if(response && response.errors){
				
				setDisabled(false);
				let errorsList = response.errors;
				let err = {};
				for(let i in errorsList){
					err[errorsList[i].param] = errorsList[i].msg;
				}
				
				setFormErrors(err);

			}
		}

	}

	return (
		<div>

			<Subheader />
			<div className="quoteRequestSec CreateCTNFTSec burger">
				<Container>
					<Row className="justify-content-md-center">
						<Col sm={12}>
							<Form className="moreInfoSec formContent boxShaddow" noValidate validated={validated} onSubmit={e=>onFormSubmit(e)}>
								<Row>
									<Col sm={12}>
										<h4 classname="heading">Create Marketplace Listing</h4>

										<h4 className="assetsID">Assets ID: <span>CTNFT{nftDetail.assetsId?nftDetail.assetsId:''}</span></h4>
										<h4 className="CTNFTcrtfct">Certificates: {nftDetail.noOfCopies} CTNFT</h4>
									</Col>
								</Row>
								<hr />

								<Row>
									<Col lg={9}>
										<h3 className="price">Price</h3>
											<div classname="btn-group btn-group-togglepriceTypeCheck" data-toggle="buttons">
												<label classname="btn active" htmlFor="auctionStatusFixed">
												    <input type="radio" id="auctionStatusFixed" name="auctionStatus" value="fixed"  checked={formData.auctionStatus==='fixed'?true:false}  onChange={e=>inputHandler(e, 'auctionStatus')} required/> Fixed Price
												</label>
												&nbsp;
												<label classname="btn" htmlFor="auctionStatusTimed">
												<input type="radio" id="auctionStatusTimed" name="auctionStatus" value="timed"  checked={formData.auctionStatus==='timed'?true:false}  onChange={e=>inputHandler(e, 'auctionStatus')} required/> Time Auction
												</label>
										  	</div>
										<hr />
									</Col>

									<Col sm={8} md={5} xl={4}>
										<Form.Group className="mb-3 mt-4 aud" controlId="formBasicQtyRequir">
										    <Form.Control type="text" placeholder="Price for per CTNFT"  defaultValue={formData.price?formData.price:''} onChange={e=>inputHandler(e, 'price')} placeholder={formData.auctionStatus === 'fixed'?'Price for per CTNFT':"Minimum bid for per CTNFT"} required/>
										    <span>AUD</span>
										</Form.Group>
										{ formData.auctionStatus === 'fixed' && 
											<><p className="mb-1">Service fee 2.5%</p>
											<p>You will receive 0 AUD</p></>
										}
										{ formData.auctionStatus === 'timed' && 
											<small>Bids below this amount wan't be allowed.</small>
										}
									</Col>
								</Row>
									{ formData.auctionStatus == 'timed' && 
									
									<Row className="mb-4">
											<Col lg={4} >
												<Form.Group className="mb-3 mt-4 aud" controlId="startDate">
													<DatePicker
														selected={startDate}
														onChange={(date) =>inputHandler(date, 'startDate')}
														showTimeSelect
														dateFormat="MMMM d, yyyy h:mm aa"
														className="form-control"
														placeholderText="Start Date & Time"
														required
													/>
												</Form.Group>
											</Col>
											<Col lg={4}>
												<Form.Group className="mb-3 mt-4 aud" controlId="endDate">
													<DatePicker
														selected={endDate}
														onChange={(date) => inputHandler(date, 'endDate')}
														showTimeSelect
														dateFormat="MMMM d, yyyy h:mm aa"
														className="form-control"
														placeholderText="End Date & Time"
														required
													/>
												</Form.Group>
											</Col>
											<small>Any bid placed in the last 10 minutes extends the auction by 10 minutes.</small>
										</Row>
									
									}
								<Row>
									<Col lg={8}>
										<div className="unloackPurchased">
											<div>
												<h4>Unlock once purchased</h4>
												<p>Carbon Credit Certificates will be unlocked after successful transaction</p>
											</div>

											<div>												
												<Form.Check type="switch" id="custom-switch" value={true} onChange={e=>inputHandler(e, 'unlockStatus')} />												
											</div>
										</div>
									</Col>
									
									<Col xs={12} lg={5}>
										<div className="moreInfoBtns">
											<Button className="secondaryBtn submitBtn" variant="" type="submit" disabled={isDisabled}>Submit</Button>
										</div>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default CreatemarketplaceListing;
