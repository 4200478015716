import axios from "axios";
import { StorageHelper } from "../utils/StorageHelper";
//import toastr from "../_Common/toaster";
var CancelToken = axios.CancelToken;
let wj_cancel;
let resp;
export const request = (path, data = {}, method, image = false) => {

  console.log("spath", path, "sdata", data, "smethod", method, "simage", image)
  const userInfo = StorageHelper.getUserInfo();
  if (
    wj_cancel &&
    resp &&
    resp.config &&
    resp.config.url === `${process.env.REACT_APP_API_URL}/${path}`
  ) {
    wj_cancel && wj_cancel();
  }
  var options = {
    method: method,
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    headers: {
      "Content-Type": image ? "multipart/form-data" : "text/plain",
    },

    // dataType: "json",
    // cancelToken: new CancelToken(function executor(c) {
    //   wj_cancel = c;
    // }),
  };

  console.log("API HIT TESTING",options.url)

  // const userId = 11;
  // const accessToken = "3vr7wewg7a";
  const userId = userInfo.user_id;
  const accessToken = userInfo.access_token;
  if (userId) {
    // options.headers["user_id"] = parseInt(userId);
    data["user_id"] = parseInt(userId);
  }
  if (accessToken) {
    // options.headers["auth_access_token"] = accessToken;
    data["auth_access_token"] = accessToken;
  }

  if (method === "GET") {
    // options["params"] = data;
  } else {
    options["data"] = data;
  }
  let responce = axios(options);
  responce
    .then((res1) => {
      resp = res1;
      if (res1.data.code === 402 || res1.data.status === 401) {
        //  localStorage.clear();
        //toastr.show({message: res1.data.msg,intent: "danger"});
        //window.location = "/";
        //history.push('/');
      }
    })
    .catch((error) => {
      console.log("ERR =>", error);

      // if (error.response) {
      //   console.log('123',error);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      // }
    });
  return responce;
};

export const postRequest = (path, data, image) =>
  request(path, data, "POST", image);
export const putRequest = (path, data) => request(path, data, "PUT");
export const getRequest = (path, data) => request(path, data, "GET");
