import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
	name: "admin",
	initialState: {
		userList: [],
		ctnftList: [],
	},
	reducers: {
		setUserList: (state, action) => {
			state.userList = action.payload;
		},
		setCtnftList: (state, action) => {
			state.ctnftList = action.payload;
		},
		verificationData: (state,action) => {
			state.verificationData = action.payload
		}
	},
});

export const { 
	setUserList,
	setCtnftList,
	verificationData
} = adminSlice.actions;

export default adminSlice.reducer;
