import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Label } from '@blueprintjs/core';
import { useLoader } from "../context/Loader/useLoader";
import { useToast } from "../context/Toast/useToast";
import { postContactUs } from "../action/Api.action";
import { ContactQuery} from '../action/walletApiAction'
import Footer from "../component/HomeNew/Footer";
import { Link } from 'react-router-dom'
import CustomLoader from "../common/customLoader";


const Contactus = ({ history }) => {
  const [IsLoading,setIsLoading] = useState(false)
  const { addToast } = useToast();
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    subject: "",
    question: "",
  });
  // useEffect(() => {
  //   setLoading(false);
  // }, []);
  const handleChange = (e) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const { name, email, subject, question } = userInfo;
    if (name && email && subject && question) {
      const body = {
        name,
        email,
        subject,
        question,
      };
      const res = await postContactUs(body);
      // const res = await ContactQuery(body);

      if (res.status) {
        addToast({
          message: res.message,
          type: "success",
        });
        setUserInfo({
          name: "",
          email: "",
          subject: "",
          question: "",
        })
      } else {
        addToast({
          message: res.message,
          type: "error",
        });
      }
      setIsLoading(false)
    } else {
      addToast({
        message: "Please enter all details",
        type: "error",
      });
      setIsLoading(false)

    }
  };
  return (
    <div className="container-fluid "  style={{padding : '0'}}>
     <div>
     {IsLoading && <CustomLoader/>}

<div className="contactHead" style={{width : '100%'}}>
  <p className="contactPagination" style={{textAlign : 'left'}}> <Link to="/" style={{color : "#fff"}}>Home</Link> <b> &gt; Contact Us</b></p>
    <h1 style={{textAlign : 'center'}}> Get In Touch</h1>
<p >Your email will remain private, please fill in the required fields marked *</p>
</div>

<div className="contactFormSec burger">
    <Row className="formBoxContact">
    <Col className="formBoxCol" lg={5} xl={4}>
    
    <Row >
      <div className="contactDetails infoBox1">
        <div className="imgBox" style={{backgroundColor : '#B2EED6',borderRadius : '50%'}}>
        <img src="/images/png/EnvelopeOpen.png" alt="producer-icon" />
        </div>
        <div>
          
        <h5>Email</h5>
          <p>support@phaeton.io</p>
        </div>
      </div>
    </Row>
    <Row >
      <div className="contactDetails infoBox1">
        <div className="imgBox" style={{backgroundColor : '#B2EED6',borderRadius : '50%'}}>
          <img src="/images/png/PaperPlaneTilt.png" alt="producer-icon" />
        </div>
        <div>
          <h5>Location</h5>
          <p>17 Discovery Drive, 6163 WA, Australia.</p>
        </div>
      </div>
    </Row>

    {/* <Col sm={4}>
      <div className="contactDetails infoBox1">
        <div className="imgBox" style={{backgroundColor : '#B2EED6',borderRadius : '50%'}}>
          <img src="assets/img/call.png" alt="trader-icon" />
        </div>
        <div>
          <h5>Our Phone</h5>
          <p>+61 (212) 64-33-35</p>
          <p>+61 (212) 27-85-22</p>
        </div>
      </div>
    </Col> */}

    <Row>
      <div className="contactDetails infoBox1">
        <div className="imgBox" style={{backgroundColor : '#B2EED6',borderRadius : '50%'}}>
          <img src="/images/png/Phone.png" alt="polluter-icon" />
        </div>
        <div>
        <h5>Phone Number</h5>
          <p>+87 -73839 09030</p>
        </div>
      </div>
    </Row>
 
    </Col>
      <Col className="" lg={5} xl={6}>
     
         
         <div className='mx-5 px-5'>
         <p className='aboutHead text-left'>Contact Us</p>

         <h1 className=''><b>Send Message</b></h1>
         <p className='text-black text-left'>Your email address is kept confidential and will only be used to respond to your inquiry.</p>
         </div>
       <div className="formContainer ">
       <Form className="formContent" onSubmit={handleSubmit}>
       <div className='formMain transparentBg'>
      <Form.Group>
       <div className='position-relative'>
       <i class="fa-regular fa-circle-user imgIcon"></i>
       <Form.Control
         id='name'
         className='formInput transparentBg'
         style={{background : 'white'}}
           type="text"
           placeholder="Name*"
           value={userInfo.name}
           name="name"
           
           onChange={(e) => handleChange(e)}
         
         />
    {!userInfo.name &&   <Label className='inputLabel transparentBg' htmlFor='name'>Name</Label>}
      </div>
      </Form.Group>
     

       </div>
       

       
       <div className='formMain transparentBg'>

       <i class="fa-regular fa-envelope-open imgIcon"></i> 
       <Form.Control
         className='formInput transparentBg'
           id='emailid'
           type="email"
           value={userInfo.email}
           placeholder="Email*"
           name="email"
           onChange={(e) => handleChange(e)}
         />
    {!userInfo.email &&   <Label className='inputLabel transparentBg' htmlFor='emailid'>Email</Label>}
     
       </div>
     
       <div className='formMain transparentBg'>

       <i class="fa-solid fa-paperclip imgIcon"></i>
                     <Form.Control
         className='formInput transparentBg'
           type="text" id='sww'
           placeholder="Subject*"
           name="subject"
           value={userInfo.subject}
         onChange={(e) => handleChange(e)}
         />
   {!userInfo.subject  && <Label className='inputLabel transparentBg' htmlFor='sww'>Subject</Label>}
      
       </div>

       <div className='formMain transparentBg'>
       <Form.Control
          className='formInput transparentBg'
           as="textarea"
           id='description'
           rows={5}
           name="question"
           value={userInfo.question}
         onChange={(e) => handleChange(e)}
         />
       {!userInfo.question && <Label className='inputLabel transparentBg' htmlFor='description'>Description</Label>}
      
       </div>

       <Button className="primaryBtn contactBtn" variant="" type="submit">
         Send Message
       </Button>
     </Form>
       </div>

      </Col>
    
    </Row>
  </div> 
  <div className=" burger px-5">
    <Row>
    <div className="mapSecContact ">
          
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9287.492155547525!2d115.81494308894834!3d-32.10055576844735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32987a445672a3%3A0x844f8c2fda3c7c1d!2s17%20Discovery%20Dr%2C%20Bibra%20Lake%20WA%206163!5e0!3m2!1sen!2sau!4v1686713054100!5m2!1sen!2sau"
              width="1600"
              height="600"
              style={{ border: 0}}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="new-iframe1"></iframe>
        </div>
    </Row>
  </div>
  <div>
  <Row>
              <div className='aboutNewsLetterPanel'>
              <div className="container infoPanel my-5">
            <div className="row mt-2">
              <img className="imgNewsletter" src="/images/png/rafiki.png" style={{height : '100%' , width : '30%',marginLeft : '5%', transform: 'translateY(-40%)'}}></img>

          
          <h2 className='mt-5' ><b>Subscribe to our Newsletter</b></h2>
          <p className='text-black text-left'>Stay updated with the latest news, updates, and exclusive offers from EcoCredit. Get insights on our projects, sustainability tips, and ways to make a positive impact.</p>
        
        <Form className='newsLetterForm'>
          <Row>
            <Col md={5}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
             <Form.Control
          className=' newsEmail'
            type="email"
            placeholder="Enter your email address"
            name="email"
            //onChange={(e) => handleChange(e)}
          />              </Form.Group> 

            </Col>
            <Col md={7}>
            <Button className="primaryBtn contactBtn newsletterEmail mx-4" variant="" type="submit" style={{display :'inline'}}>
         Subscribe
        </Button>
            </Col>
            </Row> 
         

      
      </Form>
       
              
            </div>
            </div>
              </div>
          </Row>  
  </div>
  <Footer></Footer>

 

     </div>
       
    </div>
  );
};

export default Contactus;
