import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartData,
  getOrderData,
  getPrice,
  getTradingData,
  postOrderData,
  updateCartApi,
} from "../../action/Api.action";
import CartItem from "../../common/CartItem";
import { useToast } from "../../context/Toast/useToast";
import { useHistory } from "react-router-dom";
import { useLoader } from "../../context/Loader/useLoader";
import { BASE_CURRENCY } from "../../constant/constant";
import moment from "moment";
import { setFooter } from "../../slices/home";
import { StorageHelper } from "../../utils/StorageHelper";

function BuyCTNF() {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [qnty, setQnty] = useState();
  const [price, setPrice] = useState(0);
  const rx_live = /^[0-9\b]+$/;
  // const rx_live = /^-?(0|[1-9]\d*)(?<!-0)$/;
  const { addToast } = useToast();
  const MINUTE_MS = 600000;
  const [tradingData, setTradingData] = useState({
    orderHistory: [],
    tradingHistory: [],
  });
  dispatch(setFooter(true));
  const quantData = useSelector((state) => state?.home?.quoteData);
  const cartData = useSelector((state) => state?.home?.cartData);
  const loginCheck = useSelector((state) => state?.auth?.loginCheck);
  const userInfo = StorageHelper.getUserInfo();
  // const userDetail = useSelector((state) => state?.auth?.profileData);
  const history = useHistory();
  const handleDepositeAmountChange = (e) => {
    if (rx_live.test(e.target.value) && e.target.value.length < 11) {
      setQnty(e.target.value);
    }
    if (qnty?.length == 1 && e.target.value == "") setQnty();
  };
  const fetchPrice = async () => {
    const body = {
      certificates: parseFloat(qnty),
      order_id: quantData ? quantData : false,
    };
    const res = await getPrice(body);
    if (res.status) {
      setPrice(res.total_price);
    }
  };
  useEffect(() => {
    if (qnty) {
      fetchPrice();
    }
    if (qnty == undefined) {
      setPrice(0);
    }
  }, [qnty]);
  const updateCart = () => {
    if (qnty == undefined) {
      addToast({
        message: "Please Enter a Value",
        type: "error",
        duration: 3000,
      });
    } else if (qnty == 0) {
      addToast({
        message: "Please Enter a value grater then 0",
        type: "error",
        duration: 3000,
      });
    } else {
      CartItem(quantData, qnty, addToast, dispatch);
      setQnty();
    }
  };

  const buyCart = async () => {
    if (loginCheck) {
      if (qnty !== undefined && qnty !== "0") {
        let show = false;
        const res = await CartItem(quantData, qnty, addToast, dispatch, show);
        if (res.status) {
          history.push("/cart");
        } else {
          if (cartData.length > 0) {
            history.push("/cart");
          }
        }
      } else if (cartData && cartData.length > 0) {
        history.push("/cart");
      } else {
        addToast({
          message: "Please Enter a Value",
          type: "error",
          duration: 3000,
        });
      }
    } else {
      addToast({
        message: "Please Login First",
        type: "error",
        duration: 3000,
      });
    }
  };
  const fetchTradingData = async () => {
    const res = await getTradingData();
    const resp = userInfo?.user_id
      ? await postOrderData()
      : await getOrderData();
    setTradingData({
      orderHistory: resp,
      tradingHistory: !res.status ? [] : res,
    });
    setLoading(false);
  };
  useEffect(() => {
    if (tradingData.tradingHistory == "" || tradingData.orderHistory == "") {
      setLoading(true, "Loading...");
      fetchTradingData();
    }
    // const interval = setInterval(() => {
    //   fetchTradingData();
    // }, MINUTE_MS);

    // return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);
  console.log("tradingData.tradingHistory", tradingData.tradingHistory);
  return (
    <div className="orderpage">
      <Row className="order-row">
        <Col className="order-column" xs={12} sm={12} md={12} lg={12}>
          <div className="place-order pb-4">
            <div className="heading">
              <h1 className="order-title">PLACE ORDER</h1>
            </div>

            <div className="mb-3 order-form">
              <p className="order-quantity">ENTER QUANTITY</p>
              <input
                type="text"
                value={qnty == undefined ? "" : qnty}
                className="order-input"
                id="quantity"
                name="quantity"
                min={0}
                // pattern="[+-]?\d+(?:[.,]\d+)?"
                onChange={handleDepositeAmountChange}
              />
            </div>
            <div className="mb-3 order-form">
              <p className="order-quantity">TOTAL PRICE</p>
              <input
                type="text"
                className="order-input-disbaled"
                value={price}
                disabled
                id="price"
                name="price"
              />
            </div>
            <div className="order-form btn-wrapper ">
              <button className="add-cart-btn" onClick={updateCart}>
                ADD TO CART
              </button>
              <div className="buy-btn-wrp">
                <button className="buy-btn" onClick={buyCart}>
                  BUY
                </button>
              </div>
            </div>
            {/* <div className="text-center order-fee">FEE: 0.05%</div> */}
          </div>
        </Col>
      </Row>
      <Row className="order-row">
        <Col className="order-column" xs={12} sm={12} md={12} lg={5}>
          <div className="place-order">
            <div className="d-flex justify-content-between align-items-center order-book">
              <h1 className="order-book-heading">ORDER BOOK</h1>
              {/*<div className="d-flex justify-content-between">
                <p className="m-0 market-depth">MARKET DEPTH</p>
                <p className="order-volume m-0">ORDER VOLUME</p>
              </div>*/}
            </div>
            <Table striped>
              <thead className="text-center">
                <tr>
                  <th className="order-th">VOLUME</th>
                  <th className="order-th">BUY PRICE</th>
                </tr>
              </thead>
              <tbody>
                {tradingData &&
                  tradingData?.orderHistory.map((info, index) => {
                    if (index < 10) {
                      return (
                        <tr key={index} className="text-center">
                          <td>{info?.volume}</td>
                          <td>${info?.price}</td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col className="order-column" xs={12} sm={12} md={12} lg={7}>
          <div className="place-order trade-history">
            <div className="d-flex justify-content-between align-items-center order-book">
              <h1 className="order-book-heading">TRADE HISTORY</h1>
            </div>

            <Table striped>
              <thead className="text-center">
                <tr>
                  <th className="order-th">PRICE</th>
                  <th className="order-th">VOLUME</th>
                  <th className="order-th">TIME</th>
                </tr>
              </thead>
              <tbody>
                {tradingData &&
                  tradingData?.tradingHistory.map((info, index) => {
                    if (index < 10) {
                      return (
                        <tr key={index} className="text-center">
                          <td
                            className={
                              info.trend == "up" ? "table-green" : "table-red"
                            }>
                            <Row>
                              <Col md={5} className="text-right p-0">
                                {info.trend == "up" ? (
                                  <img
                                    alt="upArrow"
                                    className="pe-1"
                                    src="assets/img/upArrow.png"
                                  />
                                ) : info.trend == "down" ? (
                                  <img
                                    alt="downArrow"
                                    className="pe-1"
                                    src="assets/img/downArrow.png"
                                  />
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col className="p-0" md={1}>
                                {BASE_CURRENCY.SYMBOL}
                              </Col>
                              <Col className="text-left p-0">{info?.price}</Col>
                            </Row>
                          </td>
                          <td>{info?.volume}</td>
                          <td>{info?.date}</td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BuyCTNF;
