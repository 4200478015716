import React from "react";
import styles from "./profile.module.scss";
import { Link } from "react-router-dom";
import { useToast } from "../../../context/Toast/useToast";
const ProfileComplete = ({ finishUpdate }) => {
  const {addToast} = useToast();
  
  return (
   
    <div className={styles.ProfileComplete}>
      
    <img src="/images/svg/submitDocs.svg" width={200} className="mx-auto" alt="" />
      <div className={styles.ProfileCompleteMessage}>
        You have provided your Documents successfully.
      </div>
      {/* <div className={styles.ProfileCompleteSummary}>
        Thank you for providing updated information. Once platform verify your
        personal and documents then you got an email.
      </div> */}
      <div className={styles.ProfileCompleteButtonWrapper}>
        <Link to="/dashboard">
          <div className={'secondaryBtn submitBtn btn'}>Back to Home</div>
        </Link>
      </div>
    </div>
  );
};

export default ProfileComplete;
