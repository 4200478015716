import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLoginCheck, setProfile } from "../slices/auth";
import { setSuccessData } from "../slices/home";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import { loginAction } from "../action/Api.action";
import { message } from "../common/Constant";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { saveStorage, getStorageData } from "../utils/common";
import { getWalletAction, verifyUser } from "../action/walletApiAction";
import { useToast } from "../context/Toast/useToast";
import CustomLoader from "../common/customLoader";




import { useLocation } from 'react-router-dom'




const Login = ({ history }) => {


//Check for login
  useEffect(() => {
   if(localStorage.getItem('userId'))
   history.push('/')
 }, []);


  const location = useLocation()
  const { addToast } = useToast();
  const [IsLoading, setIsLoading] = useState(false)
  const [showPass, setshowPass] = useState("password");

  const [loginState, setLoginstate] = useState(0)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  //const loginData = useSelector((state) => state.auth.loginData);
  const dispatch = useDispatch();
  const [loginError, setloginError] = useState({
    code: 0,
    message: "",
    severity: "",
  });
  const onSubmit = async (data) => {
    setIsLoading(true)
    //console.log(data)
    let res = await loginAction(data);
      // New Tests
    console.log(res)
    if (res && res.status === 200) {
      addToast({
        message: "Logged In successfully!",
        type: "success",
      });

      let authData = res.data;
      console.log(res)
      // dispatch(setLoginCheck(authData));
      // localStorage.setItem("Token", res.token);
      // localStorage.setItem("authorization", authData.token);
      if (
        // authData.userType === "SUPER_ADMIN" ||
        // authData.email === "admin@admin.com"
        false
      ) {
        //document.cookie = "userType=ADMIN";
        // history.push({
        //   pathname: "/admin/dashboard",
        //   state: { detail: authData },
        // });
      } else {
        //document.cookie = "userType=";
        // if (authData.token) {
        //   localStorage.setItem("jwtToken", authData.token);
        // }
        //saveStorage("authData", authData);
        saveStorage(
          "userType", "user"
          // authData.userType != "" ? authData.userType : "user"
        );
        //register, otpVerified, companyCreated, contractSend, contractSign, companyVerified, companyReject
        let url = "/edit-profile";
        let data = authData;
        let msgData = {};
        // if (authData.steps === "register") {
        //   url = "/verification";
        // } else if (authData.steps === "otpVerified") {
        //   url = "/companyinfo";
        // } else if (authData.steps === "companyCreated") {
        //   msgData = message["contractSign"];
        //   url = "/success";
        // } else if (authData.steps === "contractSend") {
        //   url = "/agreement";
        // } else if (authData.steps === "contractSign") {
        //   msgData = message["contractSign"];
        //   url = "/success";
        // } else if (authData.steps === "companyVerified") {
        //   url = "/dashboard";
        // } else if (authData.steps === "companyReject") {
        //   url = "/success";
        // }
        //dispatch(setSuccessData(msgData));
        //console.log(res.user)
        let body={}
        body.user_id=res.user
        console.log("userData",res.user)
      const verify = await verifyUser({user_id  : res.user})
      console.log(verify)
      if(verify?.data?.status == 401)
      {
        console.log("Ashmin Test For verification")
        
        setIsLoading(false)
        let obj = {}
				obj.email = verify.data.email
				obj.partner_id = verify.data.partner_id
        console.log("OBJECT OF TEST",obj)
				//  let verify = await verification(obj)
				//  if (verify.status === 200) {
				//  	console.log("working")
				//  }
				// dispatch(verificationData(obj))
        localStorage.clear()
          addToast({
            message : "Please verify email to continue",
            type : "warning",
            duration  : 3000
          })

          history.push({ pathname: "/verification", state: obj });



      }
      else{
        console.log("Ashmin Test For login")
        const wallet = await getWalletAction(body)
        console.log("walletaction", wallet)
        console.log("wallet", wallet)
        // localStorage.setItem("user_name",wallet.userInfo.name)
        if (wallet.data.wallet_address) {
          localStorage.setItem("wallet_address", wallet.data.wallet_address)
          localStorage.setItem("private_key", wallet.data.private_key)
        }
        else {
          localStorage.setItem("wallet_address", '')
        }
        console.log("walletinlogin", wallet)
        console.log(localStorage)
        // sahil
        console.log("resloginl", res)
        localStorage.setItem("userId", res.user);
        localStorage.setItem("userName", res.user_name)
        localStorage.setItem("email", res.creds.email);
        //console.log("user type",res.userType)
        localStorage.setItem("userType", res.userType)
        localStorage.setItem("userState", res.userState)
        // let walletAddress = (res.userType == "polluter" ? "" : "")  //kajshdh2kh1kh123huh1uh
        localStorage.setItem("wallet_connect", false)
        localStorage.setItem("balance", wallet.data.balance)
        localStorage.setItem("kyc_status", res.kyc_status)
        // localStorage.setItem("balance",balance)

        // {"inaction"}
        // alert("Login Successful!!! Redirecting to Home Page")
        dispatch(setProfile({
          user_id: res.user,
          user_type: res.userType
        }))
        //dispatch(setLoginCheck(true))
        setIsLoading(false)
        history.push({
          pathname: "/",
        });
      }
      setIsLoading(false)
       
      }
    } else {
      setIsLoading(false)
      addToast({

        message: "Please enter the correct email and password.",
        type: "error",
      });
      // setloginError({
      //   code: 500,
      //   message: "Invalid username/password... !",
      //   severity: "warning",
      // });
    }
  };






  const hideLoginError = () => {
    setloginError({
      code: 0,
      message: "",
    });
  };
  // useEffect(() => {
  //   let type = localStorage.getItem("userType")
  //     ? localStorage.getItem("userType")
  //     : "";
  //   if (type !== "") {
  //     history.push({ pathname: "/dashboard" });
  //   }
  // }, []);
  const showPassword = (e) => {
    console.log(e.target);
    const getPassId = document.getElementById(e)
    if (getPassId.id === 'password') {
      setshowPass(showPass === 'password' ? 'text' : 'password')
    }


  }
  return (

    <Container>

      {IsLoading && (
        <div>
          <CustomLoader />
        </div>
      )}

      {/* <Snackbar
        open={loginError.code != 0}
        autoHideDuration={8000}
        onClose={hideLoginError}>
        <MuiAlert
          elevation={10}
          variant="outlined"
          severity={loginError.severity}
          onClose={hideLoginError}>
          <h4>{loginError.message}</h4>
        </MuiAlert>
      </Snackbar> */}
      <div className="loginForm burger">
        <Row className="align-items-center justify-content-center">
          <Col xs={12} sm={9} md={7} lg={5}>
            <form
              className="formContent boxShaddow"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}>
              <div className="heading">
                <h2>Login</h2>
              </div>
              <div className="mb-3 form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  id="email"
                  name="email"
                  autoComplete="off"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Please enter your email"
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address."
                    }
                  })}
                />
                {errors.email && <span style={{ "color": "red" }}>* {errors.email.message}</span>}
              </div>
              <div className="mb-3">


                <div className="position-relative">
                  <input
                    type={showPass}
                    className="form-control"
                    placeholder="Password"
                    id="password"
                    name="password"
                    autoComplete="none"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Please enter your password"
                      }, minLength: {
                        value: 8,
                        message: "Password cannot be less than 8 characters"
                      },
                    })}
                  />
                  <button type="button" className="btn passwordSHow" onClick={() => showPassword('password')}><i class={"fa-regular  " + (showPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
                </div>
                {errors.password && <span style={{ "color": "red" }}>* {errors.password.message}</span>}
              </div>

              <div className="loginFrgtPwdBtns">
                <input
                  className="secondaryBtn btn"
                  type="submit"
                  value="Login"
                />
                <Navbar.Text>
                  <Link to="/forgot-password" className="forgotPwd">
                    Forgot Password?
                  </Link>
                  <Link to="/signUp" className="signUp">
                    Sign Up
                  </Link>
                </Navbar.Text>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
export default Login;













