import AdminLayout from '../../Layout/AdminLayout';
import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Select, Button } from 'react-bootstrap';

import SideBar from '../component/SideBar';

const VerifyCompanyDetails = ({ history }) => {


	return (
		<AdminLayout>
			<Row className="mb-4">
				<Col xs={12}>
					<h3 className="heading">Verify Company Details</h3>
				</Col>
			</Row>

			<Row className="border-bottom mb-4 pb-4">
				<Col lg={6}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicOrgName">
							<Form.Control type="text" placeholder="Organization Name" />
						</Form.Group>
					</Form>
				</Col>
				<Col lg={4}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicCrediterType">
							<Form.Control type="text" placeholder="Producers/Traders/Polluters" />
						</Form.Group>
					</Form>
				</Col>

				<Col lg={6}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicOrgRegNumber">
							<Form.Control type="text" placeholder="Organization Registration Number" />
							<span className="verifyd"><img src="assets/img/verifydCheck.png" /></span>
						</Form.Group>
					</Form>
				</Col>
				<Col lg={4}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicCompanyType">
							<Form.Control type="text" placeholder="Type of Company Entity" />
						</Form.Group>
					</Form>
				</Col>

				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicTaxNumber">
							<Form.Control type="number" placeholder="Taxation Number" />
							<span className="verifyd"><img src="assets/img/verifydCheck.png" /></span>
						</Form.Group>
					</Form>
				</Col>
				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicTaxNumber">
							<Form.Control type="number" placeholder="Taxation Number" />
							<span className="verifyd"><img src="assets/img/verifydCheck.png" /></span>
						</Form.Group>
					</Form>
				</Col>
			</Row>

			<Row className="border-bottom mb-4 pb-4">
				<Col xs={12}><h4>Business Address</h4></Col>

				<Col lg={6}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicAddress">
							<Form.Control type="text" placeholder="Address" />
						</Form.Group>
					</Form>
				</Col>
				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicZipCode">
							<Form.Control type="text" placeholder="Zip Code" />
						</Form.Group>
					</Form>
				</Col>
				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicCountry">
							<Form.Control type="number" placeholder="Country" />
						</Form.Group>
					</Form>
				</Col>

				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicState">
							<Form.Control type="text" placeholder="State/ Provins" />
						</Form.Group>
					</Form>
				</Col>
				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicCity">
							<Form.Control type="text" placeholder="City" />
						</Form.Group>
					</Form>
				</Col>
			</Row>

			<Row className="border-bottom mb-4 pb-4">
				<Col xs={12}><h4>Contact Person Details</h4></Col>

				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicFullName">
							<Form.Control type="text" placeholder="Full Name" />
						</Form.Group>
					</Form>
				</Col>
				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicMobile">
							<Form.Control type="tel" placeholder="Mobile" />
						</Form.Group>
					</Form>
				</Col>

				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Control type="mail" placeholder="Email Address" />
						</Form.Group>
					</Form>
				</Col>
				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicDesignation">
							<Form.Control type="text" placeholder="Designation" />
						</Form.Group>
					</Form>
				</Col>
			</Row>

			<Row className="border-bottom mb-4 pb-4">
				<Col xs={12}><h4>Banking  Details</h4></Col>

				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicAcName">
							<Form.Control type="text" placeholder="Account Name" />
						</Form.Group>
					</Form>
				</Col>
				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicAcNumber">
							<Form.Control type="number" placeholder="Account Number" />
						</Form.Group>
					</Form>
				</Col>

				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicBankBranch">
							<Form.Control type="text" placeholder="Bank Name & Branch" />
						</Form.Group>
					</Form>
				</Col>
				<Col lg={3}>
					<Form className="formContent">
						<Form.Group className="mb-3" controlId="formBasicIFSCCode">
							<Form.Control type="text" placeholder="IFSC Code" />
						</Form.Group>
					</Form>
				</Col>
			</Row>

			<Row>
				<Col xs={12}>
					<div className="CTNFTrequestQuote tableListing boxShaddow burger">
						<h4>My CTNFT Listing</h4>
						<div className="listing">
							<div className="tableHead">
								<ul className="headContent">
									<li className="srNo">Sr. No</li>
									<li className="name">Document Name</li>
									<li className="issuesBy">Issuing Authority</li>
									<li className="date">Uploaded On</li>
									<li className="createdBy">Uploaded By</li>
									<li className="ipAddress">IP Address</li>
									<li className="listingStatus appr">Status</li>
									<li className="action">Action</li>
								</ul>
							</div>

							<div className="tableBody">
								<ul className="bodyContent">
									<li className="srNo">1</li>
									<li className="name">Company Registration</li>
									<li className="issuesBy">Govt. of Australia</li>
									<li className="date">2021 June 20th </li>
									<li className="createdBy">Manager</li>
									<li className="ipAddress">210.123.211.210</li>
									<li className="listingStatus appr verifydText">Verified</li>
									<li className="action multiBtnOptn">
										<Button className="editBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>
										<Button className="checkedBtn" variant="" type="submit"><i className="fa fa-check"></i></Button>
										<Button className="cancelBtn" variant="" type="submit"><i className="fa fa-times"></i></Button>
										<Button className="downloadBtn" variant="" type="submit"><i className="fa fa-download"></i></Button>
										<Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">2</li>
									<li className="name">Taxation Number</li>
									<li className="issuesBy">Govt. of Australia</li>
									<li className="date">2021 June 20th </li>
									<li className="createdBy">Manager</li>
									<li className="ipAddress">210.123.211.210</li>
									<li className="listingStatus appr">Waiting for Approval</li>
									<li className="action multiBtnOptn">
										<Button className="editBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>
										<Button className="checkedBtn" variant="" type="submit"><i className="fa fa-check"></i></Button>
										<Button className="cancelBtn" variant="" type="submit"><i className="fa fa-times"></i></Button>
										<Button className="downloadBtn" variant="" type="submit"><i className="fa fa-download"></i></Button>
										<Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button>
									</li>
								</ul><ul className="bodyContent">
									<li className="srNo">3</li>
									<li className="name">ERF Registration</li>
									<li className="issuesBy">Govt. of Australia</li>
									<li className="date">2021 June 20th </li>
									<li className="createdBy">Manager</li>
									<li className="ipAddress">210.123.211.210</li>
									<li className="listingStatus appr">Waiting for Approval</li>
									<li className="action multiBtnOptn">
										<Button className="editBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>
										<Button className="checkedBtn" variant="" type="submit"><i className="fa fa-check"></i></Button>
										<Button className="cancelBtn" variant="" type="submit"><i className="fa fa-times"></i></Button>
										<Button className="downloadBtn" variant="" type="submit"><i className="fa fa-download"></i></Button>
										<Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">4</li>
									<li className="name">CCX Producer Agreement</li>
									<li className="issuesBy">CCX Admin</li>
									<li className="date">2021 June 20th </li>
									<li className="createdBy">Manager</li>
									<li className="ipAddress">210.123.211.210</li>
									<li className="listingStatus appr">Waiting for Approval</li>
									<li className="action multiBtnOptn">
										<Button className="editBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>
										<Button className="checkedBtn" variant="" type="submit"><i className="fa fa-check"></i></Button>
										<Button className="cancelBtn" variant="" type="submit"><i className="fa fa-times"></i></Button>
										<Button className="downloadBtn" variant="" type="submit"><i className="fa fa-download"></i></Button>
										<Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button>
									</li>
								</ul>
							</div>
						</div>

						<div className="verifyBtns">
							<Button className="secondaryBtn addprdBtn" variant="" type="submit">Verify</Button>
							<Button className="secondaryBtn cancelBtn" variant="" type="submit">Cancel</Button>
						</div>
					</div>
				</Col>
			</Row>
		</AdminLayout>
	);
};

export default VerifyCompanyDetails;
