import BurnOrderList from "../../common/BurnListItem";
import Loader from "../../common/loader";
import NoRecordsFound from "../NoRecordFound";
import styles from "./style.module.scss";
const BurnOrders = ({ data }) => {
  return (
    <>
      <div className={styles.ResellBondsSectionAlignment}>
        <div className={styles.pageTitle}>
          <h1>Burn Certificates</h1>
        </div>
        <div className={styles.allCardAlignmentForScreen}>
          {console.log(data)}
          {data === undefined ? (
            <Loader />
          ) : data && data?.length > 0 ? (
            <BurnOrderList data={data} />
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </>
  );
};
export default BurnOrders;
