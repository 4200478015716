import axios from "axios";
import Url from "../utils/appUrls";
import { postRequest, getRequest } from "./WalletApiHelper";

export const generateVerificationOTP = async(data)=>{
  const res = await postRequest(Url.GENERATE_OTP,data)
  console.log("OTP generateion",res.data )
  return res.data
}
export const verifyAccountOTP = async(data)=>{
  console.log("data", data)
  const res = await postRequest(Url.VERIFY_OTP,data)
  return res
}
export const getCryptoDepositData = (data) => {
  return postRequest(Url.CRYPTO_DEPOSITS_LIST, data).then((res) => {
    return res.data;
  });
};
export const kycStatus = async(data)=>{
  console.log("data", data)
  const res = await postRequest(Url.kycStatus,data)
  return res
}

export const forgotPassword = async(data)=>{
  console.log("data", data)
  const res = await postRequest(Url.forgotPassword,data)
  return res
}

export const resetPassword = async(data)=>{
  const urlParams = new URLSearchParams(window.location.search);
  console.log("data", urlParams)
  const res = await postRequest(Url.resetPassword,data)
  return res
}

export const verifyUser = async(data)=>{
  console.log("data", data)
  const res = await postRequest(Url.VERIFY_USER,data)
  return res
}
export const createWalletAction = async(data) => {
  // console.log("createWalletApiHitDta", data)
  const res = await  postRequest(Url.CREATE_WALLET, data)
  return res.data
};


export const getWalletAction = async(data) => {
  // console.log("getWalletApiHitDta", data)
  const res = await  postRequest(Url.GET_WALLET, data)
  // console.log("getWalletApiHitDtares", res)
  return res.data
};

export const connectWalletAction = async(data) => {
  // console.log("connectWalletApiHitDta", data)
  const res = await  postRequest(Url.CONNECT_WALLET, data)
  // console.log("rsdd", res)
  return res.data
};

export const getParticularListing = async(data) => {
  // console.log("getParticularListing", data)
  const res = await  postRequest(Url.GET_PARTICULAR_LISTING, data)
  // console.log("getWalletApiHitDtares", res)
  return res.data
};

// nft related apis
export const createMintnftAction = async(data) => {
  // console.log("createMintnftAction", data)
  const res = await  postRequest(Url.CREATE_MINTNFT, data)
  return res.data
};


export const createSalenftAction = async(data) => {
  // console.log("createSalenftAction", data)
  const res = await  postRequest(Url.CREATE_SALENFT, data)
  return res.data
};

export const getUserBalance = async(data) => {
  // console.log("getParticularListing", data)
  const res = await  postRequest(Url.GET_USER_BALANCE, data)
  // console.log("getWalletApiHitDtares", res)
  return res.data
};


export const getListedNft = async(data) => {
  const res = await  postRequest(Url.GET_LISTED_NFT, data)
  return res.data
};


export const updateMintedStatusAction = async(data) => {
  const res = await  postRequest(Url.UPDATE_MINTED_STATUS, data)
  return res.data
};

export const getMarketPlaceNft = async(data) => {
  const res = await  postRequest(Url.GET_MARKET_PLACE_NFT, data)
  return res.data
};

export const getNftFromIdAndRelatedProjects = async(data) => {
  const res = await  postRequest(Url.NFT_FROM_ID_RELATED_PROJECT, data)
  return res.data
};

export const addMoneyAction = async(data) => {
  const res = await  postRequest(Url.ADD_MONEY, data)
  return res.data
};

export const updatePaymentSatusAction = async(data) => {
  const res = await  postRequest(Url.UPDATE_PAYMENT_STATUS, data)
  return res.data
};

export const buyBidAction = async(data) => {
  const res = await  postRequest(Url.BUY_BID, data)
  return res.data
};

export const bidAction = async(data) => {
  const res = await  postRequest(Url.BID_NFT, data)
  return res.data
};

export const get_bid_transactions = async(data) => {
  const res = await  postRequest(Url.GET_BID_TRANS, data)
  return res.data
};

export const withdrawAmount = async(data) => {
  const res = await  postRequest(Url.WITHDRAW_AMOUNT, data)
  return res.data
};

export const burnNftAction = async(data) => {
  const res = await  postRequest(Url.BURN_NFT, data)
  return res.data
};

export const withdrawTransactionsLists = async(data) => {
  const res = await  postRequest(Url.WITHDRAW_TRANSACTIONS_LIST, data)
  return res.data
};

export const getSavedBankAcc = async(data) => {
  const res = await postRequest(Url.GET_SAVED_ACCOUNTS, data)
  return res.data
};
export const removeSavedBankAcc = async(data) => {
  const res = await postRequest(Url.REMOVE_SAVED_ACCOUNTS, data)
  return res.data
};
export const ContactQuery = async(data) => {
  const res = await postRequest(Url.CONTACT_QUERY_MAIL, data)
  return res.data
};