import { Container,Row,Col,Button} from 'react-bootstrap';
import {Link} from 'react-router-dom'
const Whyphaeton = ({ data }) => {
    const images = ["/images/png/Rectangle20000.png","/images/png/Rectangle20001.png","/images/png/Rectangle20002.png"]
    return (
        <>
        <div className="container-fluid featureSec py-5">
            
               <div className='whyphaetonOuter pt-5'  >
                <Row className="py-5">
               
                <Col className='listText'>
                <div className=''>
                <h1 className='aboutTitle'>Why Phaeton?</h1>
                <ul className="listTextList">
                    <li><span className='listHead'>Transparency :</span><span className="listBody">Our blockchain technology ensures immutability, eliminating the possibility of falsified transactions. Every transaction is visible in real-time to all stakeholders, guaranteeing the authenticity and value of carbon credits.</span></li>
                    <li><span className='listHead'>Security :</span><span className="listBody">Phaeton utilizes a consensus-based token system recorded on the public blockchain, ensuring that the source and value of carbon credits are known immediately, enhancing trust and security.</span></li>
                    <li><span className='listHead'>Innovation :</span><span className="listBody">Our platform supports WEB3 infrastructure, offering an enhanced wallet accessible via QRC, integrated with identity, payments, and messaging features for a seamless user experience.</span></li>
                
                </ul>
                </div>
                
               
                </Col>
                <Col >
                  <div className="row">
                    <div className="col " >
                    <div className="aboutFeatureBox">
                                <img className='aboutFeatureImgStyle' src={images[0]} alt="register-icon" />
                            </div>
                    </div>
                    <div className="col ">
                    <div className="aboutFeatureBox">
                                <img className='aboutFeatureImgStyle' src={images[1]} alt="register-icon" />
                            </div>
                    </div>

                    <div className="col ">
                    <div className="aboutFeatureBox">
                                <img className='aboutFeatureImgStyle' src={images[2]} alt="register-icon" />
                            </div>
                    </div>
                  </div>
                  

              </Col>
               
                </Row>
                </div>
        </div>
       </>
    );
};

export default Whyphaeton;
