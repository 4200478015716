import React, { useEffect } from 'react'
import ViewCTNFT from '../component/ViewCTNFT'

function ViewNFT(props) {
  return (
    <>
        <ViewCTNFT  props={props}/>
    </>
  )
}

export default ViewNFT