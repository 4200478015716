import classNames from "classnames";
import React from "react";
import { ToastProps, ToastType } from ".";
import styles from "./styles.module.scss";
const ToastContainer: React.FC<{
  toasts: ToastProps[];
  removeToast: (id: string) => void;
}> = ({ toasts, removeToast }) => {
  const getToastIcon = (type?: ToastType) => {
    switch (type) {
      case "success":
        return <i className="fa-regular fa-circle-check"></i>;
      case "error":
        return <i className="fa-solid fa-triangle-exclamation"></i>;
      default:
        return <i className="fa-solid fa-circle-info"></i>;
    }
  };
  const getClassName = (type?: ToastType) => {
    switch (type) {
      case "success":
        return styles.success;
      case "error":
        return styles.error;
      default:
        return styles.info;
    }
  };
  return (
    <div className={styles.Container}>
      {toasts.map((toast) => (
        <div
          className={classNames([
            styles.Toast,
            getClassName(toast.type),
            "slide-in-blurred-right slide-out-top",
          ])}
          key={toast.id}>
          {getToastIcon(toast.type)}
          <div className={styles.ToastMessage}>{toast.message}</div>
          <i
            className="fa-solid fa-xmark"
            onClick={() => {
              removeToast(toast.id);
            }}></i>
        </div>
      ))}
    </div>
  );
};

export default ToastContainer;
