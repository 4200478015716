export class StorageHelper {
  static getUserInfo() {
    const user_id = localStorage.getItem("userId");
    const access_token = localStorage.getItem("accessToken");
    const wallet_address = localStorage.getItem("walletAddress");
    const partner_type = localStorage.getItem("partner_type");
    const partner_state = localStorage.getItem("partner_state");
    return {
      user_id,
      access_token,
      wallet_address,
      partner_type,
      partner_state,
    };
  }
}
