import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import SubHeader from "../component/Subheader";
import MyCollections from "../component/MyCollection";
import { useHistory } from "react-router-dom";
import { StorageHelper } from "../utils/StorageHelper";
const MyCollection = () => {
  const history = useHistory();
  const userDetail = StorageHelper.getUserInfo();
  const [partnerType] = [userDetail.partner_type];
  useEffect(() => {
		if (localStorage.getItem('userType') !== "polluter") {
		  history.push(`/edit-profile`);
		}
	}, [partnerType]);
  return (
    <>
      <SubHeader />
      <Container>
        <MyCollections />
      </Container>
    </>
  );
};
export default MyCollection;
