import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useToast } from "../../context/Toast/useToast";
import useModalClose from "../customHooks/useModalClose";
import styles from "./modal.module.scss";
export default function CommonModal({
  modalOpen,
  handleClose,
  handleSubmit,
  BodyMessage,
  ButtonMessage,
  textbox = false,
  bondData,
  SecondMessage,
}) {
  let ref = useRef(null);
  const rx_live = /^[0-9\b]+$/;
  const { addToast } = useToast();
  const [price, setPrice] = useState(bondData?.certificates[0]?.sale_price);
  useModalClose(ref, handleClose);
  const onsubmit = () => {
    if (textbox) {
      if (price > 0 && price !== undefined) {
        handleSubmit(price);
      } else {
        addToast({
          message: "Please enter a valid Price",
          type: "error",
        });
      }
    } else {
      handleSubmit();
    }
  };

  const handleInput = (e) => {
    console.log(rx_live.test(e.target.value));
    if (rx_live.test(e.target.value)) {
      setPrice(e.target.value);
    }
    if (price.toString().length == 1 && e.target.value == "") {
      setPrice("");
    }
  };
  return (
    <div>
      {modalOpen && (
        <div className={styles.modalWrapper}>
          <div ref={ref} className={styles.paymentModal}>
            <div className={styles.paymentModalHeader} onClick={handleClose}>
              <img
                src="/images/common/close-modal.svg"
                width={25}
                height={25}
                alt=""
              />
            </div>
            <div className={styles.paymentModalBody}>
              <div className={styles.imageCenterAlignment}>
                {/* <img src="images/dollar-icon.svg" /> */}
              </div>
              <h3>{BodyMessage ? BodyMessage : ""}</h3>
              <h1>{SecondMessage ? SecondMessage : ""}</h1>
              {textbox ? (
                <div className="documentType my-4">
                  <Form>
                    <Form.Group controlId="formBasicQtyRequir">
                      <Form.Control
                        type="number"
                        placeholder="Price at you want to sell ?"
                        value={price ? price : ""}
                        onChange={handleInput}
                        min={0}
                        minLength={1}
                      />
                    </Form.Group>
                  </Form>
                </div>
              ) : null}
              <button onClick={() => onsubmit()}>
                {ButtonMessage ? ButtonMessage : ""}
              </button>
              <div className={styles.textGrid}>
                <div className={styles.textGridItems}>
                  <div className={styles.textDivider}></div>
                </div>
                <div className={styles.textGridItems} onClick={handleClose}>
                  <span>Cancel</span>
                </div>
                <div className={styles.textGridItems}>
                  <div className={styles.textDivider}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
