import React, { useEffect, useState } from "react";
import { Container, Row, Col, Carousel, Form, Button } from "react-bootstrap";

import { useLoader } from "../context/Loader/useLoader";
import { useToast } from "../context/Toast/useToast";
import { postContactUs } from "../action/Api.action";
import { ContactQuery} from '../action/walletApiAction'

const Contact = ({ history }) => {
  const { setLoading } = useLoader();
  const { addToast } = useToast();
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    subject: "",
    question: "",
  });
  useEffect(() => {
    setLoading(false);
  }, []);
  const handleChange = (e) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, subject, question } = userInfo;
    if (name && email && subject && question) {
      const body = {
        name,
        email,
        subject,
        question,
      };
      const res = await postContactUs(body);
      // const res = await ContactQuery(body);

      if (res.status) {
        addToast({
          message: res.message,
          type: "success",
        });
      } else {
        addToast({
          message: res.message,
          type: "error",
        });
      }
    } else {
      addToast({
        message: "Please enter all details",
        type: "error",
      });
    }
  };
  return (
    <Container>
      <div className="homeBanner burger">
        <Row>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="assets/img/homeBanner1.png"
                alt="First slide"
              />
              <Carousel.Caption>
                <h2>
                  Access the World's <br /> Carbon Credit Markets
                </h2>
                <p>
                  via the World's First Phaeton Blockchain Based Exchange for
                  Carbon Credits
                </p>
                <div className="bannerBtns">
                  <Button variant="" className="secondaryBtn">
                    Contact us
                  </Button>
                  <Button variant="" className="primaryBtn">
                    Join Now
                  </Button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="assets/img/homeBanner1.png"
                alt="Second slide"
              />
              <Carousel.Caption>
                <h2>
                  Access the World's <br /> Carbon Credit Markets
                </h2>
                <p>
                  via the World's First Phaeton Blockchain Based Exchange for
                  Carbon Credits
                </p>
                <div className="bannerBtns">
                  <Button variant="" className="secondaryBtn">
                    Contact us
                  </Button>
                  <Button variant="" className="primaryBtn">
                    Join Now
                  </Button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src="assets/img/homeBanner1.png"
                alt="Third slide"
              />
              <Carousel.Caption>
                <h2>
                  Access the World's <br /> Carbon Credit Markets
                </h2>
                <p>
                  via the World's First Phaeton Blockchain Based Exchange for
                  Carbon Credits
                </p>
                <div className="bannerBtns">
                  <Button variant="" className="secondaryBtn">
                    Contact us
                  </Button>
                  <Button variant="" className="primaryBtn">
                    Join Now
                  </Button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Row>
      </div>

      <div className="contactSec burger">
        <Row>
          <Col lg={5} xl={4}>
            <div className="heading">
              <h5 className="miniHeading">Contact Us</h5>
              <h1 className="bigHeading">Get In Touch</h1>
              <p>
                Your email will remain private, please fill in the required
                fields marked *{" "}
              </p>
            </div>

            <Form className="formContent" onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicFirstName">
                <Form.Control
                  type="text"
                  placeholder="Name*"
                  name="name"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email*"
                  name="email"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicFirstName">
                <Form.Control
                  type="text"
                  placeholder="Subject*"
                  name="subject"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  as="textarea"
                  placeholder="Question*"
                  rows={4}
                  name="question"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>

              <Button className="primaryBtn" variant="" type="submit">
                Send Message
              </Button>
            </Form>
          </Col>
          <Col lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 2 }}>
            <div className="mapSec">
              <div className="imgBox">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9287.492155547525!2d115.81494308894834!3d-32.10055576844735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32987a445672a3%3A0x844f8c2fda3c7c1d!2s17%20Discovery%20Dr%2C%20Bibra%20Lake%20WA%206163!5e0!3m2!1sen!2sau!4v1686713054100!5m2!1sen!2sau"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="new-iframe"></iframe>
                {/* <img src="assets/img/addressMap.png" className="img-fluid" /> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="contactInfo burger">
        <Row>
          <Col sm={4}>
            <div className="contactDetails">
              <div className="imgBox">
                <img src="assets/img/location.png" alt="producer-icon" />
              </div>
              <div>
                <h5>Find Us</h5>
                <p>17 Discovery Drive, 6163 WA, Australia.</p>
              </div>
            </div>
          </Col>

          {/* <Col sm={4}>
            <div className="contactDetails">
              <div className="imgBox">
                <img src="assets/img/call.png" alt="trader-icon" />
              </div>
              <div>
                <h5>Our Phone</h5>
                <p>+61 (212) 64-33-35</p>
                <p>+61 (212) 27-85-22</p>
              </div>
            </div>
          </Col> */}

          <Col sm={4}>
            <div className="contactDetails">
              <div className="imgBox">
                <img src="assets/img/email.png" alt="polluter-icon" />
              </div>
              <div>
                <h5>Our Email</h5>
                <p>support@phaeton.io</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Contact;
