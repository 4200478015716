import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SubHeader from '../../Subheader'
import {getBidData} from "../../../action/Api.action"

import { useLocation } from 'react-router-dom';

function OfferMade() {
  const { state } = useLocation();
  const [subPagination, SetPagination] = useState()
  const [bidList, setBidList] = useState([])
useEffect(async()=>{
  SetPagination(state)
  let res
  if(state.pagename == 'Offer Received'){
        res = await getBidData({
            user_id : localStorage.getItem('userId'),
            bid_type : "received"
        })
   }
   else{
    res = await getBidData({
        user_id : localStorage.getItem('userId'),
        bid_type : "made"
    })
   }
   console.log("Ashmin",res)
setBidList(res.Bid_list)
  
},[state])

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


console.log("Ashmin Test for category",state.pagename)
  return (
    <>
        <SubHeader subpagi={subPagination} />
        <Container className='bg-white' fluid>
            <Row>
                <Col lg={12}>
                    <div className='table-resposive tableBox'>
                        <table>
                            <thead>
                                <tr>
                                    <td>Sr. No</td>
                                    <td>NFT Name</td>
                                    <td>Bid Amount</td>
                                    <td>Transaction Hash</td>
                                    <td>NFT Registration No.</td>
                                    <td>NFT Asset ID</td>
                                    <td>{state.pagename == 'Offer Made' ? "Owner" : "Bidder"} address</td>
                                    <td>Date of Bid</td>
                                    <td>Bid Status </td>
                                    <td>Auction Status</td>
                                </tr>
                            </thead>
                            <tbody>
                            {bidList && bidList?.length > 0 ? (
                    bidList?.map((item, i) => {
                      return (<>
                        <tr>
                          {/* <td>{res?.accu}</td>
                                    <td>{res?.authority_id}</td>
                                    <td>{res?.price}</td>
                                    <td>{capitalizeFirstLetter(res?.state)}</td> */}
                          <td>{i + 1}</td>
                          <td>{item.project_title}</td>
                          <td>{item.price}</td>
                          <td>{item.transaction_hash.slice(0,4) + "..." + item.transaction_hash.slice(-3)}</td>
                          <td>{item.registration_number}</td>
                          <td>{item.serial_number}</td>
                          <td>{state.pagename == 'Offer Made' ? (item.owner_address.slice(0,4) + "..." + item.owner_address.slice(-3)) : (item.bidder_address.slice(0,4)+"..."+item.bidder_address.slice(-3))}</td>
                          <td>{item.date}</td>
                          <td style={{"color" : (item.bid_status == "pending" ? "orange" : (item.bid_status == "success" ? "green" : "red")) }}><strong>{capitalizeFirstLetter(item.bid_status)}</strong></td>
                          <td style={{"color" : (item.auction_end? "red" : "green")}}><strong>{item.auction_end ? "Ended" : "Live"}</strong></td>  
                          
                        </tr>

                      </>)
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>No Records Found.</td>
                    </tr>
                  )}
                                
                                                    </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default OfferMade