import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CreateCTNFT from "./CreateCTNFT";

const EditCTNFT = () => {
  // const getDapp = useSelector((state) => state.home.dapp);
  // const history = useHistory();
  // if (!getDapp) history.push("/edit-profile");

  // return <CreateCTNFT editDapp={getDapp} />;
  return <CreateCTNFT />;
};

export default EditCTNFT;
