import { Container,Row,Col,Button} from 'react-bootstrap';
import './styles.css'
import {Link} from 'react-router-dom'
const FeatureSec = ({ data }) => {
    const images = ["/images/png/register.png","/images/png/update.png","/images/png/create-ctnft.png","/images/png/publish-ctnft.png","/images/png/sold.png","/images/png/money-received.png"]
    return (
        <>
        <div className="container-fluid featureSec py-5">
            
               <div className=' pt-5'  >
                <Row>
               
                <Col className='featureText'>
                <div className='mx-5 px-5'>
                <p className='aboutHead'>Marketplace</p>
                <h1 className='aboutTitle'>Features</h1>
                <p className='aboutSubTitle'>Reduction in operating costs for the whole product team which creates Reduction in operating costs for the whole product team which creates Reduction in operating costs.</p>
                <Button variant="" className="primaryBtn featureBtn">View Features</Button>
                </div>
                
               
                </Col>
                <Col >
                <div className="container">
                  <div className="row">
                    <div className="col " >
                    <div className="featuresContent featureBox">
                                <img src={images[0]} alt="register-icon" />
                                <p className='featureTitle'>Register Yourself</p>
                                <p className="featureText">Sign up and create your account.</p>
                            </div>
                    </div>
                    <div className="col ">
                    <div className="featuresContent featureBox">
                                <img src={images[1]} alt="register-icon" />
                                <p className='featureTitle'>Update & KYC Documents</p>
                                <p className="featureText">Complete your profile and upload  KYC documents.</p>
                            </div>
                    </div>

                    <div className="col ">
                    <div className="featuresContent featureBox">
                                <img src={images[2]} alt="register-icon" />
                                <p className='featureTitle'>Create Your CNFT</p>
                                <p className="featureText">Mint your carbon credits as NFTs.</p>
                            </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col ">
                    <div className="featuresContent featureBox">
                                <img src={images[3]} alt="register-icon" />
                                <p className='featureTitle' >Publish Your CNFT</p>
                                <p className="featureText">List your carbon credit NFTs on the marketplace.</p>
                            </div>
                    </div>
                    <div className="col ">
                    <div className="featuresContent featureBox">
                                <img src={images[4]} alt="register-icon" />
                                <p className='featureTitle'>Sell Your CNFT</p>
                                <p className="featureText">Engage with buyers and sell your carbon credit NFTs.</p>
                            </div>
                    </div>
                    <div className="col ">
                    <div className="featuresContent featureBox">
                                <img src={images[5]} alt="register-icon" />
                                <p className='featureTitle'>Receive Money From Buyers</p>
                                <p className="featureText">Get paid for your sold carbon credit NFTs.</p>
                            </div>
                    </div>
                  </div>
                </div>

              </Col>
               
                </Row>
                </div>
        </div>
       </>
    );
};

export default FeatureSec;
