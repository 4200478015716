import React, { useState, useEffect } from "react";
import styles from "./order.module.scss";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import NoRecordsFound from "../../component/NoRecordFound";
import Pagination from "../../common/pagination";
import { BASE_URL } from "../../utils/appUrls";
import axios from "axios";
import fileDownload from "js-file-download";

function BurnOrderList({ data }) {
  const [viewMore, setViewMore] = useState(false);
  const [cardOpen, setCardOpen] = useState();
  const [width, setWidth] = useState();
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [bondData, setBondData] = useState("");
  const itemsPerPage = process.env.REACT_APP_ITEMPERPAGE;
  const [currentPage, setCurrentPage] = useState(1);
  const [isReformatCertificates, setReformatCertificates] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);
  const shortIssuerName = (issuerName) => {
    if (issuerName && issuerName.length > 10) {
      return issuerName.substr(0, 10) + "...";
    } else {
      return issuerName;
    }
  };
  const handleOpen = () => {
    setWithdrawModal(!withdrawModal);
  };

  const updatePrice = (event, bonddata) => {
    event.preventDefault();
    setModalOpen(true);
    setBondData(bonddata);
  };
  const handleClose = () => {
    setModalOpen(false);
  };
  const handleStatusClose = () => {
    setStatusModalOpen(false);
  };
  const updateStatus = (event, bonddata) => {
    event.preventDefault();
    setStatusModalOpen(true);
    setBondData(bonddata);
  };

  const handleViewMore = (i) => {
    let checkCardOpen = cardOpen ? cardOpen : i;

    setViewMore(i === checkCardOpen ? !viewMore : viewMore);
    setCardOpen(i);
    let certificates = [];
    data[i].certificates.forEach((info) => {
      certificates.push(info);
    });
    let reformatCertificates = [];
    for (let j = 0; j < itemsPerPage; j++) {
      reformatCertificates.push(certificates[j]);
    }
    setReformatCertificates(reformatCertificates);
    setAllCertificates(certificates);
  };
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };
  const shortHeader = (name) => {
    if (name.length > 8) {
      let shortName = name?.substr(0, 8);
      return shortName + "...";
    } else {
      return name;
    }
  };
  const handleDocDownload = (resp) => {
    let docs = [];
    resp?.docs.forEach((info) => {
      docs.push({
        document_url: info.document_url,
        file_type: info.file_type,
        doc_name: info.doc_name,
      });
    });
    docs.forEach((download) => {
      const fileType = download.file_type;
      const splitExt = fileType.split("/");
      const fileExt = splitExt[1];
      const fileNameReplace = download.doc_name.replace(/ /g, "_");
      const fileName = fileNameReplace + "." + fileExt;
      axios
        .get(BASE_URL + download.document_url, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, fileName);
        });
    });
  };

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  const handleDocPagination = (page) => {
    if (page) {
      let startPage = page * itemsPerPage;
      let endPage = page + 1 * itemsPerPage;
      let nextPrevData = [];
      allCertificates.forEach((data, index) => {
        if (index + 1 >= startPage && index + 1 < endPage) {
          nextPrevData.push(data);
        }
      });
      setReformatCertificates(nextPrevData);
      //window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  const handlePagination = (page) => {
    if (page) {
      window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  return (
    <>
    {console.log("New Test for burn Orders")}
      <div className={styles.orderSection}>
        <div className={`${styles.list}`}>
          <div className={`${styles.listItems}`}>
            <div className={styles.orderListBox}>
              <div>
                <div className={`${styles.listBoxBody}`}>
                  <div className={`${styles.listAlignment}`}>
                    <div>
                      <p>Id# </p>
                    </div>
                    <div>
                      <p>owner </p>
                    </div>

                    <div>
                      <p>Status</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.orderSection}>
          <div className="container-wrapper">
            <div className={`${styles.list}`}>
              {data && data.length > 0 ? (
                data?.map((res, i) => {
                  return (
                    <div key={i} className={`${styles.listItems}`}>
                      <div className={styles.orderListBox}>
                        <div>
                          <div className={`${styles.listBoxBody}`}>
                            <div className={`${styles.listAlignment}`}>
                              <div>
                                {/* <p>Order# </p> */}
                                <span>{shortHeader(res?.order_reference)}</span>
                              </div>
                              <div>
                                {/* <p>Partner Name </p> */}
                                <span>{shortHeader(res?.partner_name)}</span>
                              </div>
                              <div>
                                {/* <p>Date</p> */}
                                <span>
                                  {" "}
                                  {moment(res?.date).format("MMM-D-yy")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"d-grid"}>
                          <div className={`${styles.listBoxFooter}`}>
                            <div className={styles.ExplorerButton}>
                              {viewMore && width <= 768 && cardOpen === i ? (
                                <></>
                              ) : (
                                <button onClick={() => handleViewMore(i)}>
                                  {viewMore && cardOpen === i
                                    ? "View Less"
                                    : "View More"}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {viewMore && cardOpen === i ? (
                        <div className={styles.viewMoreContainer}>
                          {isReformatCertificates?.map((resp, index) => {
                            return (
                              <div className={styles.orderDetails} key={index}>
                                <div className={styles.orderBondDetail}>
                                  <div>
                                    <h4>ACCU Certificate</h4>
                                    <p>{shortHeader(resp?.accu)}</p>
                                  </div>
                                  <div>
                                    <h4>Price</h4>
                                    <p>{resp?.price}</p>
                                  </div>
                                  <div>
                                    <h4>Document</h4>
                                    <p>
                                      <button
                                        onClick={() => {
                                          handleDocDownload(resp);
                                        }}
                                        className="secondaryBtn submitBtn btn btn-primary">
                                        Download
                                      </button>
                                    </p>
                                    {/*resp.docs.map((info, index) => {
                                      return (
                                        <p key={index} className="my-2">
                                          <button
                                            onClick={() => {
                                              handleDownload(
                                                BASE_URL.HOST +
                                                  info.document_url,
                                                "download.png"
                                              );
                                            }}
                                            className="secondaryBtn submitBtn btn btn-primary">
                                            Download
                                          </button>
                                        </p>
                                      );
                                    })*/}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <Pagination
                            itemsCount={res.certificates.length || 0}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            setCurrentPage={handleDocPagination}
                            alwaysShown={false}
                          />
                          {viewMore && width <= 768 ? (
                            <div className={"d-grid"}>
                              <div className={`${styles.listBoxFooter}`}>
                                <button onClick={() => handleViewMore(i)}>
                                  {viewMore ? "View Less" : "View More"}
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <NoRecordsFound />
              )}
            </div>
            {data && data?.total_burn_count > 0 ? (
              <Pagination
                itemsCount={data?.total_burn_count || 0}
                itemsPerPage={data?.burn_count || 9}
                currentPage={currentPage}
                setCurrentPage={handlePagination}
                alwaysShown={false}
              />
            ) : null}
          </div>
        </div>
      </div>

      {/* <div className={styles.BondListItem} key={index}>
        <div className={styles.TitleWrapper}>
          <span className={styles.IssuerName} title={bonddata?.order_reference}>
            {shortIssuerName(bonddata?.order_reference)}
          </span>
        </div>
        <Row>
          <div className={styles.BondInfo} key={index}>
            <Col>
              <div className={styles.InfoItem}>
                <span className={styles.Label}>Burn Date</span>
                <span className={styles.Value}>{bonddata?.date}</span>
              </div>
            </Col>
            <Col>
              <div className={styles.InfoItem}>
                <span className={styles.Label}>Partner Name</span>
                <span className={styles.Value}>{bonddata?.partner_name}</span>
              </div>
            </Col>
            <Col>
              <div className={styles.InfoItem}>
                <span className={styles.Label}>Partner Id</span>
                <span className={styles.Value}>{bonddata?.partner_id}</span>
              </div>
            </Col>
          </div>
        </Row>
      
      </div> */}
      {/* {withdrawModal && (
        <ResellModal
          handleClose={handleOpen}
          modalOpen={withdrawModal === true}
          quantity={bonddata?.qty}
          name={bonddata?.bond_name}
          bondId={bonddata?.bond_id}
          bondQuantId={bonddata?.bond_quant_id}
        />
      )}
      {modalOpen && (
        <BondUpdatePrice
          modalOpen={modalOpen}
          handleClose={handleClose}
          setModalOpen={setModalOpen}
          bondData={bondData}
    }
        />
      )}
      {statusModalOpen && (
        <BondUpdateStatus
          statusModalOpen={statusModalOpen}
          handleStatusClose={handleStatusClose}
          setStatusModalOpen={setStatusModalOpen}
          bondData={bondData}
    }
        />
      )} */}
    </>
  );
}

export default BurnOrderList;
