import { Row, Col, Button } from 'react-bootstrap';
import './styles.css'
import { Link } from 'react-router-dom'
const BannerSec = ({ data }) => {
    const images = ["/images/png/producer.png", "/images/png/trader.png", "/images/png/consumer.png"]
    return (
         <div className="bannerSec1 container-fluid" >

<div className='bannerDiv' >
    <Row>
        <Col className = 'mt-5 '>
            <div >
                <h1 className='bannerTitle'>Access the World's Carbon Credit Markets</h1>
                <p className='bannerSubTitle'> Via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
            </div>
            <Link to="/contactus"><Button variant="" className="bannerBtn ">Contact us</Button></Link>
            <Link to="/login"><Button variant="" className="bannerBtn " style={{  marginLeft: '5px'}}>Join Now</Button></Link>

        </Col>
        <Col className="bannerImgSec" >

            <img className='bannerImg' src='/images/png/homeBanner1.png' style={{  height: '100%'}}></img>

        </Col>
    </Row>
    <Row>
        <div className='infoPanelBanner'>
        <div className="container infoPanel">
      <div className="row mt-2 py-3 px-3">
        <div className="col" >
        <div className="centreText">
                    <img src={images[0]} alt="register-icon" />
                    <p className='ProducerBlock py-1'>Producer</p>
                    <p>Landowners, Framers, and entities who comply with the Clean Energy Regulator’s policies.</p>
                </div>
        </div>
        <div className="col">
        <div className="centreText">
                    <img src={images[1]} alt="register-icon" />
                    <p className='TraderBlock py-1'>Trader</p>
                    <p>Traders can range from institutions to small everyday investors (Moms and Dads and Millennials)</p>
                </div>
        </div>

        <div className="col">
        <div className="centreText">
                    <img src={images[2]} alt="register-icon" />
                    <p className='ConsumerBlock py-1'>Consumer</p>
                    <p>Industrialists, coal-fired plants, manufacturers</p>
                </div>
        </div>
      </div>
      </div>
        </div>
    </Row>
</div>
</div>
    );
};

export default BannerSec;
