import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalTitle, Nav } from 'react-bootstrap'
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Link } from "react-router-dom";

function KycStatus(props) {
  // const [showWallet, setShowWallet] = useState(true);
  console.log("KYC CALLEDSSSSSSSSSSSss ", props)

  // useEffect(() => {
  //     setShowWallet(props.statusshow);
  //     // setShowFucn(props.walletFunc)
  //     console.log('deepak',props);
  //   }, [props]);
  //   const showFucn = () => {
  //     props.walletFunc();
  //   };
  return (
    <>
      <Modal show={props.show} onHide={props.setModalOpen} size="lg" aria-labelledby="contained-modal-title-vcenter" className="kycstatusdialog" centered>
        <ModalBody>
          <div className="kycstatusBox">
            <img src="/images/svg/kyStatusImage.svg" alt="" width={350} />
            <h1>Verifying Your Details</h1>
            <p>Your KYC  details are currently being processed. Our team is diligently working on verifying the information provided to ensure compliance with regulatory standards.</p>
            <Link to="/">
              <button className="secondaryBtn  mx-auto w-auto btn px-5">Back to Home</button>
            </Link>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default KycStatus