import React from "react";
const CustomRadioButton = ({
  items,
  selected,
  name,
  onChange,
  direction = "horizontal",
  //   className,
}) => {
  return (
    <div
      //   className={className}
      style={{
        display: "flex",
        flexDirection: direction === "horizontal" ? "row" : "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 20,
        height: 50,
      }}>
      {items.map((item, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              // fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "18",
              lineHeight: "49",
              color: "black",
              gap: 10,
            }}
            onClick={(e) => {
              e.preventDefault();
              if (onChange) {
                onChange(item);
              }
            }}>
            {item.value === selected?.value ? (
              <i className="fa-regular fa-circle-dot"></i>
            ) : (
              <i className="fa-regular fa-circle"></i>
            )}{" "}
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default CustomRadioButton;
