import axios from "axios";
import Url from "../utils/appUrls";
import { postRequest, getRequest } from "./helper";
var authorization = localStorage.getItem('token')
console.log("authorization", authorization)
// const api = `your api here`
// axios.get(api, { headers: { "Authorization": `Bearer ${token}` } })
//   .then(res => {
//     console.log(res.data);
//     this.setState({
//       items: res.data,  /*set response data in items array*/
//       isLoaded: true,
//       redirectToReferrer: false
//     })
//   })

export const loginAction = (data) => {
  return postRequest(Url.USER_AUTH, data).then((res) => {
    return res.data;
  });
};

export const signup = (data) => {
  return postRequest(Url.USER_REGISTER, data).then((res) => {
    return res.data;
  });
};
export const otpGenerate = (data) => {
  console.log("checkin",data)
  return postRequest(Url.USER_verification, data).then((res) => {
   
    return res.data;
  });
};

export const verifyauth = (data) => {
  console.log("checkin verify Auth",data)
  return postRequest(Url.verifyauth, data).then((res) => {
   
    return res.data;
  });
};
export const uploadProfilePicture = (data) => {
  return postRequest(Url.UPLOAD_PROFILE_PICTURE , data).then((res) => {
    return res.data;
  });
};
export const fetchProfilePicture = (data) => {
  return postRequest(Url.FETCH_PROFILE_PICTURE , data).then((res) => {
    return res.data;
  });
};
export const updateUserProfile = (data) => {
  return postRequest(Url.UPDATE_USER_PROFILE, data).then((res) => {
    return res.data;
  });
};
export const deleteProfilePicture = (data) => {
  return postRequest(Url.DELETE_PROFILE_PICTURE , data).then((res) => {
    return res.data;
  });
};
export const updateUserPassword = (data) => {
  return postRequest(Url.UPDATE_USER_PASSWORD, data).then((res) => {
    return res.data;
  });
};
export const uploadProjectAssets = (data) => {
  return postRequest(Url.UPLOAD_PROJECT_ASSETS , data).then((res) => {
    return res.data;
  });
};
export const fetchUser = (data) => {
  return postRequest(Url.FETCH_USER, data).then((res) => {
    return res.data;
  });
};
export const getUserData = (data) => {
  return postRequest(Url.USER_DATA, data).then((res) => {
    return res.data;
  });
};
export const otpVerficationAction = (data) => {
  return postRequest("otpVerification", data).then((res) => {
    console.log("checkinini for res from wallet api", res.data)
    return res.data;
  });
};
export const forgotPasswordAction = (data) => {
  return postRequest("forgotPassword", data).then((res) => {
    return res.data;
  });
};

export const resetPasswordAction = (data) => {
  return postRequest("resetPassword", data).then((res) => {
    return res.data;
  });
};

export const updateCompanyInfoAction = (data) => {
  return postRequest("updateCompanyInfo", data).then((res) => {
    return res.data;
  });
};

export const uploadContractFileAction = (data) => {
  return postRequest("uploadContractFile", data).then((res) => {
    return res.data;
  });
};

export const getUserProductListAction = (data) => {
  return getRequest("getUserProductList", data).then((res) => {
    return res.data;
  });
};

export const getCTNFTListAction = (data) => {
  return postRequest("getProductList", data).then((res) => {
    return res.data;
  });
};

export const addBid = (data) => {
  return postRequest("addBid", data).then((res) => {
    return res.data;
  });
};
export const fetchWalletConnect = async (data) => {
  return await getRequest(Url.RANDOM_MESSAGE + data).then((res) => {
    return res.data;
  });
};
export const fetchCountryData = async () => {
  return await getRequest(Url.COUNTRY_DROWPDOWN).then((res) => {
    return res.data;
  });
};
export const getStatesList = async (data) => {
  return await getRequest(Url.STATE_DROPDOWN + data).then((res) => {
    return res.data;
  });
};
export const getUserDetails = async () => {
  return await postRequest(Url.PROFILE_DETAIL).then((res) => {
    return res.data;
  });
};
export const generateAccessToken = async (account, signedMessage) => {
  const data = {
    address: account,
    digital_signature: signedMessage,
  };
  return await postRequest(Url.VERIFY_SIGN, data).then((res) => {
    return res.data;
  });
};
export const uploadProfileImage = async (props) => {
  const image = true;
  const data = new FormData();
  console.log("PROPS", props);
  data.append("auth_access_token", props.auth_access_token);
  data.append("user_id", props.user_id.toString());
  data.append("image", props.image);

  return await postRequest(Url.UPLOAD_PROFILE_IMAGE, data, image).then(
    (res) => {
      return res.data;
    }
  );
};
export const updateKYCstateSubmitted = async (data) => {
  return await postRequest(Url.KYC_STATUS_UPDATE, data).then((res) => {
    return res.data;
  });
};
export const deleteKYCDocument = async (data) => {
  return await postRequest(Url.KYC_DOC_DELETE, data).then((res) => {
    return res.data;
  });
};
export const updatePartnerState = async (data) => {
  return await postRequest(Url.PARTNER_STATE_UPDATE, data).then((res) => {
    return res.data;
  });
};
export const addProductAction = async (data) => {
  return await postRequest(Url.CERTIFICATE_UPDATE, data).then((res) => {
    return res.data;
  });
};
export const addProject = async (data) => {
  return await postRequest(Url.PROJECT_CREATE, data).then((res) => {
    return res.data;
  });
};

export const UploadDocument = async (props) => {
  const image = true;
  const data = new FormData();
  console.log("Check this", props);
  data.append("auth_access_token", props.auth_access_token);
  data.append("user_id", props.user_id.toString());
  data.append("doc_type_id", props.doc_type_id.toString());
  data.append("file_name", props.doc_file.name.trim());
  data.append("doc_file", props.doc_file);
  if (props.doc_id) {
    data.append("document_id", props.doc_id.toString());
  }
  // console.log("Check this", data);
  return await postRequest(Url.UPLOAD_PROFILE_DOCUMENT, data, image).then(
    (res) => {
      return res.data;
    }
  );
};
export const UploadCTNFDocument = async (props) => {
  const image = true;
  const data = new FormData();

  data.append("auth_access_token", props.auth_access_token);
  data.append("user_id", props.user_id.toString());
  data.append("document_id", props.document_id.toString());
  data.append("file_name", props.file_name.trim());
  data.append("doc_file", props.doc_file);

  return await postRequest(Url.CERTIFICATE_DOCUMENTS_UPLOAD, data, image).then(
    (res) => {
      return res.data;
    }
  );
};

export const downloadProfileDocuments = (id) => {
  return getRequest(Url.DOWNLOAD_PROFILE_DOCUMENTS + id).then((res) => {
    return res.data;
  });
};
export const getRequiredDocuments = () => {
  return getRequest(Url.GET_REQUIRED_DOCUMENTS).then((res) => {
    return res.data;
  });
};
export const getQuateDate = () => {
  return postRequest(Url.GET_QUOTE).then((res) => {
    return res.data;
  });
};
export const getWebContent = async (link, id, token) => {
  const config = {
    method: "post",
    url: Url.HOST + link,
    headers: {
      "Content-Type": "text/plain",
    },
    data: {
      auth_access_token: token,
      user_id: id,
    },
    responseType: "blob",
  };
  const result = await axios(config);
  return result;
};

export const certificateReqDocument = (data) => {
  return getRequest(Url.CERTIFICATE_DOCUMENTS_REQUIRE + data).then((res) => {
    return res.data;
  });
};
export const certificateDocFetch = (data) => {
  return postRequest(Url.FETCH_CERTIFICATE_DOCUMENTS, data).then((res) => {
    return res.data;
  });
};
export const projectDataFetch = (data) => {
  return postRequest(Url.FETCH_PROJECT_DATA , data).then((res) => {
    return res.data;
  });
};
export const projectAssetDataFetch = (data) => {
  return postRequest(Url.FETCH_PROJECT_ASSET_DATA , data).then((res) => {
    return res.data;
  });
};
export const getPrice = (data) => {
  return postRequest(Url.GET_PRICE, data).then((res) => {
    return res.data;
  });
};
export const updateCartApi = (data) => {
  return postRequest(Url.UPDATE_CART, data).then((res) => {
    return res.data;
  });
};
export const getCartData = (data) => {
  return postRequest(Url.GET_CART_DETAIL, data).then((res) => {
    return res.data;
  });
};
export const getTradingData = () => {
  return getRequest(Url.TRADING_HISTORY).then((res) => {
    return res.data;
  });
};
export const getOrderData = () => {
  return getRequest(Url.ORDER_HISTORY).then((res) => {
    return res.data;
  });
};
export const postOrderData = () => {
  return postRequest(Url.ORDER_HISTORY).then((res) => {
    return res.data;
  });
};
export const getPhaePrice = () => {
  return postRequest(Url.PHAE_VALUE).then((res) => {
    return res.data;
  });
};
export const getMyCollection = () => {
  return postRequest(Url.MY_COLLECTION).then((res) => {
    return res.data;
  });
};
export const getMyReSell = () => {
  return postRequest(Url.MY_RESELL).then((res) => {
    return res.data;
  });
};
export const getMyOrder = () => {
  return postRequest(Url.MY_ORDER).then((res) => {
    return res.data;
  });
};
export const saveTransactionHash = (data) => {
  return postRequest(Url.SAVE_TRANSACTION_HASH, data).then((res) => {
    return res.data;
  });
};
export const createResell = (data) => {
  return postRequest(Url.CREATE_RESELL, data).then((res) => {
    return res.data;
  });
};
export const createDelist = (data) => {
  return postRequest(Url.DELIST_CTNF, data).then((res) => {
    return res.data;
  });
};
export const createBurn = (data) => {
  return postRequest(Url.CREATE_BURN, data).then((res) => {
    return res.data;
  });
};
export const resellBlockChainMetaData = (data) => {
  return postRequest(Url.RESELL_BLOCKCHAIN_METADATA, data).then((res) => {
    return res.data;
  });
};
export const deListBlockChainMetaData = (data) => {
  return postRequest(Url.DELIST_BLOCKCHAIN_METADATA, data).then((res) => {
    return res.data;
  });
};

export const getBurnList = () => {
  return postRequest(Url.BURN_ORDER_LIST).then((res) => {
    return res.data;
  });
};
export const resellPriceChange = (data) => {
  return postRequest(Url.RESELL_PRICE_CHANGE, data).then((res) => {
    return res.data;
  });
};
export const getWithdrawList = () => {
  return postRequest(Url.WITHDRAW_LIST).then((res) => {
    return res.data;
  });
};
export const createBurnOrder = (data) => {
  return postRequest(Url.CREATE_BURN_ORDER, data).then((res) => {
    return res.data;
  });
};

export const getNonceData = (address) => {
  return getRequest(Url.GET_NONCE + address).then((res) => {
    return res;
  });
};
export const getCtnftList = (data, currentPage) => {
  return postRequest(Url.CTNFTLIST_LIST + "/" + currentPage, data).then(
    (res) => {
      return res.data;
    }
  );
};
export const getPendingList = () => {
  return postRequest(Url.RESELL_LIST).then((res) => {
    return res.data;
  });
};
export const getNFTList = (data) => {
  return postRequest(Url.FETCHTRANSACTIONNFTS,data).then((res) => {
    return res.data;
  });
};
export const userLogout = (data) => {
  return postRequest(Url.USER_LOGOUT, data).then((res) => {
    return res.data;
  });
};
export const getSaleOrderMetaData = (data) => {
  return postRequest(Url.SALE_ORDER_METADATA, data).then((res) => {
    return res.data;
  });
};
export const postCertificateActivate = (data) => {
  return postRequest(Url.CERTIFICATE_ACTIVATE, data).then((res) => {
    return res.data;
  });
};
export const submitApproval = (data) => {
  return postRequest(Url.CTNFT_SUBMIT_APPROVAL, data).then((res) => {
    return res.data;
  });
};
export const createCtnftSignature = (data) => {
  return postRequest(Url.CREATE_CTNFT_SIGNATURE, data).then((res) => {
    return res.data;
  });
};
export const getPageTypeData = (data) => {
  return getRequest(Url.HOMEPAGE + data).then((res) => {
    return res.data;
  });
};
export const getFooterData = () => {
  return getRequest(Url.FOOTER_API).then((res) => {
    return res.data;
  });
};
export const getCmspageData = (data) => {
  return getRequest(Url.CMS_API + data).then((res) => {
    return res.data;
  });
};
export const getAuthorityData = (data) => {
  return getRequest(Url.GET_AUTHORITY + data).then((res) => {
    return res.data;
  });
};
export const getDashboardData = (data) => {
  return postRequest(Url.DASHBOARD_POLLUTER, data).then((res) => {
    return res.data;
  });
};
export const getBidData = (data) => {
  return postRequest(Url.BID_DATA, data).then((res) => {
    return res.data;
  });
};
export const getWalletTransactionData = (data) => {
  return postRequest(Url.FETCH_WALLET_TRANSACTIONS, data).then((res) => {
    return res.data;
  });
};


export const getUserDashboardData = (data) => {
  return postRequest(Url.DASHBOARD_DATA, data).then((res) => {
    return res.data;
  });
};
export const postContactUs = (data) => {
  return postRequest(Url.CONTACT_US, data).then((res) => {
    return res.data;
  });
};
   