import React, { useEffect } from "react";
import BuyCTNF from "../component/Buy-CTNF";
import { Container, Nav, Button, Figure, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useToast } from "../context/Toast/useToast";
import { updatePaymentSatusAction } from "../action/walletApiAction";
import styles from "../component/ProducerProfile/profile/profile.module.scss";
function PaymentSuccess() {
  const { addToast } = useToast();
  useEffect(()=>{
    updateStatus()
  }, [])
  const updateStatus=async()=>{
    let body={}
    body.session_id= localStorage.getItem("stripeSessionId")
    body.status="success"
    const statusUpdateRes=await updatePaymentSatusAction(body)
    console.log("statusUpdateRes", statusUpdateRes)
    if(statusUpdateRes.Status==="success"){
      
    }else{
      addToast({
				message: "Internal Server Error",
				type: "error",
			});

    }
  }
  return (
    <>
    <div className="contentcenter">
    <div className={styles.ProfileComplete}>
     <img src="/images/png/paymentSuccess.png" width={300} className="mx-auto" alt="" />
      <div className={styles.ProfileCompleteMessage}>
       Payment Success
      </div>
      <div className={styles.ProfileCompleteButtonWrapper}>
        <Nav.Link as={Link}  to={'/wallet/dashboard'} >
          <div className={'secondaryBtn submitBtn btn'}  >Back to wallet</div>
        </Nav.Link>
      </div>
    </div>
    </div>
    </>
  )
}

export default PaymentSuccess;
