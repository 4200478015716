import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { bidAction, buyBidAction } from "../../action/walletApiAction";
import { useToast } from "../../../src/context/Toast/useToast";
import CustomLoader from "../../common/customLoader";
import { convertCurrency } from "../../utils/convertCurrency";
function BuyNFT(props) {
  const [showWallet, setShowWallet] = useState(true);
  const [nftDet, setNftDet] = useState()
  const [gasFee, setGasFee] = useState(0)
  const [finalPrice, setFinalPrice] = useState(0)
  const [authState, setAuthState] = useState("");
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false)
  const [price, setPrice]= useState(0)

  const history = useHistory()
  const [userWallet, setUserWallet] = useState(localStorage.getItem("wallet_address"))
  const [userId, setUserId] = useState(localStorage.getItem("userId"))
  const [dollarExchangeRate, setDollarExchangeRate] = useState("USD")

  function percentage(partialValue, totalValue) {
    console.log("partialValue", partialValue, "totalValue", totalValue)
    return (2.75000 * totalValue) / 100
  }
  const getCurrency = async () => {
    const res = await convertCurrency("USDC", "USD");
    setDollarExchangeRate(res.USD)
}
  useEffect(() => {
    setShowWallet(props.statusshow);
    console.log("props", props.data)
    
    setNftDet(props.data.nftDet)
    setGasFee(0.549)
    getCurrency()
    console.log("sfdsfsdf", props)
    setPrice(props?.data?.nftDet?.sell_type==="auction"? Number(props?.data?.bidAmount) : Number(props?.data?.nftDet?.price))
  }, [props]);
  useEffect(() => {
    if (props.data.userId == null) {
      setAuthState("Sign In")
    } else if(localStorage.getItem('userState') !== 'approved'){
      setAuthState("KYC")
    }else if (props.data.isWalletConnected == "false" && (props.data.isWalletConnected == "false" && (props.data.userWallet == "" | undefined | null))) {

      setAuthState("Create Wallet")
    }
    else if ((props.data.isWalletConnected == "false" && (props.data.userWallet != "" | undefined | null))) {
      setAuthState("Connect Wallet")
    } else {
      setAuthState("Buy NFT")
    }
  }, [props])
  useEffect(() => {
   
  console.log("pricdsdasdse", price)
      // console.log("percentage(Number(gasFee.toFixed(5)), Number(nftDet?.price).toFixed(5)) + Number(nftDet?.price).toFixed(5)", percentage(Number(gasFee.toFixed(5)), Number(nftDet?.price).toFixed(5)) + Number(nftDet?.price).toFixed(5))
      // setFinalPrice(percentage(Number(gasFee.toFixed(5)), Number(nftDet?.price)) + Number(nftDet?.price))
      setFinalPrice(percentage(Number(gasFee.toFixed(5)),Number(price))+ price )

  }, [price]);
  const handleBuyBidButton = async()=>{
    if(authState==="Sign In"){
      history.push("/Login")
      return
    }
    else if(authState==="Create Wallet"){
      localStorage.setItem('redirectAfterConnect', window.location.pathname);
      history.push("/create-wallet")
      return
    }
    else if(authState==="Connect Wallet"){
      localStorage.setItem('redirectAfterConnect', window.location.pathname);
      history.push("/create-wallet")
      return
    }
 
    setLoading(true)
    if(nftDet?.sell_type==="fixed"){
    let body={}
    body.type=nftDet?.saletype
    body.saleType=nftDet?.saletype
    body.price=nftDet?.price
    body.tokenId=nftDet?.token_id
    body.buyer_wallet_address=props.data.userWallet
    body.user_id=props.data.userId
    body.nft_id=nftDet?.id
    body.gas_fee=gasFee
    body.type= nftDet?.saletype ==="fixed" ? "buy" : "bid"
    body.seller_wallet_address= nftDet?.currentowner_wallet

    const res= await buyBidAction(body)
    setLoading(false)
    console.log("rescscsd", res)
    if(res.Status==="Error"){
      addToast({
        message: res.message,
        type: "error",
      });
    }else{
      props.StatusFunc();
      addToast({
        message: "Nft Bought Successfully",
        type: "success",
      });
      history.push("/marketplace")
    }
  }else{
    let body = {};
    body.nft_id = nftDet.id
    body.from_address = nftDet.currentowner_wallet
    body.to_address = userWallet
    body.creator_address = nftDet.creator_wallet
    body.user_id = userId
    body.sale_type = nftDet.sell_type
    body.buyer_id = userId
    body.buyer_wallet = userWallet
    body.price = nftDet.price
    body.bid_price = Number(price)
    body.seller_id = nftDet?.user_id
    body.token_id = nftDet?.token_id
    body.gas_fee = gasFee
    body.currentowner_wallet = nftDet?.currentowner_wallet
    body.current_price = nftDet?.current_price
    const res = await bidAction(body)
    console.log("res", res)
    setLoading(false)
    if (res.Status === "Error") {
        addToast({
            message: res.message,
            type: "error",
        });
    } else {
        // setBidAmount("")
        addToast({
            message: "Bid Successfull",
            type: "success",
        });
        props.StatusFunc();
        // history.push(`/viewctnft/${nftDet?.id}`)
        // await getNftFromIdAndRelatedProjectFunc()

    }
  }
  }
  const showFucn = () => {
    props.StatusFunc();
  };
  return (
    <>
    {loading && (
      <div>
        <CustomLoader />
      </div>
    )}
    <Modal className="buynftdiALOG " size="sm" centered show={showWallet}>
      <div className=" position-relative p-4">
        <div className="buynftHead">
          <h1>{nftDet?.name}</h1>
          <button
            className="closeBtn btn "
            onClick={() => showFucn()}
            variant="secondary"
          >
            <img src="/images/svg/closeBtn.svg" alt="close" />
          </button>
        </div>
        <div className="bodyBox">
          <table>
            <tr>
              <td>Price</td>
              <td>
                <div className="d-flex align-items-center justify-content-end gap-2">
                  <img src="/images/svg/usdcIcon.svg" width={20} alt="" />
                  <p>{ price}</p>
                </div>
              </td>

            </tr>
          </table>
          <hr />
          <table>
            <tr>
              <td>Platform Fee</td>
              <td>
                <div className="d-flex align-items-center justify-content-end gap-2">
                  <p>2.75%</p>
                </div>
              </td>

            </tr>
            <tr>
              <td>Gas Fee</td>
              <td>
                <div className="d-flex align-items-center justify-content-end gap-2">
                  <img src="/images/svg/maticIcon.svg" width={20} alt="" />
                  <p>{gasFee}</p>
                </div>
              </td>

            </tr>
            <tr>
              <td>Final Price</td>
              <td>
                <div className="d-flex align-items-center justify-content-end gap-2">
                  <img src="/images/svg/usdcIcon.svg" width={20} alt="" />
                  <p>{Number(finalPrice).toFixed(5)}</p>
                </div>
                <div className="d-flex align-items-center justify-content-end gap-2 convertprice">
                  {/* <img src="/images/svg/usdcIcon.svg" width={15} alt="" /> */}
                  $<p>{Number(finalPrice*dollarExchangeRate).toFixed(5)}</p>
                </div>
              </td>

            </tr>

          </table>
        </div>
        <div className="w-100 text-center mt-4">
          <button className="primaryBtnNew  mx-auto w-75 btn px-5" onClick={()=>handleBuyBidButton()}>{nftDet?.sell_type==="auction"? "Place Bid" : authState} </button>
        </div>
      </div>
    </Modal>
    </>
  );
}

export default BuyNFT;
