import axios from "axios";
import { StorageHelper } from "../utils/StorageHelper";
//import toastr from "../_Common/toaster";
var CancelToken = axios.CancelToken;
let wj_cancel;
let resp;
// export const request = (path, data = {}, method, image = false) => {
//   const userInfo = StorageHelper.getUserInfo();
//   if (
//     wj_cancel &&
//     resp &&
//     resp.config &&
//     resp.config.url === `${process.env.REACT_APP_WALLET_API_URL}/${path}`
//   ) {
//     wj_cancel && wj_cancel();
//   }
//   var options = {
//     method: method,
//     url: `${process.env.REACT_APP_WALLET_API_URL}/${path}`,
//     headers: {
//       "Content-Type": image ? "multipart/form-data"  : "text/plain",
//     },
//     // body:options
//     // dataType: "json",
//     // cancelToken: new CancelToken(function executor(c) {
//     //   wj_cancel = c;
//     // }),
//   };
//   // const userId = 11;
//   // const accessToken = "3vr7wewg7a";
//   console.log("csmdncksdn", data)
//   const userId = userInfo.user_id;
//   const accessToken = userInfo.access_token;
//   if (userId) {
//     // options.headers["user_id"] = parseInt(userId);
//     data["user_id"] = parseInt(userId);
//   }
//   if (accessToken) {
//     // options.headers["auth_access_token"] = accessToken;
//     data["auth_access_token"] = accessToken;
//   }

//   if (method === "GET") {
//     // options["params"] = data;
//   } else {

//     options["body"] = data;
//     console.log("datainsdeielse", data, "options[data]==> ", options["data"])
//   }
//   let responce = axios(options).then((res1) => {
//       resp = res1;
//       if (res1.data.code === 402 || res1.data.status === 401) {
//         localStorage.clear();
//         //toastr.show({message: res1.data.msg,intent: "danger"});
//         //window.location = "/";
//         //history.push('/');
//       }
//     })
//     .catch((error) => {
//       console.log("ERR =>", error);

//       // if (error.response) {
//       //   console.log('123',error);
//       //   console.log(error.response.status);
//       //   console.log(error.response.headers);
//       // }
//     });
//   return responce;
// };

export const request = async (path, data, method, image = false) => {
  console.log("patth", path, "data", data)

  async function postDataToServer(paths, datas) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_WALLET_API_URL + `/${paths}`,
        // Send data directly as JSON
        datas,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8"
          }
        })
      // .then(res=> console.log("res", res)).catch(err=> console.log("err", err))

      console.log("Response:", response)
      return response;
      // Handle response as needed
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  }

  // Example usage:
  const paths = path
  const datas = data;

 const res = await  postDataToServer(paths, datas);
 return res
}
export const postRequest = async(path, data, image) =>{
   const res = await request(path, data, "POST", image)
   return res;
}
export const putRequest = (path, data) => request(path, data, "PUT");
export const getRequest = (path, data) => request(path, data, "GET");
