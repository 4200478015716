import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
const Aboutbanner = ({ data }) => {
    const images = ["/images/png/producer.png", "/images/png/trader.png", "/images/png/consumer.png"]
    return (
        <div className="aboutBannerSec1 container-fluid" >

            <div className=' aboutBannerDiv' >
                
                        <div className='aboutBannerBox' >
                        <p className='aboutBannerHead'>About Us</p>
                            <div className=' aboutTitleBox'> <p className='aboutBannerTitle centerTitle'>Shaping the Future of Carbon Trading</p></div>
                           
                            <p className='aboutBannerSubTitle centerTitle'> Welcome to Phaeton Carbon Credit Exchange, where innovation meets environmental responsibility on the Phaeton Blockchain. Our platform revolutionizes the traditional carbon credit market by introducing Real World Assets (RWA) tokenization, transforming conventional carbon credits into digital tokens for transparent and efficient trading.</p>
                        </div>
                       
               
                 
            </div>
            <div className=" aboutBannerImgBox py-5">
                        <div className="row">
                               <div className="col-md-4 aboutBannerImgDiv">
                                 <img className="aboutBannerDivImg" src="/images/png/Rectangle26801.png" />
                               </div>
                               <div class="col-md-7 aboutBannerImgDiv">
                               <img className="aboutBannerDivImg" src="/images/png/Rectangle26802.png" />

                               </div>
                             </div>
                        </div>
                  
        </div>
    );
};

export default Aboutbanner;
