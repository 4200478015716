import React,{useEffect, useState} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Timer from '../component/Timer';
import {serviceFeeFn, getDecimal} from "../common/Constant";
import {addBid} from "../action/Api.action";

const CtnftDetail = ({ history }) => {

	const [ctnft, setCTNFT] = useState({});
	const [lastBidPrice, setLastBidPrice] = useState(0);
	const [currency, setCurrency] = useState('PHAe');
	const [Bid, setBid] = useState(0);
	const [submitStatus, setSubmitStatus] = useState(true);
	const [spinner,setSpinner] = useState(false);
	
	const setBidPrice = (val=0)=>{

		let bidPrice = val>0?(parseFloat(val)+parseFloat(serviceFeeFn(val))):0;
		setBid(bidPrice);
		setSubmitStatus(bidPrice>lastBidPrice?false:true);
	}

	/* Api Calling */
	useEffect(() => {

		console.log(history);
		if(history.location.state && history.location.state._id){
			let item = history.location.state;
			setCTNFT(item);
			let lastBidPrice = parseFloat(item.lastBidPrices);
			let price = parseFloat(item.price);
			setLastBidPrice(lastBidPrice>0?lastBidPrice+0.01:(price?price+0.01:0));
			setCurrency(item && item.currency?item.currency:'PHAe');
		}else{
			history.push("");
		}		
	},[]);

	const onSubmit = async () => {
		setSubmitStatus(true);
		   
		if (parseFloat(Bid) > parseFloat(lastBidPrice)){
			
			let resp = await addBid({ "productId":ctnft._id,  "price":(serviceFeeFn(parseFloat(Bid))+parseFloat(Bid)), "currency":ctnft.currency });
	
			if(resp.success){    

				setSpinner(false);     
				window.alert("The bid has been placed successfully");                 
				//setAlertData({message: "The bid has been placed successfully",intent: "success", action:'', status:true});
			  
			}else if(resp.success===false){
				setSpinner(false);
				window.alert(resp.msg);
				//setAlertData({message: resp.msg, intent: "danger", status:true});
			}
			
		} else {
			window.alert("Bid amount less than current value.");
		}
	};

	

	return (
		<Container>
			
			<div className="PlacebidSec burger">
				<Row>
					<Col md={6}>
						<div className="bidInfo">
							<h2>100 Tons Carbon Credits</h2>
							<div className="personInfo">
								<img src="assets/img/viewerImg.png" />
								<h6>{ctnft.fullName} <br /><small>ABC Australia Inc</small></h6>
								<p>Total Bids: {ctnft.totalBidCount}</p>
							</div>

							<div className="assetsIdInfo">
								<span className="includes">includes</span>
								<div className="assetsId">
									<h6>Assets ID: {ctnft.assetsId}</h6>
									<div>
										<a className="like" href="javascript:void(0)"><img src="assets/img/heart-clrd.png" /></a>
										<a className="share ms-3" href="javascript:void(0)"><img src="assets/img/share-clrd.png" /></a>
									</div>
								</div>
							</div>

							<div className="currentBid boxShaddow">
								<p className="currentBidText">Current Bid</p>
								<h1>{ctnft.price} {currency}</h1>
								<p className="aud">AUD $ {ctnft.doller}</p>
								{ctnft && ctnft._id && 
									<Timer data={ctnft} />
								}
								{/* <div className="timer">
									<ul>
										<li>03</li>
										<li>D</li>
									</ul>
									<ul>
										<li>23</li>
										<li>H</li>
									</ul>
									<ul>
										<li>59</li>
										<li>M</li>
									</ul>
									<ul>
										<li>09</li>
										<li>S</li>
									</ul>
								</div> */}

								<div className="bidAmount">
									<h5>
										<input placeholder="Amount" className="form-control" style={{border:"none"}} onChange={(e) => setBidPrice(e.target.value)}/> <span>{currency}</span>
									</h5>
									<ul>
										<li>Must be atleast {lastBidPrice} {currency}</li>
										<li>Your Biding Balance <span>0 {currency}</span></li>
										<li>Your Balance <span>0 {currency}</span></li>
										<li>Service Fee <span>{getDecimal(serviceFeeFn(lastBidPrice),6)} {currency}</span></li>
										<li>Total Bid Amount <span>{getDecimal(Bid,6)} {currency}</span></li>
									</ul>

									<div className="placeBidBtn">
										{localStorage.getItem('userType') && localStorage.getItem('userType') !== '' && localStorage.getItem('userType') !== 'PRODUCERS' &&
											<Button className="primaryBtn" disabled={submitStatus} onClick={e=>onSubmit()}>Place Bid</Button>
										}
									</div>
								</div>
							</div>
						</div>
					</Col>

					<Col md={6} lg={{ span: 5, offset: 1 }}>
						<p className="ltstBidHdng">Latest Bids</p>
						<div className="latestBids">
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default CtnftDetail;
