import React, { useState, useEffect } from "react";
import styles from "./order.module.scss";
// import BondTags from "components/common/bondTags";
// import ResellModal from "components/modal/resellModal";
// import BondUpdatePrice from "components/modal/bondUpdatePrice";
// import BondUpdateStatus from "components/modal/bondUpdateStatus";
// import moment from "moment";
// import { Row, Col } from "react-bootstrap";
import CommonModal from "../modal";
import { resellPriceChange } from "../../action/Api.action";
import NoRecordsFound from "../../component/NoRecordFound";
import Pagination from "../../common/pagination";
import { useToast } from "../../context/Toast/useToast";
import { useLoader } from "../../context/Loader/useLoader";
function BondListItem(props) {
  const { bonddata, index, fetchResell } = props;
  const { addToast } = useToast();
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [bondData, setBondData] = useState("");
  const [viewMore, setViewMore] = useState(false);
  const [cardOpen, setCardOpen] = useState();
  const [width, setWidth] = useState();
  const itemsPerPage = process.env.REACT_APP_ITEMPERPAGE;
  const [currentPage, setCurrentPage] = useState(1);
  const [isReformatCertificates, setReformatCertificates] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);
  const { setLoading } = useLoader();
  const shortIssuerName = (issuerName) => {
    if (issuerName && issuerName.length > 10) {
      return issuerName.substr(0, 10) + "...";
    } else {
      return issuerName;
    }
  };
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  const handleOpen = () => {
    setWithdrawModal(!withdrawModal);
  };
  const updatePrice = (event, bonddata, index) => {
    event.preventDefault();
    //handleViewMore(index);
    setModalOpen(true);
    setCardOpen();
    setViewMore();
    setBondData(bonddata);
  };
  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };
  const handleStatusModal = async () => {
    setLoading(true, "Updating the Certificate Price");
    const body = {
      resell_id: bondData?.id,
      updated_state:
        bondData?.current_status == "active" ? "inactive" : "active",
    };
    const res = await resellPriceChange(body);
    setLoading(false);
    if (res.status) {
      addToast({
        message: res?.message,
        type: "success",
        duration: 3000,
      });
    } else {
      addToast({
        message: "Somthing went wrong",
        type: "success",
        duration: 3000,
      });
    }
    fetchResell();
    handleOpen();
  };
  const updateStatus = (data) => {
    handleOpen();
    setCardOpen();
    setViewMore();
    setBondData(data);
  };

  const handleSubmitModal = async (price) => {
    setLoading(true, "Updating the Certificate Price");
    const body = {
      resell_id: bondData?.id,
      updated_price: price,
    };
    const res = await resellPriceChange(body);
    setLoading(false);
    if (res.status) {
      addToast({
        message: res?.message,
        type: "success",
        duration: 3000,
      });
    } else {
      addToast({
        message: "Somthing went wrong",
        type: "success",
        duration: 3000,
      });
    }
    fetchResell();
    handleModalOpen();
  };
  const handleViewMore = (i) => {
    let checkCardOpen = cardOpen ? cardOpen : i;
    setViewMore(i === checkCardOpen ? !viewMore : viewMore);
    setCardOpen(i);

    let certificates = [];
    bonddata[i].certificates.forEach((info) => {
      certificates.push(info);
    });
    let reformatCertificates = [];
    for (let j = 0; j < itemsPerPage; j++) {
      reformatCertificates.push(certificates[j]);
    }

    setReformatCertificates(reformatCertificates);
    setAllCertificates(certificates);
  };
  const handleDocPagination = (page) => {
    if (page) {
      let startPage = page * itemsPerPage;
      let endPage = page + 1 * itemsPerPage;
      let nextPrevData = [];
      allCertificates.forEach((data, index) => {
        if (index + 1 >= startPage && index + 1 < endPage) {
          nextPrevData.push(data);
        }
      });
      setReformatCertificates(nextPrevData);
      //window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };

  const handlePagination = (page) => {
    if (page) {
      window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <>
      <div className={styles.orderSection}>
        <div className={`${styles.list}`}>
          <div className={`${styles.listItems}`}>
            <div className={styles.orderListBox}>
              <div>
                <div className={`${styles.listBoxBody}`}>
                  <div className={`${styles.listAlignment}`}>
                    <div>
                      <p>Id# </p>
                    </div>
                    <div>
                      <p>Reference </p>
                    </div>

                    <div>
                      <p>Status</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.orderSection}>
        <div className="container-wrapper">
          <div className={`${styles.list}`}>
            {bonddata && bonddata.length > 0 ? (
              bonddata?.map((res, i) => {
                return (
                  <div className={`${styles.listItems}`}>
                    <div className={styles.orderListBox}>
                      <div>
                        <div className={`${styles.listBoxBody}`}>
                          <div className={`${styles.listAlignment}`}>
                            <div>
                              <span>{res?.id}</span>
                            </div>
                            <div>
                              <span>{res?.name}</span>
                            </div>
                            <div>
                              <span>
                                {capitalizeFirstLetter(res?.current_status)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={"d-grid"}>
                        <div className={`${styles.listBoxFooter}`}>
                          <div className={styles.ExplorerButton}>
                            {viewMore && width <= 768 && cardOpen === i ? (
                              <></>
                            ) : (
                              <>
                                <a
                                  href={res.explorer_link}
                                  target="_blank"
                                  rel="noreferrer">
                                  View in Explorer
                                </a>

                                <button
                                  className="button-green"
                                  onClick={() => handleViewMore(i)}>
                                  {viewMore && cardOpen === i
                                    ? "View Less"
                                    : "View More"}
                                </button>
                                <button
                                  className="button-yellow"
                                  onClick={(e) => updatePrice(e, res, i)}>
                                  Update Price
                                </button>
                                <button
                                  className="button-purple"
                                  onClick={(e) => updateStatus(res)}>
                                  Update Status
                                </button>
                                {/* {res?.status == "active" ? (
                                  <button onClick={handleOpen}>Withdraw</button>
                                ) : null} */}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {viewMore && cardOpen === i ? (
                      <div className={styles.viewMoreContainer}>
                        {isReformatCertificates?.map((resp, index) => {
                          return (
                            <div className={styles.orderDetails} key={index}>
                              <div className={styles.orderBondDetail}>
                                <div>
                                  <h4>ACCU Certificate</h4>
                                  <p>{resp?.accu}</p>
                                </div>
                                <div>
                                  <h4>Price</h4>
                                  <p>{resp?.sale_price}</p>
                                </div>
                                <div>
                                  <h4>Country</h4>
                                  <p>{resp?.country}</p>
                                </div>
                                <div>
                                  <h4>Status</h4>
                                  <p>{capitalizeFirstLetter(resp?.state)}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <Pagination
                          itemsCount={res.certificates.length || 0}
                          itemsPerPage={itemsPerPage}
                          currentPage={currentPage}
                          setCurrentPage={handleDocPagination}
                          alwaysShown={false}
                        />
                        {viewMore && width <= 768 ? (
                          <div className={"d-grid"}>
                            <div className={`${styles.listBoxFooter}`}>
                              <button onClick={() => handleViewMore(i)}>
                                {viewMore ? "View Less" : "View More"}
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                );
              })
            ) : (
              <NoRecordsFound />
            )}
          </div>
          {bonddata && bonddata?.total_resell_count > 0 ? (
            <Pagination
              itemsCount={bonddata?.total_resell_count || 0}
              itemsPerPage={bonddata?.resell_count || 9}
              currentPage={currentPage}
              setCurrentPage={handlePagination}
              alwaysShown={false}
            />
          ) : null}
        </div>
      </div>
      {modalOpen && (
        <CommonModal
          modalOpen={modalOpen}
          BodyMessage="Do you want to Update the price of the Certificate ?"
          ButtonMessage="Update Price"
          textbox="true"
          number="true"
          handleClose={handleModalOpen}
          handleSubmit={handleSubmitModal}
          bondData={bondData}
        />
      )}
      {withdrawModal && (
        <CommonModal
          modalOpen={withdrawModal}
          BodyMessage="Do you want to Update the Status of the Certificates ?"
          ButtonMessage="Update Status"
          // textbox="true"
          handleClose={handleOpen}
          handleSubmit={handleStatusModal}
        />
      )}
      {/* {statusModalOpen && (
        <BondUpdateStatus
          statusModalOpen={statusModalOpen}
          handleStatusClose={handleStatusClose}
          setStatusModalOpen={setStatusModalOpen}
          bondData={bondData}
          />
    
      )} */}
    </>
  );
}

export default BondListItem;
