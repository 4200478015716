import React, { useState, useEffect } from "react";
import { useLoader } from "../../context/Loader/useLoader";
import { useToast } from "../../context/Toast/useToast";
import styles from "./order.module.scss";
import moment from "moment";
import NoRecordsFound from "../../component/NoRecordFound";
import Pagination from "../../common/pagination";

const WithdrawList = ({ data }) => {
  const [viewMore, setViewMore] = useState(false);
  const [cardOpen, setCardOpen] = useState();
  const [width, setWidth] = useState();
  const { addToast } = useToast();
  const { setLoading } = useLoader();
  const itemsPerPage = process.env.REACT_APP_ITEMPERPAGE;
  const [currentPage, setCurrentPage] = useState(1);
  const [isReformatCertificates, setReformatCertificates] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);

  const handleViewMore = (i) => {
    let checkCardOpen = cardOpen ? cardOpen : i;

    setViewMore(i === checkCardOpen ? !viewMore : viewMore);
    setCardOpen(i);
    let certificates = [];
    data?.withdraw_list[i].certificates.forEach((info) => {
      certificates.push(info);
    });
    let reformatCertificates = [];
    for (let j = 0; j < itemsPerPage; j++) {
      reformatCertificates.push(certificates[j]);
    }
    setReformatCertificates(reformatCertificates);
    setAllCertificates(certificates);
  };

  const handleDocPagination = (page) => {
    if (page) {
      let startPage = page * itemsPerPage;
      let endPage = page + 1 * itemsPerPage;
      let nextPrevData = [];
      allCertificates.forEach((data, index) => {
        if (index + 1 >= startPage && index + 1 < endPage) {
          nextPrevData.push(data);
        }
      });
      setReformatCertificates(nextPrevData);
      //window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  const handlePagination = (page) => {
    if (page) {
      window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  return (
    <>
      <div>
        <div className={styles.orderSection}>
          <div className="container-wrapper">
            <div className={`${styles.list}`}>
              {data && data.withdraw_count > 0 ? (
                data?.withdraw_list.map((res, i) => {
                  return (
                    <div key={i} className={`${styles.listItems}`}>
                      <div className={styles.orderListBox}>
                        <div>
                          <div className={`${styles.listBoxBody}`}>
                            <div className={`${styles.listAlignment}`}>
                              <div>
                                <p>Order# </p>
                                <span>{res?.resell_request}</span>
                              </div>
                              <div>
                                <p>Name </p>
                                <span>{res?.name}</span>
                              </div>
                              <div>
                                <p>State</p>
                                <span>{res?.state}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"d-grid"}>
                          <div className={`${styles.listBoxFooter}`}>
                            <div className={styles.ExplorerButton}>
                              {viewMore && width <= 768 && cardOpen === i ? (
                                <></>
                              ) : (
                                <button onClick={() => handleViewMore(i)}>
                                  {viewMore && cardOpen === i
                                    ? "View Less"
                                    : "View More"}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {viewMore && cardOpen === i ? (
                        <div className={styles.viewMoreContainer}>
                          {isReformatCertificates?.map((resp, index) => {
                            return (
                              <div className={styles.orderDetails} key={index}>
                                <div className={styles.orderBondDetail}>
                                  <div>
                                    <h4>ACCU Certificate</h4>
                                    <p>{resp?.accu}</p>
                                  </div>
                                  <div>
                                    <h4>Price ({resp?.currency_id})</h4>
                                    <p>{resp?.price}</p>
                                  </div>
                                  <div>
                                    <h4>Name</h4>
                                    <p>{resp?.name}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <Pagination
                            itemsCount={res.certificates.length || 0}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            setCurrentPage={handleDocPagination}
                            alwaysShown={false}
                          />
                          {viewMore && width <= 768 ? (
                            <div className={"d-grid"}>
                              <div className={`${styles.listBoxFooter}`}>
                                <button onClick={() => handleViewMore(i)}>
                                  {viewMore ? "View Less" : "View More"}
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <NoRecordsFound />
              )}
            </div>
            {data && data?.total_withdraw_count > 0 ? (
              <Pagination
                itemsCount={data?.total_withdraw_count || 0}
                itemsPerPage={data?.withdraw_count || 9}
                currentPage={currentPage}
                setCurrentPage={handlePagination}
                alwaysShown={false}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default WithdrawList;
