import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CustomRadioButton from "../common/CommonRadioButton";
import PolluterProfile from "../component/PolluterProfile";
import ProducerProfile from "../component/ProducerProfile";
import SubHeader from "../component/Subheader";
import { NAME } from "../constant/constant";
import { setFooter } from "../slices/home";
import { useLocation , useHistory} from 'react-router-dom';
import { updatePartnerState } from "../action/Api.action";


function  EditPage() {
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [subPagination, SetPagination] = useState()
useEffect(async()=>{
  const partnerState = await updatePartnerState({user_id : localStorage.getItem('userId')})
  console.log('partner KYC status :  ',partnerState)
  if(partnerState.status)
  {localStorage.setItem('userState',partnerState.partner_state)
  localStorage.setItem('kyc_status',partnerState.partner_kyc_status)
  localStorage.setItem('kyc_doc_status',partnerState.document_status)

}
  if(!localStorage.getItem('userId'))
  history.push('/')
  console.log('useEffect triggered. Status : ')
  SetPagination(state)
},[state])
  console.log("Profile page test")
 
  console.log(localStorage.getItem("userId"))
  const userData = useSelector((state) => state?.auth?.profileData);
  const [UserType, setUserType] = useState({
    label: NAME.buyer,
    value: "polluter",
  });
  const UserTypes = [
    { label: NAME.buyer, value: "polluter" },
    { label: NAME.supplier, value: "producer" },
  ];
  useEffect(() => {
    if(!localStorage.getItem('userId'))
    console.log("Test for login :" , localStorage.getItem('userId'))
 
    dispatch(setFooter(false));
    console.log("Local storage",localStorage)
  }, []);
  if(localStorage.getItem('userState')!= 'approved'){
        console.log("ashmin test for polluter profile")
      return (
        <>
        
          
          <Container>
          <h1 className="pageheading">KYC</h1>
            <div className="moreInfoSec signUpForm boxShaddow burger">
              {/* <CustomRadioButton
                items={UserTypes}
                name="User Type"
                selected={UserType}
                onChange={(value) => setUserType(value)}
                direction="horizontal"
                //   className={styles.RadioButton}
              /> */}
              {/* <PolluterProfile /> */}
             
            {/* {localStorage.getItem('userType') === 'producer' ? <ProducerProfile /> : <PolluterProfile/>} */}
            <ProducerProfile/>
              {/* {UserType?.value === "producer" && <ProducerProfile />} */}
            </div>
          </Container>
        </>
      );
    
  }
  else
  
    return (
      <>
        <SubHeader subpagi={subPagination} />
        <Container>
          
            {/* {localStorage.getItem('userType') === 'producer' ? <ProducerProfile /> : <PolluterProfile/>} */}
          <ProducerProfile/>
        </Container>
      </>
    );
  
}

export default EditPage;
