import axios from "axios"
export const convertCurrency = async(symbol="MATIC", currency="USD")=>{

    try {
        const res = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=${currency}`)
        return res.data
    } catch (error) {
        console.log("error", error)
    }
}
