import React, { useEffect, useState } from 'react'
import WalletMenu from '../WalletMenu'
import { Col, Row } from 'react-bootstrap';
import {  getWalletTransactionData } from "../../../action/Api.action";
import { getCryptoDepositData,withdrawTransactionsLists } from '../../../action/walletApiAction';
import { useToast } from "../../../context/Toast/useToast.ts";
import CustomLoader from '../../../common/customLoader';
import Pagination from 'react-bootstrap/Pagination';
function Index(props) {
  const { page } = props
  const [userData, setUserData] = useState([])
  const [cateogry, setCategory] = useState()
  var [allTransactions, setAllTransactions] = useState([])
  const [withdrawList, setWithdrawlists] = useState([])
  const [cryptoDepositList,setCryptoDepositList] = useState([])
  const [loading, setLoading] = useState(false)
  const [timezone, setTimezone] = useState('');
  const [itemPerPage, setItemPerPage] = useState(10)
  const [totalCountArr, setTotalCountArr] = useState([])
  const [totalCountArr2, setTotalCountArr2] = useState([])
  const [afterPagedWithdrawl, setAfterPagedWithdrawl] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [sNo, setSNo] = useState(1)
  const { addToast } = useToast();
  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(userTimezone);
  }, [])
  const getWithdrawList = async () => {
    setLoading(true)
    let data = {}
    data.user_id = localStorage.getItem("userId")
    data.page_no = 1
    if (data.user_id) {
      const withdrawList = await withdrawTransactionsLists(data)
      setLoading(false)
      if (withdrawList.Status === 'success') {
        setWithdrawlists(withdrawList.data)
        const x = Math.ceil(Number(withdrawList.total_count) / itemPerPage)
        const totalCountArr = Array.from(Array(x), (_, index) => index + 1);
        setTotalCountArr(totalCountArr)

      } else {
        addToast({
          message: "Internal Sever Error",
          type: "success",
        });
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    let arr = []
    let arr2= cateogry==="withdrawl"? withdrawList : userData
    for (let i = 0; i < itemPerPage; i++) {
      if (arr2[i]) {
        arr.push(arr2[i])
      }
    }
    setAfterPagedWithdrawl(arr)
  }, [withdrawList, userData])
  const handlePage = (pageNo) => {
    setCurrentPage(pageNo)
    const startIndex = (pageNo - 1) * itemPerPage
    const endIndex = startIndex + itemPerPage
    let arr = []
    let arr2= cateogry==="withdrawl" ? withdrawList : userData
    setSNo(startIndex + 1)
    for (let i = startIndex; i < endIndex; i++) {
      if (arr2[i]) {
        arr.push(arr2[i])
      }
    }
    setAfterPagedWithdrawl(arr)
  }
  useEffect(() => {
    handlePage(1)
    if (cateogry === "" || cateogry === "withdrawl") {
      getWithdrawList()
    }
  }, [cateogry])

  const changeCategory = () => {
    let category = document.getElementById("walletTransactionHistoryTransactionType").value
    if (category == 1) {
      setCategory("cryptodeposit")
      setUserData(cryptoDepositList)
    }
    if (category == 2) {
      setCategory("withdrawl")
      setUserData(allTransactions.filter((item) => item.payment_type == "withdrawl"))
    }
    if (category == 3) {
      setCategory("deposit")
      setUserData(allTransactions.filter((item) => item.payment_type == "deposit"))
    }
    if (category == 1)
      setUserData(allTransactions)
  };
  useEffect(async () => {
    let queryData = {
      user_id: localStorage.getItem('userId')
    }
    let transactions = await getWalletTransactionData(queryData)
    let CryptoDeposits = await getCryptoDepositData({wallet_address : localStorage.getItem('wallet_address')})
    console.log(transactions)
    console.log("Ashmin Test for crypto deposits", CryptoDeposits.data)
    setCryptoDepositList(CryptoDeposits.data)
    setAllTransactions(transactions.transaction_list)
    setUserData(transactions.transaction_list)
    setWithdrawlists(transactions.transaction_list)
    var x = Math.ceil((transactions.transaction_list.length) / itemPerPage)
    const totalCountArr2 = Array.from(Array(x), (_, index) => index + 1);
    setTotalCountArr2(totalCountArr2)
    changeCategory()
  }, [props])




  return (
    <>
      {loading && (
        <div>
          <CustomLoader />
        </div>
      )}
      <div className='walletMaineBox'>
        <WalletMenu pagename={page} />
        <div className='walletRanderBox'>
          <Row className='justify-content-end'>
            <Col lg={2}>
              <select id="walletTransactionHistoryTransactionType" className='form-control trasectionselect form-select' onChange={changeCategory}>
              <option value="1">Deposit(Crypto)</option>
                <option value="2">Withdrawal</option>
                <option value="3">Deposit</option>

              </select>
            </Col>
            <Col lg={12}>
              <div className='TrasactionTable table-responsive'>
                <table>
                  <thead>
                    {cateogry =="cryptodeposit" && 
                      <tr>
                        <td>Serial No.</td>
                        <td>Transaction Hash</td>
                        <td>Currency</td>
                        <td>Amount</td>
                        <td>From Address</td>
                        <td>Date</td>
                      </tr>
}
                    {cateogry !="cryptodeposit" && (cateogry === "deposit" ?
                      <tr>
                        <td>Serial No.</td>
                        
                        <td>Currency</td>
                        <td>Amount</td>
                        <td>USDC Amount</td>
                        <td>Matic Amount</td>
                        <td>Date</td>
                        <td>Status</td>
                      </tr>
                      :
                      <tr>
                        <td>Serial No.</td>
                       
                        <td>From Currency</td>
                        <td>To Currency</td>
                        <td>USDC Amount</td>
                        <td>Matic Amount</td>
                        <td>Branch</td>
                        <td>Account No./Wallet Address</td>
                        <td>Date</td>
                        <td>Status</td>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {cateogry === "deposit" && afterPagedWithdrawl && afterPagedWithdrawl?.length > 0 ? (
                      afterPagedWithdrawl?.map((item, i) => {
                        return (<>
                          <tr>
                            <td>{sNo + i}</td>
                            <td>{item.currency}</td>
                            <td>{item.amount}</td>
                            <td>{item.usdc_amount}</td>
                            <td>{item.matic_amount}</td>
                            <td>{new Date(item?.date)?.toLocaleString('en-US', { timeZone: timezone })}</td>
                            <td style={{ "color": (item.admin_approval == 'pending' ? "orange" : (item.admin_approval == 'rejected' ? "red" : "green")) }}><strong>{item.admin_approval === 'pending' ? "Pending" : item.admin_approval === 'rejected' ? "Rejected" : "Approved"}</strong></td>
                          </tr>
                        </>)
                      })
                    ) :
                      cateogry === "withdrawl" && afterPagedWithdrawl && afterPagedWithdrawl?.length > 0 ? (
                        afterPagedWithdrawl?.map((item, i) => {
                          return (<>
                            <tr>
                              <td>{sNo + i}</td>
                              <td>{item?.from_currency}</td>
                              <td>{item?.to_currency}</td>
                              <td>{item?.usdc_amount}</td>
                              <td>{item?.matic_amount}</td>
                              <td>{item?.bank_branch ? item?.bank_branch : "N/A"}</td>

                              <td>{item?.account_number ? item?.account_number : (item?.wallet_address?.slice(0, 5) + "..." + item?.wallet_address?.slice(item?.wallet_address?.length - 5, item?.wallet_address?.length))}</td>

                              <td><span className='d-block'>{new Date(item?.create_date)?.toLocaleString('en-US', { timeZone: timezone })}</span></td>
                              <td style={{ "color": (item?.admin_status == 'pending' ? "orange" : (item?.admin_status == 'rejected' ? "red" : "green")) }}><strong>{item?.admin_status === 'pending' ? "Pending" : item?.admin_status === 'rejected' ? "Rejected" : "Approved"}</strong></td>
                            </tr>
                          </>)
                        })
                      )
                        :
                        (
                         cateogry == "cryptodeposit" && cryptoDepositList && cryptoDepositList?.length > 0 ? (
                          cryptoDepositList?.map((item, i) => {
                            return (<>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{item.tx_hash}</td>
                                <td>{item.currency}</td>
                                <td>{item.amount}</td>
                                <td>{item.from_address}</td>
                                <td>{item.timestamp}</td>
                              </tr>
                            </>)
                          })
                        ) :  <tr>
                            <td colSpan={8}>No Records Found.</td>
                          </tr>
                        )}
                  </tbody>
                </table>
              </div>
            </Col>
            {(cateogry === "withdrawl" && afterPagedWithdrawl.length >0 ) &&
              <Col className='text-end' lg={12}>
                <Pagination className='justify-content-end mt-3'>
                  {currentPage > 1 && <>
                    <Pagination.First onClick={() => handlePage(1)} />
                    <Pagination.Prev onClick={() => handlePage(currentPage - 1)} />
                  </>}
                  {totalCountArr.map((item) =>
                    <Pagination.Item active={item === currentPage ? true : false} onClick={() => handlePage(item)}>{item}</Pagination.Item>
                  )}
                  {currentPage < Math.ceil((withdrawList.length) / itemPerPage) && <>
                    <Pagination.Next onClick={() => handlePage(currentPage + 1)} />
                    <Pagination.Last onClick={() => handlePage(Math.ceil((withdrawList.length) / itemPerPage))} />
                  </>}
                </Pagination>
              </Col>
            }
            {(cateogry === "deposit" && afterPagedWithdrawl.length >0) &&
              <Col className='text-end' lg={12}>
                <Pagination className='justify-content-end mt-3'>
                  {(currentPage > 1  && Math.ceil((userData.length) / itemPerPage))&& <>
                    <Pagination.First onClick={() => handlePage(1)} />
                    <Pagination.Prev onClick={() => handlePage(currentPage - 1)} />
                  </>}
                  {totalCountArr2.map((item) =>
                    <Pagination.Item active={item === currentPage ? true : false} onClick={() => handlePage(item)}>{item}</Pagination.Item>
                  )}
                  {(currentPage < Math.ceil((userData.length) / itemPerPage)) && <>
                    <Pagination.Next onClick={() => handlePage(currentPage + 1)} />
                    <Pagination.Last onClick={() => handlePage(Math.ceil((userData.length) / itemPerPage))} />
                  </>}
                </Pagination>
              </Col>
            }
          </Row>
        </div>
      </div>
    </>
  )
}

export default Index