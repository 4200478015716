import SignClient from "@walletconnect/sign-client";
export let client;

export const CreateWalletConnectClient = async () => {
  client = await SignClient.init({
    projectId:
      process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID ||
      "11c92c104940c4b8463b9d1d9724f631",
    relayUrl:
      process.env.NEXT_PUBLIC_WALLET_CONNECT_RELAY_URL ||
      "wss://relay.walletconnect.com",
    metadata: {
      name:
        process.env.NEXT_PUBLIC_WALLET_CONNECT_METADATA_NAME ||
        "Carbon Credit Exchange",
      description:
        process.env.NEXT_PUBLIC_WALLET_CONNECT_METADATA_DESCRIPTION ||
        "Carbon Credit Exchange",
      url:
        process.env.NEXT_PUBLIC_WALLET_CONNECT_METADATA_URL ||
        "https://phaetonccx.io/",
      icons: ["https://avatars.githubusercontent.com/u/37784886"],
    },
  });
};
