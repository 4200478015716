import React, { useEffect, useState } from 'react'
import WalletMenu from '../WalletMenu'
import { Col, Form, Row } from 'react-bootstrap';

function Index(props) {
  const [showPass, setshowPass] = useState("password");
const [confshowPass, setconfshowPass] = useState("password");
    const {page} = props
  useEffect(()=>{
    
    console.log(props);
},[props])
const showPassword = (e) =>{
  console.log(e.target);
  const getPassId =  document.getElementById(e)
  if(getPassId.id === 'password'){
      setshowPass(showPass === 'password' ? 'text':'password')
  } else {
      setconfshowPass(confshowPass === 'password' ? 'text':'password')


  }
  

}
  return (
    <>
      <div className='walletMaineBox'>    
        <WalletMenu pagename={page} />
        <div className='walletRanderBox'>
          <div className='walletchnagepassbox' >
          <h1>Change Password</h1>
                    <Row>
                    <Col lg={6}>
                    <Form autoComplete="false">
                   
                    <Form.Group className="mb-3" >
                      <Form.Label>old Password</Form.Label>
                     <div className="position-relative">
                     <Form.Control type='password' id="password" placeholder="Enter old Password" />
                     </div>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                      <Form.Label>Password</Form.Label>
                     <div className="position-relative">
                     <Form.Control type={showPass} id="password" placeholder="Enter Password" />
                      <button type="button" className="btn passwordSHow" onClick={()=> showPassword('password')}><i class={"fa-regular "  + (showPass === 'text' ? 'fa-eye':'fa-eye-slash')}></i></button>
                     </div>
                     <Form.Text className="text-muted">
                     Password Strength Indicator: <span className='week'>Weak</span> | <span className='medium'>Medium</span> | <span className='strong'>Strong</span>
                    </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-0" >
                      <Form.Label>Confirm Password</Form.Label>
                      <div className="position-relative">
                     <Form.Control type={confshowPass} id="Confirmpassword" placeholder="Enter Confirm Password" />
                      <button type="button" className="btn passwordSHow" onClick={()=> showPassword('Confirmpassword')}><i class={"fa-regular  " + (confshowPass === 'text' ? 'fa-eye':'fa-eye-slash') }></i></button>
                     </div>
                    </Form.Group>
                  </Form>
                
                   <button className="secondaryBtn   btn mt-4 " >Change Password</button>
                    
                   
                    </Col>
                </Row>
          </div>
        </div>
    </div>
    </>
  )
}

export default Index