import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import SubHeader from "../component/Subheader";
import MyOrders from "../component/My-Orders";
import NoRecordsFound from "../component/NoRecordFound";
import { useHistory } from "react-router-dom";
import { StorageHelper } from "../utils/StorageHelper";
const MyOrder = () => {
  const history = useHistory();
  const userDetail = StorageHelper.getUserInfo();
  const [partnerType] = [userDetail.partner_type];
  useEffect(() => {
		if (localStorage.getItem('userType') !== "polluter") {
		  history.push(`/edit-profile`);
		}
	}, [partnerType]);
  
  return (
        <>
          <SubHeader />
          <Container>
            <MyOrders />
          </Container>
        </> 
  );
};
export default MyOrder;
