import { Container, Row, Col } from 'react-bootstrap';
import SideBar from "../../admin/component/SideBar";

const AdminLayout = ({ children }) => {
  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col xs={12} className="ps-0">
            <div className="adminDashboard">
              <SideBar />
              <div className="moreInfoSec signUpForm boxShaddow">{children}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdminLayout