import React,{useEffect} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Verifysuccessfully = ({ history }) => {

	/* Api Calling */
	useEffect(() => {},[]);

	return (
		<Container>
			
			<div className="verifyseccessSec burger">
				<Row>
					<Col lg={{ span: 10, offset: 1 }}>
						<Form className="boxShaddow">
							<div className="heading">
								<div className="imbBox">
									<img src="assets/img/check.png" className="img-fluid" />
								</div>
								
								<h4>Verify Successfully</h4>
								<p>Thank you for verify your company information. An automated company information email will be sent to your registered email.</p> 
							</div>
					
							 <div className="">
								<Button className="secondaryBtn" variant="" type="submit">Back</Button>
							</div>
						</Form>
					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default Verifysuccessfully;
