import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { getCmspageData } from "../action/Api.action";
import { useLoader } from "../context/Loader/useLoader";

const CmsPage = () => {
    const { setLoading } = useLoader();
    const { pageId } = useParams();
    const pageTitle = pageId.replace(/^./, (str) => str.toUpperCase());
    const [description, setDescrption] = useState("");

    const fetchCmsData = async (pageId) => {
        if (pageId !== "") {
            const result = await getCmspageData(pageId);
            if (result && result.status) {        
                setDescrption(result.cms_page_description);
            }
            setLoading(false);
        }else{
            setLoading(false);
        }
    };

  useEffect(() => {
    setLoading(true, "Loading...");
    window.scrollTo(0, 0);
    fetchCmsData(pageId);
  }, []);

  return (
        <Container>
			
			<div className="aboutSec burger cms-pages">
				<Row className="align-items-center">
					<Col xs={12}>
						<h1 className="bigHeading">{pageTitle}</h1>
					</Col>
					
					<Col xs={12} lg={12}>
						<div className="aboutContent">
                            <div dangerouslySetInnerHTML={{ __html: description.description_content }} />
						</div>
					</Col>
				</Row>
			</div>

		</Container>
  );
};

export default CmsPage;
