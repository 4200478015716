//import { getBalanceAction } from "../Action/Api.action";

import React, { useState, useEffect } from "react";

export const serviceFee = 1.5;
export const siteName = "carbon";

export const userRole = [
  //{value:'SUPER_ADMIN', label:'SUPER ADMIN'},
  { value: "POLLUTERS", label: "POLLUTERS" },
  { value: "PRODUCERS", label: "PRODUCERS" },
  { value: "TRADERS", label: "TRADERS" },
];

export const kycDocumentList = [
  { value: "PAN", label: "PAN" },
  { value: "PASSPORT", label: "PASSPORT" },
];

export const message = {
  companyCreated: {
    head: "You have added company details with KYC documents successfully",
    desc: "Thank you for your company information and KYC submission. Once platform admin verify your company information and documents then you got an email confirmation for your account activation.",
    success: true,
    step: "companyCreated",
  },

  contractSign: {
    head: "Welcome to Carbon Credit Exchange",
    desc: "Your Company Information, Documents and Contract Agreement waiting for Admin Approval. Once admin verify all provided Information's Documents and Contract Agreement you will receive confirmation email, then you will be able to use full features of the Carbon Credit Exchange.",
    success: true,
    step: "contractSign",
  },

  otpVerified: {
    head: "Verify Successfully",
    desc: "Thank you for verify your account information.",
    success: true,
    step: "otpVerified",
  },

  CTNFTCreated: {
    head: "CTNFT Marketplace Listing Successfully Created",
    desc: "You have Successfully Created CTNFT Marketplace Listing. An automated email will be sent to your registered email. As per your selection CTNFT Marketplace listing will be shown on Marketplace.",
    success: true,
    step: "CTNFTCreated",
  },

  xyz: { head: "", desc: "", success: true, step: "" },
};

export const networkMsg = {
  1: "Please select Ethereum Mainnet network.",
  3: "Please select Repsten test network.",
  80001: "Please select Matic Polygon network.",
  97: "Please select BSC Testnet network.",
};

export const serviceFeeFn = (price) => {
  return (price / 100) * serviceFee;
};

export const getBalance = async (id = "", type = "") => {
  if (sessionStorage.getItem("ConnectStatus") == "true") {
    let data = {}; //await getBalanceAction();
    if (data.code === 200) {
      let oldBal = sessionStorage.getItem("balance");
      sessionStorage.setItem("balance", data.data);
      if (type === "" || oldBal !== data.data) {
        window.location.reload();
      }
    }
  }
};

export const getDollerValue = (price = 0, type = "AXC") => {
  price = parseFloat(price);
  if (sessionStorage.getItem("currencyAXC") && type === "AXC") {
    return (price * parseFloat(sessionStorage.getItem("currencyAXC"))).toFixed(
      2
    );
  } else if (sessionStorage.getItem("currencyETH") && type === "ETH") {
    return (price * parseFloat(sessionStorage.getItem("currencyETH"))).toFixed(
      2
    );
  } else {
    //console.log('out');
    return price.toFixed(2);
  }
};

export const checkLogin = () => {
  if (
    sessionStorage.getItem("ConnectStatus") == "true" &&
    sessionStorage.getItem("userId")
  ) {
    return true;
  } else {
    window.location.href = "/";
  }
};

export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (cname) => {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const getDecimal = (price, num = 3) => {
  //console.log(price, num);

  price = price.toString();

  let priceArr = price.split(".");

  if (priceArr.length > 1 && priceArr[1]) {
    let p = priceArr[1];
    let d = p.substr(0, num);

    if (d > 0) {
      return priceArr[0] + "." + d;
    } else {
      return price;
    }
  } else {
    return price;
  }
};

export const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "InActive", label: "InActive" },
];

export const capitalizeFirstLetter = (string) => {
  let str1 = string.replaceAll("_", " ");
  const arr = str1.split(" ");
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};
export const useContainerDimensions = (myRef) => {
  const getDimensions = () => ({
    width: myRef.current.offsetWidth,
    height: myRef.current.offsetHeight,
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};
//https://api.kucoin.com/api/v1/prices?currencies=AXC
