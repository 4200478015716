import React,{useEffect} from 'react';
import { Container, Row, Col, Navbar, Nav, Carousel, Image, Form, Button, Card, Figure } from 'react-bootstrap';

const Quoterequest = ({ history }) => {


	/* Api Calling */
	useEffect(() => {},[]);

	return (
		<div>

			<div className="subHeader">
				<Container>
					<div className="clientInfo">
						<Figure>
							<Figure.Image width={100} height={100} alt="171x180" src="assets/img/viewerImg.png" />
						</Figure>
						<h5 className="name">John Deo</h5>
						<span>All Food & beverages Inc.</span>
					</div>

					<div className="subNav">
                        <Nav className="mx-auto">
                            <Nav.Link href="">Request for Quote</Nav.Link>
                            <Nav.Link href="" className="active">My Listings</Nav.Link>
                            <Nav.Link href="">Bid Approvals</Nav.Link>
                            <Nav.Link href="">Payments</Nav.Link>
                            <Nav.Link href="">Followings/ Followers</Nav.Link>
                        </Nav>
						<Button className="secondaryBtn editPrfl" variant="primary" type="button"><i className="fa fa-edit"></i> Edit Profile</Button>
					</div>
				</Container>
			</div>

			<div className="quoteRequestSec burger">
				<Container>
					<Row className="justify-content-md-center">
						<Col sm={12}>
							<Form className="formContent boxShaddow">
								<h3 class="heading">Request for Quote - Carbon Credits Requirement</h3>
								<Row>
									<Col sm={6} md={5}>
										<Form.Group className="mb-3" controlId="formBasicQtyRequir">
										    <Form.Control type="text" placeholder="Total Quanity Require" />
										</Form.Group>
									</Col>
									<Col sm={6} md={5}>
										<Form.Group className="mb-3" controlId="formBasicPassword">
										    <Form.Control type="text" placeholder="Proposed Buying Price in AUD only" />
										</Form.Group>
									</Col>

									<Col sm={6} md={5}>
										<Form.Group className="mb-3" controlId="formBasicStartDate">
										    <Form.Control type="date" placeholder="Start Date" />
										</Form.Group>
									</Col>

									<Col sm={6} md={5}>
										<Form.Group className="mb-3" controlId="formBasicCloseDate">
										    <Form.Control type="date" placeholder="Closing Date" />
										</Form.Group>
									</Col>
									
									<Col sm={12} md={10}>
										<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										    <Form.Control className="textField" as="textarea" rows={3} placeholder="Notes" />
										</Form.Group>
									</Col>

									<Col sm={6} md={5}>
										<Button className="secondaryBtn" variant="primary" type="submit">Submit</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default Quoterequest;
