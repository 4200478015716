import { FormikProps, useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import styles from "./styles.module.scss";
import axios, { AxiosRequestConfig } from "axios";
import CustomInput from "../../common/input";
import { useToast } from "../../context/Toast/useToast";
import { ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  getUserDetails,
  getStatesList,
  fetchCountryData,
  uploadProfileImage,
} from "../../action/Api.action";
import { StorageHelper } from "../../utils/StorageHelper";
import CustomSelect from "../../common/select";
import Loader from "../../common/loader";
import Url from "../../utils/appUrls";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../slices/auth";

export const validatePolluterProfileSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Mobile is required")
    .min(9, "Mobile Number must be at least 9 characters.")
    .max(15, "Mobile Number must not be more than 15 characters.")
    .matches(/^[+]{0,1}[0-9]{9,15}$/, "Invalid mobile number"),
  street: Yup.string()
    .trim()
    .required("Address Line 1 is required")
    .max(150, "Address Line 1 must not be more than 150 characters."),
  street2: Yup.string()
    .trim()
    .optional()
    .max(150, "Address Line 2 must not be more than 150 characters."),
  city: Yup.string()
    .trim()
    .required("City is required")
    .max(50, "City must not be more than 50 characters."),
  zip: Yup.string()
    .optional()
    .matches(/([0-9])/g, "Accept only numeric value.")
    .max(10, "Zip must not be more than 10 characters."),
  country_id: Yup.number()
    .required("Country is required")
    .min(0, "Please Select a country."),
  phone: Yup.string()
    .optional()
    .min(9, "Phone must be at least 9 characters.")
    .max(15, "Phone must not be more than 15 characters.")
    .matches(/^[+]{0,1}[0-9]{9,15}$/, "Invalid phone number"),
});

const initialValues = {
  name: "",
  image_url: "",
  street: "",
  street2: "",
  city: "",
  zip: "",
  country_name: "",
  country_id: -1,
  state_name: "",
  state_id: -1,
  ccx_vat: "",
  phone: "",
  mobile: "",
  email: "",
  website: "",
  investor_id: -1,
  portal_user_type: "",
};

const PolluterProfile = () => {
  const [PolluetProfileValues, setPolluterProfileValues] =
    useState(initialValues);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsSaving, setIsSaving] = useState(false);
  const [Copied, setCopied] = useState(false);

  const ImageInputRef = useRef(null);
  const { addToast } = useToast();
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [Image, setImage] = useState(null);
  const [UploadProgress, setUploadProgress] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const userDetail = StorageHelper.getUserInfo();
  const [UserId, AccessToken, WalletAddress] = [
    userDetail.user_id,
    userDetail.access_token,
    userDetail.wallet_address,
  ];

  const handleSubmit = async (data) => {
    setIsSaving(true);
    setIsLoading(true);
    const ReqData = {
      partner_type: "polluter",
      user_id: parseInt(String(UserId)),
      name: data.name.trim(),
      email: data.email.trim(),
      street: data.street.trim(),
      street2: data.street2.trim(),
      city: data.city.trim(),
      zip: data.zip.trim(),
      state_id:
        parseInt(String(data.state_id)) > -1
          ? parseInt(String(data.state_id))
          : false,
      country_id:
        parseInt(String(data.country_id)) > -1
          ? parseInt(String(data.country_id))
          : false,
      ccx_vat: data.ccx_vat,
      website: data.website.trim(),
      mobile: data.mobile,
      phone: data.phone,
    };
    const config = {
      method: "post",
      url: Url.HOST + "/" + Url.PROFILE_UPDATE,
      headers: {
        "Content-Type": "text/plain",
      },
      data: ReqData,
    };
    const response = await axios(config)
      .then(async (res) => {
        let result = await getUserDetails();
        if (res.data.status) {
         
          history.push("/edit-profile");
          addToast({
            message: res.data.message,
            type: "success",
            duration: 5000,
          });
        } else {
          addToast({ message: res.data.message, type: "error" });
        }
        return res;
      })
      .catch((err) => {
        addToast({
          message: "Failed to update Profile.",
          type: "error",
        });
        return err;
      });
    setIsSaving(false);
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: PolluetProfileValues,
    onSubmit: handleSubmit,
    validationSchema: validatePolluterProfileSchema,
    enableReinitialize: true,
  });

  const fetchInvestorProfile = async () => {
    setIsLoading(true);
    const result = await getUserDetails();
    if (result?.status) {
      setPolluterProfileValues(result.data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchInvestorProfile();
  }, [UserId, AccessToken]);
  useEffect(() => {
    const fetchCountryList = async () => {
      const result = await fetchCountryData();
      setCountryList(result.result[0].country_ids);
    };
    fetchCountryList();
  }, []);
  useEffect(() => {
    const fetchStateList = async (country_id) => {
      const result = await getStatesList(country_id);
      setStateList(result.result);
    };
    fetchStateList(formik.values.country_id);
  }, [formik.values?.country_id]);
  return (
    <>
      <div className={styles.InvestorProfile}>
        <div className={styles.HeaderRow}>
          <div className={styles.Title}>Personal Information</div>
          <div
            className={styles.RefreshButton}
            onClick={() => {
              formik.setFormikState((prevState) => ({
                ...prevState,
                values: {
                  ...prevState.values,
                  name: PolluetProfileValues.name,
                  email: PolluetProfileValues.email,
                  mobile: PolluetProfileValues.mobile,
                  phone: PolluetProfileValues.phone,
                  ccx_vat: PolluetProfileValues.ccx_vat,
                  website: PolluetProfileValues.website,
                },
              }));
            }}>
            <img
              src="/images/common/Refresh_Icon.png"
              className={styles.RefreshIcon}
            />
            Reset Section
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.ImageUpload}>
            <input
              type="file"
              ref={ImageInputRef}
              accept="image/*"
              className={styles.Input}
              onChange={(e) => {
                if (e.target.files !== null && e.target.files.length > 0) {
                  const img = e.target.files[0];
                  if (
                    img.size < 2000000 &&
                    (img.type === "image/jpeg" ||
                      img.type === "image/png" ||
                      img.type === "image/jpg")
                  ) {
                    setImage(img);
                    setUploadProgress(0);
                    uploadProfileImage({
                      image: img,
                      auth_access_token: AccessToken,
                      user_id: UserId,
                      onProgressUpload: (event) => {
                        setUploadProgress(
                          Math.round((100 * event.loaded) / event.total)
                        );
                      },
                    }).then((res) => {
                      setUploadProgress(undefined);
                      if (res.status) {
                        addToast({
                          message: "Profile Image saved successfully.",
                          type: "success",
                        });
                      } else {
                        addToast({
                          message: "Profile Image save failed.",
                          type: "error",
                        });
                      }
                    });
                  } else {
                    if (img.size > 2000000) {
                      addToast({
                        message: "Image size should be less than 2MB.",
                        type: "error",
                      });
                    }
                    if (
                      img.type !== "image/jpeg" &&
                      img.type !== "image/png" &&
                      img.type !== "image/jpg"
                    ) {
                      addToast({
                        message: "Image type should be jpeg, png or jpg.",
                        type: "error",
                      });
                    }
                  }
                }
              }}
            />
            <div className={styles.UploadWrapper}>
              <div className={styles.TitleWrapper}>
                <div className={styles.Title}>Logo</div>
                <div className={styles.UploadButton}>
                  {/* <img
                  src="/plus-outline.png"
                  alt="Image Upload"
                  onClick={() => {
                    if (ImageInputRef !== null) {
                      ImageInputRef.current!.click();
                    }
                  }}
                /> */}
                  <img
                    src="/images/common/edit.png"
                    alt="Change Image"
                    onClick={() => {
                      if (ImageInputRef !== null) {
                        ImageInputRef.current.click();
                      }
                    }}
                  />
                  {/*<img
                  src="/images/profile/trash.png"
                  alt="Delete Image"
                  onClick={() => {
                    setImage(null);
                  }}
                />*/}
                </div>
              </div>
              <div className={styles.PreviewWrapper}>
                <img
                  src={
                    Image !== null
                      ? URL.createObjectURL(Image)
                      : //? Image
                        formik.values.image_url
                  }
                  className={styles.Preview}
                />
              </div>
              {!!UploadProgress && (
                <ProgressBar
                  now={UploadProgress}
                  label={`${UploadProgress}%`}
                  animated
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "stretch",
              flex: 1,
              gap: 30,
            }}>
            
            <div className={styles.Row}>
              <CustomInput
                label="Name"
                important
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.errors.name}
              />
              <CustomInput
                label="Email"
                important
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.errors.email}
              />
            </div>
            <div className={styles.Row}>
              <CustomInput
                label="Mobile"
                important
                name="mobile"
                onChange={formik.handleChange}
                value={formik.values.mobile}
                error={formik.errors.mobile}
              />
              <CustomInput
                label="Phone"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                error={formik.errors.phone}
              />
              <CustomInput
                label="VAT"
                name="ccx_vat"
                onChange={formik.handleChange}
                value={formik.values.ccx_vat}
                error={formik.errors.ccx_vat}
              />
              <CustomInput
                label="Website"
                name="website"
                onChange={formik.handleChange}
                value={formik.values.website}
                error={formik.errors.website}
              />
            </div>
          </div>
        </div>
        <div className={styles.HeaderRow}>
          <div className={styles.Title}>Address</div>
          <div
            className={styles.RefreshButton}
            onClick={(e) => {
              formik.setFormikState((prevState) => ({
                ...prevState,
                values: {
                  ...prevState.values,
                  street: PolluetProfileValues.street,
                  street2: PolluetProfileValues.street2,
                  city: PolluetProfileValues.city,
                  state_name: PolluetProfileValues.state_name,
                  state_id: PolluetProfileValues.state_id,
                  zip: PolluetProfileValues.zip,
                  country_name: PolluetProfileValues.country_name,
                  country_id: PolluetProfileValues.country_id,
                },
              }));
            }}>
            <img
              src="/images/common/Refresh_Icon.png"
              className={styles.RefreshIcon}
            />
            Reset Section
          </div>
        </div>
        <div className={styles.Row}>
          <CustomInput
            label="Address Line 1"
            important
            name="street"
            onChange={formik.handleChange}
            value={formik.values.street}
            error={formik.errors.street}
          />
          <CustomInput
            label="Address Line 2"
            name="street2"
            onChange={formik.handleChange}
            value={formik.values.street2}
            error={formik.errors.street2}
          />
        </div>
        <div className={styles.Row}>
          <CustomInput
            label="City"
            important
            name="city"
            onChange={formik.handleChange}
            value={formik.values.city}
            error={formik.errors.city}
          />
          <CustomInput
            label="Zip"
            name="zip"
            onChange={formik.handleChange}
            value={formik.values.zip}
            error={formik.errors.zip}
          />
          <CustomSelect
            label="Country"
            important
            items={CountryList.map((c) => ({
              label: c.name,
              value: c.country_id,
            }))}
            selectedValue={formik.values.country_id}
            onChange={(v) => {
              formik.handleChange({ target: { name: "country_id", value: v } });
            }}
            error={formik.errors.country_id}
          />
          <CustomSelect
            label="State"
            items={StateList.map((c) => ({ label: c.name, value: c.state_id }))}
            selectedValue={formik.values.state_id}
            onChange={(v) => {
              formik.handleChange({ target: { name: "state_id", value: v } });
            }}
            error={formik.errors.state_id}
          />
        </div>
        <div className={styles.Row}>
          <div className={styles.NextButtonWrapper}>
            <div
              className={styles.NextButton}
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}>
              {IsSaving ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PolluterProfile;
