import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartData,
  getPhaePrice,
  getQuateDate,
  getSaleOrderMetaData,
  postCertificateActivate,
  saveTransactionHash,
} from "../../action/Api.action";
import { useHistory } from "react-router-dom";
import CustomLoader from "../../common/customLoader";
import CommonModal from "../../common/modal";
import YourMiniCart from "../../common/YourMiniCart";
import { useToast } from "../../context/Toast/useToast";
import NoRecordsFound from "../NoRecordFound";
import {
  buyCTNFTTransaction,
  walletConnectSessionTimeout,
} from "../walletConnect";
import styles from "./style.module.scss";
import { useLoader } from "../../context/Loader/useLoader";
import { BASE_CURRENCY, LENGTH, NAME } from "../../constant/constant";
import { setCartData, setFooter, setQuoteData } from "../../slices/home";
import CartItem from "../../common/CartItem";
import { setLoginCheck, setProfile } from "../../slices/auth";
const Cart = () => {
  const cartData = useSelector((state) => state?.home?.cartData);
  const quantData = useSelector((state) => state?.home?.quoteData);
  const history = useHistory();
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  dispatch(setFooter(false));
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [phaePrice, setPhaePrice] = useState();
  const { addToast } = useToast();
  const [totalSum, setTotalSum] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const MINUTE_MS = 600000;
  const fetchPhaePrice = async () => {
    const res = await getPhaePrice();
    if (res.status) {
      return res.conversion_rate;
    }
  };
  // useEffect(async () => {
  //   let cardAmount = cartData
  //     ?.map((i) => i.price)
  //     .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  //   setTotalSum(cardAmount);
  //   let cardVolume = cartData
  //     ?.map((i) => i.volume)
  //     .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  //   setTotalVolume(cardVolume);
  //   if (phaePrice == undefined && cardAmount != 0) {
  //     const rate = await fetchPhaePrice();
  //     setPhaePrice(rate * cardAmount);
  //   }
  //   const interval = setInterval(async () => {
  //     const rate = await fetchPhaePrice();
  //     setPhaePrice(rate * cardAmount);
  //   }, MINUTE_MS);
  //   return () => clearInterval(interval);
  // }, [cartData]);
  useEffect(() => {
    if (cartData) {
      let cardAmount = cartData
        ?.map((i) => i.price * i.volume)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
      cardAmount = String(cardAmount).substring(0, LENGTH.CRYPTO);
      setPhaePrice(cardAmount);
      let Amount = cartData
        ?.map((i) => i.fiat_price * i.volume)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
      setTotalSum(Amount);
      let cardVolume = cartData
        ?.map((i) => i.volume)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
      setTotalVolume(cardVolume);
    }
  }, [cartData]);
  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };
  const handleSubmitModal = async () => {
    const body = {
      sale_order_id: quantData,
    };

    const timeoutRef = setTimeout(async () => {
      setLoading(false);
      const body = {
        sale_order_id: quantData,
      };
      const res = await postCertificateActivate(body);
      const result = await walletConnectSessionTimeout();
      dispatch(setProfile({}));
      dispatch(setLoginCheck(false));
      history.push(`/`);
      addToast({
        message:
          "Intermittent connection, request time out! Please re-connect the wallet",
        type: "error",
        duration: 3000,
      });
    }, 60 * 1000);

    const res = await getSaleOrderMetaData(body);
    if (res.status) {
      setLoading(true, "Accept Transaction from your Phaeton Wallet");
      let info = res.data.asset;

      // const stringify = (obj) => {
      //   return JSON.stringify(
      //     obj,
      //     (key, value) => (typeof value === "bigint" ? value.toString() : value) // return everything else unchanged
      //   );
      // };
      let body = {
        salesOrderID: info.salesOrderID,
        buyerPublicKey: Buffer.from(info.buyerPublicKey, "hex"),
        saleOrderLines: info.saleOrderLines?.map((data) => {
          return {
            saleOrderLineID: data.saleOrderLineID,
            certificateID: data.certificateID,
            sellerPublicKey: Buffer.from(data.sellerPublicKey, "hex"),
            // type: "Sale",
            type: data.type,
            currency: data.currency,
            salePrice: data.salePrice,
            platformCommissionRate: data.platformCommissionRate,
            platformCommissionValueInCurrency:
              data.platformCommissionValueInCurrency,
            totalValue: data.totalValue,
            sellerPayable: data.sellerPayable,
            platformPayable: data.platformPayable,
          };
        }),
        saleOrderTotal: info.saleOrderTotal,
        conversionRate: info.conversionRate,
        paymentCurrencyTotal: info.paymentCurrencyTotal,
        platformSignature: Buffer.from(info.platformSignature, "hex"),
      };
      const resp = await buyCTNFTTransaction(res.data, body);
      handleModalOpen();
      setLoading(true, "Uploading the transaction hash");

      if (resp.status) {
        clearTimeout(timeoutRef);
        addToast({
          message: "Transaction Successful",
          type: "success",
          duration: 3000,
        });
        const infos = {
          txn_type: "sale_order",
          object_id: quantData,
          transaction: resp.transactionHash,
        };
        const saveTransaction = await saveTransactionHash(infos);
        setLoading(false);
        if (saveTransaction.status) {
          history.push("/my-orders");
          addToast({
            message: saveTransaction.message,
            type: "success",
            duration: 3000,
          });
          const data = {
            price: cartData.price,
            fiat_price: cartData.fiat_price,
          };
          dispatch(
            setCartData([
              {
                fiat_price: data.fiat_price,
                price: data.price,
                volume: 0,
              },
            ])
          );
          const result = await getQuateDate();
          dispatch(setQuoteData(result?.order_id));
        }
      } else {
        const body = {
          sale_order_id: quantData,
        };
        const res = await postCertificateActivate(body);
        addToast({
          message: resp.message ? resp.message : "Something went wrong",
          type: "error",
          duration: 3000,
        });
        setLoading(false);
      }
    } else {
      addToast({
        message: res.message ? res.message : "Something went wrong",
        type: "error",
        duration: 3000,
      });
    }
    // console.log("clicked");
  };

  return (
    <>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className={`${styles.mainContainer}`}>
          <div className="container-wrapper">
            <Container fluid>
              <div className={styles.pagesection}>
                <h2>Cart</h2>
              </div>
              {cartData && cartData?.length > 0 ? (
                <YourMiniCart cartData={cartData} />
              ) : (
                <NoRecordsFound removeSpacing={true} />
              )}
            </Container>
          </div>
          {cartData.length > 0 ? (
            <div className={`container-fluid ${styles.totalBond}`}>
              <div className={styles.bondInvestment}>
                <div className={styles.phaeInvestment}>
                  <div className={styles.totalBondInvestment}>
                    <p className={styles.totalBondInv}>Total Investment</p>
                    <p>Total Investment ({BASE_CURRENCY.CRYPTO})</p>
                    <h3>{phaePrice || 0}</h3>
                  </div>
                  <div className={styles.totalBondImage}>
                    {/* <img src="/images/double-arrow.svg" alt="NO" /> */}
                    <img src="images/common/exchange.png" />
                  </div>
                  <div className="d-flex align-items-center">
                    <div className={styles.totalBondInvestment}>
                      <p className={styles.totalBondSec}> &nbsp;</p>
                      <p>Investment ({BASE_CURRENCY.CURRENCY})</p>
                      <h3>
                        {BASE_CURRENCY.SYMBOL}
                        {totalSum || 0}
                      </h3>
                    </div>
                    <div className={styles.payNowMobile}>
                      <p className={styles.payNowBtnMobile}>Pay Now</p>
                    </div>
                  </div>
                </div>
                <div className={styles.phaeSection}>
                  <div className={styles.payNow}>
                    <p className={styles.payNowBtn} onClick={handleModalOpen}>
                      Pay Now
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
      {modalOpen && (
        <CommonModal
          modalOpen={modalOpen}
          BodyMessage={`Do you wish to buy ${totalVolume} Certificates?`}
          SecondMessage={` ${phaePrice} ${BASE_CURRENCY.CRYPTO}`}
          ButtonMessage={`Buy ${NAME.certificate}`}
          handleClose={handleModalOpen}
          handleSubmit={handleSubmitModal}
        />
      )}
      {/* <PaymentModal
          handleClose={handleClose}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          totalBoundInvest={totalAmount || 0}
          handleSubmit={() => {
            const method = getMethodConnect();
            if (method === "metamask") {
              metamaskTransaction();
            } else {
              handlePayNow();
            }
          }}
          amount={payNowRes?.asset}
        /> */}
    </>
  );
};
export default Cart;
