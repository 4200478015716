import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import SubHeader from "../component/Subheader";
import Resells from "../component/Resells";
import { getMyReSell, getBurnList } from "../action/Api.action";
import { useHistory } from "react-router-dom";
import { StorageHelper } from "../utils/StorageHelper";
const Resell = () => {
  const [data, setData] = useState();
  const history = useHistory();
  const userDetail = StorageHelper.getUserInfo();
  const [partnerType] = [userDetail.partner_type];
  useEffect(() => {
    if (localStorage.getItem('userType') !== "polluter") {
      history.push(`/edit-profile`);
    }
  }, [partnerType]);
  const fetchResell = async () => {
    console.log("CALLED");
    const res = await getMyReSell();
    if (res.status) {
      setData(res);
    }
  };
  // const fetchBurn = async () => {
  //   const res = await getBurnList();
  // };

  useEffect(() => {
    fetchResell();
    // fetchBurn();
  }, []);
  return (
    <>
      <SubHeader />
      <Container>
        <Resells data={data} fetchResell={fetchResell} />
      </Container>
    </>
  );
};
export default Resell;
