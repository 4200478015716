import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import SubHeader from "../component/Subheader";
import Withdraws from "../component/WithdrawOrders";
import { getWithdrawList } from "../action/Api.action";
import NoRecordsFound from "../component/NoRecordFound";
import { useHistory } from "react-router-dom";
import { StorageHelper } from "../utils/StorageHelper";
const Withdraw = () => {
  const [data, setData] = useState();
  const history = useHistory();
  const userDetail = StorageHelper.getUserInfo();
  const [partnerType] = [userDetail.partner_type];
  useEffect(() => {
		if (partnerType !== "polluter") {
		  history.push(`/edit-profile`);
		}
	}, [partnerType]);
  const fetchBurn = async () => {
    const res = await getWithdrawList();
    if (res?.status) {
      setData(res);
    }
  };

  useEffect(() => {
    fetchBurn();
  }, []);
  return (
    <>
      <SubHeader />
      <Container>
        <Withdraws data={data} />
      </Container>
    </>
  );
};
export default Withdraw;
