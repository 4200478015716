import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import SubHeader from "../component/Subheader";
import DelistCTNFT from "../component/Delist";
import { useHistory } from "react-router-dom";
import { StorageHelper } from "../utils/StorageHelper";
const MyCollection = () => {
  const history = useHistory();
  const userDetail = StorageHelper.getUserInfo();
  const [partnerType] = [userDetail.partner_type];
  useEffect(() => {
    if (partnerType !== "producer") {
      history.push(`/edit-profile`);
    }
  }, [partnerType]);
  return (
    <>
      <SubHeader />
      <Container>
        <DelistCTNFT />
      </Container>
    </>
  );
};
export default MyCollection;
