import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import NFTcard from '../Marketplace/NFTcard'
import ImageGallery from "react-image-gallery";
import { bidAction, getNftFromIdAndRelatedProjects, get_bid_transactions } from '../../action/walletApiAction';
import { useParams, useLocation, useHistory , Link} from 'react-router-dom';
import { useToast } from "../../context/Toast/useToast";
import CustomLoader from "../../common/customLoader/index"
import { convertCurrency } from '../../utils/convertCurrency';
import RetireNFT from './RetireNFT';
import BuyNFT from './BuyNFT';
import { certificateDocFetch } from '../../action/Api.action';
import Url from '../../utils/appUrls';
import { Country} from 'country-state-city';
import ReactCountryFlag from "react-country-flag";


function ViewCTNFT(props) {
    const blinkAnimation = `
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`

    // Injecting the blink animation CSS into the document head
    const styleSheet = document.createElement("style")
    styleSheet.type = "text/css"
    styleSheet.innerText = blinkAnimation
    document.head.appendChild(styleSheet)
    const spanStyle = {
        display: 'inline-block',
        width: '20px',
        height: '20px',
        marginRight: '4px',
        backgroundColor: 'green',
        borderRadius: '50%',
        animation: 'blink 1s infinite'
    }
    const activeButtonStyle = {
        display: 'inline-block',
        width: '100px',
        marginLeft: '0',
        marginTop: '0',
        marginBottom: '10px',
        marginRight: '30px'
    }
    const [StatusModalOpen, setStatusModalOpen] = useState(false);
    const [checkoutModalOpen, setcheckoutModalOpen] = useState(false);
    const [gasFee, setGasFee] = useState(0)
    const [nfttype, Setnfttype] = useState('owned')
    const [nftSellType,setNftSellType] = useState('')
    const location = useLocation()
    const history = useHistory()
    const params = useParams()
    const { addToast } = useToast();
    const [nftDet, setNftDet] = useState({})
    const [timeZone, setTimeZone] = useState('')
    const [loading, setLoading] = useState(false)
    const [relatedProejcts, setRelatedProjects] = useState([])
    const [dollarExchangeRate, setDollarExchangeRate] = useState("USD")
    const [images, setImages] = useState([])
    const [bidAmount, setBidAmount] = useState("")
    const [bidsData, setBidData] = useState([])
    const [userWallet, setUserWallet] = useState(null)
    const [isWalletConnected, setIsWalletConnected] = useState(null);
    const [userId, setIsUserID] = useState(null);
    const [timezone, setTimezone] = useState('');
    const [certificateDoc, setCertificateDoc] = useState({})
    const handleDownload = async (fileName) => {

        setLoading(true)
        try {
            const response = await fetch(certificateDoc);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.target = "_blank";
            link.download = fileName || "downloaded-file";
            link.style.display = 'none'; // Hide the link
            document.body.appendChild(link);
            // Simulate click to start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error("Error fetching the file:", error);

            setLoading(false)
        }

        setLoading(false)
    };
    useEffect(() => {
        
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimezone(userTimezone);
    }, [])

    useEffect(async () => {
        let certData = {}
        console.log("Ashmin test :", params)
        certData.nft_id = params.nft_id
        console.log("Ashmin Test for req payload", certData)

        let certiDoc = await certificateDocFetch(certData)
        console.log("Ashmin test for document fetch", certiDoc)
        if (certiDoc.status) {

            console.log("ashmin test", certiDoc.certificates_ids[0].docs[0])
            console.log("Document url ashmin test : ", `${Url.HOST + certiDoc.certificates_ids[0].docs[0].document_url}`)
            setCertificateDoc(`${Url.HOST + certiDoc.certificates_ids[0].docs[0].document_url}`)
        }
    }, []);
    useEffect(() => {
        setUserWallet(localStorage.getItem("wallet_address"))
        setIsWalletConnected(localStorage.getItem("wallet_connect"))
        setIsUserID(localStorage.getItem("userId"))
        setGasFee(0.549)

    }, []);

    const getCountry =  (countryCode) => {
        console.log('Ashmin Code to fetch country')
        var country = Country.getCountryByCode(countryCode)
        if (country )
        return country.name
        else return '' 
        }

    const getNftFromIdAndRelatedProjectFunc = async () => {
        console.log("NFT type for view hyperlinking", nfttype)

        setLoading(true)
        let body = {}
        body.res_nft_id = params.nft_id
        body.view = location.state.view
        const nftRes = await getNftFromIdAndRelatedProjects(body)
        await getBidTransaction()
        setLoading(false)
        if (nftRes.Status === "success") {
            setNftDet(nftRes.data.nftDet)

            let arr = []
            for (let i = 0; i < nftRes.data.nftDet.nftImages.length; i++) {
                if (nftRes.data.nftDet.nftImages[i]) {
                    arr.push({ original: nftRes.data.nftDet.nftImages[i].image })
                }
            }
            setImages(arr)
            if (nftRes?.data?.relatedProjects.length > 0) {
                setRelatedProjects(nftRes?.data?.relatedProjects)
            }
        } else {
            addToast({
                message: nftRes.message,
                type: "error",
            });
        }
    }
    useEffect(() => {
        
        getNftFromIdAndRelatedProjectFunc()
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeZone(timeZone)
    }, [params])
    useEffect(() => {
    }, [relatedProejcts])
    const getCurrency = async () => {
        const res = await convertCurrency("USDC", "USD");
        setDollarExchangeRate(res.USD)
    }
    useEffect(() => {
        // Setnfttype(location?.state?.view === "Listed" ? "Listed NFT's" : "Market Place")
        Setnfttype(location?.state?.view)
        setNftSellType(location?.sellType)
        console.log("Ashmin  Test for nFT sell Type ",nftSellType)
        getCurrency();
    }, [location])

    const statusdialog = () => {
        setStatusModalOpen(!StatusModalOpen);

    };
    const checkoutdialog = (type) => {
        
     if (type==="auction" && (!bidAmount || bidAmount===undefined|| bidAmount===null || bidAmount==='')){
            return addToast({
              message: "Please enter the bid amount",
              type: "error",
            });
          }
        setcheckoutModalOpen(!checkoutModalOpen);
      
    };
    useEffect(()=>{
        if (!checkoutModalOpen) {
            getNftFromIdAndRelatedProjectFunc()
            setBidAmount('')
        }

    }, [checkoutModalOpen])
    const handleKYC = async (nftDetail) => {

        history.push("/edit-profile")
    }
    const handlePlaceBid = async (nftDetail) => {
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        console.log("NFT type for view hyperlinking", nfttype,nftDet)
        if (authState === "Sign In") {
            history.push("/Login")
            return
        }
        else if (authState === "Create Wallet") {
            localStorage.setItem('redirectAfterConnect', window.location.pathname);
            history.push("/create-wallet")
            return
        }
        else if (authState === "Connect Wallet") {
            localStorage.setItem('redirectAfterConnect', window.location.pathname);
            history.push("/create-wallet")
            return
        }
      
        // setLoading(true)
        // let body = {};
        // body.nft_id = nftDetail.id
        // body.from_address = nftDetail.currentowner_wallet
        // body.to_address = userWallet
        // body.creator_address = nftDetail.creator_wallet
        // body.user_id = userId
        // body.sale_type = nftDetail.sell_type
        // body.buyer_id = userId
        // body.buyer_wallet = userWallet
        // body.price = nftDetail.price
        // body.bid_price = bidAmount
        // body.seller_id = nftDetail?.user_id
        // body.token_id = nftDetail?.token_id
        // body.gas_fee = gasFee
        // body.currentowner_wallet = nftDetail?.currentowner_wallet
        // body.current_price = nftDetail?.current_price
        // const res = await bidAction(body)
        // console.log("res", res)
        // setLoading(false)
        // if (res.Status === "Error") {
        //     addToast({
        //         message: res.message,
        //         type: "error",
        //     });
        // } else {
        //     setBidAmount("")
        //     addToast({
        //         message: "Bid Successfull",
        //         type: "success",
        //     });
        //     await getNftFromIdAndRelatedProjectFunc()

        // }
    }
    const [authState, setAuthState] = useState("Sign In")
    useEffect(() => {
        if (userId == null) {
            setAuthState("Sign In")
        }
        else if (authState === 'complete KYC') {
            setAuthState("KYC")
        }
        else if (isWalletConnected == "false" && (isWalletConnected == "false" && (userWallet == "" | undefined | null))) {

            setAuthState("Create Wallet")
        }
        else if ((isWalletConnected == "false" && (userWallet != "" | undefined | null))) {
            setAuthState("Connect Wallet")
        } else {
            setAuthState("Place Bid")
        }
    }, [isWalletConnected, userWallet, userId])
    const handleBuyBidButton = async () => {
        if (authState === "Sign In") {
            history.push("/Login")
            return
        }
        else if (authState === 'KYC') {
            history.push('/edit-profile')
        }
        else if (authState === "Create Wallet") {
            history.push("/create-wallet")
            return
        }
        else if (authState === "Connect Wallet") {

            history.push("/create-wallet")
            return
        }
    }
    useEffect(() => {
        if (nftDet) {
            getBidTransaction()
        }
    }, [nftDet])

    const getBidTransaction = async () => {
        if (nftDet) {
            setLoading(true)
            let body = {}
            body.nft_id = nftDet.id
            body.type = "bid"
            const bidTransRes = await get_bid_transactions(body)
            console.log("bidTransRes", bidTransRes)

            setLoading(false)
            if (bidTransRes.Status === "success") {
                setBidData(bidTransRes.data)
            }
        }
    }
    const handleBidAmount = (value) => {

        if (value.toString().length < 9) setBidAmount(value)

    }


    return (
        <>
            {loading && (
                <div>
                    <CustomLoader />
                </div>
            )}

            <Container className='marketplacebx' fluid>
                <div className='marketplaceMaine'>
                    <Row>
                        <Col lg={8}>
                            <ul>
                            {nfttype == "Market Place" && <li > <Link to={`/marketplace`}><h1>{nfttype}</h1></Link></li>}
                                {/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
                               {nfttype !== "Market Place" && <li > <Link to={`/${nfttype}CNFT`}><h1>{nfttype}</h1></Link></li>}
                                <li> <h1 className='viewpagehead'>{nftDet?.name}</h1></li>
                            </ul>

                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col lg={7}>
                            <div className='ImagesBox position-relative'>
                                {nftDet?.sell_type === 'fixed' ? (
                                    ''
                                ) : (<div className='auctionLabel'>Auction End in&nbsp;
                                    {new Date(Number(nftDet?.end_time)).toLocaleString('en-US', { timeZone: timeZone })}

                                </div>)}
                                <ImageGallery autoPlay={true} items={images} />

                                {/* <div className='imgbx bx1'>
                            <img src={'https://s3-alpha-sig.figma.com/img/1ddd/b97b/8c574bd43ccfe1de990c38023eacd89e?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fX4v5PZXTNlFyWmU38BONV2cf2qxnzO4Ifi91xSH7nW44aTVcQWagp1tCS4g8K5PtTbUHwYJ9j0xtz8t3Ijtb1HiZ--WLjr0g004DW3ERvld2DmUCLmfSgDCxvmyzVxL3h4wnI4a51ZENpWzllu~1k5TQkQ6AQQsim7em4pJC3pS3H1b4~ML8xD7fNl20DjSiw8kWLLw1MNdVuHqkVU-tkLWHzg~RRQKrqWGfEYG28HN18S9Rayj2UlkFyznYXNmVy7zCPDtgP76nq9ZEtaHgbb-L2XxQWii0KRygD5FhPDfRMBaePt-DC8beuFKxuHB659bayXqPumBuUnmoZqZrg__'} alt='' />
                            </div>
                            <div className='imgbx noimg'>
                                <img src="/images/svg/imageIcon.svg" alt="" />
                            </div>
                            <div className='imgbx noimg'>
                                <img src="/images/svg/imageIcon.svg" alt="" />
                            </div>
                            <div className='imgbx noimg'>
                                <img src="/images/svg/imageIcon.svg" alt="" />
                            </div>
                            <div className='imgbx noimg'>
                                <img src="/images/svg/imageIcon.svg" alt="" />
                            </div> */}

                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className='NFTcontentBox'>
                           
                                 {((nftDet.creator_wallet == localStorage.getItem('wallet_address') && nfttype === "owned" ) && nftSellType !="sold") && <div className='d-flex align-items-center mb-3'><button className='primaryBtn btn mb-0' style={activeButtonStyle}>Active</button>
                                 <span style={spanStyle}></span>
                                 </div>}
                              
                                <div className='d-flex align-items-center gap-2 viewprice mb-2'>
                                    <img width={30} src="/images/svg/usdcIcon.svg" alt="" />
                                    {nftDet?.sell_type === 'auction' ? nftDet?.current_price : nftDet?.price}  
                                <ReactCountryFlag countryCode={nftDet.country} style={{display:'inline', fontSize: "2em" ,marginLeft : 'auto'}} />
                                </div>
                                <h2>{nftDet?.name}</h2>
                                <p>{new Date(nftDet?.create_date).toLocaleString('en-US', { timeZone })}, {" " + getCountry(nftDet.country)}</p>
                                <div className='discriptionBox'>
                                    {/* {nftDet?.description} */}
                                    <p>{nftDet?.description}</p>
                                </div>
                                <h3>Address</h3>
                                <p>{nftDet?.address}, {nftDet?.city}, {nftDet?.country} </p>
                                <h3>Registry</h3>
                                <p>{nftDet?.registry}</p>
                                {/* <h3>Other Details</h3>
                                <table>
                                    <tr>
                                        <td>Price</td>
                                        <td>{nftDet?.price} (${nftDet?.price * dollarExchangeRate})</td>
                                    </tr>
                                    <tr>
                                        <td>Project Type</td>
                                        <td>{nftDet?.project_type}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Credit</td>
                                        <td>{Number(nftDet?.total_credit)}</td>
                                    </tr>
                                    <tr>
                                        <td>Fixed/Auction</td>
                                        <td>{nftDet?.sell_type === "fixed" ? "Fixed" : "Auction"}</td>
                                    </tr>
                                </table> */}

                                {(nfttype === "Market Place" && nftDet?.sell_type == "fixed") &&
                                    <> {localStorage.getItem('userState') == 'approved' && (<button onClick={() => checkoutdialog(nftDet?.sell_type)} className='primaryBtn btn'>Buy</button>)}
                                        {localStorage.getItem('userState') != 'approved' && (<button className='primaryBtn btn' onClick={() => handleKYC()}>Complete your KYC to continue</button>)}
                                    </>
                                }
                                {nftDet?.sell_type === 'auction' && nfttype === 'Market Place' &&
                                    (<>
                                        <h3>Place Bid</h3>
                                        <Form.Control type='number' maxLength={99999999} placeholder='Enter Your Bid Amount' disabled={authState !== "Place Bid" ? true : false} value={bidAmount} onChange={(e) => { handleBidAmount(e.target.value) }} ></Form.Control>


                                        {localStorage.getItem('userState') == 'approved' && (authState !== "Place Bid" ?
                                            <button className='primaryBtn btn' onClick={() => handlePlaceBid(nftDet)}>Please {authState} to make bid</button>
                                            :
                                            // <button className='primaryBtn btn' onClick={() => handlePlaceBid(nftDet)}>{authState} </button>)
                                            <button className='primaryBtn btn' onClick={() => checkoutdialog(nftDet?.sell_type)}>{authState} </button>)
                                        }
                                        {localStorage.getItem('userState') != 'approved' && (<button className='primaryBtn btn' onClick={() => handleKYC()}>Complete your KYC to continue</button>)}
                                    </>)}

                                {nfttype === "bought" &&
                                    <div className='text-center'>
                                        <button className='retirebtn btn' onClick={() => statusdialog()}>Retire NFT & Download Certificate</button>
                                    </div>
                                }
                                {nfttype === "sold" &&
                                    <div className='text-center'>
                                        <button className='retirebtn btn ' >Sold Out</button>
                                    </div>
                                }
                                {(nfttype === "owned") && (
                                    <>
                                        <div className='text-center'>
                                        {/* <button onClick={() => checkoutdialog()} className='primaryBtn btn'>Resell</button> */}
                                        {/* <button onClick={() => checkoutdialog()} className='primaryBtnNew btn'>Active</button> */}
                                       {/* {(nftDet.creator_wallet == localStorage.getItem('wallet_address') ) && <button className='primaryBtn btn'>Active</button>} */}
                                       {(nftDet.creator_wallet != localStorage.getItem('wallet_address') || nftSellType == "sold" )&& <button className='primaryBtnNew btn'>Resell</button>}
                                        
                                        </div>
                                    </>
                                )}
                            </div>
                        </Col>

                    </Row>
                    {/* // view and auction  */}

                    <Row className='mb-4'>
                        <Col lg={7}>
                            <Row className='h-100'>
                                <Col lg={6}>
                                    <div className='nftDetailsBox tblebxnft'>
                                        <h3>Offset</h3>
                                        <table>
                                            <tr>
                                                <td>Verifier</td>
                                                <td>{nftDet?.verifier}</td>
                                            </tr>
                                            <tr>
                                                <td>Credit</td>
                                                <td>{nftDet?.total_credit}</td>
                                            </tr>
                                            <tr>
                                                <td>Currency</td>
                                                <td>{nftDet?.currency}</td>
                                            </tr>

                                            <tr>
                                                <td>Serial Number</td>
                                                <td>{nftDet?.accu_number}</td>
                                            </tr>
                                            <tr>
                                                <td>Standard</td>
                                                <td>{nftDet?.standard}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='nftDetailsBox tblebxnft'>
                                        <h3>Other Details</h3>
                                        <table>

                                            <tr>
                                                <td>Project Type</td>
                                                <td>{nftDet?.project_type}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Credit</td>
                                                <td>{nftDet?.total_credit}</td>
                                            </tr>
                                            <tr>
                                                <td>Fixed/Auction</td>
                                                <td>{nftDet?.sell_type === 'fixed' ? "Fixed" : "Auction"}</td>
                                            </tr>
                                            <tr>
                                                <td>No. of Certificates</td>
                                                <td>{nftDet?.no_of_docs}</td>
                                            </tr>

                                        </table>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                        {nftDet?.sell_type === 'auction' && (<>
                            <Col lg={5}>
                                <div className='nftDetailsBox offertable px-0'>
                                    <h3><i class="fa-solid fa-list"></i> Offers</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>Price</td>
                                                <td>From</td>
                                                <td>To</td>
                                                <td>Bid Date</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bidsData.length > 0 && bidsData.map((item, index) =>
                                                <tr key={index}>
                                                    <td><div className='d-flex align-items-center gap-2'><img width={20} src="/images/svg/usdcIcon.svg" alt="" /> {item?.price}</div></td>
                                                    <td>{item?.from_address.slice(0, 4) + "..." + item?.from_address.slice(-4)}</td>
                                                    <td>{item?.to_address?.slice(0, 4) + "..." + item?.to_address?.slice(-4)}</td>
                                                    <td><span className='d-block'>{new Date(item?.create_date)?.toLocaleString('en-US', { timeZone: timezone })}</span></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </>)}
                        {(nfttype === "retire") && (<>
                            <Col lg={5}>
                                <div className='nftDetailsBox h-auto'>
                                    <h3> Certificates</h3>
                                    <div className='certificateBox'>


                                        <div><div className='certificates images'>
                                            <a href={certificateDoc} download="MyExampleDoc" rel="noreferrer" target='_blank'>

                                                <img src={certificateDoc} alt='' />
                                            </a>
                                        </div>



                                        </div>


                                        {/* <div className='certificates pdfasset'>
                                                <img src="/images/svg/pdfIcon.svg" alt='' />
                                            </div>
                                            <div className='certificates images'>
                                                <img src='https://s3-alpha-sig.figma.com/img/66b8/889a/0c9c213ed62941b2febb12377ced3e1c?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jt7w1rq54RFGERjdpDSAIsqfj3yuyrilwiFu6d5rePbrU0xpFrGQztPoKbYq~qT2Zu0yR0G9dXl7sVXNHimydonWzEoajW4n0VXz0PLgJXB3ZUZ2ofLJtDiHVvFD33nIxaXYioub0z8jIGPbrOmLizsOeaCKec2yOHEg~EFpiJf3L4Jm9lB3usC1MkqdfAoDCn-LjHqKpdG6pVZXUNt1XxxE2QSOV8FobS-5DK7kjNJXki3EAEvgNTCsMIVqexYsn4x5qhJz2NiVgOm8jgdw0zAXBFVaXdRtf1Pi6OYEzwBEpXa7objhU~DGVtiaicC1H-y9SN1Ne1KR5y6ztMcLqg__' alt='' />
                                            </div>
                                            <div className='certificates pdfasset'>
                                                <img src="/images/svg/pdfIcon.svg" alt='' />
                                            </div> */}


                                    </div>
                                </div>
                                <button className='primaryBtn btn mt-4 ms-auto' onClick={() => { handleDownload("Certificate Document" + certificateDoc) }}>Download Certificates</button>
                            </Col>
                        </>)}


                    </Row>

                    {nfttype === 'Market Place' && (
                        <Row>

                            <Col lg={12}>
                                <div className='marketplaceMaineInner'>
                                    <div className='headwithView'>
                                        <h1>Related Project</h1>
                                        <p className='d-flex align-items-center gap-2'>View All <img width={30} src="/images/svg/arrowRight.svg" alt='' /></p>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <Row>
                                                {relatedProejcts.length > 0 && relatedProejcts.map((item, i) =>
                                                    <Col lg={3} key={i}>
                                                        <NFTcard NFTType={item} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            </Container>
            <RetireNFT statusshow={StatusModalOpen} StatusFunc={statusdialog} nftDet={nftDet}></RetireNFT>

            <BuyNFT statusshow={checkoutModalOpen} StatusFunc={checkoutdialog} data={{ userWallet, isWalletConnected, userId, nftDet, bidAmount }}></BuyNFT>
        </>
    )
}

export default ViewCTNFT