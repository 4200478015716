import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import CustomRadioButton from "../common/CommonRadioButton";
import PolluterProfile from "../component/PolluterProfile";
import ProducerProfile from "../component/ProducerProfile";
import { NAME } from "../constant/constant";

function Registration() {
  const [UserType, setUserType] = useState({
    label: NAME.buyer,
    value: "polluter",
  });
  const UserTypes = [
    { label: NAME.buyer, value: "polluter" },
    { label: NAME.supplier, value: "producer" },
  ];

  return (
    <>
      {/* <SubHeader /> */}
      <Container>
        <div className="moreInfoSec signUpForm boxShaddow burger">
          <CustomRadioButton
            items={UserTypes}
            name="User Type"
            selected={UserType}
            onChange={(value) => setUserType(value)}
            direction="horizontal"
            //   className={styles.RadioButton}
          />
          {UserType?.value === "polluter" && <PolluterProfile />}
          {UserType?.value === "producer" && <ProducerProfile />}
        </div>
      </Container>
    </>
  );
}

export default Registration;
