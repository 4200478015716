import { Component } from "react";
import Chart from "react-apexcharts";
class Charts extends Component {
    constructor(props) {
        super(props);
        const user = props.user
        var chartData
        if(localStorage.getItem("userType") !== "producer"){
          this.chartData = [
            {
              name: "Monthly NFT Buying",
              data: [31, 40, 28, 51, 42, 109, 100],
            },
            {
              name: "Total Burn",
              data: [11, 32, 45, 32, 34, 52, 41],
            },
          ]
        } else {
          this.chartData = [
            {
              name: "Monthly NFT Selling",
              data: [31, 40, 28, 51, 42, 109, 100],
            },
            {
              name: "Total Retired",
              data: [11, 32, 45, 32, 34, 52, 41],
            },
            {
              name: "Recently Resold",
              data: [14, 20, 25, 15, 36, 40, 20],
            },
            {
              name: "Recently Purchased",
              data: [20, 35, 25, 15, 30, 25, 45],
            },
          ]
        }
        this.state = {
          series: this.chartData,
          options: {
            chart: {
              height: 400,
              width: "100%",
              type: "area",
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              type: "datetime",
              categories: [
                "2018-09-19T00:00:00.000Z",
                "2018-09-19T01:30:00.000Z",
                "2018-09-19T02:30:00.000Z",
                "2018-09-19T03:30:00.000Z",
                "2018-09-19T04:30:00.000Z",
                "2018-09-19T05:30:00.000Z",
                "2018-09-19T06:30:00.000Z",
              ],
            },
            tooltip: {
              x: {
                format: "dd/MM/yy HH:mm",
              },
            },
          },
        };
        console.log('chaarts',props);
      }
    render() {
        return (
            <>
                 <Chart
                  height={415}
                  className={"chartheihgt"}
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                />
            </>
        )
    }
}

export default Charts;