import React from 'react'
import ViewCTNFT from '../component/ViewCTNFT'

function ViewCTNFTByList() {
  return (
    <>
        <ViewCTNFT />
    </>
  )
}

export default ViewCTNFTByList