import {React } from 'react'
import Outlet from 'react'



import { Nav } from 'react-bootstrap'
import WalletMenu from './WalletMenu'

const WalletMaine = () => {
  return (
   <>
     <div className='walletMaineBox'>    
        <WalletMenu />
        <div className='walletRanderBox'>
          Dashboard
        </div>
    </div>
   </>
  )
}

export default WalletMaine