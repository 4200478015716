import React, { Component, useState, useEffect } from "react";
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { setadminPathName } from '../../slices/home';

import { setAdminNavName } from "../../utils/common";



const SideBar = () => {

    const [name, setName] = useState("");
    const [SidebarWidth, setSidebarWidth] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        let name1 = setAdminNavName();
        setName(name1);
        dispatch(setadminPathName(name1));

    }, []);

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= 768) {
                setSidebarWidth(0);
            } else {
                setSidebarWidth(undefined);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    return (

        // <div className="sidebar">
        //     {/* <Nav activeKey="/admin/dashboard" onSelect={selectedKey => activeNav = selectedKey}> */}
        //     <Nav>
        //         <div className="sidebar-sticky"></div>

        //         <Nav.Item>
        //             <Nav.Link className={name==="Dashboard"?"active":""} as={Link} eventKey="101" to="/admin/dashboard"><img src="../assets/img/dashboard.png" /></Nav.Link>
        //         </Nav.Item>
        //         <Nav.Item>
        //             <Nav.Link className={name==="Manage Producers"?"active":""} as={Link} eventKey="102" to="/admin/manageProducers"><img src="../assets/img/users.png" /></Nav.Link>
        //         </Nav.Item>
        //         <Nav.Item>
        //             <Nav.Link className={name==="CTNFT Listing"?"active":""} as={Link} eventKey="103" to="/admin/ctnftListing"><img src="../assets/img/supplier.png" /></Nav.Link>
        //         </Nav.Item>
        //         {/* <Nav.Item>
        //             <Nav.Link className={name==="CTNFT Listing"?"active":""} as={Link} eventKey="104" to="/admin/ctnftListing"><img src="../assets/img/creditor.png" /></Nav.Link>
        //         </Nav.Item> */}
        //         <Nav.Item>
        //             <Nav.Link className={name==="Manage Document"?"active":""} as={Link} eventKey="105" to="/admin/manageDocument"><img src="../assets/img/invoice.png" /></Nav.Link>
        //         </Nav.Item>
        //         <Nav.Item>
        //             <Nav.Link className={name==="Business Entity"?"active":""} as={Link} eventKey="106" to="/admin/businessEntity"><img src="../assets/img/offer.png" /></Nav.Link>
        //         </Nav.Item>
        //         <Nav.Item>
        //             <Nav.Link className={name==="Report"?"active":""} as={Link} eventKey="107" to="/admin/report"><img src="../assets/img/report.png" /></Nav.Link>
        //         </Nav.Item>
        //         <Nav.Item>
        //             <Nav.Link className={name==="Ledger"?"active":""} as={Link} eventKey="108" to="/admin/ledger"><img src="../assets/img/ledger.png" /></Nav.Link>
        //         </Nav.Item>
        //         <Nav.Item>
        //             <Nav.Link className={name==="Settings"?"active":""} as={Link} eventKey="109" to="/admin/settings"><img src="../assets/img/settings.png" /></Nav.Link>
        //         </Nav.Item>

        //     </Nav>
        // </div>
        <div className="sidebarSticky">
            <div
                className="sidebarCheveronButton"
                onClick={() => {
                    switch (SidebarWidth) {
                        case 0:
                            setSidebarWidth(90);
                            break;
                        case 90:
                            setSidebarWidth(0);
                            break;
                        case 300:
                            setSidebarWidth(0);
                            break;
                    }
                }}
                style={{
                    left: SidebarWidth ? SidebarWidth - 5 : undefined,
                    transform: SidebarWidth === 0 ? "rotate(0deg)" : "rotate(180deg)",
                }}
            >
                <i className="fa-solid fa-chevron-right"></i>
            </div>
            <div
                className="sidebar"
                style={{
                    width: window.innerWidth > 768 ? undefined : SidebarWidth,
                }}
                onMouseOver={() => {
                    setSidebarWidth(300);
                }}
            >
                <div className="sidebarAlignment">

                    <div
                        className={`sidebarMenu Active`}
                    >
                        <div>
                            <img
                                src={`../assets/img/dashboard.png`}
                                width={24}
                                height={24}
                                alt=""
                            />
                        </div>
                        <div>
                            <Link href="/addnewbond">
                                <span>Add New Bond</span>
                            </Link>
                        </div>
                    </div>
                    <div
                        className={`sidebarMenu`}
                    >

                        <div>
                            <img
                                src={`../assets/img/users.png`}
                                width={24}
                                height={24}
                                alt=""
                            />
                        </div>
                        <div>
                            <Nav.Item>
                                <Nav.Link className={name === "Dashboard" ? "active" : ""} as={Link} eventKey="101" to="/admin/dashboard">
                                    <span>My Collection</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Link href="/my-collections">
                            </Link>
                        </div>
                    </div>
                    <div
                        className={`sidebarMenu`}
                    >

                        <div>
                            <img
                                src={`../assets/img/supplier.png`}
                                width={24}
                                height={24}
                                alt=""
                            />
                        </div>
                        <div>
                            <Nav.Item>
                                <Nav.Link className={name === "Dashboard" ? "" : ""} as={Link} eventKey="101" to="/admin/dashboard">
                                    <span>My Collection</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Link href="/my-collections">
                            </Link>
                        </div>
                    </div>
                    <>
                        <div
                            className={`sidebarMenu`}
                        >

                            <div>
                                <img
                                    src={`../assets/img/creditor.png`}
                                    width={24}
                                    height={24}
                                    alt=""
                                />
                            </div>
                            <div>
                                <Nav.Item>
                                    <Nav.Link className={name === "Dashboard" ? "" : ""} as={Link} eventKey="101" to="/admin/dashboard">
                                        <span>My Collection</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Link href="/my-collections">
                                </Link>
                            </div>
                        </div>
                        <div
                            className={`sidebarMenu`}
                        >

                            <div>
                                <img
                                    src={`../assets/img/dashboard.png`}
                                    width={24}
                                    height={24}
                                    alt=""
                                />
                            </div>
                            <div>
                                <Nav.Item>
                                    <Nav.Link className={name === "Dashboard" ? "" : ""} as={Link} eventKey="101" to="/admin/dashboard">
                                        <span>My Collection</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Link href="/my-collections">
                                </Link>
                            </div>
                        </div>
                        <div
                            className={`sidebarMenu`}
                        >

                            <div>
                                <img
                                    src={`../assets/img/dashboard.png`}
                                    width={24}
                                    height={24}
                                    alt=""
                                />
                            </div>
                            <div>
                                <Nav.Item>
                                    <Nav.Link className={name === "Dashboard" ? "" : ""} as={Link} eventKey="101" to="/admin/dashboard">
                                        <span>My Collection</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Link href="/my-collections">
                                </Link>
                            </div>
                        </div>
                    </>
                    <div
                        className={`sidebarMenu`}
                    >

                        <div>
                            <img
                                src={`../assets/img/dashboard.png`}
                                width={24}
                                height={24}
                                alt=""
                            />
                        </div>
                        <div>
                            <Nav.Item>
                                <Nav.Link className={name === "Dashboard" ? "" : ""} as={Link} eventKey="101" to="/admin/dashboard">
                                    <span>My Collection</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Link href="/my-collections">
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SideBar;