import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select';


import { useSelector, useDispatch } from 'react-redux';
import { setUserList } from '../../slices/admin';

import SideBar from '../component/SideBar';

import { getAdminUserListAction, tableListStatusAction } from "../../action/Admin.action";
import AdminLayout from "../../Layout/AdminLayout";

let pendingArr = ['register', 'otpVerified', 'contractSend', 'companyVerified', 'companyReject'];
let pendingArr1 = ['register', 'otpVerified'];

const ManageProducers = ({ history }) => {

	const dispatch = useDispatch();
	const dataList = useSelector(state => state.admin.userList);

	const options = [
		{ value: 'chocolate', label: 'Chocolate' },
		{ value: 'strawberry', label: 'Strawberry' },
		{ value: 'vanilla', label: 'Vanilla' }
	];



	//const [dataList, setDataList] = useState([]);
	const [rowData, setRowData] = useState({});
	const [spinner, setSpinner] = useState(true);

	const getAdminUserList = async (e) => {

		if (dataList && !dataList.length) {
			let res = await getAdminUserListAction();
			if (res.code === 200) {
				dispatch(setUserList(res.data));
				setSpinner(false);
			}
		}

	}


	const actionFunction = (cell, row) => {

		return (<div className="action multiBtnOptn">
			<Button className="editBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>

			{pendingArr.includes(row.steps) ?
				<Button className="checkedBtn notAllowed" variant="" type="submit"><i className="fa fa-check"></i></Button> :
				<Button className="checkedBtn" variant="" type="submit" onClick={e => listActionFunction(row)}><i className="fa fa-check"></i></Button>
			}

			{(row.steps === 'companyReject') ?
				<Button className="cancelBtn notAllowed" variant="" type="submit"><i className="fa fa-times"></i></Button> :
				<Button className="cancelBtn" variant="" type="submit" onClick={e => listActionFunction(row, 'companyReject')}><i className="fa fa-times"></i></Button>
			}

			<a className="btn downloadBtn" href={row.contractPath} download> <i className="fa fa-download"></i> </a>

			<Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button>
		</div>)
	}

	const listActionFunction = async (row, actionType = '') => {
		await tableListStatusAction({ '_id': row._id, 'status': row.steps, 'type': 'kyc', actionType: actionType });
		getAdminUserList();
	}

	const kycStatusFunction = (cell, row) => {
		let status = '';

		//register, otpVerified, companyCreated, contractSend, contractSign, companyVerified, companyReject

		if (pendingArr1.includes(row.steps)) {
			status = 'Pending';
		} else if (row.steps === 'contractSend') {
			status = 'contractSend';
		} else if (row.steps === 'contractSign') {
			status = 'contractSign';
		} else if (row.steps === 'companyVerified') {
			status = 'Approved';
		} else if (row.steps === 'companyReject') {
			status = 'Rejected';
		} else {
			status = 'Pending';
		}

		return (<span class="">{status} </span>)
	}

	useEffect(() => {
		getAdminUserList();
	}, []);

	function indexN(cell, row, enumObject, index) {
		return (<div>{index + 1}</div>)
	}

	return (
		<AdminLayout>
			<Row>
				<Col xs={12}>
					<div className="CTNFTrequestQuote tableListing boxShaddow burger">
						<h3 className="heading">Manage Producers</h3>

						{/* <div className="tableSelection">
											<Form>
												<Form.Group className="date mb-3" controlId="exampleForm.ControlStartDate">
												    <Form.Control type="date" placeholder="09-09-2020" />
												</Form.Group>
												<Form.Group className="date mb-3" controlId="exampleForm.ControlEndDate">
												    <Form.Control type="date" placeholder="09-05-2021" />
												</Form.Group>
												<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlProducers">
												    <Select options={options} />
												</Form.Group>
												<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlStatus">
												    <Select options={options} />
												</Form.Group>
												<Button className="primaryBtn submitBtn mb-3" variant="">SUBMIT</Button>
												<Button className="primaryBtn addNewProdBtn mb-3" variant="">Add New Producer</Button>
											</Form>
										</div> */}


						<BootstrapTable width='150' scrollTop={'Left'} className="mt-3" data={dataList} pagination={true} search={true} bordered={false}>
							<TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='_id' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>

							<TableHeaderColumn dataField="any" width='58' dataFormat={indexN}>Sr. No</TableHeaderColumn>

							<TableHeaderColumn width='100' dataField='fullName'>Name</TableHeaderColumn>
							<TableHeaderColumn width='100' dataField='companyRegisterNumber'>Registration No.</TableHeaderColumn>
							<TableHeaderColumn width='100' dataField='companyTaxationNumber'>ERF Registration</TableHeaderColumn>
							<TableHeaderColumn width='100' dataField='contactName'>Contact Person</TableHeaderColumn>
							<TableHeaderColumn width='100' dataField='email'>Email</TableHeaderColumn>
							<TableHeaderColumn width='100' dataField='phone'>Mobile</TableHeaderColumn>
							<TableHeaderColumn width='100' dataField='createdAt'>Created By</TableHeaderColumn>
							<TableHeaderColumn width='100' dataFormat={kycStatusFunction}>KYC Status</TableHeaderColumn>
							<TableHeaderColumn width='200' dataFormat={actionFunction}>Action</TableHeaderColumn>




							{/* <TableHeaderColumn dataFormat={nftCount} width='80'>NFT Count</TableHeaderColumn>
											<TableHeaderColumn dataFormat={totalSale} width='100'>Total Sale</TableHeaderColumn> */}
							{/* <TableHeaderColumn dataField='enabled' width='120' dataFormat={action} dataSort={true} searchable={false}>Status</TableHeaderColumn>
											<TableHeaderColumn dataField='enabled' width='120' dataFormat={details} dataSort={true} searchable={false}>Details</TableHeaderColumn> */}
						</BootstrapTable>

					</div>
				</Col>
			</Row>
		</AdminLayout>
	);
};

export default ManageProducers;
