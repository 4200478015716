import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDecimal, getDollerValue } from "../common/Constant";
import { BASE_CURRENCY } from "../constant/constant";
import Timer from "./Timer";

const SingleCTNFT = ({ data }) => {
  //console.log(data);

  const [ctnft, setCTNFT] = useState({});

  /* Api Calling */
  useEffect(() => {
    let nftData = data;
    nftData.price = getDecimal(nftData.price, 2);
    nftData.doller = getDollerValue(nftData.price, nftData.currency);
    setCTNFT(nftData);
    if (data.auctionStatus === "timed") {
      timer(data.endTime, "ctnft-" + data._id);
    }
  }, [data]);

  const timer = (endDate, id, type = "live") => {
    // Get today's date and time
    let now = new Date().getTime();
    let countDownDate = new Date(endDate).getTime();

    // Find the distance between now and the count down date
    let distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));

    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );

    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    let html = ``;
    // Display the result in the element with id="demo"
    if (document.getElementById(id)) {
      if (days > 0) {
        html +=
          `<ul>
							<li>` +
          (days < 10 ? "0" : "") +
          days +
          `</li>
							<li>D</li>
						</ul>`;
      }

      html +=
        `<ul>
							<li>` +
        (hours < 10 ? "0" : "") +
        hours +
        `</li>
							<li>H</li>
						</ul>
						<ul>
							<li>` +
        (minutes < 10 ? "0" : "") +
        minutes +
        `</li>
							<li>M</li>
						</ul>
						<ul>
							<li>` +
        (seconds < 10 ? "0" : "") +
        seconds +
        `</li>
							<li>S</li>
						</ul>`;

      document.getElementById(id).innerHTML = html;
    }

    // If the count down is finished, write some text
    if (distance < 0) {
      // if(type === 'highBid'){
      //     getHomeSingleProductData();
      // }else{
      //    // get_ProductList();
      // }
    } else {
      setTimeout(() => {
        timer(endDate, id, type);
        //console.log(endDate);
        //console.log(id);
      }, 1000);
    }
  };

  return (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <div className="ctnftBox">
        <p className="likeShare">
          <span>
            <i className="fa fa-heart"></i>
            {ctnft.likes}
          </span>
          <span className="share">
            <img src="assets/img/share.png" />
          </span>
        </p>
        <div className="ccxInfo timeInfo">
          <img
            src="assets/img/top-trending.png"
            alt="First slide"
            className="colorBG"
          />
          <img
            src="assets/img/mouseover-top-trending.png"
            alt="First slide"
            className="whiteBG"
          />
          {ctnft && ctnft._id && <Timer data={ctnft} />}
          {/* <div className="timer" id={"ctnft-"+ctnft._id}>
							 <ul>
								<li>03</li>
								<li>D</li>
							</ul>
							<ul>
								<li>23</li>
								<li>H</li>
							</ul>
							<ul>
								<li>59</li>
								<li>M</li>
							</ul>
							<ul>
								<li>09</li>
								<li>S</li>
							</ul> 
						</div> */}
        </div>
        <p className="credit">{ctnft.noOfCopies} Carbon credits</p>
        <p className="aud">
          {ctnft.price} {BASE_CURRENCY.CRYPTO} AUD $ {ctnft.doller}
        </p>
        {ctnft.auctionStatus === "timed" && (
          <div className="bidInfo">
            <span>Total bids: {ctnft.totalBidCount}</span>
            <Link
              to={{ pathname: "ctnft-detail", state: ctnft }}
              className="placeBidBtn">
              Place Bid
            </Link>
          </div>
        )}
        {ctnft.auctionStatus === "fixed" && (
          <div className="bidInfo">
            <span></span>
            <Link
              to={{ pathname: "ctnft-detail", state: ctnft }}
              className="placeBidBtn">
              Buy Now
            </Link>
          </div>
        )}
      </div>
    </Col>
  );
};

export default SingleCTNFT;
