import React from "react";
import { Col, Row } from "react-bootstrap";
import BarCharts from "../../common/Dashboard/BarChart";
import ChartBar from "../../common/Dashboard/ChartBar";
import DonutChart from "../../common/Dashboard/DonutChart";
import LineChart from "../../common/Dashboard/LineChart";
import TableData from "../../common/Dashboard/TableData";
import Toptital from "../../common/Dashboard/TopTital";
import { NAME } from "../../constant/constant";
import SubHeader from "../Subheader";

const PolluterDashboard = ({ data }) => {
  return (
    <>
    {console.log(data)}
      <Row className="mt-3">
        <Row>
          {data &&
            Object.entries(data?.tile_data).map((info, index) => {
              return (
                <Col key={index}>
                  <Toptital data={info} index={index} />
                </Col>
              );
            })}
        </Row>
        {data && data?.chart_data && (
          <Row className="mt-3">
            <Col md={4}>
              {data?.chart_data?.donut_chart && (
                <DonutChart data={data.chart_data.donut_chart} />
              )}
            </Col>
            <Col md={8}>
              {data?.chart_data?.sale_history && (
                <LineChart data={data.chart_data.sale_history} />
              )}
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          {data && data?.chart_data && <ChartBar data={data.chart_data} />}
        </Row>

        {data && data?.table_data?.buy_data && (
          <Row className="mt-3">
            <TableData
              data={data?.table_data?.buy_data}
              tableHead={`Recently Purchased ${NAME.certificate}(s)`}
            />
          </Row>
        )}
        {data && data?.table_data?.resale_data && (
          <Row className="mt-3">
            <TableData
              data={data?.table_data?.resale_data}
              tableHead={`Recently Re-Sold ${NAME.certificate}(s)`}
            />
          </Row>
        )}
        {data && data?.table_data?.burn_data && (
          <Row className="mt-3">
            <TableData
              data={data?.table_data?.burn_data}
              tableHead={`Recently Burnt ${NAME.certificate}(s)`}
            />
          </Row>
        )}
      </Row>
    </>
  );
};
export default PolluterDashboard;
