import React, { useState, useEffect } from "react";
import { useLoader } from "../../context/Loader/useLoader";
import { useToast } from "../../context/Toast/useToast";
import NoRecordsFound from "../NoRecordFound";
import styles from "./order.module.scss";
import moment from "moment";
import Pagination from "../../common/pagination";
import { Col, Row } from "react-bootstrap";
import { getMyOrder } from "../../action/Api.action";
import { NAME } from "../../constant/constant";

const MyOrders = () => {
  const itemsPerPage = process.env.REACT_APP_ITEMPERPAGE;
  const [data, setData] = useState();
  const [viewMore, setViewMore] = useState(false);
  const [cardOpen, setCardOpen] = useState();
  const [width, setWidth] = useState();
  const { addToast } = useToast();
  const { setLoading } = useLoader();
  const [currentPage, setCurrentPage] = useState(1);
  const [isReformatCertificates, setReformatCertificates] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);

  const fetchMyOrder = async () => {
    const res = await getMyOrder();
    if (res.status) {
      setData(res);
    }
  };
  useEffect(() => {
    fetchMyOrder();
  }, []);

  const handleViewMore = (i) => {
    let checkCardOpen = cardOpen ? cardOpen : i;
    setViewMore(i === checkCardOpen ? !viewMore : viewMore);
    setCardOpen(i);
    let certificates = [];
    if (data?.data[i]?.order_detail) {
      data?.data[i]?.order_detail.forEach((info) => {
        certificates.push(info);
      });
    } else {
      data?.data[i]?.order_detail.forEach((info) => {
        certificates.push(info);
      });
    }
    let reformatCertificates = [];
    for (let j = 0; j < itemsPerPage; j++) {
      reformatCertificates.push(certificates[j]);
    }
    setReformatCertificates(reformatCertificates);
    setAllCertificates(certificates);
  };
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  const handleDocPagination = (page) => {
    if (page) {
      let startPage = page * itemsPerPage;
      let endPage = page + 1 * itemsPerPage;
      let nextPrevData = [];
      allCertificates.forEach((orderDetail, index) => {
        if (index + 1 >= startPage && index + 1 < endPage) {
          nextPrevData.push(orderDetail);
        }
      });
      setReformatCertificates(nextPrevData);
      setCurrentPage(page);
    }
  };
  const handlePagination = (page) => {
    if (page) {
      window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  /*useEffect(() => {
    if (currentPage) {
      fetchCtnftList();
    }
  }, [currentPage]);*/

  return (
    <>
      <div>
        <div className={styles.orderSection}>
          <div className="container-wrapper">
            <div
              className={`d-flex justify-content-between ${styles.pageTitle}`}>
              <h1>My Orders</h1>
            </div>
            {data && data.total_order_count > 0 ? (
              <div className={styles.orderSection}>
                <div className={`${styles.list}`}>
                  <div className={`${styles.listItems}`}>
                    <div className={styles.orderListBox}>
                      <div>
                        <div className={`${styles.listBoxBody}`}>
                          <div className={`${styles.listAlignment}`}>
                            <div>
                              <p>Order# </p>
                            </div>
                            <div>
                              <p>Order Date </p>
                            </div>

                            <div>
                              <p>Total Quantity</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* {orderLoading || pageBondLoading ? (
            <CustomLoader />
          ) : ( */}
            <div className={`${styles.list}`}>
              {data && data.total_order_count > 0 ? (
                data?.data?.map((res, i) => {
                  return (
                    <div key={i} className={`${styles.listItems}`}>
                      <div className={styles.orderListBox}>
                        <div>
                          <div className={`${styles.listBoxBody}`}>
                            <div className={`${styles.listAlignment}`}>
                              <div>
                                <span>{res.order_ref}</span>
                              </div>
                              <div>
                                <span>
                                  {moment(res.order_date).format("MMM-D-yy")}
                                </span>
                              </div>
                              <div>
                                <span>{res.total_qty}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={"d-grid"}>
                          <div className={`${styles.listBoxFooter}`}>
                            <div className={styles.ExplorerButton}>
                              <a
                                href={res.explorer_link}
                                target="_blank"
                                rel="noreferrer">
                                View in Explorer
                              </a>

                              {viewMore && width <= 768 && cardOpen === i ? (
                                <></>
                              ) : (
                                <button
                                  className="button-green"
                                  onClick={() => handleViewMore(i)}>
                                  {viewMore && cardOpen === i
                                    ? "View Less"
                                    : "View More"}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {viewMore && cardOpen === i ? (
                        <div className={styles.viewMoreContainer}>
                          {isReformatCertificates?.map((resp, index) => {
                            return (
                              <div className={styles.orderDetails} key={index}>
                                <Row className={styles.orderBondDetail}>
                                  <Col>
                                    <h4>TOTAL Fees</h4>
                                    <p>{resp?.price_unit}</p>
                                  </Col>
                                  <Col>
                                    <h4>Platform Fee RATE</h4>
                                    <p>{resp?.platform_commission_rate} %</p>
                                  </Col>
                                  <Col>
                                    <h4>Platform Fees</h4>
                                    <p>{resp?.platform_payable}</p>
                                  </Col>
                                </Row>
                                <Row className={styles.orderBondDetail}>
                                  <Col>
                                    <h4>ACCU Certificate</h4>
                                    <p>{resp?.certificate_accu}</p>
                                  </Col>
                                  <Col>
                                    <h4>{NAME.supplier}</h4>
                                    <p>{resp?.producer_id} </p>
                                  </Col>
                                  <Col>
                                    <h4>Currency</h4>
                                    <p>{resp?.currency_id}</p>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                          <Pagination
                            itemsCount={res?.order_detail?.length || 0}
                            itemsPerPage={itemsPerPage}
                            currentPage={currentPage}
                            setCurrentPage={handleDocPagination}
                            alwaysShown={false}
                          />
                          {viewMore && width <= 768 ? (
                            <div className={"d-grid"}>
                              <div className={`${styles.listBoxFooter}`}>
                                <button onClick={() => handleViewMore(i)}>
                                  {viewMore ? "View Less" : "View More"}
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  );
                })
              ) : (
                <NoRecordsFound />
              )}
            </div>
            {/* )} */}
            {data && data?.total_order_count > 0 ? (
              <Pagination
                itemsCount={data?.total_order_count || 0}
                itemsPerPage={data?.order_count || 9}
                currentPage={currentPage}
                setCurrentPage={() => handlePagination()}
                alwaysShown={false}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default MyOrders;
