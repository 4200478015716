import React,{useEffect} from 'react';
import { Container, Row, Col, Carousel, Image, Form, Button, Card } from 'react-bootstrap';

const Placebid = ({ history }) => {


	/* Api Calling */
	useEffect(() => {},[]);

	return (
		<Container>
			
			<div className="PlacebidSec burger">
				<Row>
					<Col md={6}>
						<div className="bidInfo">
							<h2>100 Tons Carbon Credits</h2>
							<div className="personInfo">
								<img src="assets/img/viewerImg.png" />
								<h6>John Deo <br /><small>ABC Australia Inc</small></h6>
								<p>Total Bids: 119</p>
							</div>

							<div className="assetsIdInfo">
								<span className="includes">includes</span>
								<div className="assetsId">
									<h6>Assets ID: CTNFT964637</h6>
									<div>
										<a className="like" href="javascript:void(0)"><img src="assets/img/heart-clrd.png" /></a>
										<a className="share ms-3" href="javascript:void(0)"><img src="assets/img/share-clrd.png" /></a>
									</div>
								</div>
							</div>

							<div className="currentBid boxShaddow">
								<p className="currentBidText">Current Bid</p>
								<h1>10043.00 PHAe</h1>
								<p className="aud">AUD $ 11,000.00</p>
								<div className="timer">
									<ul>
										<li>03</li>
										<li>D</li>
									</ul>
									<ul>
										<li>23</li>
										<li>H</li>
									</ul>
									<ul>
										<li>59</li>
										<li>M</li>
									</ul>
									<ul>
										<li>09</li>
										<li>S</li>
									</ul>
								</div>

								<div className="bidAmount">
									<h5>Amount <span>PHAe</span></h5>
									<ul>
										<li>Must br atleast 10050.00 PHAe</li>
										<li>Your Biding Balance <span>0 PHAe</span></li>
										<li>Your Balance <span>0 PHAe</span></li>
										<li>Service Fee <span>150 PHAe</span></li>
										<li>Total Bid Amount <span>10200.00 PHAe</span></li>
									</ul>

									<div className="placeBidBtn">
										<Button variant="" className="primaryBtn">Place Bid</Button>
									</div>
								</div>
							</div>
						</div>
					</Col>

					<Col md={6} lg={{ span: 5, offset: 1 }}>
						<p className="ltstBidHdng">Latest Bids</p>
						<div className="latestBids">
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
							<div className="bider">
								<img src="assets/img/viewerImg.png" />
								<div className="biderInfo">
									<h6>10043.00 PHAe <span>at 09.00 am 5 July 2021</span></h6>
									<h6>John Deo @ <span>ABC Australia Inc.</span></h6>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default Placebid;
