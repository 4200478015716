import AdminLayout from "../../Layout/AdminLayout";
import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";

import SideBar from "../component/SideBar";

const AddNewProducers = ({ history }) => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  /* Api Calling */
  useEffect(() => {}, []);

  return (
    <>
      <Row className="mb-4">
        <Col xs={12}>
          <h3 className="heading">Company Information</h3>
          <p>Enter your legal entity details here</p>
        </Col>
      </Row>

      <Row className="border-bottom mb-4 pb-4">
        <Col xs={12}>
          <h4>Company Details</h4>
        </Col>

        <Col lg={6}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicOrgName">
              <Form.Control type="text" placeholder="Organization Name" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={6}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicOrgRegNumber">
              <Form.Control
                type="text"
                placeholder="Organization Registration Number"
              />
            </Form.Group>
          </Form>
        </Col>

        <Col lg={3}>
          <Form className="formContent">
            <Form.Group
              className="selectBox mb-3"
              controlId="formBasicCrediterType">
              <Select options={options} />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicTaxNumber">
              <Form.Control type="number" placeholder="Taxation Number" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group
              className="selectBox mb-3"
              controlId="formBasicCompanyType">
              <Select options={options} />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row className="border-bottom mb-4 pb-4">
        <Col xs={12}>
          <h4>Business Address</h4>
        </Col>

        <Col lg={6}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicAddress">
              <Form.Control type="text" placeholder="Address" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicZipCode">
              <Form.Control type="text" placeholder="Zip Code" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="selectBox mb-3" controlId="formBasicCountry">
              <Select options={options} />
            </Form.Group>
          </Form>
        </Col>

        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="selectBox mb-3" controlId="formBasicState">
              <Select options={options} />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="selectBox mb-3" controlId="formBasicCity">
              <Select options={options} />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row className="border-bottom mb-4 pb-4">
        <Col xs={12}>
          <h4>Contact Person Details</h4>
        </Col>

        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicFullName">
              <Form.Control type="text" placeholder="Full Name" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicMobile">
              <Form.Control type="tel" placeholder="Mobile" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="mail" placeholder="Email Address" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group
              className="selectBox mb-3"
              controlId="formBasicDesignation">
              <Select options={options} />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row className="border-bottom mb-4 pb-4">
        <Col xs={12}>
          <h4>Banking Details</h4>
        </Col>

        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicAcName">
              <Form.Control type="text" placeholder="Account Name" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicAcNumber">
              <Form.Control type="number" placeholder="Account Number" />
            </Form.Group>
          </Form>
        </Col>

        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicBankBranch">
              <Form.Control type="text" placeholder="Bank Name & Branch" />
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3}>
          <Form className="formContent">
            <Form.Group className="mb-3" controlId="formBasicIFSCCode">
              <Form.Control type="text" placeholder="IFSC Code" />
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row>
        <h4>KYC Detail Information</h4>
        <Col xs={12}>
          <div className="documentType">
            <Form className="formContent">
              <Form.Group
                className="selectBox"
                controlId="formBasicCompanyType">
                <Select options={options} />
              </Form.Group>
            </Form>
            <Form.Group controlId="formFile" className="selectFileOption mt-2">
              <Form.Label>Upload Document</Form.Label>
              <span>
                <img src="../assets/img/mountain.png" />
              </span>
              <Form.Control type="file" />
            </Form.Group>
          </div>
          <div className="documentType">
            <Form className="formContent">
              <Form.Group
                className="selectBox"
                controlId="formBasicCompanyType">
                <Select options={options} />
              </Form.Group>
            </Form>
            <Form.Group controlId="formFile" className="selectFileOption mt-2">
              <Form.Label>Upload Document</Form.Label>
              <span>
                <img src="../assets/img/mountain.png" />
              </span>
              <Form.Control type="file" />
            </Form.Group>
          </div>
          <div className="documentType">
            <Form className="formContent">
              <Form.Group
                className="selectBox"
                controlId="formBasicCompanyType">
                <Select options={options} />
              </Form.Group>
            </Form>
            <Form.Group controlId="formFile" className="selectFileOption mt-2">
              <Form.Label>Upload Document</Form.Label>
              <span>
                <img src="../assets/img/mountain.png" />
              </span>
              <Form.Control type="file" />
            </Form.Group>
          </div>
        </Col>

        <Col xs={12} lg={8}>
          <div className="moreInfoBtns">
            <Button className="secondaryBtn addprdBtn" variant="" type="submit">
              Add New Producers
            </Button>
            <Button className="secondaryBtn cancelBtn" variant="" type="submit">
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AddNewProducers;
