import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import BondListItem from "../../common/BondListItem";
import Loader from "../../common/loader";
import NoRecordsFound from "../NoRecordFound";
import styles from "./style.module.scss";
const Resells = ({ data, fetchResell }) => {
  return (
    <>
      <div className={styles.ResellBondsSectionAlignment}>
        <div className={styles.pageTitle}>
          <h1>Resell Certificates</h1>
        </div>
        <div className={styles.allCardAlignmentForScreen}>
          {data === undefined ? (
            <Loader />
          ) : data && data?.resell_list?.length > 0 ? (
            <BondListItem
              bonddata={data?.resell_list}
              fetchResell={fetchResell}
            />
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </>
  );
};
export default Resells;
