import React from "react";
import styles from "./Loader.module.scss";

function Loader({ style }) {
  return (
    <div className={`d-flex justify-content-center`}>
      <div
        className={`spinner-border text-light ${styles.smallLoader}`}
        role="status"
        style={style}></div>
    </div>
  );
}

export default Loader;
