import React, { useEffect, useState } from 'react'
import WalletMenu from '../WalletMenu'
import { ButtonGroup, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { getUserBalance } from '../../../action/walletApiAction';
import { useToast } from "../../../context/Toast/useToast.ts";
import { Link } from "react-router-dom";
import { getNFTList } from '../../../action/Api.action';
import Pagination2 from '../../../common/pagination2/Pagination';
function Index(props) {
  const { addToast } = useToast();
  const [usdcBalance, setUsdcbalance] = useState(0);
  const [maticBalance, setMaticbalance] = useState(0);
  const [eventKey, setEventKey] = useState('USDC')
  const [IsLoading, setIsLoading] = useState(false)
  const [ctnftList, setCTNFTList] = useState([])
  const [timezone, setTimezone] = useState('');
  const [afterPagedData, setAfterPagedData] = useState([])
  const [itemPerPage, setItemPerPage] = useState(8)
  const [curr, setCurr] = useState({ value: '0.0000 USDC ', arrow: <i class="fa-solid fa-angle-down"></i> })
  const [sNo, setSNo] = useState(1)
  const { page } = props


  const getWalletBalance = async () => {
    let body = {}
    body.wallet_address = localStorage.getItem("wallet_address")
    const balanceRes = await getUserBalance(body)
    if (balanceRes.status === "Error") {
      addToast({
        message: balanceRes.message,
        type: "error",
      });
    }
    setUsdcbalance(balanceRes.data.balance.usdc_balance)
    setMaticbalance(balanceRes.data.balance.matic_balance)
  }
  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimezone(userTimezone);
  }, [])
  useEffect(() => {

    getWalletBalance()
    fetchCtnftList()
    const togglebtn = document.getElementById('dropdown-button-drop-end')
    togglebtn.classList.remove('btn-primary')
  }, [])


  const fetchCtnftList = async () => {
    setIsLoading(true)
    const status = "owned"
    let searchObj = {
      "user_id": localStorage.getItem('userId'),
      "nft_type": status,
      "wallet_address": localStorage.getItem('wallet_address')

    }
    const resp = await getNFTList(searchObj);
    if (resp?.status) {
      resp?.data &&
        resp.data.map((nft) => {
          if (nft.state == "resubmission") {
            addToast({
              message: "CNFT Documents Rejected, Please Resubmit Documents",
              type: "error",
              duration: 3000,
            });
            return;
          }
        });
      setCTNFTList(resp?.NFT_list);
    }
    setIsLoading(false)
  };



  const getDataAfterPagination=(arr, sno)=>{
    setAfterPagedData(arr)
    setSNo(sno)
  }
  return (
    <>
      <div className='walletMaineBox'>
        <WalletMenu pagename={page} />
        <div className='walletRanderBox'>
          <Row>
            <Col lg={4}>
              <div className='TotalBalanaceBox'>
                <h3>Total balance <i class="fa-solid fa-eye"></i></h3>
                <div className="dropdownwallet dashboarddrop">

                  <DropdownButton
                    as={ButtonGroup} className="btn"
                    key={'end'}
                    id={`dropdown-button-drop-end`}
                    drop={'end'}
                    varient={'link'}
                    title={`${eventKey === "Matic" ? Number(maticBalance).toFixed(5) : Number(usdcBalance).toFixed(5)} ${eventKey}`}
                  >

                    <Dropdown.Item eventKey="USDC" onClick={() => setEventKey("USDC")}>USDC</Dropdown.Item>
                    <Dropdown.Item eventKey="Matic" onClick={() => setEventKey("Matic")}>Matic</Dropdown.Item>

                  </DropdownButton>


                </div>
                {/* <h2>{Number(walletBalance).toFixed(5)} USDC</h2> */}
                <div className='btnBoxBalance'>
                  <Link to={"/wallet/withdrawal"}>
                    <div className='innerBox'>
                      <div className='imageBox'>
                        <img src="/images/svg/withdrawalIcon.svg" width={53} height={53} alt='' />
                      </div>
                      <h4>Withdraw</h4>
                    </div>
                  </Link>
                  <Link to={"/wallet/deposit"}>
                    <div className='innerBox'>
                      <div className='imageBox'>
                        <img src="/images/svg/depositIcon.svg" width={53} height={53} alt='' />
                      </div>
                      <h4>Deposit</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className='contentBoxWallet'>
                <h2>NFTs</h2>
                <div className='table-responsive walletTable '>
                  <table>
                    <thead>
                      <tr>
                        <td>Serial No.</td>
                        <td>Name</td>
                        <td>Price (USDC)</td>
                        <td>Txn. Hash</td>
                        <td>Date</td>

                      </tr>
                    </thead>
                    <tbody>
                      {afterPagedData.length > 0 && afterPagedData.map((item, i) =>
                        <tr>
                          <td>{sNo + i}</td>
                          <td>{item?.project_title}</td>
                          <td>{item?.price.toFixed(5)}</td>
                          <td><div className='addresswa'>{item?.transaction_hash.slice(0, 5) + "..." + item?.transaction_hash.slice(item?.transaction_hash.length - 5, item?.transaction_hash.length - 1)} </div></td>
                          <td>{new Date(item?.date)?.toLocaleString('en-US', { timeZone: timezone })}</td>
                          {/* <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td> */}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

              </div>
            </Col>
            {/* {afterPagedData.length > 0 &&
              <Col className='text-end' lg={12}>
                <Pagination className='justify-content-end mt-3'>
                  {(currentPage > 1 && afterPagedData.length > 1) && <>
                    <Pagination.First onClick={() => handlePage(1)} />
                    <Pagination.Prev onClick={() => handlePage(currentPage - 1)} />
                  </>}
                  {totalCountArr.map((item) =>
                    <Pagination.Item active={item === currentPage ? true : false} onClick={() => handlePage(item)}>{item}</Pagination.Item>
                  )}
                  {(currentPage < Math.ceil((ctnftList.length) / itemPerPage) && afterPagedData.length > 1) && <>
                    <Pagination.Next onClick={() => handlePage(currentPage + 1)} />
                    <Pagination.Last onClick={() => handlePage(Math.ceil((ctnftList.length) / itemPerPage))} />
                  </>}
                </Pagination>
              </Col>
            } */}
            <Pagination2 data={ctnftList} getPagedData={getDataAfterPagination} itemPerPage={itemPerPage} />
          </Row>
        </div>
      </div>
    </>
  )
}

export default Index