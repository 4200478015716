import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { getFooterData } from "../action/Api.action";
import BASE_URL from "../utils/appUrls";
import { Link } from "react-router-dom";
const Footer = () => {
  const [column2, setColumn2] = useState([]);
  const [column3, setColumn3] = useState([]);
  const [footerLogo, setFooterLogo] = useState("");
  const [copy_right, setCopyright] = useState("");
  const [socialMedia, setSocialMedia] = useState("");

  const fetchFooterData = async () => {
    const result = await getFooterData();
    if (result && result.success_msg === "Successfully") {
      setColumn2(result.column2);
      setColumn3(result.column3);
      setCopyright(result.copyright);
      setFooterLogo(result.footer_logo);
      setSocialMedia(result.social_media_dict);
    }
  };
  useEffect(() => {
    fetchFooterData();
  }, []);
  let footer_logo;
  if (footerLogo) {
    footer_logo = BASE_URL.HOST + footerLogo;
  } else {
    footer_logo = "../assets/img/carbon_exchnage.png";
  }

  return (
    <div className="footerSec burger">
      <div className="container">
        <Row>
          <Col sm={6} xl={2}>
            <div className="bravvox footHead">
              <img
                src="/assets/img/phaeton_full_white_logo.png"
                alt="logo"
                width="170"
              />
            </div>

            {/* <ul className="socialLinks">
              {socialMedia.social_instagram && (
                <li>
                  <a href={socialMedia.social_instagram} target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              )}
              {socialMedia.social_linkedin && (
                <li>
                  <a href={socialMedia.social_linkedin} target="_blank">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              )}
              {socialMedia.social_facebook && (
                <li>
                  <a href={socialMedia.social_facebook} target="_blank">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
              )}
              {socialMedia.social_twitter && (
                <li>
                  <a href={socialMedia.social_twitter} target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
              )}
            </ul> */}
          </Col>
          <Col xl={8} sm={6}>
            <p className="at-magnum-periculum">
              Access the Carbon Credit Markets from all over the world with PhaetonCCX today.
            </p>
          </Col>

          {/* <Col sm={6} xl={3}>
            <div className="footHead">
              <h6>Contact Info</h6>
            </div>
            <div className="contactInfo">
              <div className="address">
                <p>
                  <strong>Address : </strong> Patricia C. Amedee 4401 Waldeck
                  Street Grapevine NSW, 6051
                </p>
              </div>
              <div className="contact">
                <p>
                  <strong>Phone : </strong> +61 (0) 101 0000 888
                </p>
                <p>
                  <strong>Email : </strong> Info@carboncredit.com.au
                </p>
              </div>
            </div>
          </Col> */}

          <Col sm={6} xl={2}>
            {/* <div className="footHead">
              <h6>Quick Links</h6>
            </div> */}
            <div className="quickLinks d-flex">
              <ul>
                {column2.map((data, col2) => (
                  <Link to={`/${data.page_type_name}`} key={col2}>
                    <li>{data.display_name}</li>
                  </Link>
                ))}
                {column3.map((data, col3) => (
                  <>
                    {data.page_type_name === "privacy" ? (
                      <Link to={`/page/${data.page_type_name}`} key={col3}>
                        <li>{data.display_name}</li>
                      </Link>
                    ) : (
                      <Link to={`/${data.page_type_name}`} key={col3}>
                        <li>{data.display_name}</li>
                      </Link>
                    )}
                  </>
                ))}
              </ul>
            </div>
          </Col>

          {/* <Col sm={6} xl={3}>
            <div className="footHead">
              <h6>Newsletter</h6>
            </div>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Email Address" />
              </Form.Group>
              <Button className="primaryBtn" type="submit">
                Subscribe
              </Button>
            </Form>
          </Col> */}
        </Row>

        <div className="footer-section">
          <div className="copyrightText border-top">
            {copy_right ? (
              <>{copy_right}</>
            ) : (
              <>
                © Carbon Credit Exchange {new Date().getFullYear()}. All rights
                reserved.
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
