import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Navbar, Form, Button } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { signup } from '../../action/Api.action';

import SideBar from '../component/SideBar';
import AdminLayout from '../../Layout/AdminLayout';


const ChangePassword = ({ history }) => {

	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});
	const [isUpdate, setUPdate] = useState(false);
	const [isDisabled, setDisabled] = useState(false);

	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});


	const handleSubmit = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();

			setValidated(true);
		} else {
			event.preventDefault();
			event.stopPropagation();
			setDisabled(true);
			//setValidated(true);
			let response = await signup(formData);

			if (response.code === 200) {

				setState({ open: true, vertical: 'top', horizontal: 'center' });
				setFormData({});
				setTimeout(() => {
					history.push({ pathname: "/verification", state: response.data });
				}, 2000)


			} else if (response && response.errors) {

				setDisabled(false);
				let errorsList = response.errors;
				let err = {};
				for (let i in errorsList) {
					err[errorsList[i].param] = errorsList[i].msg;
				}

				setErrors(err);

			}
		}

	};

	const formHandler = (e, field) => {
		let data = formData;
		let err = errors;
		if (field === 'phone') {
			data[field] = e;
		} else {
			data[field] = e.target.value;
		}

		if (err[field]) {
			delete err[field];
		}
		setErrors(err);
		setFormData(data);
		setUPdate(!isUpdate);

	}


	useEffect(() => {
	}, []);


	return (

		<AdminLayout>
			<Row className="align-items-center">
				<Col xs={20} sm={{ span: 20, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>

					<Form className="formContent" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
						<div className="heading">
							<h3>Change Password</h3>
						</div>

						<div className="mb-4 form-group">
							<Form.Group className="mb-3" controlId="formBasicPassword">
								<Form.Control type="text" defaultValue={formData.oldPassword} placeholder="Old Password" minLength="6" onChange={e => formHandler(e, 'oldPassword')} maxLength="32" autoComplete="old-password" required />
							</Form.Group>
						</div>

						<div className="mb-4">
							<Form.Group className="mb-3" controlId="formBasicPassword">
								<Form.Control text="password" defaultValue={formData.newPassword} placeholder="New Password" minLength="6" onChange={e => formHandler(e, 'newPassword')} maxLength="32" autoComplete="new-password" required />
							</Form.Group>
						</div>

						<div className="mb-4">
							<Form.Group className="mb-3" controlId="formBasicPassword">
								<Form.Control text="password" defaultValue={formData.confirmPassword} placeholder="Confirm Password" minLength="6" onChange={e => formHandler(e, 'confirmPassword')} maxLength="32" autoComplete="confirm-password" required />
							</Form.Group>
						</div>


						<div className="loginFrgtPwdBtns">
							<Button className="secondaryBtn" variant="" type="submit" disabled={isDisabled}>Change Password</Button>
						</div>
					</Form>

				</Col>
			</Row>
		</AdminLayout>

	);
};

export default ChangePassword;
