import React from "react";
import styles from "../component/NoRecordFound/styles.module.scss";
const ComingSoon= ({ removeSpacing }) => {
  return (
    <div className={!removeSpacing ? styles.NoBondsFound : styles.noMarginBond}>
      <div className={styles.icon}>
        <i className="fa-solid fa-triangle-exclamation"></i>
      </div>
      <div className={styles.text}>Coming Soon!</div>
    </div>
  );
};

export default ComingSoon;
