import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  certificateReqDocument,
  UploadCTNFDocument,
  submitApproval,
  createCtnftSignature,
} from "../../../action/Api.action";
import CustomLoader from "../../../common/customLoader";
import { useLoader } from "../../../context/Loader/useLoader";
import { useToast } from "../../../context/Toast/useToast";
import Url from "../../../utils/appUrls";
import { StorageHelper } from "../../../utils/StorageHelper";
import { createCTNFT, walletConnectSessionTimeout } from "../../walletConnect";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import { setPrevious } from "../../../slices/home";
import { NAME } from "../../../constant/constant";
import { setLoginCheck, setProfile } from "../../../slices/auth";
const AddDocument = ({ changeStep, editDapp }) => {
  const { setLoading } = useLoader();
  const dappData = useSelector((state) => state?.home?.dapp);
  const resubmit = useSelector((state) => state?.home?.resubmission);
  const history = useHistory();
  const userDetail = StorageHelper.getUserInfo();
  const [UserId, AccessToken] = [
    parseInt(userDetail.user_id),
    userDetail.access_token,
  ];
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const { addToast } = useToast();
  const [IsLoading, setIsLoading] = useState(false);
  const getInfo = async () => {
    const res = await certificateReqDocument(dappData);
    setIsLoading(true);
    console.log("Ashmin Test for document fetch",res)
    if (res?.certificates_ids.length > 0) {
      setData(res.certificates_ids);
    }
    setIsLoading(false);
  };
  useEffect(async () => {
    getInfo();
  }, []);

  const handleUpload = async (e, info) => {
    if (
      e.target.files !== null &&
      e.target.files.length > 0 &&
      e.target.files[0] !== null
    ) {
      const doc_file = e.target.files[0];
      let validedSize = 20971520;
      const allowedType = [
        "image/jpg",
        "image/png",
        "application/pdf",
        "image/jpeg",
    
      ];
      if (!allowedType.includes(doc_file?.type)) {
        addToast({
          message:
            "You can only upload these formats jpg/png/pdf/jpeg",
          type: "error",
          duration: 3000,
        });
        return false;
      }

      if (doc_file?.size < validedSize) {
      } else {
        addToast({
          message: "Please Choose a file that is less than 20 MB",
          type: "error",
          duration: 3000,
        });
        return false;
      }
      setLoading(true, "Uploading File to the Server");
      await UploadCTNFDocument({
        document_id: info?.document_id,
        doc_file: doc_file,
        user_id: UserId,
        auth_access_token: AccessToken,
        file_name: info?.doc_name,
      })
        .then((res) => {
          setLoading(false);
          if (res.status) {
            addToast({
              message: "Document uploaded successfully.",
              type: "success",
            });
            getInfo();
            // fetchIssuerDocuments();
          } else {
            addToast({
              message: res?.message,
              type: "error",
            });
          }
        })
        .catch((err) => {
          addToast({
            message: "Document failed to upload.",
            type: "error",
          });
        });
    }
  };

  const handlePrevious = () => {
    dispatch(setPrevious(true));
    changeStep(0);
  };
  const handleSubmitApproval2 = async () => {
    changeStep(2);
  }
  const handleSubmitApproval = async () => {
    try {
      let check;
      data.map((info) => {
        info.docs.forEach((element) => {
          if (element.document_url == "") {
            check = true;
          }
        });
      });
      if (check) {
        addToast({ 
          message: "Please Upload all the documents",
           type: "error" });
        return null;
      }
      // if (editDapp) {
      //   addToast({
      //     message: "Your Certificate has been Updated",
      //     type: "success",
      //   });
      //   history.push("/pendingCNFT");
      //   return;
      // }
        
      
      
      const payloadApproval = {
        dapp_id: dappData,
        wallet_address : localStorage.getItem('wallet_address')
      };
      const res = await submitApproval(payloadApproval);
      if (res?.status) {
        console.log(res)
       /*
        setLoading(true, "Accept Transaction on your mobile");
        const timeoutRef = setTimeout(async () => {
          setLoading(false);
          const result = await walletConnectSessionTimeout();
          dispatch(setProfile({}));
          dispatch(setLoginCheck(false));
          history.push(`/`);
          addToast({
            message:
              "Intermittent connection, request time out! Please re-connect the wallet",
            type: "error",
            duration: 3000,
          });
        }, 60 * 1000);
        //setLoading(false);
          */


        console.log(dappData);





        /**
        
        // const meta_data = {
        //   erfRegistrationNumber: String(res?.erfRegistrationNumber),
        //   sellerPublicKey: window.phaeton.hexToBuffer(
        //     res?.sellerPublicKey,
        //     "hex"
        //   ),
        //   numberOfCertificate: String(res?.numberOfCertificate),
        //   certificates: res?.certificates.map((line) => {
        //     return {
        //       accu: String(line.accu),
        //       documents: line?.documents.map((document) => {
        //         return {
        //           name: String(document?.name),
        //           type: String(document?.type),
        //           hash: window.phaeton.hexToBuffer(document?.hash, "hex"),
        //           uri: String(document?.uri),
        //           uploadedBy: window.phaeton.hexToBuffer(
        //             document?.uploadedBy,
        //             "hex"
        //           ),
        //         };
        //       }),
        //     };
        //   }),
        //   // producerSignature: window.phaeton.hexToBuffer(
        //   //   res?.producerSignature,
        //   //   "hex"
        //   // ),
        //   //platformSignature: window.phaeton.hexToBuffer(res?.platformSignature, "hex"),
        // };

         */





        const meta_data = res
        console.log(meta_data)
      
        
          /*
        const signature = await createCTNFT(meta_data);
        // const res = await createCtnftSignature()
        
        clearTimeout(timeoutRef);
        */
          //new Variable bypass  - > signature 

          const signature = 'Test Signature for user'
          console.log("New Test")
        if (signature && signature.status === false ) {
          setLoading(false);
          console.log("Error 1 DETECT")
          addToast({
            message: signature.message,
            type: "error",
            duration: 3000,
          });
        } else {
          if (signature) {
            console.log("Error 2 DETECT")
            const payload = {
              dapp_id: dappData,
              signature: signature,
              meta_data: meta_data,
              wallet_address : localStorage.getItem('wallet_address')
            };

            setIsLoading(true)
            const result = await createCtnftSignature(payload);
            console.log(result)
            setIsLoading(false)
            if (result) {
              console.log("Error 3 DETECT")
          
              setLoading(false);

              addToast({
                message: `CTNFT successfully submitted for approval`,
                type: "success",
                duration: 3000,
              });





              changeStep(2);










              //history.push(`/pendingCNFT`);
              /*setProjectMessage(successMsg);
              setModalOpen(true);
              setLoading(false);*/
            } else {
              console.log("Test block3")
              addToast({
                message: result.message,
                type: "error",
                duration: 3000,
              });
              setLoading(false);
            }
          }
        }
      } else {
        setLoading(false);
        console.log("Test block1")
        addToast({
          message: res.error_msg || res.message,
          type: "error",
          duration: 3000,
        });
      }
    } catch (error) {
      console.log("Test block")
      addToast({
        message: error.message,
        type: "error",
        duration: 3000,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {IsLoading && (
        <div>
          <CustomLoader />
        </div>
      )}
      <Row>
        <h4 className="my-4">Upload Certificates</h4>
        <Col   xs={12}>
        <div  className="moreInfoSec formContent boxShaddow">
          {data &&
            data.length > 0 &&
            data
              .sort((a, b) => a.id - b.id)
              .map((item, index) => {
                return (
                  <div className="documentType certificatessss mb-3" key={index}>
                    <Row>
                    
                        <Col lg={4}>
                          <span>Asset ID :</span>
                          <Form className="formContent">
                            <Form.Group controlId="formBasicCompanyType">
                              <Form.Control
                                type="text"
                                placeholder="Enter ACCU certificate Number"
                                defaultValue={item.accu}
                                name="accu"
                                required
                                autoComplete="off"
                                disabled={true}
                              />
                            </Form.Group>
                          </Form>
                        </Col>
                        <Col lg={4}>
                          <span>Sale Price :</span>
                          <Form className="formContent">
                            <Form.Group controlId="formBasicCompanyType">
                              <Form.Control
                                type="number"
                                placeholder="Enter Sale Price"
                                defaultValue={item.sale_price}
                                name="sale_price"
                                required
                                disabled={true}
                                autoComplete="off"
                              />
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                   
                     <Row className="mt-4" >
                        {item &&
                          item.docs.length > 0 &&
                          item.docs.map((info, index) => {
                           // console.log("INFO", info);
                            return (
                              <Col
                                md={8}
                                key={index}
                                className={styles.uploadedFile}>
                                <Row>
                                  <Col md={6}>
                                    <Form.Group
                                      controlId="formFile"
                                      className="selectFileOption mt-2  d-block ">
                                      <Form.Label>{info?.doc_name}</Form.Label>
                                      {resubmit &&
                                        info.status == "resubmission" && (
                                          <div className={styles.resubmission}>
                                            * Resubmit Document
                                          </div>
                                        )}
                                        <div>
                                    {/* {info?.document_url && (
                                    <img src={Url.HOST + info.document_url} />
                                  )} */}
                                    {info &&
                                    info.file_type.includes("image") ? (
                                      <img src={Url.HOST + info.document_url} />
                                    ) : null}
                                    {info && info.file_type.includes("pdf") ? (
                                      <embed
                                        src={`${Url.HOST + info.document_url}`}
                                        type="application/pdf"
                                        height="240"
                                        width="100%"></embed>
                                    ) : null}
                                  </div>
                                      {/* <span>
                                    <img src="assets/img/mountain.png" />
                                  </span>
                                  <Form.Control
                                    type="file"
                                    accept="image/*"
                                    // hidden
                                    // onChange={(e) =>
                                    //   formHandler(e, "image", index)
                                    // }
                                    onChange={(e) => handleUpload(e, info)}
                                    required
                                  /> */}
                                    </Form.Group>
                                  </Col>
                                  <Col className="my-auto" style={{position :  "relative"}} md={6}>
                                  <div className="selectImage position-relative">
                        
                          <label className="uploadassets"
                                      for="imageUpload"
                                     >
                                      <img src={"/images/svg/UploadImage.svg"} alt="upload" />
                            <h3>Upload from computer</h3>
                                      <input
                                        type="file"
                                        className={styles.UploadFileButton}
                                        onChange={(e) => handleUpload(e, info)}
                                      />
                                    </label>
                        </div>
                                   
                                  </Col>
                                </Row>
                               
                              </Col>
                            );
                          })}
                      </Row>
                  
                     
                 
                  </div>
                );
              })}
              <Row>
              <Col className="ms-auto" lg={2}>
              
              {!resubmit && (
                <Button
                  className="secondaryBtn-outline w-100 submitBtn"
                  // disabled={isDisabled}
                  onClick={handlePrevious}>
                  Previous
                </Button>
              )}
             
          
                  </Col>
                  <Col  lg={2}>
              <Button
              className="secondaryBtn submitBtn"
              variant=""
              //type="submit"
              onClick={handleSubmitApproval}
              // onClick={handleSubmitApproval2}

              // disabled={isDisabled}
            >
              Submit
            </Button>
              </Col>
              </Row>
            </div>
           
        </Col>
        <Col hidden xs={12}>
          <Form  className="moreInfoSec formContent boxShaddow">
          {data &&
            data.length > 0 &&
            data
              .sort((a, b) => a.id - b.id)
              .map((item, index) => {
                return (
              <div className="documentType certificatessss mb-3" key={index}>
              <Row>
                <Col className="mb-3" md={3} xs={3}>
                    <span>ACCU :</span>
                    <Form className="formContent">
                      <Form.Group controlId="formBasicCompanyType">
                        <Form.Control
                          type="text"
                          placeholder="Enter ACCU certificate Number"
                          defaultValue={item.accu}
                          name="accu"
                          required
                          autoComplete="off"
                          disabled={true}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col className="mb-3" md={3} xs={3}>
                    <span>Price :</span>
                    <Form className="formContent">
                      <Form.Group controlId="formBasicCompanyType">
                        <Form.Control
                          type="number"
                          placeholder="Enter Sale Price"
                          defaultValue={item.sale_price}
                          name="sale_price"
                          required
                          disabled={true}
                          autoComplete="off"
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col  lg={12}>
                  <div className="uploadedimageBox">
                  <Row>
                  <Col  lg={2}>
                    <div className="selectedImg">
                      <div className="ImageBox">
                        <img src="/images/png/uplodedImage.png" alt="" />
                        <div className="ovrelay">
                          <button className="closeBtnimg" type="button">
                            <img src="/images/svg/CloseWindow.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <h3>Banner239303bdcfgg.jpeg</h3>
                    </div>
                  </Col>
                  <Col  lg={2}>
                    <div className="selectedImg">
                      <div className="ImageBox">
                        <img src="/images/png/uplodedImage.png" alt="" />
                        <div className="ovrelay">
                          <button className="closeBtnimg" type="button">
                            <img src="/images/svg/CloseWindow.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <h3>Banner239303bdcfgg.jpeg</h3>
                    </div>
                  </Col>
                  <Col  lg={2}>
                    <div className="selectedImg">
                      <div className="ImageBox">
                        <img src="/images/png/uplodedImage.png" alt="" />
                        <div className="ovrelay">
                          <button className="closeBtnimg" type="button">
                            <img src="/images/svg/CloseWindow.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <h3>Banner239303bdcfgg.jpeg</h3>
                    </div>
                  </Col>
                  <Col  lg={2}>
                    <div className="selectedImg">
                      <div className="ImageBox">
                        <img src="/images/png/uplodedImage.png" alt="" />
                        <div className="ovrelay">
                          <button className="closeBtnimg" type="button">
                            <img src="/images/svg/CloseWindow.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <h3>Banner239303bdcfgg.jpeg</h3>
                    </div>
                  </Col>
                  <Col  lg={2}>
                    <div className="selectedImg">
                      <div className="ImageBox">
                        <img src="/images/png/uplodedImage.png" alt="" />
                        <div className="ovrelay">
                          <button className="closeBtnimg" type="button">
                            <img src="/images/svg/CloseWindow.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <h3>Banner239303bdcfgg.jpeg</h3>
                    </div>
                  </Col>
                  <Col  lg={2}>
                    <div className="selectedImg">
                      <div className="ImageBox">
                        <img src="/images/png/uplodedImage.png" alt="" />
                        <div className="ovrelay">
                          <button className="closeBtnimg" type="button">
                            <img src="/images/svg/CloseWindow.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <h3>Banner239303bdcfgg.jpeg</h3>
                    </div>
                  </Col>
                  <Col  lg={2}>
                    <div className="selectedImg">
                      <div className="ImageBox">
                        <img src="/images/png/uplodedImage.png" alt="" />
                        <div className="ovrelay">
                          <button className="closeBtnimg" type="button">
                            <img src="/images/svg/CloseWindow.svg" alt="" />
                          </button>
                        </div>
                      </div>
                      <h3>Banner239303bdcfgg.jpeg</h3>
                    </div>
                  </Col>
                  </Row>
                  </div>
                
                </Col>
                <Col lg={12}>
                <div className="selectImage">
                    <input className="d-none" type="file"  id="uploadassets"></input>
                    <label className="uploadassets" htmlFor="uploadassets">
                      <img src={"/images/svg/UploadImage.svg"} alt="upload" />
                      <h3>Upload from computer</h3>
                    </label>
                  </div>
                </Col>
           
                   
                      </Row>
              </div>
                )})}
              <Row>
              <Col className="ms-auto" lg={2}>
              
            {!resubmit && (
              <Button
                className="secondaryBtn-outline w-100 submitBtn"
                // disabled={isDisabled}
                onClick={handlePrevious}>
                Previous
              </Button>
            )}
           
        
                </Col>
              <Col  lg={2}>
              <Button
              className="secondaryBtn submitBtn"
              variant=""
              //type="submit"
              onClick={handleSubmitApproval}
              // onClick={handleSubmitApproval2}

              // disabled={isDisabled}
            >
              Submit
            </Button>
              </Col>
              </Row>
             
          </Form>
        </Col>
      
      
      </Row>
    </>
  );
};
export default AddDocument;
