import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginCheck: false,
    profileData: {},
  },
  reducers: {
    setLoginCheck: (state, action) => {
      state.loginCheck = action.payload;
    },
    setProfile: (state, action) => {
      state.profileData = action.payload;
    },
  },
});

export const { setLoginCheck, setProfile } = authSlice.actions;
export default authSlice.reducer;
