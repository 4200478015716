import { MutableRefObject, useEffect, useState } from "react";

function useModalClose(ref, handleClose) {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleClose();
    }
  };
  const handleEscapeButton = (event) => {
    if (event.keyCode === 27) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", handleEscapeButton, false);
    return () => {
      document.removeEventListener("keydown", handleEscapeButton, false);
    };
  }, []);
}

export default useModalClose;
