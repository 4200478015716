import { Container,Row,Col,Button,Form} from 'react-bootstrap';
import { Label } from '@blueprintjs/core';

const Whoweserve = ({ data }) => {

    const images = ["/images/png/RegisterHow.png","/images/png/VerificationHow.png"]
    return (
        <>
        <div className="container-fluid  whoweserve pt-5" >
            
               <div className='pt-5'   >
              <Row>
              <div className=' aboutBannerDiv' >
                
                <div className='aboutBannerBox' >
                    <div className=' aboutTitleBox'> <p className='aboutBannerTitle centerTitle'>Who We Serve</p></div>
                   
</div>    
              <div className="tileBox">

<div className="row">
                    <div className="col" >
                    <div className="whoweserveDivs">
                                <img src='/images/png/usericon1.png' alt="register-icon" />
                                <p className='tileTitle'>Producer</p>
                                <p className='tileDescription'>Individuals or organizations that undertake activities to reduce or offset greenhouse gas emissions.</p>
                            </div>
                    </div>
                    <div className="col">
                    <div className="whoweserveDivs">
                                <img src='/images/png/usericon1.png' alt="register-icon" />
                                <p className='tileTitle'>Trader</p>
                                <p className='tileDescription'>Entities involved in buying and selling carbon credits, including small everyday investors.</p>
                            </div>
                    </div>

                    <div className="col">
                    <div className="whoweserveDivs">
                                <img src='/images/png/usericon1.png' alt="register-icon" />
                                <p className='tileTitle'>Consumer</p>
                                <p className='tileDescription'>Entities required to offset their own greenhouse gas emissions.</p>
                            </div>
                    </div>
                  </div>
                
              </div>
       
         
    </div>
              </Row>
                <Row>
                    <div className='aboutNewsLetterPanel'>
                    <div className="container infoPanel my-5">
                  <div className="row mt-2">
                    <img className="imgNewsletter" src="/images/png/rafiki.png" style={{height : '100%' , width : '30%',marginLeft : '5%', transform: 'translateY(-40%)'}}></img>

                
                <h2 className='mt-5' ><b>Subscribe to our Newsletter</b></h2>
                <p className='text-black text-left'>Stay updated with the latest news, updates, and exclusive offers from EcoCredit. Get insights on our projects, sustainability tips, and ways to make a positive impact.</p>
              
              <Form className='newsLetterForm'>
                <Row>
                  <Col md={5}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                   <Form.Control
                className=' newsEmail'

                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  //onChange={(e) => handleChange(e)}
                />              </Form.Group> 

                  </Col>
                  <Col md={7}>
                  <Button className="primaryBtn contactBtn newsletterEmail mx-4" variant="" type="submit" style={{display :'inline'}}>
               Subscribe
              </Button>
                  </Col>
                  </Row> 
               

            
            </Form>
             
                    
                  </div>
                  </div>
                    </div>
                </Row>
                </div>
        </div>
       </>
    );
};

export default Whoweserve;
