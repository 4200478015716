import React,{useEffect} from 'react';
import { Container, Row, Col, Carousel, Image, Form, Button, Card } from 'react-bootstrap';

const Projects = ({ history }) => {


	/* Api Calling */
	useEffect(() => {},[]);

	return (
		<Container>
			
			<div className="projectsSec burger">
				<Row className="align-items-center">
					<Col xs={12} lg={5} xl={4}>
						<h5 className="themeClr1">Projects</h5>
						<h1 className="bigHeading">Carbon Credit <br /> Exchange For All</h1>
						<p>Carbon Credit Exchange offers a wide variety projects to fit every budget. Our inventory changes on a daily basis. Projects Developers have the ability to change the price at the click of a button.</p>
						<Button variant="" className="secondaryBtn">Watch Video</Button>
						<Button variant="" className="primaryBtn">Get Started</Button>
					</Col>

					<Col xs={12} lg={7} xl={{ span: 6, offset: 2 }}>
						<div className="graphImgSec">
							<div className="graphGroup1">
								<img src="assets/img/graph-img1.png" className="img-fluid" />
								<img src="assets/img/graph-img2.png" className="img-fluid" />
							</div>
							<div className="graphGroup2">
								<img src="assets/img/graph-img3.png" className="img-fluid" />
								<img src="assets/img/graph-img4.png" className="img-fluid" />
							</div>
						</div>
					</Col>
				</Row>
			</div>

			<div className="accessworldSec burger">
				<Row className="align-items-center">
					<Col xs={12} lg={6}>
						<div className="videoSec">
							<img src="assets/img/sub-banner-project.png" class="img-fluid" />
						</div>
					</Col>

					<Col xs={12} lg={{ span: 5, offset: 1 	}}>
						<h5 className="miniHeading themeClr1">Get Started</h5>
						<h1 className="bigHeading">Access the Carbon <br /> Credit World</h1>
						<p>CORSIA compliant Carbon Credits are available at wholesale trading rates from the leading Credits Standards. Save time and money by selecting one of the pre-vetted, clearly marked projects that fully meet the ICAO-CORSIA criteria. A separate landing page is available for our aviation customers, let's get you ready for a carbon neutral take off!</p>
						<Button variant="" className="primaryBtn">Get Started</Button>
					</Col>
				</Row>
			</div>

			<div className="postRequestSec burger">
				<Row>
					<Col xs={12}>
						<div className="postRequestContent">
							<img src="assets/img/cta-bottom.png" className="img-fluid" />
							<div className="img-caption">
								<Button variant="" className="requestBtn">Request for Quote</Button>
								<h2>Post Your Request With Us?</h2>
								<p>A Wonderful serenity has taken possession far away, behind the word mountains.</p>
								<Button variant="" className="secondaryBtn">Start Now</Button>
							</div>
						</div>
					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default Projects;
