import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SideBar from '../component/SideBar';
import AdminLayout from '../../Layout/AdminLayout';

const Ledger = ({ history }) => {


	/* Api Calling */
	useEffect(() => {
	}, []);

	return (
		<AdminLayout>
			<Row>
				<h1>Ledger</h1>
			</Row>
		</AdminLayout>
	);
};

export default Ledger;
