import AdminLayout from '../../Layout/AdminLayout';
import React, { useEffect } from 'react';
import { Container, Row, Col, Form, FormCheck, Button, Nav } from 'react-bootstrap';
import Select from 'react-select'

import SideBar from '../component/SideBar';

const SaleReport = ({ history }) => {

	const options = [
		{ value: 'chocolate', label: 'Chocolate' },
		{ value: 'strawberry', label: 'Strawberry' },
		{ value: 'vanilla', label: 'Vanilla' }
	]

	/* Api Calling */
	useEffect(() => {
	}, []);

	return (
		<AdminLayout>
			<Row>
				<Col xs={12}>
					<div className="CTNFTrequestQuote tableListing boxShaddow burger">
						<div className="heading">
							<h3 className="heading">Sales Report</h3>

							<Nav variant="tabs">
								<Nav.Item>
									<Nav.Link href="link-1">Fixed</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="link-2" className="active">Auction</Nav.Link>
								</Nav.Item>
							</Nav>
						</div>

						<div className="tableSelection">
							<Form>
								<Form.Group className="date mb-3" controlId="exampleForm.ControlInput1">
									<Form.Control type="date" placeholder="09-09-2020" />
								</Form.Group>
								<Form.Group className="date mb-3" controlId="exampleForm.ControlInput1">
									<Form.Control type="date" placeholder="09-05-2021" />
								</Form.Group>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Control type="text" placeholder="Assets ID" />
								</Form.Group>
								<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlInput1">
									<Select options={options} />
								</Form.Group>
								<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlInput1">
									<Select options={options} />
								</Form.Group>
								<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlInput1">
									<Select options={options} />
								</Form.Group>
								<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlInput1">
									<Select options={options} />
								</Form.Group>
								<Button className="primaryBtn submitBtn mb-3" variant="">SUBMIT</Button>
							</Form>
						</div>

						<div className="listing">
							<div className="tableHead">
								<ul className="headContent">
									<li className="srNo">Sr. No</li>
									<li className="date">Sell Date</li>
									<li className="producersName">Producers Name</li>
									<li className="assetsIDs">Assets ID</li>
									<li className="ctnftCreated">CTNFT Created</li>
									<li className="numeric quantity">Quantity</li>
									<li className="numeric minAmount">Min. Amount</li>
									<li className="numeric soldPrice">Sold Price</li>
									<li className="buyerName">Buyer Name</li>
									<li className="listingStatus">Certificate Status</li>
								</ul>
							</div>

							<div className="tableBody">
								<ul className="bodyContent">
									<li className="srNo">1</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn activeBtn" variant="" type="submit">Alive</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">2</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn usedBtn" variant="" type="submit">Used</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">3</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn resellBtn" variant="" type="submit">Resell</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">4</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn usedBtn" variant="" type="submit">Used</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">5</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn activeBtn" variant="" type="submit">Alive</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">6</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn usedBtn" variant="" type="submit">Used</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">7</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn resellBtn" variant="" type="submit">Resell</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">8</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn usedBtn" variant="" type="submit">Used</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">9</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn resellBtn" variant="" type="submit">Resell</Button>
									</li>
								</ul>
								<ul className="bodyContent">
									<li className="srNo">10</li>
									<li className="date">03-July-2021</li>
									<li className="producersName">Boston Trading Company</li>
									<li className="assetsIDs">CTNFT-8648-2132</li>
									<li className="ctnftCreated">01-July-2021</li>
									<li className="numeric quantity">2 CTNFT</li>
									<li className="numeric minAmount">$ 20000</li>
									<li className="numeric soldPrice">$ 24000</li>
									<li className="buyerName">Glen Maxwell/ ABC Australia Inc.</li>
									<li className="listingStatus">
										<Button className="primaryBtn usedBtn" variant="" type="submit">Used</Button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</AdminLayout>
	);
};

export default SaleReport;
