import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import SubHeader from "../component/Subheader";
import BurnOrders from "../component/BurnOrders";
import { getBurnList } from "../action/Api.action";
import { useHistory } from "react-router-dom";
import { StorageHelper } from "../utils/StorageHelper";
const BurnOrder = () => {
  const [data, setData] = useState();
  const history = useHistory();
  const userDetail = StorageHelper.getUserInfo();
  const [partnerType] = [userDetail.partner_type];
  useEffect(() => {
		if (localStorage.getItem('userType') !== "polluter") {
		  history.push(`/edit-profile`);
		}
	}, [partnerType]);
  const fetchBurn = async () => {
    const res = await getBurnList();
    if (res?.status) {
      setData(res?.burn_order);
    }
  };

  useEffect(() => {
    fetchBurn();
  }, []);
  return (
    <>
      <SubHeader />
      <Container>
        <BurnOrders data={data} />
      </Container>
    </>
  );
};
export default BurnOrder;
