import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {uploadContractFileAction} from '../action/Api.action';

import { useDispatch } from 'react-redux';
import { setSuccessData } from '../slices/home';

import {message} from '../common/Constant';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Polluteragreement = ({ history }) => {

	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({});
	const [formErrors, setFormErrors] = useState({});
	const [isUpdate, setUPdate] = useState(false);
	const [isDisabled, setDisabled] = useState(false);
	const [userType, setUserType] = useState('');

	const [userInfo, setUserInfo] = useState({});
	const dispatch = useDispatch();

	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	  });
	  
	const { vertical, horizontal, open } = state;


	const handleClick = (newState) => () => {
		setState({ open: true, ...newState });
	};

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	const fileChangedHandler=(event, elename)=>{ 
		event.preventDefault();
		let formErrorsData = formErrors;      
	
		let form = formData;	  
		let file = event.target.files[0];
		  if(!file && file===undefined){
			//formDataData['preview'] = '';
			//formDataData[elename] = '';
			//setFormData({...formData,...formDataData});
			return false;
		  }
		  var fileName = (file && file.name?file.name:'');
		  let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
		  
		if(file.size>31457280){
			//formErrorsData[elename] = "File size not greater then 30MB.";
		}else if (extensions == 'pdf' || extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg'){
		  
			//formErrorsData[elename] = "";
			//formErrorsData["preview"] = "";
			//formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
			//formDataData['fileType'] = extensions;
			form.contractFile  = event.target.files[0];
			//setFormData({...formData,...formDataData});
		}else {          
			 // formErrorsData[elename] = "File extensions doesn't match.";
					 
		}
		//setFormErrors({...formErrors,...formErrorsData});

		setFormData(form);
		setUPdate(!isUpdate);	
	}
	const handleSubmit = async(event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
	
			setValidated(true);
		}else{
			event.preventDefault();
			event.stopPropagation();
			setDisabled(true);
			//setValidated(true);
			
			let formDataData = formData;
				

			let postData = new FormData();
			for(let x in formDataData){
	
				let item = formDataData[x];       
				postData.append(x, item);

			}			

			let response = await uploadContractFileAction(postData);
		
			if(response.code === 200){
				
				//setState({ open: true, vertical: 'top', horizontal: 'center' });
				setFormData({});
				//setTimeout(()=>{
					dispatch(setSuccessData(message['contractSign']));
					history.push({pathname:"/success"});
				//},2000)
				

			}else if(response && response.errors){
				
				setDisabled(false);
				let errorsList = response.errors;
				let err = {};
				for(let i in errorsList){
					err[errorsList[i].param] = errorsList[i].msg;
				}
				
				setFormErrors(err);

			}
		}		
	};


	/* Api Calling */
	useEffect(() => {

		let userDetail = JSON.parse(localStorage.getItem('authData'));		
		setUserInfo(userDetail);

		if(userDetail.userType === 'POLLUTERS'){
			setUserType('Polluter');
		}else if(userDetail.userType === 'PRODUCERS'){
			setUserType('Producer');
		}else if(userDetail.userType === 'TRADERS'){
			setUserType('Trader');
		}

	},[]);

	return (
		<Container>

			<Snackbar
			 		autoHideDuration={2000}
					anchorOrigin={{ vertical, horizontal }}
					open={open}
					onClose={handleClose}
					//message="I love snacks"
					key={vertical + horizontal}
					//severity="error"
				>
				<Alert onClose={handleClose} severity="success">Successfully Uploaded.</Alert>
			</Snackbar>
			
			<div className="moreInfoSec signUpForm boxShaddow burger">
				<Row className="mb-4">
					<Col xs={12}>
						<div className="heading">
							<h3>{userType} Contract Agreement</h3>
						</div>
						
						<div className="scrollbar">
							{userInfo && 
								<div className="companyAddInfo">
									<p>{userType} Company Name: <strong>{userInfo.companyName}</strong></p>
									<p>Company Registration Number: <strong>{userInfo.companyRegisterNumber}</strong></p>
									<p>Address: <strong>{userInfo.address+','+userInfo.city+','+userInfo.state+','+userInfo.zipCode+' '+userInfo.country}</strong></p>
									<p className="between">Between</p>
									<p>Company Name: <strong>Carbon Credit Exchange Australia</strong></p>
									<p>Company Registration Number: <strong>AUS-23648680-1267</strong></p>
									<p>Address: <strong>201 Business Center, Sydney South, NSW, Delivery Area 2006 Australia</strong></p>
								</div>
							}
							
							<div className="companyDiscription">
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam porttitor ultricies ipsum, ac mollis mi semper non. Ut molestie ullamcorper quam ac scelerisque. Nulla augue sapien, consequat sit amet dapibus malesuada, molestie ac massa. Aliquam et ipsum mi. Vivamus eu egestas erat. Praesent in consequat nunc. Donec non mollis ante, a rhoncus tellus. Etiam placerat venenatis egestas. In hac habitasse platea dictumst. Phasellus at neque vitae ex varius consequat. Praesent egestas mollis dui a faucibus. Phasellus sollicitudin ultrices enim eu facilisis. Nulla aliquam nulla eget dolor blandit dictum. Maecenas vitae commodo augue. Donec tristique pulvinar imperdiet.</p>
								<p>Mauris tincidunt feugiat ipsum non luctus. Sed venenatis euismod sagittis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh velit, rutrum at purus eget, semper ultricies ante. Proin tincidunt tempus neque quis dictum. Vivamus enim ligula, hendrerit vitae metus eu, imperdiet fermentum ligula. Morbi elementum venenatis rutrum.</p>
								<p>Etiam sem orci, finibus in tincidunt eget, ultrices bibendum elit. Vivamus tempus velit ac dui mattis, accumsan ornare odio rhoncus. Pellentesque vel mi id ante dapibus condimentum. Phasellus interdum sem erat. Maecenas id varius felis. Praesent et sem non massa suscipit lobortis vel a lorem. Cras tincidunt felis ac felis rutrum finibus. Duis eu lectus massa. Mauris ac tellus id arcu dapibus luctus.</p>
								<p>Maecenas tincidunt, diam vitae efficitur elementum, diam diam tempus nunc, molestie tristique lorem nibh ut purus. Integer lacinia quam id efficitur facilisis. Etiam hendrerit leo vel orci tempor, id mollis justo ornare. Aenean at viverra sem. Etiam lacus massa, convallis vel euismod eu, dapibus a purus. Nullam mauris elit, ultricies a molestie sed, posuere ac purus. Phasellus sodales erat vel mauris viverra, nec consectetur lacus auctor.</p>
								<p>Donec et leo et dolor maximus tempor. Nullam fermentum aliquet tristique. Sed libero quam, ultricies quis est quis, rutrum efficitur dui. Duis euismod magna non arcu fermentum, vel fringilla enim malesuada. Morbi ex ex, porta quis ligula in, tincidunt posuere neque. Sed lacinia velit id tempor hendrerit. Aliquam erat volutpat. Cras molestie porta molestie.</p>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam porttitor ultricies ipsum, ac mollis mi semper non. Ut molestie ullamcorper quam ac scelerisque. Nulla augue sapien, consequat sit amet dapibus malesuada, molestie ac massa. Aliquam et ipsum mi. Vivamus eu egestas erat. Praesent in consequat nunc. Donec non mollis ante, a rhoncus tellus. Etiam placerat venenatis egestas. In hac habitasse platea dictumst. Phasellus at neque vitae ex varius consequat. Praesent egestas mollis dui a faucibus. Phasellus sollicitudin ultrices enim eu facilisis. Nulla aliquam nulla eget dolor blandit dictum. Maecenas vitae commodo augue. Donec tristique pulvinar imperdiet.</p>
								<p>Mauris tincidunt feugiat ipsum non luctus. Sed venenatis euismod sagittis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nibh velit, rutrum at purus eget, semper ultricies ante. Proin tincidunt tempus neque quis dictum. Vivamus enim ligula, hendrerit vitae metus eu, imperdiet fermentum ligula. Morbi elementum venenatis rutrum.</p>
								<p>Etiam sem orci, finibus in tincidunt eget, ultrices bibendum elit. Vivamus tempus velit ac dui mattis, accumsan ornare odio rhoncus. Pellentesque vel mi id ante dapibus condimentum. Phasellus interdum sem erat. Maecenas id varius felis. Praesent et sem non massa suscipit lobortis vel a lorem. Cras tincidunt felis ac felis rutrum finibus. Duis eu lectus massa. Mauris ac tellus id arcu dapibus luctus.</p>
								<p>Maecenas tincidunt, diam vitae efficitur elementum, diam diam tempus nunc, molestie tristique lorem nibh ut purus. Integer lacinia quam id efficitur facilisis. Etiam hendrerit leo vel orci tempor, id mollis justo ornare. Aenean at viverra sem. Etiam lacus massa, convallis vel euismod eu, dapibus a purus. Nullam mauris elit, ultricies a molestie sed, posuere ac purus. Phasellus sodales erat vel mauris viverra, nec consectetur lacus auctor.</p>
								<p>Donec et leo et dolor maximus tempor. Nullam fermentum aliquet tristique. Sed libero quam, ultricies quis est quis, rutrum efficitur dui. Duis euismod magna non arcu fermentum, vel fringilla enim malesuada. Morbi ex ex, porta quis ligula in, tincidunt posuere neque. Sed lacinia velit id tempor hendrerit. Aliquam erat volutpat. Cras molestie porta molestie.</p>
							</div>

							<div className="moreAbtAgrmt">
								<div className="AbtAgrmtBox">Is there an air quality problem?</div>
								<div className="AbtAgrmtBox">Characterise the problem</div>
								<div className="AbtAgrmtBox">National coordination required?</div>
								<div className="AbtAgrmtBox">Priority setting. Roles and responsibilities.</div>
								<div className="AbtAgrmtBox">Agreement Work Plan - options to manage</div>
								<div className="AbtAgrmtBox">Decisions taken. Implement options.</div>
								<div className="AbtAgrmtBox">Review of work plan and measures implemented.</div>
							</div>
							<div className="moreAbtAgrmt">
								<div className="AbtAgrmtBox2">Concern Brought to government attention (various means).</div>
								<div className="AbtAgrmtBox2">Examine nature, scale, impact. Consider current management.</div>
								<div className="boxs2">
									<div className="AbtAgrmtBox2">Ministers agree priorities and roles and responsibilities.</div>
									<div className="AbtAgrmtBox2 red">Not prioritised - may be addressed through other arrangements.</div>
								</div>
								<div className="AbtAgrmtBox2">Options assessed, including costs and benefits as appropriate.</div>
								<div className="AbtAgrmtBox2">Considered by Ministers</div>
								<div className="boxs2">
									<div className="AbtAgrmtBox2">Decisions reported and implementation phase.</div>
									<div className="AbtAgrmtBox2">Formal review by Ministers every two years.</div>
								</div>
							</div>
						</div>

						<div>
							<Button className="secondaryBtn dwldCntrctBtn" variant="" type="submit">Download and Contract Agreement</Button>
						</div>
					</Col>
				</Row>
				<Form className="formContent"  noValidate validated={validated} onSubmit={e=>handleSubmit(e)}>
					<Row>
						<Col xs={12}>
							<div className="documentType">
								{/* <Form className="formContent">
									<Form.Group className="" controlId="formBasicCompanyType">
										<Form.Control type="text" placeholder="Select Ducument Type" />
									</Form.Group>
								</Form> */}
								<Form.Group controlId="formFile" className="selectFileOption mt-2">
									<Form.Label>Upload Document</Form.Label>
									<span><img src="assets/img/mountain.png" /></span>
									<Form.Control type="file" accept="image/*, pdf" onChange={e => fileChangedHandler(e, 'contractFile')} required />
								</Form.Group>
							</div>
						</Col>
						
						<Col xs={12} md={8}>
							<div className="moreBtns">
								<Button className="secondaryBtn" variant="" type="submit">Accepted</Button>
								<p>or</p>
								<Button className="primaryBtn" variant="" type="button">Sign by DocuSign</Button>
							</div>
						</Col>
					</Row>
				</Form>
			</div>

		</Container>
	);
};

export default Polluteragreement;
