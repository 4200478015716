import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_CURRENCY, LENGTH } from "../../constant/constant";
import { useToast } from "../../context/Toast/useToast";
import CartItem from "../CartItem";
// import { hasAPIFailed } from "utils/ApiHelper";
import Loader from "../loader";
import styles from "./YourCart.module.scss";
// import { removeBondFromCart, addBondToCart } from "redux/slices/cart";

function YourMiniCart({ groupByData, getPayNowData, cartData }) {
  const [isLoading, setLoading] = useState(false);
  const [isAllLoading, setIsAllLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState("");
  const [sumAllQty, setSumAllQty] = useState(0);
  const dispatch = useDispatch();
  const [sumAllAmount, setSumAllAmount] = useState(0);
  const { addToast } = useToast();
  const quantData = useSelector((state) => state?.home?.quoteData);

  //   const handleRemoveBond = (lineId) => {
  //     setLoading(true);
  //     const bondData = {
  //       bond_id: groupByData.bond_id,
  //       line_id: lineId,
  //     };
  //     dispatch(removeBondFromCart(bondData))
  //       .then((res) => {
  //         if (!hasAPIFailed(res.payload)) {
  //           addToast({
  //             message: res.payload.message,
  //             type: "success",
  //             duration: 3000,
  //           });
  //         } else {
  //           addToast({
  //             message: res.payload.message,
  //             type: "error",
  //             duration: 3000,
  //           });
  //         }
  //         setLoading(false);
  //         getPayNowData();
  //       })
  //       .catch((err) => {
  //         addToast({
  //           message: "Failed to remove bond from cart. Please try again",
  //           type: "error",
  //           duration: 3000,
  //         });
  //         setLoading(false);
  //       });
  //   };

  const handleDeleteBond = (qnty) => {
    const add_qty = -qnty;
    CartItem(quantData, add_qty, addToast, dispatch);
  };

  const handleRemoveAllBonds = async () => {
    const add_qty = -sumAllQty;
    CartItem(quantData, add_qty, addToast, dispatch);
  };

  useEffect(() => {
    let cardQty = cartData
      ?.map((i) => i.volume)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    let cardAmount = cartData
      ?.map((i) => i.price)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    setSumAllQty(cardQty);
    setSumAllAmount(cardAmount);
  }, [cartData]);

  const handleQuantity = async (loader) => {
    setUpdateLoading(loader);
    const add_qty = loader === "plus" ? 1 : -1;
    const res = await CartItem(quantData, add_qty, addToast, dispatch);
    setUpdateLoading("");
    // const bondData = {
    //   bond_id: bondId,
    //   add_qty: loader === "plus" ? 1 : -1,
    //   line_id: loader === "plus" ? 0 : lineId,
    // };
    // dispatch(addBondToCart(bondData))
    //   .then((res) => {
    //     if (!hasAPIFailed(res.payload)) {
    //       addToast({
    //         message: res.payload.message,
    //         type: "success",
    //         duration: 3000,
    //       });
    //     } else {
    //       addToast({
    //         message: res.payload.message,
    //         type: "error",
    //         duration: 3000,
    //       });
    //       setUpdateLoading("");
    //     }
    //     getPayNowData();
    //   })
    //   .catch((err) => {
    //     setUpdateLoading("");
    //     addToast({
    //       message:
    //         "Failed to update bond quantity in the cart. Please try again",
    //       type: "error",
    //       duration: 3000,
    //     });
    //   });
  };
  // console.log("card", cartData);()
  return (
    <div className={styles.mycartsWrapper}>
      <div className={`d-flex ${styles.mycarts} `}></div>
      <div className={`table-responsive ${styles.tableContainer}`}>
        <table>
          <thead>
            <tr>
              {/* <th scope="col">Seller Name</th>
              <th scope="col">Seller Type</th> */}
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {cartData?.map((item, index) => {
              return (
                <tr key={index}>
                  {/* <td>{item.issuer}</td>
                  <td>{item.seller_type}</td> */}
                  <td>
                    {BASE_CURRENCY.CRYPTO} {item?.price * item?.volume}
                  </td>
                  <td>
                    <div className={styles.buttongroup}>
                      {/* {updateLoading === "minus" ? (
                        <Loader updateLoader={true} />
                      ) : (
                        <div
                          className={styles.subButton}
                          onClick={() => handleQuantity("minus")}>
                          <i className="fa-solid fa-circle-minus"></i>
                        </div>
                      )} */}
                      <div className={styles.counter}>
                        <input type="text" value={item.volume} readOnly />
                      </div>
                      {/* {updateLoading === "plus" ? (
                        <Loader updateLoader={true} />
                      ) : (
                        <div
                          className={styles.subButton}
                          onClick={() => handleQuantity("plus")}>
                          <i className="fa-solid fa-circle-plus"></i>
                        </div>
                      )} */}
                    </div>{" "}
                  </td>
                  <td>
                    <div>
                      <span>
                        {BASE_CURRENCY.SYMBOL} {item.fiat_price * item?.volume}
                      </span>
                      <span onClick={() => handleDeleteBond(item.volume)}>
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <i
                            className={`far fa-trash-alt ${styles.deleteIcon}`}
                          />
                        )}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.footerarea}>
        <div className={styles.FinalAmountAlignment}>
          <div>
            <h2>
              <span>Total Qty:</span>
              {sumAllQty}
            </h2>
            <h3>
              <span>Total:</span>
              {BASE_CURRENCY.CRYPTO}{" "}
              {String(sumAllAmount).substring(0, LENGTH.CRYPTO)}
            </h3>
          </div>
          <button onClick={() => handleRemoveAllBonds()}>
            {isAllLoading ? (
              <Loader />
            ) : (
              <>
                <img
                  src="./images/common/deletetableicon.svg"
                  width={16}
                  height={20}
                  alt=""
                />
                <span>REMOVE</span>
              </>
            )}
          </button>
        </div>
      </div>
      {/* mobile view card */}
      <div className={styles.onlymobileViewCardShow}>
        {groupByData?.list?.map((item, index) => {
          return (
            <div key={index} className={styles.cardAllListAlignment}>
              <div className={styles.firstSectionAlignment}>
                <div>
                  <span>
                    {item.issuer} ({item.seller_type})
                  </span>
                </div>
                <div>
                  <input type="text" />
                </div>
              </div>
              <div className={styles.secGrid}>
                <div className={styles.secGridItems}>
                  <div className={styles.childContentAlignent}>
                    <div>
                      <h2>Seller Type</h2>
                      <span>{item.seller_type}</span>
                    </div>
                    <div>
                      <h2>Unit value</h2>
                      <span>{item.per_unit_value}</span>
                    </div>
                    <div>
                      <h2>Total</h2>
                      <span>{item.total_amount}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.secGridItems}>
                  <div className={styles.allContentAlignment}>
                    <div
                      className={styles.counterButtonDesign}
                      onClick={() =>
                        handleQuantity(item.line_id, "minus", item.bond_id)
                      }>
                      <i className="fa-solid fa-minus"></i>
                    </div>
                    <div className={styles.counterMobile}>
                      <input type="text" value={item.qty} readOnly />
                    </div>
                    <div
                      className={styles.counterButtonDesign}
                      onClick={() =>
                        handleQuantity(item.line_id, "plus", item.bond_id)
                      }>
                      <i className="fa-solid fa-plus"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default YourMiniCart;
