import Steps from "./../../common/Steps/index";
import ProfileComplete from "./profile/ProfileComplete";
import { STEPS } from "../../constant/constant";
import React, { useState } from "react";
import AddDocuments from "./AddDocuments";
import AddInformation from "./AddInformation";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import KycStatus from './KycDialog';
// import ProducerStatus from "../../common/modal/producerStatus";
// import { Modal, Button } from "react-bootstrap";

const ProducerProfile = () => {
  const [CurrentStep, setCurrentStep] = useState(0);
  const [ModalOpen, setModalOpen] = useState(false);

  const changeStep = (s) => {
    setCurrentStep(s);
  };
  const history = useHistory();
  const returnToHomePage = () => {
    // localStorage.clear();
    history.push("/");
  };
  console.log("Profile triggered")
  // console.log("STORAGE", localStorage)

  const getCurrentTab = (step) => {
    switch (step) {
      case 0:
        return <AddInformation changeStep={changeStep} />;
      case 1:
        return <AddDocuments changeStep={changeStep} />;

      // goNextStep = {() => {
      //   changeStep(2)
      // }} />
      case 2:
        return <ProfileComplete finishUpdate={returnToHomePage} />;
    }
  };
  // const statusdialog = () => {
  //   setStatusModalOpen(!StatusModalOpen);
  //   console.log("CHEKININININNNI***************", StatusModalOpen);
  //   // handleWalletConnect();
  // };

  
  return (
   
   <>
     {CurrentStep != 2  &&  <Steps
        steps={STEPS}
        currentStep={CurrentStep}
        // onClick={(s) => {
        //   setCurrentStep(s);
        // }}
      />}
    
       <div className={styles.IssuerProfile}>
      {getCurrentTab(CurrentStep)}
    </div>
    
    {
    ((localStorage.kyc_status == "submitted" && CurrentStep !=2) && (localStorage.kyc_doc_status != "resubmission"  ) ) && <KycStatus show={!ModalOpen} close={() => setModalOpen(false)} />

    
  }
    
   </>
  );
};

export default ProducerProfile;
