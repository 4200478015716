import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory , useLocation} from "react-router-dom";
import YourCart from "./YourCart";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Dropdown,
  Badge,
  Figure,
} from "react-bootstrap";
import {
  getSignMessage,
  sendAcknowledgement,
  WalletConnectMetamask,
  WalletDisconnect,
} from "../walletConnect";
import {
  fetchProfilePicture,
  fetchWalletConnect,
  generateAccessToken,
  userLogout,
} from "../../action/Api.action";
import cn from "classnames";
import classes from "./Layout.module.scss";
import { setLoginCheck, setProfile } from "../../slices/auth";
import { setHeaderList } from "../../slices/home";
import { StorageHelper } from "../../utils/StorageHelper";
import styles from "./style.module.scss";
import {
  client,
  CreateWalletConnectClient,
} from "../walletConnect/connectWallet";
import { useLoader } from "../../context/Loader/useLoader";
import { useToast } from "../../context/Toast/useToast";
import { getPageTypeData } from "../../action/Api.action";
import QRCodeModal from "@walletconnect/legacy-modal";
import { NAME } from "../../constant/constant";
import CreateWallet from "./CreateWallet";

const Header = () => {
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.loginData);
  const loginCheck = useSelector((state) => state?.auth?.loginCheck);
  const adminPathName = useSelector((state) => state.home.adminPathName);
  const cartData = useSelector((state) => state?.home?.cartData);
  const userData = useSelector((state) => state?.auth?.profileData);
  const headerList = useSelector((state) => state?.home?.headerList);
  const currPartnerType = userData?.partner_type;
  const history = useHistory();
  const { setLoading } = useLoader();
  const { addToast } = useToast();
  const [userType, setUserType] = useState("");
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [totalVolume, setTotalVolume] = useState(0);
  const [notificationMenu, setNotificationMenu] = useState(false);
  const [profilePic,setProfilePic] = useState("/images/svg/profilePhoto.svg")

  let cartRef = useRef(null);
  const logout = () => {
    addToast({
      message : "Logged out Successfully!",
      type : "success",
      duration  : 800
    })
    localStorage.setItem('wallet_connect', false)
    localStorage.clear();
    // window.location.href = "/";
    history.push("/")
  };
  const userDetail = StorageHelper.getUserInfo();
  const [wallet_address] = [userDetail.wallet_address];
  const [partnerType] = [userDetail.partner_type];
  const [partner_state] = [userDetail.partner_state];
  const [partnerUserType, setPartnerType] = useState(
    partnerType === "polluter" ? false : true
  );
  //   console.log("###################################PAth name ###################################, ", location.pathname)

    useEffect(async()=>{
      //fetch profile image
      const profileImg = await fetchProfilePicture({user_id : localStorage.getItem('userId')})
      
      if(profileImg.status)
      setProfilePic(profileImg.imageData)
    })





  useEffect(() => {
    // console.log("wallet_address", wallet_address, walletAddress);
    if (wallet_address && (walletAddress == "" || walletAddress == null)) {
      const firstFour = wallet_address?.substr(0, 4);
      const lastFour = wallet_address?.substr(
        wallet_address?.length - 4,
        wallet_address?.length
      );
      const Wallet_Address = firstFour + "......" + lastFour;
      setWalletAddress(Wallet_Address);
    }
    if (cartData.length > 0) {
      let sum = 0;
      cartData.map((info) => {
        sum = sum + info?.volume;
      });
      setTotalVolume(sum);
    } else if (cartData.length == 0) {
      setTotalVolume(0);
    }
  }, [wallet_address, cartData]);
  useEffect(() => {
    let type = localStorage.getItem("userType")
      ? localStorage.getItem("userType")
      : "";
    if (type !== "") {
      setUserType(type);
    } else if (loginData && loginData.userType) {
      setUserType(loginData.userType);
    }
  }, [loginData]);

  const handleHomeIconClick = () => {
    const getNav = document.querySelectorAll(".nav-link");
    for (let index = 0; index < getNav.length; index++) {
      getNav[index].classList.remove("active");
    }
  };
  const handleWalletConnect = async () => {
    const resp = await WalletConnectMetamask();
    const currentDate = Date.now();
    if (resp.status) {
      const { account, session } = resp;
      if (account) {
        const res = await fetchWalletConnect(account);
        setLoading(
          true,
          " Please accept safe connect request in your wallet..."
        );
        const signedMessage = await getSignMessage(
          res.random_message,
          account,
          session
        );
        if (signedMessage.code) {
          await sendAcknowledgement(
            res.random_message,
            signedMessage,
            currentDate,
            session,
            account,
            "personal_sign",
            false
          );
          setLoading(false);
          addToast({ message: signedMessage.message, type: "error" });
        } else {
          setLoading(true, "Signing in...");
          const result = await generateAccessToken(account, signedMessage);
          await sendAcknowledgement(
            res.random_message,
            signedMessage,
            currentDate,
            session,
            account,
            "personal_sign",
            true
          );
          setLoading(false);

          if (result?.status == "true") {
            localStorage.setItem("userId", result.user_id);
            localStorage.setItem("accessToken", result.access_token);
            localStorage.setItem("walletAddress", account);
            localStorage.setItem(
              "partner_type",
              result.partner_type ? result.partner_type : "polluter"
            );
            localStorage.setItem("partner_state", result.partner_state);

            // const data = setProfile({
            //   accessToken: result.access_token,
            //   userId: result.user_id,
            //   email: result.email,
            //   name: result.name,
            // });
            // dispatch(data);
            dispatch(setLoginCheck(true));
            if (res?.is_new_user) {
              history.push("/registration");
            } else {
              window.location.reload();
              history.push("/edit-profile");
            }
          }
        }
      }
    } else {
      addToast({
        message: "Something went Wrong please try again",
        type: "error",
      });
    }
  };

  const DisconnectWallet = async () => {
    setLoading(true, "Disconnecting...");
    const user_id = localStorage.getItem("userId");
    const request = {
      user_id: parseInt(user_id),
    };
    await fetchLogout(request);
    if (!client) {
      await CreateWalletConnectClient();
    }
    try {
      client.on("session_delete", () => {
        // Session was deleted -> reset the dapp state, clean up from user session, etc.
      });
      // It will removed existing session from wallet
      await WalletDisconnect();
      setWalletAddress(null);
      localStorage.clear();
      dispatch(setProfile({}));
      dispatch(setLoginCheck(false));
      history.push(`/`);
    } catch (error) {
      console.log("Error", error);
    }
    setLoading(true, "Clearing Session...");
    setWalletAddress(null);
    dispatch(setProfile({}));
    dispatch(setLoginCheck(false));
    localStorage.clear();
    history.push(`/`);
    setLoading(true, "Navigating to Home...");
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const selectWalletConnect = () => {
    setWalletModalOpen(!walletModalOpen);
    // console.log(walletModalOpen);
    // handleWalletConnect();
  };
  const handleToggle = (event) => {
    event.preventDefault();
    setPartnerType(!partnerUserType);
    const partner_type = partnerType == "polluter" ? "producer" : "polluter";
    localStorage.setItem("partner_type", partner_type);

    window.location.reload();
  };

  const fetchLogout = async (request) => {
    await userLogout(request);
  };

  const fetchPageData = async (
    link = "/api/v1/homepage/home",
    pageType = "home"
  ) => {
    const result = await getPageTypeData(pageType);
    if (result) {
      setTimeout(() => {
        dispatch(setHeaderList(result.header_list));
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPageData();
    // Close qr code model on esc button
    const handleEscapeButton = (event) => {
      if (event.keyCode === 27) {
        QRCodeModal.close();
      }
    };
    document.addEventListener("keydown", handleEscapeButton, false);
    return () => {
      document.removeEventListener("keydown", handleEscapeButton, false);
    };
  }, []);
  const myAccount = () => {
    const getprofileDIalog = document.querySelector('.customeDropDown')
    getprofileDIalog.classList.toggle('active')
  }
  // useEffect(()=>{

  //   document.body.addEventListener('click', function(){
  //     const getprofileDIalog =  document.querySelector('.customeDropDown.active')
  //     console.log('deepak',getprofileDIalog);
  //     if(getprofileDIalog){
  //       getprofileDIalog.classList.remove('active')
  //     }
  //   })

  // },[])

  if (userType === "SUPER_ADMIN") {
    return (
      <Navbar sticky="top" bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              src="../assets/img/phaeton_header_logo.png"
              alt="logo"
             
            />
          </Navbar.Brand>
          <h6>{adminPathName}</h6>
          <Navbar.Toggle className="" aria-controls="basic-navbar-nav">
            <span> </span>
            <span> </span>
            <span> </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown title="Admin" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/admin/dashboard">
                  Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/profile">
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/changePassword">
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={(e) => logout()}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  } else {
    return (
      <>
        <Navbar sticky="top" bg="light" expand="lg">
          <Container fluid>
            <Navbar.Brand
              as={Link}
              to={{pathname : localStorage.getItem("userId")  ? "/" : "/dashboard", state:{page:"NFT's", pagename:'Retired NFT'}}}
              onClick={handleHomeIconClick}>
              <img
                src="../assets/img/phaeton_header_logo.png"
                alt="logo"
                width="200"
              />
            </Navbar.Brand>
            <Navbar.Toggle className="" aria-controls="basic-navbar-nav">
              <span> </span>
              <span> </span>
              <span> </span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto ">
                <Navbar.Brand
                  as={Link}
                  to={userType === "" ? "/" : "/dashboard"}
                  onClick={handleHomeIconClick}
                  className="d-lg-none me-0 mb-3">
                  <img
                    src="assets/img/phaeton_header_logo.png"
                    alt="logo"
                    width="200"
                  />
                </Navbar.Brand>
                {!localStorage.getItem('userType') && (
                  <>
                    {/* <Nav.Link as={Link} to="/wallet/withdrawal" >
                      Wallet
                    </Nav.Link> */}
                   { (window.location.pathname.toLowerCase() !== '/login' && window.location.pathname.toLowerCase() !== '/signup') && <Nav.Link as={Link} to="/Login" >
                      Login
                    </Nav.Link>}
                    <Nav.Link as={Link} to="/about" >
                      About Us
                    </Nav.Link>
                    {/* <Nav.Link as={Link} to="/buy-cnft" eventKey="0">
                      Buy 
                    </Nav.Link> */}
                    {/*<Nav.Link as={Link} to="/projects" eventKey="2">
                      Projects
                    </Nav.Link>
                    <Nav.Link as={Link} to="/company" eventKey="3">
                      Company
                    </Nav.Link>
                    <Nav.Link as={Link} to="/newmedia" eventKey="4">
                      News & media
                    </Nav.Link>
                    <Nav.Link as={Link} to="/contact" eventKey="5">
                      Contact
                    </Nav.Link>*/}
                    {/* {headerList &&
                      headerList.map((header, index) => {
                        return (
                          <Nav.Link
                            as={Link}
                            to={`/${header.page_type_name}`}
                            eventKey={index + 1}>
                            {header.name}
                          </Nav.Link>
                        );
                      })} */}
                    {loginCheck && !userData.walletAddress !== null ? (
                      <div className={styles.relativeNotification}>
                        <a
                          className={classes.NavLink}
                          onClick={() => {
                            setNotificationMenu(!notificationMenu);
                            setWalletModalOpen(!walletModalOpen);
                          }}>
                          <i className="fa-solid fa-cart-shopping position-relavite"></i>
                          <Badge className={styles.badgeStyle} bg="info">
                            {totalVolume ? totalVolume : 0}
                          </Badge>
                        </a>
                        <div
                          ref={cartRef}
                          className={
                            notificationMenu
                              ? cn(
                                styles.cartNotification,
                                styles.cartNotificationShow
                              )
                              : cn(
                                styles.cartNotification,
                                styles.cartNotificationHidden
                              )
                          }>
                          <div className={styles.boxtitle}>
                            <h1>Your Cart</h1>
                            {cartData.length ? (
                              <p>
                                <a href="/cart">View all</a>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className={
                              !cartData.length
                                ? styles.bodyBoxQty
                                : styles.boxBody
                            }>
                            {cartData.map((i, idx) => {
                              return (
                                <YourCart key={idx} index={idx} cartData={i} />
                              );
                            })}
                            {/* {cartLoader ? <Loader /> : ""} */}
                            {!cartData.length ? (
                              <h5 className="no-items">
                                No {NAME.certificate} added to the cart
                              </h5>
                            ) : (
                              ""
                            )}
                          </div>
                          {cartData.length ? (
                            <div className={styles.checkoutButtonAlignment}>
                              <a href="/cart" passhref>
                                <button className={styles.checkout}>
                                  Checkout
                                </button>
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : null}
                    {(loginCheck &&
                      userData.walletAddress &&
                      currPartnerType === "producer") ||
                      partner_state === "approved" ? (
                      <div className={styles.swtichTextAlignment}>
                        <div className={styles.textStyle}>
                          {/* <p>Polluter</p> */}
                          <p>{NAME.buyer}</p>
                        </div>
                        <div
                          className={styles.switchSection}
                          onClick={handleToggle}>
                          <label className={styles.switch}>
                            <input type="checkbox" checked={partnerUserType} />
                            <span
                              className={cn(
                                styles.round,
                                styles.slider
                              )}></span>
                          </label>
                        </div>
                        <div className={styles.textStyleLast}>
                          {/* <p>Producer</p> */}
                          <p>{NAME.supplier}</p>
                        </div>
                      </div>
                    ) : null}
                    {userData &&
                      userData.walletAddress &&
                      userData?.image_url && (
                        <Figure>
                          <Figure.Image
                            width={100}
                            height={100}
                            alt="171x180"
                            src={userData?.image_url}
                            className={styles.userImage}
                          />
                        </Figure>
                      )}
                    {/* {userData.walletAddress ? (
                      <Dropdown onClick={() => setNotificationMenu(false)}>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="nav-Btn">
                          {userData.walletAddress ? userData.walletAddress : ""}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item>
                            <Link
                              className="text-black"
                              to={
                                partnerType == "polluter"
                                  ? "/dashboard"
                                  : "/createCNFT"
                              }>
                              <img
                                alt="portal"
                                className="dropdown-img"
                                src="../assets/img/register.png"
                              />
                              My Portal
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={DisconnectWallet}>
                            <img
                              alt="portal"
                              className="dropdown-img"
                              src="../assets/img/register.png"
                            />
                            Disconnect Wallet
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <div className="nav-Btn" onClick={()=>selectWalletConnect()}>
                        Create Wallet
                      </div>
                    )} */}
                    {/* <Nav.Link as={Link} to="/login" eventKey="6">Login</Nav.Link>
                                <Nav.Link as={Link} to="/signup" eventKey="7">SignUp</Nav.Link> */}
                  </>
                )}

                {localStorage.getItem('userType') && (
                  <>

                    <Nav.Link as={Link} to={"/marketplace"} eventKey="8">
                      Marketplace 
                    </Nav.Link>
                     {/* <Nav.Link as={Link} to={'/dashboard'} eventKey="7">
                      Dashboard
                    </Nav.Link>  */}
                    <Nav.Link as={Link} to="/" eventKey="9">
                      Explore
                    </Nav.Link>


                    <Nav.Link as={Link} to={"/dashboard"} eventKey="15">
                      How it works
                    </Nav.Link>
                    <Nav.Link eventKey="16">
                      <div className="position-relative myaccountmenu">
                        <div className="profileImage" >
                          <img src={profilePic} alt="Profile Photo" />
                        </div>
                        <div className="customeDropDown">
                          <div className="submenuinnet">
                            <Nav.Link as={Link} to={localStorage.getItem('userState') === 'approved' ? (localStorage.getItem('wallet_connect')!=="false" ? {pathname:"/dashboard", state:{page:"My Account", pagename:'Dashboard'}}: "/create-wallet") : {pathname:"/edit-profile", state:{page:"My Account", pagename:'KYC'}}} >
                            My Account
                            </Nav.Link>
                            <Nav.Link as={Link} to={localStorage.getItem('userState') == 'approved' ? (localStorage.getItem('wallet_connect')!=="false" ?{pathname:"/edit-profile", state:{page:"My Account", pagename:'KYC'}}: "/create-wallet"):  {pathname:"/edit-profile", state:{page:"My Account", pagename:'KYC'}}} >
                            KYC
                            </Nav.Link>
                            <Nav.Link as={Link} to={localStorage.getItem('userState') == 'approved'  ? (localStorage.getItem('wallet_connect')!=="false" ? "/wallet/deposit": "/create-wallet") : {pathname:"/edit-profile", state:{page:"My Account", pagename:'KYC'}}} >
                            My Wallet
                            </Nav.Link>
                            <Nav.Link onClick={(e) => logout()} >
                              Logout
                            </Nav.Link>
                          </div>
                        </div>
                      </div>
                    </Nav.Link>
                    {/* {( localStorage.getItem('wallet_address') != "" && localStorage.getItem('wallet_connect')==="true") &&
                    <Nav.Link as={Link} to={localStorage.getItem('wallet_connect') == "true" ? "/wallet/deposit" : "/create-wallet"} eventKey="16">
                      <Button variant="" className="primaryBtn">{ localStorage.getItem('wallet_connect') == "true" ?  localStorage.getItem('wallet_address').slice(0,3) + "..." + localStorage.getItem('wallet_address').slice(-3) : "Connect Wallet" }</Button>
                     
                    </Nav.Link >} */}
                    {(localStorage.getItem('wallet_address') != "" && localStorage.getItem('wallet_connect') === "true") &&
                      // <Nav.Link as={Link} to={localStorage.getItem('wallet_connect') == "true" ? "/wallet/deposit" : "/create-wallet"} eventKey="16">
                      <Button variant="" className="primaryBtn"> {localStorage.getItem('wallet_address').slice(0, 3) + "..." + localStorage.getItem('wallet_address').slice(-3)}</Button>
                    }
                    {/* // </Nav.Link >} */}
                    {(localStorage.getItem('wallet_address') != "" && localStorage.getItem('wallet_connect') === "false") &&
                      <Nav.Link as={Link} to={"/create-wallet"} eventKey="16">
                        <Button variant="" className="primaryBtn">Connect Wallet</Button>
                      </Nav.Link>}
                    {localStorage.getItem('wallet_address') == "" &&
                      <Nav.Link as={Link} to={localStorage.getItem('userState') !== "approved" ? "/edit-profile":"/create-wallet"} eventKey="16">
                        <Button variant="" className="primaryBtn">{localStorage.getItem('wallet_address') != "" ? "Connect Wallet" : "Create Wallet"}</Button>

                      </Nav.Link>}


                  </>
                )}

                {/* <div className="bannerBtns d-lg-none">
                  <Button variant="" className="secondaryBtn mt-4 mb-2 w-100">
                    Contact us
                  </Button>
                  <Button variant="" className="primaryBtn w-100">
                    Join Now
                  </Button>
                </div> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* {walletModalOpen && (
                    <WalletConnectModal
                        handleClose={handleWalletModal}
                        modalOpen={walletModalOpen === true}
                        setModalOpen={setLoginModalOpen}
                        walletConnect={selectWalletConnect}
                        generateAccessToken={generateAccessToken}
                    />
                )} */}
        {/* <CreateWallet walletshow={walletModalOpen} walletFunc={selectWalletConnect} ></CreateWallet> */}
      </>
    );
  }
};

export default Header;
