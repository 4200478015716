import React, { useState } from "react";
import { Modal } from 'react-bootstrap'
import { removeSavedBankAcc } from "../../../../action/walletApiAction";
import { useToast } from "../../../../context/Toast/useToast.ts";
import CustomLoader from "../../../../common/customLoader";
function DeleteDialog(props) {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToast();
  const showFucn = () => {

    props.StatusFunc();
  };
  const handleDeleteSavedAcc = async (e) => {
   if(props?.id){
    setLoading(true)
    let data = {}
    data.id = props?.id
    const deleteRes = await removeSavedBankAcc(data)
    setLoading(false)
    if (deleteRes.Status === "Error") {
      return addToast({
        message: "Internal Server Error",
        type: "error",
      });
    } else {
      props.StatusFunc();
      addToast({
        message: "Saved Account Delete Successfully",
        type: "success",
      });
    }
  }
  }
  return (
    <>
        {loading && (
        <div>
          <CustomLoader />
        </div>
      )}
      <Modal show={props.show} centered className="kycstatusdialog " size="lg" centeredshow={props.show} >
        <div className="kycstatusBox position-relative pt-5">
          <button
            className="closeBtn btn position-absolute top-0 end-0"
            onClick={() => showFucn()}
            variant="secondary"
          >
            <img src="/images/svg/closeBtn.svg" alt="close" />
          </button>
          <img src="/images/svg/deletedialog.svg" alt="" width={150} />
          <h1 className="mt-4">Delete Saved Account</h1>
          <p>Are you sure you want to delete your saved account?</p>
          <div className="d-flex align-items-center justify-content-center gap-3 w-50 mx-auto">
            <button className="dangerBtn  mx-auto w-auto btn px-5" onClick={() => showFucn()}>Cancel</button>
            <button className="primaryBtnNew  mx-auto w-auto btn px-5" onClick={handleDeleteSavedAcc}>Delete</button>
          </div>

        </div>
      </Modal>

    </>
  )
}

export default DeleteDialog