import React, { useEffect, useState } from "react";
import WalletMenu from "../WalletMenu";
import { ButtonGroup, Col, Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { convertCurrency } from "../../../utils/convertCurrency"
import { getSavedBankAcc, getUserBalance, removeSavedBankAcc, withdrawAmount } from "../../../action/walletApiAction";
import { useToast } from "../../../context/Toast/useToast.ts";
import { Country } from 'country-state-city';
import CustomLoader from "../../../common/customLoader";
import DeleteDialog from "./DialogDelete";
function Index(props) {
  // const [depositMethos, setdepositMethos] = useState('Cards')
  const [convertedPrice, setConvertedPrice] = useState(0)
  const [ModalOpen, setModalOpen] = useState(false);

  const { addToast } = useToast();
  const [price, setPrice] = useState('')
  const [currency, setCurrency] = useState("USD")
  const [depositMethos, setdepositMethos] = useState('bankAccount')
  const [eventKey, setEventKey] = useState('USDC')
  const [usdcBalance, setUsdcbalance] = useState(0);
  const [maticBalance, setMaticbalance] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [country, setCountry] = useState([]);
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({})
  const [saveAccount, setSavedAccount] = useState([])
  const [proceed, setProceed] = useState(false)
  const [saveAccProceedButton, setSaveAccProceedButton] = useState(false)
  const [AccNoForTrigger, setAccFroNoTrigger] = useState('')
  const [idToDelete, setIdToDelete] = useState(0)
  const { page } = props;
  const [fields, setFields] = useState({
    user_id: localStorage.getItem('userId'),
    ifsc_code: "",
    bank_name: "",
    bank_branch: "",
    recipient_name: "",
    account_number: "",
    re_account_number: "",
    to_currency: "",
    country: "",
    withdraw_type: "fiat",
    admin_status: "",
    usdc_amount: "",
    matic_amount: "",
    wallet_address: "",
    user_wallet_address: localStorage.getItem('wallet_address'),
    chain: "matic",
    selected_crypto_currency: "",
    save_acc: false
  })

  const fetchCountires = async () => {
    const countryData = await Country.getAllCountries()
    setCountry(countryData)
  }
  useEffect(() => {
    fetchCountires()
  }, [Country])
  const handleCurrencyChange = (value) => {
    setCurrency(value)
  }
  const handleChangePrice = (value) => {
    value = value.replace(/^[-+]/, '');
    console.log("value", value)
    if (/^\d*$/.test(value)) {
      setPrice(value)
    }

  }
  const getCurrency = async (symbol = "USDC", currency = "USD",) => {
    const res = await convertCurrency(symbol, currency);
    if (price !== null) {
      for (const key in res) {
        setConvertedPrice(res[key] * Number(price))
      }
    }
  }

  useEffect(() => {
    getCurrency("USDC", currency)
  }, [price, currency]);

  const getWalletBalance = async () => {
    let body = {}
    body.wallet_address = localStorage.getItem("wallet_address")
    const balanceRes = await getUserBalance(body)
    setWalletBalance(balanceRes.data.balance)
    if (balanceRes.status === "Error") {
      addToast({
        message: balanceRes.message,
        type: "error",
      });
    }
    setUsdcbalance(balanceRes.data.balance.usdc_balance)
    setMaticbalance(balanceRes.data.balance.matic_balance)
  }
  useEffect(() => {
    getWalletBalance()
    const togglebtn = document.getElementById('dropdown-button-drop-end')
    togglebtn.classList.remove('btn-primary')
  }, [])

  const handleChange = (name, e) => {
    let value = e.target.value
    console.log("value", value)
    if (name === "account_number" || name === 're_account_number' || name === "ifsc_code") {
      const specialCharRegex = /[^a-zA-Z0-9]/
      if (specialCharRegex.test(value)) {
        return
      }
    }
    if (name === "matic_amount" || name === "usdc_amount") {
      value = value.replace(/^[-+]/, '');
      // console.log("value", value)
      // if (!(/^\d*$/.test(value))) {
      //   return
      // }

    }
    // console.log("value", value)
    setFields((p) => ({ ...p, [name]: value }));
  }

  const handleBankWithdrawl = async (e) => {
    e.preventDefault()

    if (fields?.withdraw_type === "fiat") {

      if (fields.selected_crypto_currency === "USDC" && (Number(usdcBalance) < Number(fields?.usdc_amount) || Number(fields?.usdc_amount) <= 0)) {
        return addToast({
          message: "Insufficient USDC Balance",
          type: "error",
        });
      }
      if (fields.selected_crypto_currency === "Matic" && (Number(maticBalance) < Number(fields?.matic_amount) || Number(fields?.matic_amount) <= 0)) {
        return addToast({
          message: "Insufficient Matic Balance",
          type: "error",
        });
      }
      if (fields.selected_crypto_currency === "") {

        return addToast({
          message: "From currency is required",
          type: "error",
        });
      }

      if (fields.selected_crypto_currency === "USDC" && fields?.usdc_amount === '') {
        return addToast({
          message: "Amount is required",
          type: "error",
        });
      }
      if (fields.selected_crypto_currency === "Matic" && fields?.matic_amount === '') {
        return addToast({
          message: "Amount is required",
          type: "error",
        });
      }
      if (fields.bank_name === "") {
        return addToast({
          message: "Bank name is required",
          type: "error",
        });
      }
      if (fields.recipient_name === "") {
        return addToast({
          message: "Recipient name is required",
          type: "error",
        });
      }
      if (fields.account_number === "") {
        return addToast({
          message: "Account number is required",
          type: "error",
        });
      }
      if (fields.re_account_number === "") {
        return addToast({
          message: "Re-Enter Account number is required",
          type: "error",
        });
      }
      if (fields.account_number !== fields.re_account_number) {
        return addToast({
          message: "Account Number and Re-Enter Account number is not same",
          type: "error",
        });
      }
      if (fields.ifsc_code === "") {
        return addToast({
          message: "IFSC code is required",
          type: "error",
        });
      }
      if (fields.country === "") {
        return addToast({
          message: "Country is required",
          type: "error",
        });
      }
    } else {
      if (fields.wallet_address === "") {
        return addToast({
          message: "Wallet Address is required",
          type: "error",
        });
      }
      if (fields.recipient_name === "") {
        return addToast({
          message: "Recipient name is required",
          type: "error",
        });
      }
      if (fields.selected_crypto_currency === "" && fields?.selected_crypto_currency === '') {
        return addToast({
          message: "From Currency is required",
          type: "error",
        });
      }
      if (fields.selected_crypto_currency === "USDC" && fields?.usdc_amount === '') {
        return addToast({
          message: "Please enter the amount",
          type: "error",
        });
      }
      if (fields.selected_crypto_currency === "Matic" && fields?.matic_amount === '') {
        return addToast({
          message: "Please enter the amount",
          type: "error",
        });
      }
      if (fields.matic_amount === "0" || fields?.usdc_amount === "0") {
        return addToast({
          message: "Amount can not be 0",
          type: "error",
        });
      }

      if (fields.selected_crypto_currency === "USDC" && (Number(usdcBalance) < Number(fields?.usdc_amount) || Number(fields?.usdc_amount) <= 0)) {
        return addToast({
          message: "Insufficient USDC Balance",
          type: "error",
        });
      }
      if (fields.selected_crypto_currency === "Matic" && (Number(maticBalance) < Number(fields?.matic_amount) || Number(fields?.matic_amount) <= 0)) {
        return addToast({
          message: "Insufficient Matic Balance",
          type: "error",
        });
      }
    }
    setLoading(true)

    const withdrawRes = await withdrawAmount(fields)
    setLoading(false)
    if (withdrawRes.Status !== "success") {
      return addToast({
        message: "Internal Server Error",
        type: "error",
      });
    } else {
      addToast({
        message: "Request Added successfully",
        type: "success",
      });
      setFields(({
        user_id: localStorage.getItem('userId'),
        ifsc_code: "",
        bank_name: "",
        bank_branch: "",
        recipient_name: "",
        account_number: "",
        to_currency: "",
        country: "",
        withdraw_type: fields?.withdraw_type,
        admin_status: "",
        usdc_amount: "",
        matic_amount: "",
        wallet_address: "",
        user_wallet_address: localStorage.getItem('wallet_address'),
        chain: "matic",
        selected_crypto_currency: "",
        re_account_number: "",
        save_acc: false
      }))
    }
    await getWalletBalance()
  }

  const handleWithdrawByWallet = () => {
    setdepositMethos('wallet')
    setFields((p) => ({
      ...p,
      ifsc_code: "",
      bank_name: "",
      bank_branch: "",
      recipient_name: "",
      account_number: "",
      to_currency: "",
      country: "",
      withdraw_type: "crypto",
      admin_status: "",
      usdc_amount: "",
      matic_amount: "",
      wallet_address: "",
      chain: "matic",
      selected_crypto_currency: ""
    })
    )

  }
  const handleWithdrawByBankAccount = () => {
    setdepositMethos('bankAccount')
    setSaveAccProceedButton(true)
    setFields((p) => ({
      ...p,
      ifsc_code: "",
      bank_name: "",
      bank_branch: "",
      recipient_name: "",
      account_number: "",
      to_currency: "",
      country: "",
      withdraw_type: "fiat",
      admin_status: "",
      usdc_amount: "",
      matic_amount: "",
      wallet_address: "",
      chain: "matic",
      selected_crypto_currency: ""
    })
    )
  }
  const [saveAcc, setSaveAcc] = useState(true)
  useEffect(() => {
    if (selectedAccount && AccNoForTrigger !== fields?.account_number) {
      setSaveAcc(true)
    }

  }, [fields])

  const handleSavedBankAcc = async () => {
    setSaveAccProceedButton(true)
    setSelectedAccount({})
    for (let key in selectedAccount) {
      if (key !== ("user_id" || "user_wallet_address" || "chain"))
        setFields((p) => ({ ...p, [key]: "" }))
    }
    setdepositMethos('Saved')
    setLoading(true)
    let data = {}
    data.user_id = parseInt(localStorage.getItem("userId"))
    const savedAccRes = await getSavedBankAcc(data)
    setLoading(false)
    if (savedAccRes.Status === "Error") {
      addToast({
        message: "Internal Server Error",
        type: "error",
      });
    } else {
      setSavedAccount(savedAccRes.data)
    }
  }

  const handleProceed = (e) => {
    e.preventDefault()
    setAccFroNoTrigger(selectedAccount?.account_number)
    setdepositMethos('bankAccount')
    if (selectedAccount) {
      setSaveAcc(false)
      for (let key in selectedAccount) {
        setFields((p) => ({ ...p, [key]: selectedAccount[key] }))
      }

    }

  }

  const deletefun = (e, id) => {

    setIdToDelete(id)

    setModalOpen(!ModalOpen)
  }
  useEffect(() => {
    if (!ModalOpen) {
      handleSavedBankAcc()
    }
  }, [ModalOpen])
  return (
    <>
      {loading && (
        <div>
          <CustomLoader />
        </div>
      )}
      <div className="walletMaineBox">
        <WalletMenu pagename={page} />
        <div className="walletRanderBox">
          <div className="walletDeposit">
            <Row>
              <Col lg={6}>
                <div className="depositLeft">
                  <h2>Current Balance</h2>

                  <div className="depositBallance">
                    <div className="Currncy">
                      <img
                        width={45}
                        height={45}
                        src={eventKey === "USDC" ? `/images/svg/usdcIcon.svg` : `/images/svg/maticIcon.svg`}
                        alt=""
                      />
                      <div className="w-100">
                        <div className="w-100 dropdownwallet">

                          <DropdownButton
                            as={ButtonGroup} className="btn"
                            key={'end'}
                            id={`dropdown-button-drop-end`}
                            drop={'end'}
                            varient={'link'}
                            title={eventKey}
                          >
                            <Dropdown.Item eventKey="USDC" onClick={() => setEventKey("USDC")}>USDC</Dropdown.Item>
                            <Dropdown.Item eventKey="Matic" onClick={() => setEventKey("Matic")}>Matic</Dropdown.Item>
                          </DropdownButton>
                        </div>
                        <p className="text-start">Polygon Mainnet </p>
                      </div>
                    </div>
                    <div className="balanceBx">
                      <img
                        width={24}
                        height={24}
                        src="/images/svg/walletIcon.svg"
                        alt="wallet"
                      />
                      <h3>{eventKey === "Matic" ? Number(maticBalance).toFixed(5) : Number(usdcBalance).toFixed(5)}</h3>
                    </div>
                  </div>
                  <div className="depositBallance" hidden>
                    <div className="Currncy">
                      <h2 className="d-flex align-items-center gap-2"> <img
                        width={24}
                        height={24}
                        src="/images/svg/walletIcon.svg"
                        alt="wallet"
                      /> Current Balance </h2>

                    </div>
                    <div className="balanceBx">
                      <img
                        width={30}
                        height={30}
                        src="/images/svg/usdcIcon.svg"
                        alt=""
                      />
                      <h3>{Number(walletBalance).toFixed(5)}</h3>
                    </div>
                  </div>
                  <div className="addMoneyinp">
                    <div className="addMoney">
                      <div className="addmoneyInpInn">
                        <input type="text" placeholder="Enter Amount" value={price} pattern="[0-9]*" onChange={(e) => handleChangePrice(e.target.value)} />
                        <img
                          width={35}
                          height={35}
                          src="/images/svg/usdcIcon.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    {/* <div className="exchnageArrow">
                      <img src="/images/svg/exchangeArrow.svg" alt="arrow" />
                    </div> */}
                    <div className="convertMoney">
                      <div className="selectcurrentMain">
                        <div className="currencySelect">
                          <div className="currenncyNameIcon">
                            <img
                              width={35}
                              height={35}
                              src={currency === 'USD' ? "/images/png/doller.png" : currency === 'INR' ? "/images/png/INR.png" : currency === 'AUD' ? "/images/png/AUD.png" : ''}
                              alt=""
                            />
                          </div>
                          <div className="currencyValue">{convertedPrice.toFixed(2)}</div>
                          <button className="btn">
                            <i class="fa-solid fa-chevron-down"></i>
                          </button>
                        </div>
                        <div className="selectcurreny customecheckBox">
                          <ul>
                            <li>
                              <input type="radio" id='usd' checked={currency === 'USD' ? true : false} onClick={(e) => handleCurrencyChange("USD")} />
                              <label htmlFor='usd'> <img
                                width={18}
                                height={18}
                                src="/images/png/doller.png"
                                alt=""
                              /> USD</label>

                            </li>
                            <li>
                              <input type="radio" id='inr' checked={currency === 'INR' ? true : false} onClick={(e) => handleCurrencyChange("INR")} />
                              <label htmlFor='inr'> <img
                                width={18}
                                height={18}
                                src="/images/png/INR.png"
                                alt=""
                              /> INR</label>
                            </li>
                            <li>
                              <input type="radio" id='aud' checked={currency === 'AUD' ? true : false} onClick={(e) => handleCurrencyChange("AUD")} />
                              <label htmlFor='aud'> <img
                                width={18}
                                height={18}
                                src="/images/png/AUD.png"
                                alt=""
                              /> AUD</label>
                            </li>


                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="depositoptions">
                    <ul>
                      <li className={depositMethos === 'wallet' ? 'active' : ''} onClick={() => handleWithdrawByWallet()}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.5 13.75C15.5 13.3358 15.8358 13 16.25 13H18.25C18.6642 13 19 13.3358 19 13.75C19 14.1642 18.6642 14.5 18.25 14.5H16.25C15.8358 14.5 15.5 14.1642 15.5 13.75ZM3 5H3.01373C3.13809 3.87501 4.09186 3 5.25 3H16.75C17.9926 3 19 4.00736 19 5.25V6.08697C20.4333 6.42555 21.5 7.71321 21.5 9.25V17.75C21.5 19.5449 20.0449 21 18.25 21H6.25C4.45508 21 3 19.5449 3 17.75V5ZM18.25 7.5H4.5V17.75C4.5 18.7165 5.2835 19.5 6.25 19.5H18.25C19.2165 19.5 20 18.7165 20 17.75V9.25C20 8.2835 19.2165 7.5 18.25 7.5ZM17.5 6V5.25C17.5 4.83579 17.1642 4.5 16.75 4.5H5.25C4.83579 4.5 4.5 4.83579 4.5 5.25C4.5 5.66421 4.83579 6 5.25 6H17.5Z"
                            fill="#212121"
                          />
                        </svg>
                        <h5 >Withdraw by wallet</h5>
                      </li>
                      <li className={depositMethos === 'bankAccount' ? 'active' : ''} onClick={() => handleWithdrawByBankAccount()}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.25 4C3.45507 4 2 5.45507 2 7.25V16.75C2 18.5449 3.45507 20 5.25 20H18.75C20.5449 20 22 18.5449 22 16.75V7.25C22 5.45507 20.5449 4 18.75 4H5.25ZM3.5 7.25C3.5 6.2835 4.2835 5.5 5.25 5.5H18.75C19.7165 5.5 20.5 6.2835 20.5 7.25V16.75C20.5 17.7165 19.7165 18.5 18.75 18.5H5.25C4.2835 18.5 3.5 17.7165 3.5 16.75V7.25ZM5 7.75C5 7.33579 5.33579 7 5.75 7H11.25C11.6642 7 12 7.33579 12 7.75C12 8.16421 11.6642 8.5 11.25 8.5H5.75C5.33579 8.5 5 8.16421 5 7.75ZM6 13C5.44772 13 5 13.4477 5 14V16C5 16.5523 5.44772 17 6 17H13C13.5523 17 14 16.5523 14 16V14C14 13.4477 13.5523 13 13 13H6ZM5 10.25C5 9.83579 5.33579 9.5 5.75 9.5H16.25C16.6642 9.5 17 9.83579 17 10.25C17 10.6642 16.6642 11 16.25 11H5.75C5.33579 11 5 10.6642 5 10.25Z" fill="#212121" />
                        </svg>

                        <h5 >Bank Account</h5>
                      </li>
                      <li className={depositMethos === 'Saved' ? 'active' : ''} onClick={() => { handleSavedBankAcc() }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 5.75C3 4.23122 4.23122 3 5.75 3H18.25C19.7688 3 21 4.23122 21 5.75V10.3542C20.5925 9.89247 20.08 9.52556 19.5 9.29076V5.75C19.5 5.05964 18.9404 4.5 18.25 4.5H5.75C5.05964 4.5 4.5 5.05964 4.5 5.75V18.25C4.5 18.9404 5.05964 19.5 5.75 19.5H12.0036C12.0263 20.0358 12.1547 20.5386 12.3714 21H5.75C4.23122 21 3 19.7688 3 18.25V5.75ZM18 14.5C17.1716 14.5 16.5 13.8284 16.5 13C16.5 12.1716 17.1716 11.5 18 11.5V12H20.8293C20.6976 11.6274 20.4944 11.2885 20.2361 11H18V10C16.3431 10 15 11.3431 15 13C15 14.6569 16.3431 16 18 16C18.8885 16 19.6868 15.6137 20.2361 15H18V14.5ZM14.9375 17C14.3563 17 13.8688 17.2822 13.5423 17.6347C13.2184 17.9845 13 18.4582 13 18.944V19.328C13 21.2893 15.0414 23 18 23V22H21.4726C21.881 21.7117 22.2176 21.373 22.4698 21H18V21.5C15.4707 21.5 14.5 20.1114 14.5 19.328V18.944C14.5 18.8976 14.5307 18.7751 14.6429 18.654C14.7497 18.5386 14.8601 18.5 14.9375 18.5H18V18H22.7296C22.6519 17.8682 22.5601 17.7453 22.4577 17.6347C22.1312 17.2822 21.6437 17 21.0625 17H14.9375ZM18 14V13H21C21 13.3506 20.9398 13.6872 20.8293 14H18ZM22.9189 20H18V19H23V19.328C23 19.5561 22.9724 19.7809 22.9189 20Z" fill="#212121" />
                        </svg>

                        <h5>Save Accounts</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                {depositMethos === 'wallet' && <div className="depositBoxMethod">
                  <div className="d-flex align-items-center selectcard">
                    <button className="active">Withdraw by wallet</button>

                  </div>
                  <Form className="cardForm">
                    <Row>
                      <Col lg={12}>
                        <div className="walletIDbox">
                          <Form.Group className="mb-3" >
                            <Form.Label>Wallet Address</Form.Label>
                            <Form.Control className=" bg-white" value={fields?.wallet_address} onChange={(e) => { handleChange("wallet_address", e) }} type="text" placeholder="Enter wallet address" />
                          </Form.Group>
                          <Form.Group className="mb-3" >
                            <Form.Label>Recipient’s Name</Form.Label>
                            <Form.Control type="text" value={fields.recipient_name} onChange={(e) => handleChange("recipient_name", e)} placeholder="Enter recipient name" />
                          </Form.Group>
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3" >
                                <Form.Label>Which Currency To Withdraw</Form.Label>
                                <select className="form-control form-select" value={fields.selected_crypto_currency} onChange={(e) => { handleChange("selected_crypto_currency", e); setFields((p) => ({ ...p, usdc_amount: "", matic_amount: "", to_currency: e.target.value })) }}>
                                  <option value="" disabled selected>Select Currency</option>
                                  <>
                                    <option value={"USDC"}>USDC</option>
                                    <option value={"Matic"}>Matic</option>
                                  </>
                                </select>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3" >
                                <Form.Label>To Currency</Form.Label>
                                <Form.Control type="text" disabled value={fields?.selected_crypto_currency} placeholder="Currency" />
                              </Form.Group>
                            </Col>

                          </Row>


                          <Form.Group className="mb-3" >
                            <Form.Label>Amount</Form.Label>
                            <Form.Control type="number" onChange={(e) => handleChange(fields?.selected_crypto_currency === "USDC" ? "usdc_amount" : "matic_amount", e)} pattern="[0-9]*" placeholder="Enter amount" value={fields?.selected_crypto_currency === "USDC" ? fields?.usdc_amount : fields?.matic_amount} />
                          </Form.Group>

                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="walletnoteBox">
                          <div className="notebxMaine">
                            <h4>Note on Adding/Withdraw Funds to Your Wallet</h4>
                            <ul>
                              <li>Network: Ensure you are using the Polygon network.</li>
                              <li>Deposit Address: Double-check the address before sending:</li>
                              <li>Minimum Deposit: The minimum deposit is 0.00000001 USDC.</li>
                              <li>Contract Information: Contract address ends with ***xxxxx.</li>
                            </ul>
                          </div>
                          {/* <div className="barcodeBox">
                            <img src={'/images/svg/QRCode.svg'} className="img-fluid" alt="" />
                          </div> */}
                        </div>
                        <div className="walletnoteBox">
                          <div className="notebxMaine">
                            <h4>Important Notes:</h4>
                            <ul>
                              <li>Do not send NFTs to this address.</li>
                              <li>Please confirm before sending that you are using the MATIC token on the MATIC chain.</li>
                              <li>If you use a different chain, we are not responsible for any loss of funds.</li>
                            </ul>
                          </div>
                        </div>
                      </Col>


                      <Col className="mx-auto mt-4" lg={8}>

                        <button className="btn secondaryBtn  btn-primary w-100" onClick={(e) => handleBankWithdrawl(e)}>Proceed</button>

                      </Col>
                    </Row>
                  </Form>
                </div>}
                {depositMethos === 'bankAccount' && <div className="depositBoxMethod">
                  <div className="d-flex align-items-center selectcard">
                    <button className="active">Bank Account</button>

                  </div>
                  <Form className="cardForm">
                    <Row>
                      <Col lg={12}>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className="mb-3" >
                              <Form.Label>From Currency</Form.Label>
                              <select value={fields?.selected_crypto_currency} className="form-control form-select" onChange={(e) => { handleChange("selected_crypto_currency", e); setFields((p) => ({ ...p, usdc_amount: "", matic_amount: "" })) }}>
                                <option value='' disabled selected>Select Currency</option>
                                <>
                                  <option value={"USDC"}>USDC</option>
                                  <option value={"Matic"}>MATIC</option>
                                </>
                              </select>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3" >
                              <Form.Label>To Currency</Form.Label>
                              <select value={fields?.to_currency} className="form-control form-select" onChange={(e) => { handleChange("to_currency", e); setFields((p) => ({ ...p, usdc_amount: "", matic_amount: "" })) }}>
                                <option value='' disabled selected>Select Currency</option>
                                <>
                                  <option value={"INR"}>INR</option>
                                  <option value={"USD"}>USD</option>
                                  <option value={"AUD"}>AUD</option>
                                </>
                              </select>
                            </Form.Group>
                          </Col>

                        </Row>



                        <Form.Group className="mb-3" >
                          <Form.Label>Amount</Form.Label>
                          <Form.Control type="number" onChange={(e) => handleChange(fields?.selected_crypto_currency === "USDC" ? "usdc_amount" : "matic_amount", e)} placeholder="Enter amount " value={fields?.selected_crypto_currency === "USDC" ? fields?.usdc_amount : fields?.matic_amount} />
                        </Form.Group>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className="mb-3" >
                              <Form.Label>Bank's Name</Form.Label>
                              <Form.Control type="text" value={fields?.bank_name} onChange={(e) => handleChange("bank_name", e)} placeholder="Enter bank name" />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                              <Form.Label>Branch Name</Form.Label>
                              <Form.Control type="text" value={fields?.bank_branch} onChange={(e) => handleChange("bank_branch", e)} placeholder="Enter branch name" />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3" >
                              <Form.Label aria-autocomplete="off">Recipient’s Name</Form.Label>
                              <Form.Control type="text" value={fields?.recipient_name} onChange={(e) => handleChange("recipient_name", e)} placeholder="Enter recipient name" />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Form.Group className="mb-3" >
                          <Form.Label autoSave="off">Account Number</Form.Label>
                          <div className="position-relative">
                            <Form.Control type={showPassword == true ? "text" : "password"} autoComplete="off" value={fields?.account_number} onChange={(e) => handleChange("account_number", e)} placeholder="Enter account number" />
                          </div>
                        </Form.Group>
                        {/* <Form.Group className="mb-3" > */}
                        {/* <Form.Label>Re-Enter Account Number</Form.Label> */}
                        <div className="position-relative mb-3">
                          <Form.Control type="text" value={fields?.re_account_number} onChange={(e) => handleChange("re_account_number", e)} placeholder="Re-Enter account number" />
                        </div>
                        {/* </Form.Group> */}

                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" >
                          <Form.Label>Bank Code</Form.Label>
                          <Form.Control type="text" value={fields?.ifsc_code} onChange={(e) => handleChange("ifsc_code", e)} placeholder="Enter IFSC Code" />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="mb-3" >
                          <Form.Label>Bank Country/Region</Form.Label>
                          <select value={fields?.country} className="form-control form-select" onChange={(e) => handleChange("country", e)}>
                            <option value='' disabled selected>Select Country</option>
                            {country.length > 0 && country.map((item, i) =>
                              <>
                                <option value={item?.name}>{item?.name}</option>
                              </>
                            )}
                          </select>
                        </Form.Group>
                      </Col>
                      {/* <Col lg={12}>
                        <p className="text-start">Estimated fee is 5%</p>
                      </Col> */}

                      <Col className="mx-auto mt-2" lg={8}>
                        {saveAcc === true &&
                          <Form.Check // prettier-ignore
                            className="checkwallet"
                            type="checkbox"
                            id={`default-$checkbox`}
                            label={`Save this account`}
                            onClick={(e) => setFields((p) => ({ ...p, save_acc: e.target.checked }))}
                            checked={fields?.save_acc}
                          />}
                        <button className="btn secondaryBtn  btn-primary w-100" onClick={(e) => handleBankWithdrawl(e)}>Proceed</button>

                      </Col>

                    </Row>
                  </Form>
                </div>}
                {depositMethos === 'Saved' && <div className="depositBoxMethod">
                  <div className="d-flex align-items-center selectcard">
                    <button className="active">Save Accounts</button>

                  </div>
                  {/* <Form className="cardForm">
                    <Row>
                      {saveAccount.length>0 && saveAccount.map((item, i)=>
                      <Col lg={12}>
                        <div className="savedAccount">
                          <ul>
                            
                            <li>
                              <div>
                                <h2>{item?.recipient_name}</h2>
                                <div className="cardDetails">
                                  <h3>Account Number - {item?.account_number}</h3>
                                  <h3>IFSC Code - {item?.ifsc_code}</h3>
                                </div>
                              </div>
                              <Form.Check

                                type={'radio'}
                                id={`selectCard${i}`}
                              />
                            </li>
                          </ul>
                        </div>
                      </Col>)}

                      <Col className="mx-auto mt-4" lg={8}>

                        <button className="btn secondaryBtn  btn-primary w-100">Proceed</button>

                      </Col>
                    </Row>
                  </Form> */}
                  <Form className="cardForm">
                    <Row >
                      {saveAccount.length > 0 &&
                        saveAccount.map((item, i) => (
                          <Col lg={12} key={i} onClick={() => { setSelectedAccount(item); setSaveAccProceedButton(false) }}>
                            <div className="savedAccount mb-3">
                              <ul>
                                <li>
                                  <div>
                                    <h2>{item?.recipient_name}</h2>
                                    <div className="cardDetails">
                                      <h3>Account Number - {item?.account_number}</h3>
                                      <h3>IFSC Code - {item?.ifsc_code}</h3>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center gap-3 ">
                                    <Form.Check className="mt-0"
                                      type={'radio'}
                                      id={`selectCard${i}`}
                                      name="selectedCard" // Assign the same name to all radio buttons
                                    />
                                    <button type="button" onClick={(e) => deletefun(e, item.id)} className="btn p-0 deleteiconw"><i class="fa-solid fa-trash"></i></button>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Col>
                        ))}
                      <Col className="mx-auto mt-4" lg={8}>
                        {saveAccount.length > 0 ?
                          <button className="btn secondaryBtn btn-primary w-100" disabled={saveAccProceedButton} onClick={(e) => handleProceed(e)}>Proceed</button>
                          : <h3>No Saved Accounts Found</h3>
                        }
                      </Col>
                    </Row>
                  </Form>
                </div>}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <DeleteDialog show={ModalOpen} StatusFunc={deletefun} id={idToDelete} ></DeleteDialog>
    </>
  );
}

export default Index;
