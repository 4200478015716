import React,{useEffect} from 'react';
import { Container, Row, Col, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector} from 'react-redux';

const Successfully = ({ history }) => {

	//const [msg, setMsg] = useState({});

	const successData = useSelector((state) => state.home.successData);
	
	/* Api Calling */
	useEffect(() => {
		if(successData && successData.head){
			//setMsg(history.location.state);
		}else{
			window.location.href = '/login';
		}
	},[successData]);

	return (
		<Container>
			
			<div className="verifyseccessSec burger">
				<Row>
					<Col lg={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
						<Form className="boxShaddow" style={{backgroundColor:"#FFF"}}>
						
							{successData &&
								<div className="heading">
									{successData.head && successData.success === true &&
										<div className="imbBox">
											<img src="assets/img/check.png" className="img-fluid" alt=""/>
										</div>
									}
									
									<h4>{successData.head}</h4>
									<p>{successData.desc}</p>
									
								</div>	
							}						
							{successData &&
								<div className="">
									{successData.step && successData.step === 'otpVerified' &&
										<Link to="/login" className="secondaryBtn" variant="">Login</Link>
									}
									{successData.step && (successData.step === 'companyCreated' || successData.step === 'contractSend' || successData.step === 'contractSign') &&
										<Link to="/companyinfo" className="secondaryBtn" variant="">Edit Company Info</Link>
									}
								</div> 
							}						
						</Form>
					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default Successfully;
