import BurnOrderList from "../../common/BurnListItem";
import Loader from "../../common/loader";
import WithdrawList from "../../common/WithdrawList";
import NoRecordsFound from "../NoRecordFound";
import styles from "./style.module.scss";
const WithdrawOrders = ({ data }) => {
  console.log("data", data);

  return (
    <>
      <div className={styles.ResellBondsSectionAlignment}>
        <div className={styles.pageTitle}>
          <h1>Withdraw Certificates</h1>
        </div>
        <div className={styles.allCardAlignmentForScreen}>
          {data === undefined ? (
            <Loader />
          ) : data && data?.withdraw_count > 0 ? (
            <WithdrawList data={data} />
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </>
  );
};
export default WithdrawOrders;
