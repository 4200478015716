import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import {
  resellBlockChainMetaData,
  getMyCollection,
  createBurnOrder,
  createResell,
  saveTransactionHash,
  createBurn,
} from "../../action/Api.action";
import Loader from "../../common/loader";
import PaymentModal from "../../common/modal";
import { useLoader } from "../../context/Loader/useLoader";
import { useToast } from "../../context/Toast/useToast";
import {
  createBurnTransaction,
  createResellTransaction,
  walletConnectSessionTimeout,
} from "../walletConnect";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginCheck, setProfile } from "../../slices/auth";

const DeListCTNFT = () => {
  const [myCertificate, setMyCertificate] = useState([]);
  const [selected, setSelected] = useState([]);
  const [singleSelect, setSingleSelect] = useState([]);
  const { setLoading } = useLoader();
  const { addToast } = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [singleModalOpen, setSingleModalOpen] = useState(false);
  const fetchMyCollection = async () => {
    const res = await getMyCollection();
    if (res.status) {
      setMyCertificate(res);
    }
  };
  useEffect(() => {
    fetchMyCollection();
  }, []);

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };
  const handleSingleModalOpen = () => {
    setSingleModalOpen(!singleModalOpen);
  };
  const handleSubmitModal = async (num) => {
    let certificate = [];
    const timeoutRef = setTimeout(async () => {
      setLoading(false);
      const result = await walletConnectSessionTimeout();
      dispatch(setProfile({}));
      dispatch(setLoginCheck(false));
      history.push(`/`);
      addToast({
        message:
          "Intermittent connection, request time out! Please re-connect the wallet",
        type: "error",
        duration: 3000,
      });
    }, 60 * 1000);

    const getData = num === 0 ? selected : singleSelect;
    getData.forEach((data) => certificate.push(data?.certificate_id));
    let body = {
      certificates: certificate,
    };
    const res = await resellBlockChainMetaData(body);
    setModalOpen(false);
    setSingleModalOpen(false);
    setLoading(
      true,
      "Please accept the transaction on your Phaeton Wallet. Once accepted please wait for 30 seconds for Resell Order to reflect under your profile."
    );

    if (res.status) {
      const resp = await createResellTransaction(res?.data);

      clearTimeout(timeoutRef);
      if (resp.status) {
        setLoading(true, "Creating Resell Request");
        addToast({
          message: resp?.message,
          type: "success",
          duration: 3000,
        });

        const response = await createResell(body);
        if (response.status) {
          addToast({
            message:
              "Certificate Reselled Successfull and will be displayed in Resell Section in 30 secs",
            type: "success",
            duration: 3000,
          });
          // addToast({
          //   message: response?.message,
          //   type: "success",
          //   duration: 3000,
          // });
          let infos = {
            txn_type: "relist",
            object_id: response?.object_id,
            transaction: resp?.transactionHash,
          };
          const result = await saveTransactionHash(infos);
          setLoading(true, "Saving Transaction Hash");
          if (result.status) {
            history.push("/resell");
            addToast({
              message: result?.message,
              type: "success",
              duration: 3000,
            });
            setLoading(false);
          }
        } else {
          addToast({
            message: response?.message,
            type: "error",
            duration: 3000,
          });
          setLoading(false);
        }
      } else {
        addToast({
          message: resp?.message,
          type: "error",
          duration: 3000,
        });
        setLoading(false);
      }
    } else {
      addToast({
        message: res?.message,
        type: "error",
        duration: 3000,
      });
      setLoading(false);
    }
  };
  const handleBurnModal = async (num) => {
    let certificate = [];
    selected.forEach((data) => certificate.push(data?.certificate_id));
    let body = {
      certificates: certificate,
    };

    const timeoutRef = setTimeout(async () => {
      setLoading(false);
      const result = await walletConnectSessionTimeout();
      dispatch(setProfile({}));
      dispatch(setLoginCheck(false));
      history.push(`/`);
      addToast({
        message:
          "Intermittent connection, request time out! Please re-connect the wallet",
        type: "error",
        duration: 3000,
      });
    }, 60 * 1000);

    const res = await createBurnOrder(body);
    if (res.status) {
      setLoading(true, "Accept Trasaction in your Phaeton Wallet");
      const resp = await createBurnTransaction(res.data);
      clearTimeout(timeoutRef);

      if (resp.status) {
        setLoading(true, "Creating Burn Order Request");
        addToast({
          message: "Burn Order Succesfully Created",
          type: "success",
          duration: 3000,
        });

        const response = await createBurn(body);
        if (response.status) {
          addToast({
            message: response?.message,
            type: "success",
            duration: 3000,
          });
          let infos = {
            txn_type: "burn_order",
            object_id: response?.object_id,
            transaction: resp?.transactionHash,
          };
          const result = await saveTransactionHash(infos);

          setLoading(true, "Saving Transaction Hash");
          if (result.status) {
            history.push("/burn-orders");
            addToast({
              message: result?.message,
              type: "success",
              duration: 3000,
            });
            setLoading(false);
          }
        } else {
          addToast({
            message: "Something went wrong",
            type: "error",
            duration: 3000,
          });
          setLoading(false);
        }
      } else {
        addToast({
          message: resp.message ? resp.message : "Something went wrong",
          type: "error",
          duration: 3000,
        });
        setLoading(false);
      }
    }
  };
  const handleSelect = (info) => {
    // console.log("info", info);
    if (
      selected.filter((item) => item?.certificate_id == info.certificate_id)
        .length > 0
    ) {
      let filterData = selected.filter(
        (item) => item?.certificate_id !== info.certificate_id
      );
      setSelected(filterData);
    } else {
      setSelected((props) => [...props, { ...info }]);
    }
  };
  const singleCertificate = (data) => {
    // setSelected([]);
    setSingleSelect([data]);
    handleSingleModalOpen();
  };
  return (
    <>
      <Card className="border-rounded-2 mb-5">
        <h3 className="m-5 mb-1">Delist Certificate</h3>

        <div className="divider " />
        {myCertificate && (
          <>
            <Row className="mx-5">
              {/* <Col md={3}>
                <div>Total Number of Certificates :</div>
                <Form>
                  <Form.Group controlId="formBasicQtyRequir">
                    <Form.Control
                      type="text"
                      placeholder="Certificate ACCU Number"
                      defaultValue={myCertificate?.certificate_counts}
                      disabled
                    />
                  </Form.Group>
                </Form>
              </Col> */}
              <Col md={5}>
                <div>Number of Certificates Checked :</div>
                <Form>
                  <Form.Group controlId="formBasicQtyRequir">
                    <Form.Control
                      type="text"
                      placeholder="Certificate ACCU Number"
                      value={selected.length}
                      disabled
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={2}>
                <div className="moreInfoBtns mx-3 mt-4">
                  <Button
                    className="secondaryBtn submitBtn"
                    disabled={selected.length < 1}
                    onClick={handleModalOpen}>
                    ReList
                  </Button>
                </div>
              </Col>
              <Col md={3}>
                <div className="moreInfoBtns mx-3 mt-4">
                  <Button
                    className="secondaryBtn submitBtn"
                    disabled={selected.length < 1}
                    onClick={handleBurnModal}>
                    Burn Token
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
        <div className="divider " />
        {myCertificate && myCertificate?.result?.length > 0 ? (
          myCertificate?.result.map((data, index) => {
            return (
              <div key={index}>
                <Card className="my-3 mx-5 border-rounded-1 p-3 shadow bg-body ">
                  <Row>
                    <Col md={3} className="m-2">
                      ACCU Number :
                    </Col>
                    <Col md={2} className="m-2">
                      Sale Price :
                    </Col>
                    <Col md={3} className="m-2">
                      Country of Origin :
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} className="m-2">
                      <div className="documentType ">
                        <Form>
                          <Form.Group controlId="formBasicQtyRequir">
                            <Form.Control
                              type="text"
                              placeholder="Certificate ACCU Number"
                              defaultValue={data?.accu}
                              disabled
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </Col>
                    <Col md={2} className="m-2">
                      <div className="documentType ">
                        <Form>
                          <Form.Group controlId="formBasicQtyRequir">
                            <Form.Control
                              type="text"
                              placeholder="Bought Price"
                              defaultValue={data?.sale_price}
                              disabled
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </Col>
                    <Col md={2} className="m-2">
                      <div className="documentType ">
                        <Form>
                          <Form.Group controlId="formBasicQtyRequir">
                            <Form.Control
                              type="text"
                              placeholder="Country Of Origin"
                              defaultValue={data?.country}
                              disabled
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </Col>
                    <Col md={4} className="m-2 display-flex row-reverse">
                      <Form className="m-2">
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check
                            type="checkbox"
                            label="Select"
                            onClick={() => handleSelect(data)}
                          />
                        </Form.Group>
                      </Form>
                      <div className="moreInfoBtns mx-3">
                        <Button
                          className="secondaryBtn submitBtn"
                          onClick={() => singleCertificate(data)}>
                          ReList
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            );
          })
        ) : (
          <>
            <Loader />
          </>
        )}
      </Card>
      {modalOpen && (
        <PaymentModal
          modalOpen={modalOpen}
          BodyMessage={`Do you wish to Re-Sell ${
            selected.length == 0 ? "" : selected.length
          } certificates?`}
          ButtonMessage="Re-Sell Certificate"
          handleClose={handleModalOpen}
          handleSubmit={() => handleSubmitModal(0)}
        />
      )}
      {singleModalOpen && (
        <PaymentModal
          modalOpen={singleModalOpen}
          BodyMessage={`Do you wish to Re-Sell this Single certificate?`}
          ButtonMessage="Re-Sell Certificate"
          handleClose={handleSingleModalOpen}
          handleSubmit={() => handleSubmitModal(1)}
        />
      )}
    </>
  );
};
export default DeListCTNFT;
