import { Container,Row,Col,Button} from 'react-bootstrap';
import './styles.css'
import {Link} from 'react-router-dom'
const Explore = ({ data }) => {
    const images = ["/images/png/RegisterHow.png","/images/png/VerificationHow.png"]
    return (
        <>
        <div className="exploreSec py-5 px-5">
            
               <div className='px-5 pt-5'  >
                <Row>
               
                <Col className='featureText'>
                <div className='mx-5 px-5'>
                <p className='aboutHead text-center'>Explore</p>

                <h1 className='centerTitle'><b>Learning Resources</b></h1>
                <p className='centerTitle'>Delving into the Dynamic World of Carbon Credits</p>
                </div>
                
               
                </Col>
              
               
                </Row>
               <Row  className='mt-5'>
                <Col className="exploreVideoBoxLg mx-4 p-4">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZB3Me-nWL1c" title="Carbon Credit Exchange White Paper" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Col>
                <Col>
                <Row className="exploreVideoBoxSm mb-4 "> 
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZB3Me-nWL1c" title="Carbon Credit Exchange White Paper" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Row>
                <Row className="exploreVideoBoxSm ">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ZB3Me-nWL1c" title="Carbon Credit Exchange White Paper" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Row>
                
                </Col>
               </Row>
                </div>
        </div>
       </>
    );
};

export default Explore;
