import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import BannerSec from "../component/HomeNew/Banner";
import AboutSec from "../component/HomeNew/About";
import FeatureSec from "../component/HomeNew/Features";
import Howitworks from "../component/HomeNew/Howitworks";
import Explore from "../component/HomeNew/Explore";
import Footer from "../component/HomeNew/Footer";

import { useDispatch } from "react-redux";
import { setFooter } from "../slices/home";
import Aboutbanner from "../component/Aboutus/Aboutbanner";
import Whoweserve from "../component/Aboutus/Whoweserve";
import OurMission from "../component/About/OurMission";
import Whyphaeton from "../component/Aboutus/Whyphaeton";

const Aboutus = ({ history }) => {


 return (
  <>
  <Aboutbanner/>
  <OurMission/>
  <Whyphaeton/>
  <Whoweserve/>
 <Footer/> 
     
  </>
  );
};

export default Aboutus;
