import React,{useEffect} from 'react';
import { Container, Row, Col, Carousel, Image, Nav, Form, Button, Card } from 'react-bootstrap';

import Header from '../component/Header';
import Footer from '../component/Footer';

const Newmedia = ({ history }) => {


	/* Api Calling */
	useEffect(() => {},[]);

	return (
		<Container>
			
			<div className="homeBanner">
				<Row>
					<Carousel>
						<Carousel.Item>
						    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="First slide" />
						    <Carousel.Caption>
						      <h2>Access the World's <br /> Carbon Credit Markets</h2>
						      <p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
						      <div className="bannerBtns">
							      <Button variant="" className="secondaryBtn">Contact us</Button>
							      <Button variant="" className="primaryBtn">Join Now</Button>
						    	</div>
						    </Carousel.Caption>
						</Carousel.Item>

						<Carousel.Item>
						    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="Second slide" />
						    <Carousel.Caption>
					    		<h2>Access the World's <br /> Carbon Credit Markets</h2>
						    	<p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
						    	<div className="bannerBtns">
							    	<Button variant="" className="secondaryBtn">Contact us</Button>
							    	<Button variant="" className="primaryBtn">Join Now</Button>
						    	</div>
						    </Carousel.Caption>
						</Carousel.Item>
						
						<Carousel.Item>
						    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="Third slide" />
						    <Carousel.Caption>
						    	<h2>Access the World's <br /> Carbon Credit Markets</h2>
						    	<p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
						    	<div className="bannerBtns">
							    	<Button variant="" className="secondaryBtn">Contact us</Button>
							    	<Button variant="" className="primaryBtn">Join Now</Button>
						    	</div>
						    </Carousel.Caption>
						</Carousel.Item>
					</Carousel>
				</Row>
			</div>

			<div className="latestNewsSec burger">
				<Row>
					<Col xs={12}>
						<h5 className="miniHeading">News</h5>
						<h1 className="bigHeading">Latest News</h1>
					</Col>
					
					<Col md={6} lg={4} xl={3}>
						<div className="newsContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="newsDetails">
								<div className="postDate">
									<h3>01</h3>
									<p>Aug <br /> 2021</p>
								</div>
								<div>
									<p>Tomas Nikelson</p>
									<h4>Carbon Credit Event <br /> Helping Organisation</h4>
									<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6} lg={4} xl={3}>
						<div className="newsContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="newsDetails">
								<div className="postDate">
									<h3>01</h3>
									<p>Aug <br /> 2021</p>
								</div>
								<div>
									<p>George Lee</p>
									<h4>Carbon Credit Helps <br /> Green Energy Producer</h4>
									<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6} lg={4} xl={3}>
						<div className="newsContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="newsDetails">
								<div className="postDate">
									<h3>01</h3>
									<p>Aug <br /> 2021</p>
								</div>
								<div>
									<p>George Lee</p>
									<h4>Carbon Credit Simplify <br /> Life of Organisations</h4>
									<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6} lg={4} xl={3}>
						<div className="newsContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="newsDetails">
								<div className="postDate">
									<h3>01</h3>
									<p>Aug <br /> 2021</p>
								</div>
								<div>
									<p>Tomas Nikelson</p>
									<h4>Simplest Carbon <br /> Trading Platform</h4>
									<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6} lg={4} xl={3}>
						<div className="newsContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="newsDetails">
								<div className="postDate">
									<h3>01</h3>
									<p>Aug <br /> 2021</p>
								</div>
								<div>
									<p>George Lee</p>
									<h4>Carbon Credit Simplify <br /> Life of Organisations</h4>
									<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6} lg={4} xl={3}>
						<div className="newsContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="newsDetails">
								<div className="postDate">
									<h3>01</h3>
									<p>Aug <br /> 2021</p>
								</div>
								<div>
									<p>George Lee</p>
									<h4>Carbon Credit Helps <br /> Green Energy Producer</h4>
									<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6} lg={4} xl={3}>
						<div className="newsContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="newsDetails">
								<div className="postDate">
									<h3>01</h3>
									<p>Aug <br /> 2021</p>
								</div>
								<div>
									<p>Tomas Nikelson</p>
									<h4>Carbon Credit Event <br /> Helping Organisation</h4>
									<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6} lg={4} xl={3}>
						<div className="newsContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="newsDetails">
								<div className="postDate">
									<h3>01</h3>
									<p>Aug <br /> 2021</p>
								</div>
								<p>Tomas Nikelson</p>
								<h4>Simplest Carbon <br /> Trading Platform</h4>
								<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
							</div>
						</div>
					</Col>
				</Row>
			</div>

			<div className="latestCoverageSec burger">
				<Row>
					<Col xs={12}>
						<h5 className="miniHeading">Media</h5>
						<h1 className="bigHeading">Latest Media Coverage</h1>
					</Col>
					<Col lg={6}>
						<div className="coverageContent">
							<img src="assets/img/mediaNew1.png" className="img-fluid" />
							<div className="coverageDetails">
								<div className="newsType">
									<div>
										<h6>Fox11 <br /> <span>NEWS</span></h6>
									</div>
									<p>22 Jun 2021 <br /> By George Lee</p>
								</div>
								<h6>The Future of Carbon Credit -- Meeting the ESG</h6>
								<p className="discpText">There are many variations of passages Lorem available <br /> but the majority have.</p>
							</div>
						</div>
					</Col>
					<Col sm={6} lg={3}>
						<div className="coverageContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="coverageDetails">
								<p>AUS Government urged to <br /> include aviation and shipping</p>
								<p className="discpText">There are many variations of <br /> passages Lorem available,</p>
							</div>
						</div>
					</Col>
					<Col sm={6} lg={3}>
						<div className="coverageContent">
							<img src="assets/img/news-img.png" className="img-fluid" />
							<div className="coverageDetails">
								<p>CCX smashes monthly<br /> trading record with $3 Million</p>
								<p className="discpText">There are many variations of <br /> passages Lorem available,</p>
							</div>
						</div>
					</Col>
				</Row>
			</div>

			<div className="latestCoverageSec sec2">
				<Row>
					<Col sm={6} lg={3} className="px-0">
						<div className="coverageContent content2">
							<div className="coverageDetails">
								<p className="name">George Lee</p>
								<p>AUS Government urged to <br /> include aviation and shipping</p>
								<p className="discpText">There are many variations of <br /> passages Lorem available,</p>
								<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
							</div>
						</div>
					</Col>
					<Col sm={6} lg={3} className="px-0">
						<div className="coverageContent content2">
							<div className="coverageDetails">
								<p className="name">George Lee</p>
								<p>CCX smashes monthly<br /> trading record with $3 Million</p>
								<p className="discpText">There are many variations of <br /> passages Lorem available,</p>
								<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
							</div>
						</div>
					</Col>
					<Col lg={6} className="px-0">
						<div className="coverageContent content2">
							<div className="coverageDetails">
								<div className="newsType">
									<div>
										<h6>000 ABC <br /> <span>Australia</span></h6>
									</div>
									<p>22 Jun 2021 <br /> By George Lee</p>
								</div>
								<h6>The Future of Carbon Credit -- Meeting the ESG</h6>
								<p className="discpText">There are many variations of passages Lorem available <br /> but the majority have.</p>
								<Nav.Link>Read More <img src="assets/img/right-arrow.png" /></Nav.Link>
							</div>
						</div>
					</Col>
				</Row>
			</div>

			<div className="channelsSec burger">
				<Row>
					<Col xs={12}>
						<h5 className="miniHeading">Recognition</h5>
						<h1 className="bigHeading">Recognition By Renowned Channels</h1>
					</Col>
					<Col xs={12}>
						<div className="channelsLogo">
							<div className="logoBox">
								<img src="assets/img/abcAustralia-channel.png" className="img-fluid" />
							</div>
							<div className="logoBox">
								<img src="assets/img/australianNews-Channel.png" className="img-fluid" />
							</div>
							<div className="logoBox">
								<img src="assets/img/channel-9.png" className="img-fluid" />
							</div>
							<div className="logoBox">
								<img src="assets/img/sky-news.png" className="img-fluid" />
							</div>
							<div className="logoBox">
								<img src="assets/img/the-7.png" className="img-fluid" />
							</div>
						</div>
					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default Newmedia;
