export const STEPS = [
  { id: "01", name: "Add Information" },
  { id: "02", name: "Add KYC Documents" },
  { id: "03", name: "Profile Complete" },
];
export const CREATESTEPS = [
  { id: "01", name: "Add Information" },
  { id: "02", name: "Add Documents" },
  { id: "03", name: "Submitted for approval" },
];
export const BASE_CURRENCY = {
  CURRENCY: "AUD",
  CRYPTO: "CMEN",
  SYMBOL: "$",
};

export const NAME = {
  certificate: "CNFT",
  buyer: "Buyer",
  supplier: "Supplier",
};

export const LENGTH = {
  CRYPTO: 9,
  CURRENCY: 6,
};
