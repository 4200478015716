import { Container,Row,Col,Button} from 'react-bootstrap';
import {Link} from 'react-router-dom'
const AboutSec = ({ data }) => {
    const images = ["/images/png/producer.png","/images/png/trader.png","/images/png/consumer.png"]
    return (
        <div className="bannerSec mt-5 mx-5 pt-5">
            
               <div className='bannerDiv my-5 pt-5' >
                <Row>
                <Col >
              
              <img src='/images/png/carbon-credit-vector-icon.png' ></img>
              
              </Col>
                <Col>
                <div>
                <p className='aboutHead'>About Us</p>
                <h1 className='aboutTitle'>Shaping the Future of Carbon Trading</h1>
                <p className='aboutSubTitle'>Welcome to Phaeton Carbon Credit Exchange, where innovation meets environmental responsibility on the Phaeton Blockchain. Our platform revolutionizes the traditional carbon credit market by introducing Real World Assets (RWA) tokenization, transforming conventional carbon credits into digital tokens for transparent and efficient trading.</p>
                </div>
                <Link to="/about"><Button variant="" className="primaryBtn featureBtn">Read More</Button></Link>
                
                </Col>
               
                </Row>
                </div>
        </div>
    );
};

export default AboutSec;
