import AdminLayout from '../../Layout/AdminLayout';
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SideBar from '../component/SideBar';

const Settings = ({ history }) => {


	/* Api Calling */
	useEffect(() => {
	}, []);

	return (

		<AdminLayout>
			<Row>
				<h1>Settings</h1>
			</Row>
		</AdminLayout>

	);
};

export default Settings;
