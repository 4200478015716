import React, { useEffect } from "react";
import { Pagination as PaginationBootstrap } from "react-bootstrap";
import styles from "./Pagination.module.scss";

interface PaginationProps {
  itemsCount: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: (args: number) => void;
  alwaysShown: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  itemsCount,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  alwaysShown = true,
}): React.ReactElement => {
  const pagesCount = Math.ceil(itemsCount / itemsPerPage);
  const isPaginationShown = alwaysShown ? true : pagesCount > 1;
  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === pagesCount;

  const changePage = (number: number) => {
    if (currentPage === number) return;
    setCurrentPage(number);
  };

  const onPageNumberClick = (pageNumber: number) => {
    changePage(pageNumber);
  };

  const onPreviousPageClick = () => {
    changePage(currentPage - 1);
  };

  const onNextPageClick = () => {
    changePage(currentPage + 1);
  };

  const setLastPageAsCurrent = () => {
    if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  };

  let isPageNumberOutOfRange: boolean;

  const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === pagesCount;
    const isCurrentPageWithinTwoPageNumbers =
      Math.abs(pageNumber - currentPage) <= 2;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <PaginationBootstrap.Item
          className={
            pageNumber === currentPage
              ? `${styles.pageNumber} ${styles.active}`
              : `${styles.pageNumber}`
          }
          key={pageNumber}
          onClick={() => onPageNumberClick(pageNumber)}
          active={pageNumber === currentPage}>
          {pageNumber}
        </PaginationBootstrap.Item>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return (
        <PaginationBootstrap.Ellipsis
          key={pageNumber}
          className={`muted ${styles.ellipse}`}
        />
      );
    }

    return null;
  });

  useEffect(setLastPageAsCurrent, [currentPage, pagesCount, setCurrentPage]);

  return (
    <>
      {isPaginationShown && (
        <PaginationBootstrap className={styles.pagination}>
          {/*<PaginationBootstrap.Prev
            onClick={onPreviousPageClick}
            disabled={isCurrentPageFirst}
            className={`${styles.prev}`}
          />*/}
          <div className={`d-flex ${styles.pageNumberBlock}`}>
            {pageNumbers}
          </div>
          {/*<PaginationBootstrap.Next
            onClick={onNextPageClick}
            disabled={isCurrentPageLast}
            className={`${styles.next}`}
          />*/}
        </PaginationBootstrap>
      )}
    </>
  );
};

export default Pagination;
