import React from "react";
import styles from "./steps.module.scss";

export const Step = ({ id, name, active, passed, onClick }) => {
  return (
    <div
      className={styles.Step}
      key={id}
      onClick={() => {
        if (onClick) onClick();
      }}>
      <div
        className={styles.StepNumber}
        style={{
          backgroundColor: !!active || !!passed ? "#01d3a1" : "#616161",
          height: !!active ? 100 : 80,
          width: !!active ? 100 : 80,
          marginTop: !!active ? -10 : 0,
          fontWeight: !!active ? "700" : "400",
          fontSize: !!active ? "30px" : "25px",
          border: "none",
        }}>
        {!!passed ? (
          <img
            src="/images/common/check.png"
            alt="Checked"
            className={styles.Checked}
          />
        ) : (
          id
        )}
      </div>
      <div
        className={styles.StepName}
        style={{
          fontWeight: !!active ? "700" : "400",
        }}>
        {name}
      </div>
    </div>
  );
};

const Steps = ({ steps, currentStep, onClick }) => {
  return (
    <div className={styles.StepsWrapper}>
      {steps.map((step, index) => (
        <>
          <Step
            key={index}
            id={step.id}
            name={step.name}
            active={index === currentStep}
            passed={index < currentStep}
            onClick={() => {
              if (onClick) onClick(index);
            }}
          />
          {index !== steps.length - 1 && (
            <div
              className={styles.StepGaps}
              style={{
                flex:
                  index === currentStep ||
                  (index === steps.length - 2 && index === currentStep - 1)
                    ? "1"
                    : "0",
              }}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default Steps;
