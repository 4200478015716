import React, { useEffect } from "react";
import { useToast } from "../context/Toast/useToast";
import { Container, Nav, Button, Figure, Row, Col } from "react-bootstrap";

import { updatePaymentSatusAction } from "../action/walletApiAction";
import styles from "../component/ProducerProfile/profile/profile.module.scss";
import { Link } from "react-router-dom";

function PaymentFail() {
    const { addToast } = useToast();
    useEffect(()=>{
      updateStatus()
    }, [])
    const updateStatus=async()=>{
      let body={}
      body.session_id= localStorage.getItem("stripeSessionId")
      body.status="fail"
      const statusUpdateRes=await updatePaymentSatusAction(body)
      console.log("statusUpdateRes", statusUpdateRes)
      if(statusUpdateRes.Status==="success"){
        
      }else{
        addToast({
                  message: "Internal Server Error",
                  type: "error",
              });
  
      }
    }
    return (
        <>
             <div className="contentcenter">
    <div className={styles.ProfileComplete}>
     <img src="/images/png/paymentfail.png" width={300} className="mx-auto" alt="" />
      <div className={'text-danger ' + styles.ProfileCompleteMessage}>
       Payment Fail
      </div>
      <div className={styles.ProfileCompleteButtonWrapper}>
      <Nav.Link as={Link}  to={'/wallet/dashboard'} >
          <div className={'secondaryBtn submitBtn btn'}  >Back to wallet</div>
       </Nav.Link>
      </div>
    </div>
    </div>
        </>
    )
}

export default PaymentFail;
