import React,{useEffect} from 'react';
import { Container, Row, Col, Carousel, Image, Navbar, Nav, Form, Button, Card } from 'react-bootstrap';

import Header from '../component/Header';
import Footer from '../component/Footer';

const Company = ({ history }) => {


	/* Api Calling */
	useEffect(() => {},[]);

	return (
		<Container>
			
			<div className="homeBanner burger">
				<Row>
					<Carousel>
						<Carousel.Item>
						    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="First slide" />
						    <Carousel.Caption>
						    	<h2>Access the World's <br /> Carbon Credit Markets</h2>
						    	<p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
						    	<div className="bannerBtns">
							    	<Button variant="" className="secondaryBtn">Contact us</Button>
							    	<Button variant="" className="primaryBtn">Join Now</Button>
						    	</div>
						    </Carousel.Caption>
						</Carousel.Item>

						<Carousel.Item>
						    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="Second slide" />
						    <Carousel.Caption>
					    		<h2>Access the World's <br /> Carbon Credit Markets</h2>
						    	<p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
						    	<div className="bannerBtns">
							    	<Button variant="" className="secondaryBtn">Contact us</Button>
							    	<Button variant="" className="primaryBtn">Join Now</Button>
						    	</div>
						    </Carousel.Caption>
						</Carousel.Item>
						
						<Carousel.Item>
						    <img className="d-block w-100" src="assets/img/homeBanner1.png" alt="Third slide" />
						    <Carousel.Caption>
						    	<h2>Access the World's <br /> Carbon Credit Markets</h2>
						    	<p>via the World's First Phaeton Blockchain Based Exchange for Carbon Credits</p>
						    	<div className="bannerBtns">
							    	<Button variant="" className="secondaryBtn">Contact us</Button>
							    	<Button variant="" className="primaryBtn">Join Now</Button>
						    	</div>
						    </Carousel.Caption>
						</Carousel.Item>
					</Carousel>
				</Row>
			</div>

			<div className="aboutSec burger">
				<Row className="align-items-center">
					<Col xs={12}>
						<h5 className="miniHeading">About carbon Credit exchange</h5>
						<h1 className="bigHeading">We Are Here To Provide A Marketplace Where Produsers Traders & Polluters Interact With Each Other!</h1>
					</Col>
					
					<Col xs={12} lg={6}>
						<div className="aboutContent">
							<h5 className="themeClr2">About us</h5>
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
						</div>
					</Col>
					<Col xs={12} lg={6}>
						<div className="aboutContent">
							<h5 className="themeClr2">Our Mission</h5>
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
						</div>

						<div className="aboutContent">
							<h5 className="themeClr2">Our Vision</h5>
							<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
						</div>
					</Col>
				</Row>
			</div>

			<div className="aboutProjectSec burger">
				<Row className="align-items-center">
					<Col lg={5}>
						<ul className="text">	
							<li><span></span>far away, behind the word mountains</li>
							<li><span></span>large language ocean</li>
							<li><span></span>far from the countries Vokalia and Consonantia</li>
						</ul>
					</Col>
					<Col lg={7}>
						<div className="projectInfo">
							<div className="detals">
								<p>1230</p>
								<span>Total Projects</span>
							</div>
							<div className="detals">
								<p>37440</p>
								<span>Total CTNFT Listed</span>
							</div>
							<div className="detals">
								<p>+23500</p>
								<span>Total Active Users</span>
							</div>
						</div>
					</Col>
				</Row>
			</div>

			<div className="meetMindsSec burger">
				<Row>
					<Col xs={12}>
						<h5 className="miniHeading">Our Team</h5>
						<h1 className="bigHeading">Meet The Minds Shaping An Industry</h1>
					</Col>

					<Col lg={3}>
						<div className="imgBox">
							<div className="box1 mb-lg-4">
								<img src="assets/img/metIndustryMind1.png" className="img-fluid" />
							</div>
							<div className="box2">
								<img src="assets/img/metIndustryMind2.png" className="img-fluid person2Img" />
							</div>
						</div>
					</Col>
					<Col lg={3}>
						<div className="meetPersonInfo cmnBox1 infoBox1 active">
							<div>
								<p className="outOf">01/04</p>
								<p className="personPosition">Vice President</p>
								<p className="personName">Andria Robinson</p>
								<p className="discription">There are many variations of passages of Lorem Ipsome Available, but the mjority have suffered alteration in some look even slightly believable.</p>
							</div>
							<div className="socialLinks">
								<Nav.Link><i className="fa fa-facebook"></i></Nav.Link>
								<Nav.Link><i className="fa fa-instagram"></i></Nav.Link>
								<Nav.Link><i className="fa fa-linkedin"></i></Nav.Link>
								<Nav.Link><i className="fa fa-twitter"></i></Nav.Link>
							</div>
						</div>

						<div className="meetPersonInfo cmnBox1 infoBox2">
							<div>
								<p className="outOf">02/04</p>
								<p className="personPosition">Vice President</p>
								<p className="personName">Andria Robinson</p>
								<p className="discription">There are many variations of passages of Lorem Ipsome Available, but the mjority have suffered alteration in some look even slightly believable.</p>
							</div>
							<div className="socialLinks">
								<Nav.Link><i className="fa fa-facebook"></i></Nav.Link>
								<Nav.Link><i className="fa fa-instagram"></i></Nav.Link>
								<Nav.Link><i className="fa fa-linkedin"></i></Nav.Link>
								<Nav.Link><i className="fa fa-twitter"></i></Nav.Link>
							</div>
						</div>
					</Col>

					<Col lg={3}>
						<div className="imgBox">
							<div className="box3 mb-lg-4">
								<img src="assets/img/metIndustryMind3.png" className="img-fluid" id="test" />
							</div>
							<div className="box4">
								<img src="assets/img/metIndustryMind4.png" className="img-fluid" id="test" />
							</div>
						</div>
					</Col>
					<Col lg={3}>
						<div className="meetPersonInfo cmnBox2 infoBox3 active">
							<div>
								<p className="outOf">03/04</p>
								<p className="personPosition">President</p>
								<p className="personName">Andrew Symonds</p>
								<p className="discription">There are many variations of passages of Lorem Ipsome Available, but the mjority have suffered alteration in some look even slightly believable.</p>
							</div>
							<ul className="socialLinks">
								<Nav.Link><i className="fa fa-facebook"></i></Nav.Link>
								<Nav.Link><i className="fa fa-instagram"></i></Nav.Link>
								<Nav.Link><i className="fa fa-linkedin"></i></Nav.Link>
								<Nav.Link><i className="fa fa-twitter"></i></Nav.Link>
							</ul>
						</div>

						<div className="meetPersonInfo cmnBox2 infoBox4">
							<div>
								<p className="outOf">04/04</p>
								<p className="personPosition">President</p>
								<p className="personName">Andrew Symonds</p>
								<p className="discription">There are many variations of passages of Lorem Ipsome Available, but the mjority have suffered alteration in some look even slightly believable.</p>
							</div>
							<ul className="socialLinks">
								<Nav.Link><i className="fa fa-facebook"></i></Nav.Link>
								<Nav.Link><i className="fa fa-instagram"></i></Nav.Link>
								<Nav.Link><i className="fa fa-linkedin"></i></Nav.Link>
								<Nav.Link><i className="fa fa-twitter"></i></Nav.Link>
							</ul>
						</div>
					</Col>
				</Row>
			</div>

			<div className="testimonialSec burger">
				<Row>
					<Col xs={12}>
						<h5 className="miniHeading">Testimonial</h5>
						<h1 className="bigHeading">We Care About Our Customers Experience Too</h1>
					</Col>
					<Col lg={4}>
						<div className="testimonialContent">
							<img src="assets/img/viewerImg.png" className="viewerImg" />
							<h5 className="viewerName">Mark Smith</h5>
							<p className="viewerPosition">Google Inc.</p>
							<p className="viewerPost">When, while lovely Valley teems with vapour round meand meridian sun strikes the upper impenetrable.</p>
							<ul className="viewerRating">
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
							</ul>
						</div>
					</Col>

					<Col lg={4}>
						<div className="testimonialContent active">
							<img src="assets/img/viewerImg.png" className="viewerImg" />
							<h5 className="viewerName">Mark Smith</h5>
							<p className="viewerPosition">Google Inc.</p>
							<p className="viewerPost">When, while lovely Valley teems with vapour round meand meridian sun strikes the upper impenetrable.</p>
							<ul className="viewerRating">
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
							</ul>
						</div>
					</Col>

					<Col lg={4}>
						<div className="testimonialContent">
							<img src="assets/img/viewerImg.png" className="viewerImg" />
							<h5 className="viewerName">Mark Smith</h5>
							<p className="viewerPosition">Google Inc.</p>
							<p className="viewerPost">When, while lovely Valley teems with vapour round meand meridian sun strikes the upper impenetrable.</p>
							<ul className="viewerRating">
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
								<li><i className="fa fa-star"></i></li>
							</ul>
						</div>
					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default Company;
