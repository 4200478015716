import React, { Component, useState,useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getUserDashboardData } from "../../action/Api.action";
import Charts from "./chart";

import CustomLoader from "../../common/customLoader";


/**
"id": 22,
      "nft_id": 27,
      "registration_number": "101",
      "project_title": "Test NFT",
      "transaction_hash": "0x78e1712e9293e8dfe5edb11b1c18dd906ea9544ecb98a0a6ebb0432979d4186e",
      "serial_number": "91",
      "total_credits": "201",
      "price": 1.0,
      "transaction_fees": 0.06,
      "buyer_wallet_address": "0xFf6e5A4C69426e198e144E63A4Be5f2b2260DB93",
      "date": "2024-06-19 11:02:19" 


 */
const BuyerDashboard = (props)  => {
  const [usertype, setUserType] = useState('seller')
  const [IsLoading,setIsLoading] = useState(false)
  const [userData,setUserData] = useState({
    certificate_total : 0,
    certificate_sold : 0,
    certificate_bought : 0
  })
  useEffect(async() => {
    setIsLoading(true)
    let queryData = {
      user_id :  localStorage.getItem('userId'),
      user_type : localStorage.getItem('userType'),
      user_wallet_address : localStorage.getItem('wallet_address')
    }
    let dashboardData = await getUserDashboardData(queryData)
    let data = {
      certificate_total : dashboardData.Owned_NFTs,
      certificate_sold :  dashboardData.Sold_NFTs,
      certificate_bought :dashboardData.Bought_NFTs,
      certificate_burnt : dashboardData.Burnt_NFTs,
      sale_report : dashboardData.sale_report
    }
    setUserData(data)

    console.log(dashboardData)
		setIsLoading(false)
	}, []);


    return (
      <>

        <Container fluid>
        {IsLoading && <CustomLoader/>}

          <Row>
            <Col className={"mb-3 " + ( localStorage.getItem("userType") !== "producer"?'buyerBox':'')} lg={3} id="leftBoxitems">
              <Row>
                <Col className="mb-4" lg={12}>
                  <div className="dashboardbox">
                    <div className="d-flex align-items-center gap-4">
                      <img src="/images/png/certificateIcon.png"  width={64} alt="" />
                      <div>
                        <h1>Total Certificates</h1>
                        <h2>
                          {userData.certificate_total}{" "}
                          <span className="up">
                            10% <i class="fa-solid fa-arrow-trend-up"></i>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </Col>
                {localStorage.getItem("userType") === "producer" && 
                <Col className="mb-4" lg={12}>
                  <div className="dashboardbox">
                    <div className="d-flex align-items-center gap-4">
                      <img src="/images/png/soldnft.png" width={64} alt="" />
                      <div>
                        <h1>Total Sold</h1>
                        <h2>
                        {userData.certificate_sold}{" "}
                          <span className="up">
                            10% <i class="fa-solid fa-arrow-trend-up"></i>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </Col>}
               
                <Col className={'mb-4'} lg={12}>
                  <div className="dashboardbox">
                    <div className="d-flex align-items-center gap-4">
                      <img src="/images/png/bought.png"  width={64} alt="" />
                      <div>
                        <h1>Total Bought</h1>
                        <h2>
                        {userData.certificate_bought}{" "}
                          <span className="up">
                            10% <i class="fa-solid fa-arrow-trend-up"></i>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </Col>

                {localStorage.getItem("userType") === "polluter" && 
                <Col className="mb-4" lg={12}>
                  <div className="dashboardbox">
                    <div className="d-flex align-items-center gap-4">
                      <img src="/images/png/sold.png" width={64} alt="" />
                      <div>
                        <h1>Total Burnt</h1>
                        <h2>
                        {userData.certificate_burnt}{" "}
                          <span className="up">
                            10% <i class="fa-solid fa-arrow-trend-up"></i>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </Col>}
              </Row>
            </Col>
            <Col className="mb-4" lg={9}>
              <div className="dashboardbox">
               <Charts user={usertype} />
              </div>
            </Col>
            {localStorage.getItem("userType") === "producer" && 
            <Col className="mb-3" lg={12}>
            <div className="salesReport">
            <div className="d-flex align-items-center justify-content-between mb-3">
            <h2 className="mb-0">Sales Report</h2>
            <button className="btn secondaryBtn-outline btn-sm d-flex gap-2"><i class="fa-solid fa-file-export"></i> Export</button>
            </div>
              <div className="filterBox">
                <input type="date" className="form-control" />
                <input type="date" className="form-control" />
                {/* <input type="text" className="form-control" placeholder="Registration No." />

                <input type="text" placeholder="Cetificate No." className="form-control" />
               
                <select className="form-control form-select">
                  <option>Quantity</option>
                </select>
                <select className="form-control form-select">
                  <option>Buyer Name</option>
                </select> */}
              
                <button className="secondaryBtn btn">Submit</button>


            </div>
              <div className="table-resposive tableBox">
                <table>
                  <thead>
                    <tr>
                      <td>Sr.No.</td>
                      <td>NFT Name</td>
                      <td>Sale Date</td>
                      <td>Registration No. </td>
                      <td>Asset ID</td>
                      <td>Total Credits</td>
                      <td>Cost</td>
                      <td>Platform Fee</td>
                      <td>Total Earnings</td>
                      <td>Buyer Wallet Address</td>
                    </tr>
                  </thead>
                  <tbody>

                  {userData.sale_report && userData.sale_report?.length > 0 ? (
                    userData.sale_report?.map((item, i) => {
                      return (<>
                         <tr>
                      <td>{i+1}</td>
                      <td>{item.project_title}</td>
                      <td>{item.date}</td>
                      <td>{item.registration_number}</td>
                      <td>{item.serial_number}</td>
                      <td>{item.total_credits}</td>
                      <td><img width={15} src="/images/svg/usdcIcon.svg" alt="" /> {item.price}</td>
                      <td><img width={15} src="/images/svg/usdcIcon.svg" alt="" /> {item.price * 0.0325}</td>
                      <td><img width={15} src="/images/svg/usdcIcon.svg" alt="" /> {item.price * 0.9675}</td>
                      
                      <td>{item.buyer_wallet_address}</td>
                    </tr>

                      </>)
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>No Records Found.</td>
                    </tr>
                  )}


                   
                  </tbody>
                </table>
              </div>
            </div>
            </Col> }
          </Row>
        </Container>
      </>
    );
 
}

export default BuyerDashboard;
