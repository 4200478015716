import AdminLayout from '../../Layout/AdminLayout';
import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';

import SideBar from '../component/SideBar';

const Users = ({ history }) => {


	/* Api Calling */
	useEffect(() => {
	}, []);

	return (
		<AdminLayout>
			<Row>
				<h1>Users</h1>
			</Row>
		</AdminLayout>
	);
};

export default Users;
