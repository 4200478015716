import { Row, Col} from "react-bootstrap";
import {Link} from 'react-router-dom'
const Footer = () => {
 

  return (
    <>
    <div className="container-fluid footerCustom">
    <div className="footerCustom">
      <div className="footerContent">
      <Row >
        <Col className = "mx-3 phaetonLogo">
        <img src="/images/png/phaeton_header_logo.png"></img>
        </Col>
        <Col md={2}>
        <p className="centerTitle footerTextStatic">Access the world Carbon Credit Markets 
with PhaetonCCX today.</p>
        </Col>
        <Col md={1} className="linksCol">
        <p className="linksHead mt-4"><b>Pages</b></p>
        <p className="linksContent mt-5"><Link to="/about" style={{color : 'black'}}>About us</Link></p>
        <p className="linksContent ">Who We Serve</p>
        <p className="linksContent ">Our Technology</p>
        </Col>
        <Col md={1} className="linksCol">
        <p className="linksHead mt-4"><b>Links</b></p>
        <p className="linksContent mt-5">Careers</p>
        <p className="linksContent ">Privacy Policy</p>
        <p className="linksContent ">Terms & Condition</p>
        </Col>
        <Col md={2} className="linksCol">
        <p className="linksHead mt-4"><b>Contact Us</b></p>
        <p className="linksContent mt-5"><i class="fa-regular fa-envelope-open"></i> support@phaeton.io</p>
        <p className="linksContent "><i class="fa-regular fa-paper-plane"></i> 17 Discovery Drive, 6163 WA, Australia.</p>
        </Col>
        
        </Row>
      </div>
          </div>
    </div>
      <div className="copyrightText1 py-3">
      <p className="copyrightContents ">© Carbon Credit Exchange 2023 All rights reserved</p>
    </div>
    </>
  
  );
};

export default Footer;
