import AdminLayout from '../../Layout/AdminLayout';
import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';

import SideBar from '../component/SideBar';

const TransactionFees = ({ history }) => {

	const options = [
		{ value: 'chocolate', label: 'Chocolate' },
		{ value: 'strawberry', label: 'Strawberry' },
		{ value: 'vanilla', label: 'Vanilla' }
	]

	const [show, setShow] = React.useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	/* Api Calling */
	useEffect(() => {
	}, []);

	return (
		<>
			<Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Add New Transaction Fee</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="tableSelection">
						<Form>
							<Row>
								<Col sm={6}>
									<Form.Group className="date mb-3" controlId="exampleForm.ControlStartDate">
										<Form.Control type="date" placeholder="09-09-2020" />
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Group className="date mb-3" controlId="exampleForm.ControlEndDate">
										<Form.Control type="date" placeholder="09-05-2021" />
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Group className="mb-3" controlId="exampleForm.ControlMinChanges">
										<Form.Control type="text" placeholder="Minimum Changes" />
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlMaxChanges">
										<Form.Control type="text" placeholder="Maximum Changes" />
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlRate">
										<Form.Control type="text" placeholder="Rate" />
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlStatus">
										<Select options={options} />
									</Form.Group>
								</Col>
								<Col xs={12}>
									<Button className="primaryBtn addNewDocBtn mb-3 px-4" variant="">Add Transaction Fees</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</Modal.Body>
			</Modal>

			<AdminLayout>
				<Row>
					<Col xs={12}>
						<div className="CTNFTrequestQuote tableListing boxShaddow burger">
							<div className="tableSelection">
								<Form>
									<Form.Group className="date mb-3" controlId="exampleForm.ControlStartDate">
										<Form.Control type="date" placeholder="09-09-2020" />
									</Form.Group>
									<Form.Group className="date mb-3" controlId="exampleForm.ControlEndDate">
										<Form.Control type="date" placeholder="09-05-2021" />
									</Form.Group>
									<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlDocName">
										<Select options={options} />
									</Form.Group>
									<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlDocType">
										<Select options={options} />
									</Form.Group>
									<Form.Group className="selectBox mb-3" controlId="exampleForm.ControlStatus">
										<Select options={options} />
									</Form.Group>
									<Button className="primaryBtn submitBtn mb-3" variant="">SUBMIT</Button>
									<Button className="primaryBtn addNewDocBtn mb-3" variant="" onClick={handleShow}><span>+</span> Add New</Button>
								</Form>
							</div>

							<div className="listing">
								<div className="tableHead">
									<ul className="headContent">
										<li className="srNo">Sr. No</li>
										<li className="date">Start Date</li>
										<li className="date">End Date</li>
										<li className="minCharges">Min Charges</li>
										<li className="minCharges">Max Charges</li>
										<li className="percentage">Percentage</li>
										<li className="listingStatus">KYC Status</li>
										<li className="action">Action</li>
									</ul>
								</div>

								<div className="tableBody">
									<ul className="bodyContent">
										<li className="srNo">1</li>
										<li className="date">01-July-2021</li>
										<li className="date">31-Dec-2021</li>
										<li className="minCharges">$ 5.00</li>
										<li className="minCharges">$ 1000.00</li>
										<li className="percentage">2.5 %</li>
										<li className="listingStatus">Active</li>
										<li className="action multiBtnOptn">
											<Button className="editBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>
											<Button className="checkedBtn" variant="" type="submit"><i className="fa fa-check"></i></Button>
											<Button className="cancelBtn" variant="" type="submit"><i className="fa fa-times"></i></Button>
											<Button className="downloadBtn" variant="" type="submit"><i className="fa fa-download"></i></Button>
											<Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button>
										</li>
									</ul>
									<ul className="bodyContent">
										<li className="srNo">2</li>
										<li className="date">01-April-2021</li>
										<li className="date">30-June-2021</li>
										<li className="minCharges">$ 10.00</li>
										<li className="minCharges">$ 1000.00</li>
										<li className="percentage">2.00 %</li>
										<li className="listingStatus">In-active</li>
										<li className="action multiBtnOptn">
											<Button className="editBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>
											<Button className="checkedBtn" variant="" type="submit"><i className="fa fa-check"></i></Button>
											<Button className="cancelBtn" variant="" type="submit"><i className="fa fa-times"></i></Button>
											<Button className="downloadBtn" variant="" type="submit"><i className="fa fa-download"></i></Button>
											<Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button>
										</li>
									</ul>
									<ul className="bodyContent">
										<li className="srNo">3</li>
										<li className="date">01-Jan-2021</li>
										<li className="date">30-Mar-2021</li>
										<li className="minCharges">$ 1.00</li>
										<li className="minCharges">$ 1000.00</li>
										<li className="percentage">1.50 %</li>
										<li className="listingStatus">In-active</li>
										<li className="action multiBtnOptn">
											<Button className="editBtn" variant="" type="submit"><i className="fa fa-edit"></i></Button>
											<Button className="checkedBtn" variant="" type="submit"><i className="fa fa-check"></i></Button>
											<Button className="cancelBtn" variant="" type="submit"><i className="fa fa-times"></i></Button>
											<Button className="downloadBtn" variant="" type="submit"><i className="fa fa-download"></i></Button>
											<Button className="viewBtn" variant="" type="submit"><i className="fa fa-eye"></i></Button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</AdminLayout>
		</>
	);
};

export default TransactionFees;
