import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartItem from "../../../common/CartItem";
// import { addBondToCart, removeBondFromCart } from "redux/slices/cart";
import Loader from "../../../common/loader";
import { BASE_CURRENCY } from "../../../constant/constant";
import { useToast } from "../../../context/Toast/useToast";
import styles from "../style.module.scss";

function YourCart({ cartData, index }) {
  const [isLoading, setLoading] = useState("");
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const quantData = useSelector((state) => state?.home?.quoteData);
  const handleQuantity = async (operation) => {
    setLoading(operation);
    const add_qty = operation === "plus" ? 1 : -1;
    const res = await CartItem(quantData, add_qty, addToast, dispatch);
    setLoading("");
  };

  const handleRemoveBond = async () => {
    setLoading("remove");
    const add_qty = -cartData.volume;
    const res = await CartItem(quantData, add_qty, addToast, dispatch);
    setLoading("");
  };

  const hasLoading = true;

  return (
    <>
      <div key={index} className={styles.cartDesign}>
        {/* <div className={styles.profileGrid}>
          <div className={styles.profileGridItems}>
            <div className={styles.dummyProfile}>
              <img src={cartData.issuer_image_url} />
            </div>
          </div>
          <div className={styles.profileGridItems}>
            <div className={styles.profileInformationAlignment}>
              <h1>{cartData.issuer}</h1>
              <p>{cartData.rating}</p>
            </div>
            <a>{cartData.bond_name}</a>
          </div>
        </div> */}
        <div className={styles.amountTextAlignment}>
          <div className={styles.finalAmount}>
            <p>Total Amount ({BASE_CURRENCY.CRYPTO})</p>
            <h6>
              {/* {BaseCurrency.symbol} */}
              {cartData?.price}
            </h6>
          </div>
          <div className={styles.finalAmount}>
            <p>Quantity</p>
            <div className={styles.buttongroup}>
              {hasLoading && isLoading === "minus" ? (
                <Loader updateLoader={true} />
              ) : (
                <div
                  className={styles.subButton}
                  onClick={() => handleQuantity("minus")}>
                  <i className="fa-solid fa-circle-minus"></i>
                </div>
              )}
              <div className={styles.counter}>
                <input type="text" value={cartData.volume} readOnly />
              </div>
              {hasLoading && isLoading === "plus" ? (
                <Loader updateLoader={true} />
              ) : (
                <div
                  className={styles.subButton}
                  onClick={() => handleQuantity("plus")}>
                  <i className="fa-solid fa-circle-plus"></i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.buttonGrid}>
          <div className={styles.buttonGridItems} onClick={handleRemoveBond}>
            <button>
              {hasLoading && isLoading === "remove" ? <Loader /> : "Remove"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default YourCart;
