import { Container,Row,Col,Button} from 'react-bootstrap';
import './styles.css'
import {Link} from 'react-router-dom'
const Howitworks = ({ data }) => {
    const images = ["/images/png/RegisterHow.png","/images/png/VerificationHow.png"]
    return (
        <div className='container-fluid'>
        <div className="howitworkssec mb-5 py-5">
            
               <div className=' pt-5'  >
                <Row>
               
                <Col className='featureText'>
                <div className='mx-5 px-5'>
                <h1 className='centerTitle'><b>How it works</b></h1>
                <p className='centerTitle'>Check how marketplace works or submit your contact details for more details</p>
                </div>
                
               
                </Col>
              
               
                </Row>
                <Row className='mt-5'>
               
                <Col className='featureText col-md-4 mt-4'>
                <div className='buttonDiv mx-5 py-2'>
                <p className='processButtons'><span> 1 </span>  Onboarding</p>
                </div>
                <div className='buttonDiv mx-5 Unselected'>
                <p className='processButtons Unselected'><span> 2 </span>  Minting Carbon Credits</p>
                </div>
                <div className='buttonDiv mx-5 Unselected'>
                <p className='processButtons Unselected'><span> 3 </span>  Buying and Selling</p>
                </div>
                
               
                </Col>
              <Col className='howitworkscontainer featureText col-md-8'>
                <div className='row'>
                <div className='howitworksbox pt-3 mx-5 px-4 col-md-3 '>
                <img src={images[0]} ></img>
                <h1 className='boxTitle'>Register</h1>
                <p className='boxDescription'>Create an account on our platform</p>
                </div>
                <div className='howitworksbox pt-3 px-4 col-md-7'>
                <img src={images[1]} ></img>

                <h1 className='boxTitle'>Verification</h1>
                <p className='boxDescription'>Complete the Know Your Business (KYB) process to verify your identity and business.</p>
                </div>
               
                </div>
                </Col>
               
                </Row>
                </div>
        </div>
       </div>
    );
};

export default Howitworks;
