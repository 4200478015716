import React, { useEffect, useState,useRef } from 'react';
import { Container, Row, Col, Navbar, Form, Button } from 'react-bootstrap';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { signup, verification } from '../action/Api.action';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useToast } from '../context/Toast/useToast';
import Admin, { verificationData } from '../slices/admin'
import { RemoveToast } from '../context/Toast/removeToast';
import ReCAPTCHA from 'react-google-recaptcha'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const handlePhoneKeyDown = (e) => {
	const selectionStart = e.target.selectionStart;
	const selectionEnd = e.target.selectionEnd;

	// Check if backspace key is pressed and the cursor is positioned within the country code input field
	// if (e.key === 'Backspace' && selectionEnd <= 5) {
	// 	e.preventDefault(); // Prevent default behavior of backspace key
	// }
};
const SignUp = ({ history }) => {
	const recaptcha = React.createRef();
	const { addToast } = useToast();
	const [showPass, setshowPass] = useState("password");
	const [confshowPass, setconfshowPass] = useState("password");
	const { removeToast } = useToast();

	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({
		accountType: '',
		userType: '',
		email: '',
		phone: '+61',
		firstName: '',
		lastName: '',
		password: '',
		repassword: ''
	});
	const [errors, setErrors] = useState({});
	const [isUpdate, setUPdate] = useState(false);
	const [isDisabled, setDisabled] = useState(false);
	const dispatch = useDispatch();
	const [touched, setTouched] = useState({});


	const handleChange = (e) => {

		const { name, value } = e.target;
		console.log("checkin input", e)
		setFormData({ ...formData, [name]: value });
		setTouched({ ...touched, [name]: true });
		let newErrorss = {};


		if (name === 'firstName') {
			if (!value) {
				newErrorss.firstName = 'Please enter your first name';
			} else if (!/^[a-zA-Z0-9]+(?: .+)?$/.test(formData.firstName)) {

				newErrorss.firstName = 'Invalid First Name'
			}

		}
		if (name === 'lastName') {
			if (!value) {
				newErrorss.lastName = 'Please enter your last name';
			} else if (!/^[a-zA-Z0-9]+(?: .+)?$/.test(formData.lastName)) {
				newErrorss.lastName = 'Invalid Last Name'
			}

		}
		if (name === 'email') {
			if (!value) {
				newErrorss.email = 'Please enter a valid email address';
			} else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(formData.email)) {
				newErrorss.email = 'Invalid email address';
			}

		}
		if (name === 'password') {
			if (!value) {
				newErrorss.password = 'Please enter the password'
			} else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(formData.password)) {
				newErrorss.password = 'Password must be 8-16 characters, and must have at least one uppercase, one lowercase, one number and one special character'
			}

		}

		if (name === 'repassword') {
			if (!value) {
				newErrorss.repassword = 'Please enter the confirm password'
			} else if (name === 'password' != name === 'repassword') {
				newErrorss.repassword = 'Password does not match'
			}

		}



		setErrors(newErrorss);
	};
	const handlePhoneChange = (e) => {
		console.log("phone", e)
		const { name, value } = e;
		setFormData({ ...formData, [name]: value });
		setTouched({ ...touched, [name]: true })
	}


	const [state, setState] = useState({
		open: false,
		vertical: 'top',
		horizontal: 'center',
	});

	const { vertical, horizontal, open } = state;


	// const handleClick = (newState) => () => {
	// 	setState({ open: true, ...newState });
	// };

	const handleClose = () => {
		setState({ ...state, open: false });
	};
	const handleCountryChange = (country, countryData) => {
		setFormData({
		  ...formData,
		  phone: `+${countryData.dialCode}`,
		});
	  };

	const handleSubmit = async (event) => {
		let valid = true
		console.log("Ashmin Form Test : ", formData)
		const form = event.currentTarget;
		console.log("event listner", setFormData.firstName)
		event.preventDefault();
		console.log("formData email", form)
		const captchaValue = recaptcha.current.getValue()
		console.log("checking for captcha value",captchaValue)

		// Validation logic
		let newErrors = {};
		// 	console.log(formData)
		// console.log("test val", document.getElementById('robot').checked)
		// if (!document.getElementById('robot').checked) {
		// 	valid = false
		// 	newErrors.robot = "Please check the box to continue."
		// }
		if (!document.getElementById('terms').checked) {
			newErrors.terms = "Please agree to Terms and Conditions to continue."
			valid = false
		} 
		if(!captchaValue){
			newErrors.recaptcha = "Please check the box to continue."
			valid = false
		}
		// if ( !document.getElementById('terms').checked) {
		// 	newErrors.robot = (
		// 		<span style={{ color: "red" }}>
		// 			{/* Please check the box to continue.<br /> */}
		// 			* Please agree to Terms and Conditions to continue.
		// 		</span>
		// 	)
		// 	valid = false
		// }

		if (!formData.accountType) {
			valid = false
			newErrors.accountType = "Please select an account type."
		}
		if (!formData.userType) {
			valid = false
			newErrors.userType = "Please select a user type."
		}
		if (!formData.email) {
			valid = false
			newErrors.email = 'Please enter a valid email address';
		} else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(formData.email)) {
			valid = false
			newErrors.email = 'Invalid email address';
		}

		if (formData.phone.length < 10 || formData.phone.length > 12) {
			valid = false
			newErrors.phone = 'Please enter your mobile number';
		} else if (!/^\d+$/.test(formData.phone)) {
			valid = false
			newErrors.phone = 'Invalid phone Number';
		}

		if (!formData.firstName) {
			valid = false
			newErrors.firstName = "Please enter your first name";
		} else if (!/^[a-zA-Z0-9]+(?: .+)?$/.test(formData.firstName)) {
			valid = false
			newErrors.firstName = 'Invalid firstName'
		}
		if (!formData.lastName) {
			valid = false
			newErrors.lastName = 'Please enter your last name';
		} else if (!/^[a-zA-Z0-9]+(?: .+)?$/.test(formData.lastName)) {
			valid = false
			newErrors.lastName = 'Invalid lastname'
		}
		if (!formData.password) {
			valid = false
			newErrors.password = 'Please enter the password'
		}
		else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(formData.password)) {
			valid = false
			newErrors.password = 'Invalid password. Password must be 8-16 characters, and must have at least one uppercase, one lowercase, one number and one special character'
		}
		if (!formData.repassword) {
			valid = false
			newErrors.repassword = 'Please enter the confirm password'
		}
		else if (formData.password != formData.repassword) {
			valid = false
			newErrors.repassword = 'Password does not match'
		}

		console.log("newErrors", newErrors)
		setErrors(newErrors);
		console.log("errors", errors)
		if (!valid) {
			console.log("New Errors found")
			return false
		}
		// if (form.checkValidity() === false) {
		// 	event.preventDefault();
		// 	event.stopPropagation();

		// 	setValidated(true);
		// } 
		else {
			event.preventDefault();
			event.stopPropagation();
			setDisabled(true);
			//setValidated(true);
			let response = await signup(formData);
			console.log("checki response", response)
			console.log("Register Response :", response)
			if (response.status === 200) {
				let obj = {}
				obj.email = formData.email
				obj.partner_id = response.partnerId
				//  let verify = await verification(obj)
				//  if (verify.status === 200) {
				//  	console.log("working")
				//  }
				dispatch(verificationData(obj))
				addToast({
					message: "Registration Successful!",
					type: "success"
				})
				setState({ open: true, vertical: 'top', horizontal: 'center' });
				setFormData({});
				// removeToast()
				history.push({ pathname: "/verification", state: response.data });


			} else if (response.status === 401) {
				console.log("Error Found", response.message)
				addToast({
					message: response.message,
					type: "error",
				});
				setDisabled(false);

				// let errorsList = response.message;
				// let err = {};
				// for (let i in errorsList) {
				// 	err[errorsList[i].param] = errorsList[i].msg;
				// }

				// setErrors(err);

			}
		}

	};

	const onKeyDown = (e) => {
		if (e.key === ' ') {
			e.preventDefault(); // Prevent default behavior of the Space key
		}
	};

	const formHandler = (e, field) => {
		let data = formData;
		let err = errors;
		if (field === 'phone') {
			data[field] = e;
		} else {
			data[field] = e.target.value;
			
		}

		if (err[field]) {
			delete err[field];
		}
		setErrors(err);
		setFormData(data);
		setUPdate(!isUpdate);

	}

	const showPassword = (e) => {
		console.log(e.target);
		const getPassId = document.getElementById(e)
		if (getPassId.id === 'password') {
			setshowPass(showPass === 'password' ? 'text' : 'password')
		} else {
			setconfshowPass(confshowPass === 'password' ? 'text' : 'password')


		}


	}

	/**
	useEffect(() => {
		let type = localStorage.getItem('userType')?localStorage.getItem('userType'):'';
		if(type !== ''){
			history.push({ pathname: '/dashboard'});
		}
	},[]);
 */
	return (
		<Container>
			{/* <Button onClick={handleClick({ vertical: 'top', horizontal: 'center' })}>Top-Center</Button> */}
			<Snackbar
				autoHideDuration={2000}
				anchorOrigin={{ vertical, horizontal }}
				open={open}
				onClose={handleClose}
				//message="I love snacks"
				key={vertical + horizontal}
			//severity="error"
			>
				<Alert onClose={handleClose} severity="success">Successfully registered.</Alert>
			</Snackbar>
			<div className="signUpForm boxShaddow burger">
				<Row className="justify-content-center align-items-center">
					<Col lg={6} xl={5}>
						<div className="heading">
							<h1>Create An Account</h1>
							<Navbar.Text>Already a user? <Link to="/login">Sign In</Link></Navbar.Text>
						</div>

						<Form className="formContent" >
							<div className="btn-group btn-group-toggle nameSec" data-toggle="buttons">
								<Form.Group className="mb-2">
									<label class="btn active">
										<input type="radio" name="accountType" id="option1" value="Individual" onChange={handleChange} required /> Individual
									</label>
									{errors.accountType && <span style={{ "color": "red" }}>* {errors.accountType}</span>}

								</Form.Group>
								<Form.Group className="mb-2">
									<label class="btn">
										<input type="radio" name="accountType" id="option2" value="Company" onChange={handleChange} required /> Company
									</label>
								</Form.Group>
							</div>
							<div className="nameSec">
								<Form.Group className="mb-3" controlId="formBasicFirstName">
									<Form.Control type="text" defaultValue={formData.firstName} name="firstName" placeholder="First Name" onChange={handleChange} minLength="2" maxLength="32" autoComplete="none" required  />
									{errors.firstName && <span style={{ "color": "red" }}>* {errors.firstName}</span>}


								</Form.Group>
								<Form.Group className="mb-3" controlId="formBasicLastName">
									<Form.Control type="text" defaultValue={formData.lastName} name="lastName" placeholder="Last Name" onChange={handleChange} minLength="2" maxLength="32" autoComplete="none" required 
									/>
									{errors.lastName && <span style={{ "color": "red" }}>* {errors.lastName}</span>}


								</Form.Group>
							</div>

							<Form.Group className="mb-3" controlId="formBasicNumber">
								<PhoneInput
									placeholder="Phone Number"
									onChange={phone => formHandler(phone, 'phone')}
									inputProps={{
										name: 'phone',
										required: true
									}}
									onChangeCountry={handleCountryChange}

									inputStyle={{ width: "100%" }}
									isValid={(value) => {
										if (value.length < 10 || value.length > 12) {
											return false;
										} else {
											return true;
										}

									}}
									onKeyDown={handlePhoneKeyDown} // Add onKeyDown event handler

								/>
								{errors.phone && <span style={{ "color": "red" }}>* {errors.phone}</span>}

								{/* <Col sm="8">
							    	<Form.Control type="text" defaultValue={formData.phone}  placeholder="Mobile Number" onChange={e => formHandler(e, 'phone')} pattern="[0-9]{10}" minLength="10" maxLength="10" autoComplete="none" required/>	
								</Col>							 */}
								{/* <small className="error">{errors.phone ? errors.phone : ''}</small> */}
							</Form.Group>


							{/* <Form.Label>Email</Form.Label> */}
							{/* <Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Control type="email" Value={formData.email} placeholder="Email Address" onChange={handleChange} required
									onBlur={() => setTouched({ ...touched, email: true })}
									isInvalid={!!(errors.email && touched.email)} />
								<Form.Control.Feedback type="invalid">
									{errors.email}
								</Form.Control.Feedback>
							</Form.Group> */}


							<Form.Control
								type="email"
								name="email"
								placeholder="Email Address"
								value={formData.email}
								onChange={handleChange}
								onKeyDown={onKeyDown}

								required
							/>

							{errors.email && <span style={{ "color": "red" }}>* {errors.email}</span>}





							<div className="btn-group btn-group-toggle nameSec" data-toggle="buttons">
								<Form.Group className="mb-2">
									<label class="btn active">
										<input type="radio" name="userType" id="optionUser1" value="polluter" onChange={handleChange} required /> Buyer
									</label>
									{errors.userType && <span style={{ "color": "red" }}>* {errors.userType}</span>}

								</Form.Group>
								<Form.Group className="mb-2">
									<label class="btn">
										<input type="radio" name="userType" id="optionUser2" value="producer" onChange={handleChange} required /> Supplier
									</label>
								</Form.Group>
							</div>

							<Form.Group className="mb-3" >
								<div className='position-relative'>
									<Form.Control type={showPass} name="password" defaultValue={formData.password} placeholder="Password" id='password' minLength="6" onChange={handleChange} maxLength="32" autoComplete="new-password" required />
									<button type="button" className="btn passwordSHow" onClick={() => showPassword('password')}><i class={"fa-regular " + (showPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
								</div>
								{errors.password && <span style={{ "color": "red" }}>* {errors.password}</span>}
							</Form.Group>

							<Form.Group className="mb-3" >
								<div className='position-relative'>
									<Form.Control type={confshowPass} name="repassword" id='Confirmpassword' defaultValue={formData.repassword} placeholder="Confirm Password" minLength="6" onChange={handleChange} maxLength="32" autoComplete="new-password" required />
									<button type="button" className="btn passwordSHow" onClick={() => showPassword('Confirmpassword')}><i class={"fa-regular  " + (confshowPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
								</div>
								{errors.repassword && <span style={{ "color": "red" }}>* {errors.repassword}</span>}
							</Form.Group>


							<div>
								<Form.Group className="checkBox d-flex align-items-center gap-2  cardForm mt-4" controlId="formBasicCheckbox">

									<Form.Check className='checkwallet w-auto m-0' id="terms" type="checkbox" onChange={e => formHandler(e, 'terms')} required />
									<p className='my-auto'>I have read and agree to the <span className='linktext cursor-pointer fw-semibold'>Terms & Condition</span> and <span className='linktext cursor-pointer fw-semibold' onClick={() => window.open("/page/privacy", "_blank")}>Privacy Policy</span></p>
								</Form.Group>
								{errors.terms && (<span style={{ "color": "red" }}>* {errors.terms}</span>)}
							</div>

							<div>
								<br></br>
								{/* <Form.Group className="mb-3 mt-2 d-flex align-items-center gap-2 cardForm checkBox" controlId="formBasicCheckbox2">
									<Form.Check id="robot" className='checkwallet w-auto m-0' type="checkbox" onChange={e => formHandler(e, 'robot')} required />
									<p className='my-auto'>I'm not a robot</p>
								</Form.Group>
								{errors.robot && <span style={{ "color": "red" }}>* {errors.robot}<br /></span>} */}
								<ReCAPTCHA onClick={handleSubmit} ref={recaptcha} id='recaptcha' sitekey={"6LcLMPYpAAAAAFUH2UVPKqblBq7ZNsJ3sV2auIsu"} />
								{errors.recaptcha && (<span style={{ "color": "red" }}>* {errors.recaptcha}</span>)}
							</div>

							<Button className="secondaryBtn" variant="" type="button" disabled={!handleSubmit} onClick={e => handleSubmit(e)}>Sign Up</Button>
						</Form>
					</Col>
					<Col lg={6} xl={5}>
						<img src="assets/img/signUp1.png" className="img-fluid ms-auto d-none d-md-block" />
					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default SignUp;
