import React, { useEffect, useState } from "react";
import { useToast } from "../context/Toast/useToast";
import { Col, Container, Form, Row } from "react-bootstrap";
import SubHeader from "../component/Subheader";
import { Label } from "recharts";
import { useLocation } from 'react-router-dom';
import CustomLoader from "../common/customLoader";
import { deleteProfilePicture, deleteimgProfilePicture, fetchProfilePicture, getUserData, updateUserPassword, updateUserProfile, uploadProfilePicture } from "../action/Api.action";

function ViewProfile() {
  const { addToast } = useToast()
  const { state } = useLocation();
  const [oldPass, setoldPass] = useState("password");

  const [showPass, setshowPass] = useState("password");
  const [confshowPass, setconfshowPass] = useState("password");
  const [selectedImgm, SetSelectedImg] = useState([])
  const [imgPreview, SetImagePreview] = useState([])
  const [isUpdateProfilem, Setisupdateprofile] = useState(false)
  const [formData, setFormData] = useState({})
  const [IsLoading, setIsLoading] = useState(false)

  const [subPagination, SetPagination] = useState()

  const [passwordFormData, setPasswordFormData] = useState({
    user_id: localStorage.getItem('userId')
  })
  const [errors, setErrors] = useState({})
  const [passwordErrors, setPasswordErrors] = useState({})


  useEffect(() => {
    SetPagination(state)
  }, [state])
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };




  const handleImgUpload = async (encFile) => {
    try {


      const upload = await uploadProfilePicture({ "user_id": localStorage.getItem("userId"), "image": encFile })

      console.log(upload);
      return true; // Handle the response as needed
    } catch (error) {
      console.error("Error uploading image: ", error);
      return false;
    }

  };


  const uploadProfile = async (e) => {
    setIsLoading(true)

    const file = [...e.target.files]

    console.log(e.target.files)

    if (file[0].size < 2000000 && (
      file[0].type === "image/jpeg" ||
      file[0].type === "image/png" ||
      file[0].type === "image/jpg")) {
      try {
        const encFile = await convertToBase64(file[0])
        // console.log("test for encryption",encFile)
        console.log(encFile)


        let upload = await handleImgUpload(encFile)
        console.log(upload)
        if (upload) {
          SetSelectedImg(file)
        }

      } catch (error) {
        console.log('The error is : ', error)
      }
    }
    else {
      if (file[0].size > 2000000)
        addToast({
          message: "File size must be less than 2MB",
          type: "error"
        })
      if (
        file[0].type !== "image/jpeg" &&
        file[0].type !== "image/png" &&
        file[0].type !== "image/jpg"
      )
        addToast({
          message: "File type must be JPEG, PNG or JPG",
          type: "error"
        })
      SetSelectedImg([])
    }




    setIsLoading(false)
  }
  useEffect(() => {
    const imagesArray = selectedImgm.map((file) =>
      window.URL.createObjectURL(file)
    );

    SetImagePreview(imagesArray)
    console.log(selectedImgm);
  }, [selectedImgm])

  useEffect(() => {
    console.log(imgPreview);
  }, [imgPreview])
  const removeAssets = async () => {

    setIsLoading(true)
    const res = await deleteProfilePicture({ 'user_id': localStorage.getItem('userId') })
    if (res.status == 200 && res.delete == true) {
      SetSelectedImg([])
      setIsLoading(false)
      // window.location.reload()
    }
    else {
      console.log("Some Error Occured")
    }
  }

  const unlockEdit = () => {
    Setisupdateprofile(true)
  }

  const validate = () => {

    const errorData = {}
    console.log("test")
    if (formData.phone.length == 0) { errorData.phone = "Please enter the mobile number" }
    else if (!formData.phone.match((/^\+?\d{10,16}$/))) { errorData.phone = "Please enter the valid mobile number" }

    if (formData.mobile.length == 0) { errorData.mobile = "Please enter the phone number" }
    else if (!formData.mobile.match((/^\+?\d{10,16}$/)))
      errorData.mobile = "Please enter the valid phone number"

    if (formData.name.length == 0) {
      errorData.name = "Please enter the name"
    } else if (!formData.name.match((/^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/)))
      errorData.name = "Please enter a valid name"
    setErrors(errorData)






  }













  const editProfile = async () => {
    console.log(formData, errors)
    console.log("error log for error length", errors.length)
    if ((errors.name || errors.phone || errors.mobile)) {
      console.log("remove validation errors")
    }
    else {
      setIsLoading(true)
      const response = await updateUserProfile(formData)
      if (response.status) {
        console.log("Success")
        addToast({
          message: "Profile Updated Successfully",
          type: "success",
          duration: 6000
        })
        localStorage.setItem('userName', formData.name)
        window.location.reload()

      }
      else {
        addToast({
          message: "Profile update failed",
          type: "error"
        })
      }
      Setisupdateprofile(false)
      setIsLoading(false)

    }

  }

  const showPassword = (e) => {
    console.log(e.target);
    const getPassId = document.getElementById(e)
    if (getPassId.id === 'password') {
      setshowPass(showPass === 'password' ? 'text' : 'password')
    } else if ((getPassId.id === 'oldpassword')) {
      setoldPass(oldPass === 'password' ? 'text' : 'password')
    } else {
      setconfshowPass(confshowPass === 'password' ? 'text' : 'password')


    }


  }

  const updateFormData = (index, value) => {
    console.log(value)

    setFormData(prevState => ({
      ...prevState,
      [index]: value
    }));
    validate()
    console.log(errors)
  };

  // const validatePassword = ()=>{
  //   // console.log(passwordFormData)
  //  let errs = {}
  //   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/
  //  if(!(passwordFormData.newPassword).match(passwordRegex)){
  //   console.log("##############################################true for test###########################################")
  //  errs.password = "Please enter a valid password."}
  // setPasswordErrors(errs)
  // console.log("#############password errors#########################", passwordErrors)
  //   console.log("password errors", passwordErrors)
  // }



  const validatePassword = () => {
    let error = {}
    const passwordRegex1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/

    if (!passwordFormData.password)
      error.oldPassword = "Please enter the old password"

    if (!passwordFormData.newPassword)
      error.password = "Please enter the new password"
    else if (!passwordFormData.newPassword.match(passwordRegex1)) {
      console.log("##############################################true for test###########################################")
      error.password = "Password must be 8-16 characters, and must have at least one uppercase, one lowercase, one number and one special character"
    }
    //   console.log("password errors", passwordErrors)
    if (((passwordFormData.newPassword && passwordFormData.password) && !passwordFormData.reNewPassword) || ((!passwordFormData.newPassword && passwordFormData.password) && !passwordFormData.reNewPassword) || !passwordFormData.reNewPassword)
      error.confirmPassword = "Please enter the confirm password"
    else if (passwordFormData.newPassword !== passwordFormData.reNewPassword)
      error.confirmPassword = "Password does not match"
    console.log("Errors:", error)
    console.log("Error List Null test :", (!error))
    setPasswordErrors(error);
    console.log("Password Errors:", passwordErrors)
    console.log("#############password errors#########################", passwordErrors)
    return error

  }


  const updatePasswordFormData = (index, value) => {

    setPasswordFormData(prevState => ({
      ...prevState,
      [index]: value
    }));

  };
  const submitPasswordForm = async () => {
    setIsLoading(true)
    let err = validatePassword()
    console.log("Validation Return :  ", JSON.stringify(err))
    console.log("Test:", JSON.stringify(err).length)
    if (JSON.stringify(err).length > 2) {
      console.log("Check :")
      setIsLoading(false)
      return false
    }
    //   let error = {}

    //     if(passwordFormData.password.length==0)
    //     error.oldPassword = "Please enter the old password"
    //   const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/
    //   if(passwordFormData.newPassword.length==0)
    //   error.password = "Please enter the new password"
    // else
    //  if(!passwordFormData.newPassword.match(passwordRegex)){
    //   // console.log("##############################################true for test###########################################")
    //  error.password = "Please enter a valid password"}


    // // console.log("#############password errors#########################", passwordErrors)
    // //   console.log("password errors", passwordErrors)
    // if(passwordFormData.newPassword.length!=0 && passwordFormData.oldPassword.length!=0 && passwordFormData.reNewPassword.length== 0)
    //   error.confirmPassword  = "Please enter confirm password"  
    // else if(passwordFormData.newPassword !== passwordFormData.reNewPassword )
    // error.confirmPassword  = "Password does not match"


    //   console.log(passwordFormData)
    //   setPasswordErrors(error)
    //   console.log("Password ERRORS",passwordErrors)

    //   if(passwordErrors.password && passwordErrors.confirmPassword && passwordErrors.oldPassword)
    //   return false

    // if(passwordFormData.newPassword !== passwordFormData.reNewPassword)
    // addToast({
    //   message : "Passwords do not match",
    //   type:"error"
    // })
    // else 
    if (JSON.stringify(err).length <= 2) {
      setPasswordErrors({})
      console.log("true")
      console.log(passwordErrors)
      // addToast({
      //   message : "Password matched",
      //   type:"success"
      // })

      const response = await updateUserPassword(passwordFormData)
      if (response.status == 200) {

        setIsLoading(false)
        addToast({
          message: "Your password has been changed, please log in again with the updated password",
          type: "success"
        })

        setPasswordFormData({
          user_id: localStorage.getItem('userId')
        })
        localStorage.clear()
        window.location.href = "/";
      }
      else if (response.status == 401) {
        setIsLoading(false)
        // addToast({
        //   message : "Oops! The old password you entered is incorrect. Please try again.",
        //   type:"error"
        // })
        setPasswordErrors({
          ...err,
          oldPassword: 'Please enter your correct password'
        });

      }
      else {
        setIsLoading(false)
        if (!passwordErrors)
          addToast({
            message: "Some error occured",
            type: "error"
          })
      }
    }

    setIsLoading(false)
  }




  useEffect(async () => {
    setIsLoading(true)
    const userData = await getUserData({ user_id: localStorage.getItem('userId') })
    console.log("User Data is : ", userData.user_details)
    setFormData(userData.user_details[0])
    console.log("Formdata is : ")
    const profileImg = await fetchProfilePicture({ user_id: localStorage.getItem('userId') })
    if (profileImg.status)
      SetImagePreview(profileImg.imageData)
    setIsLoading(false)
  }, [])




  return (
    <>

      <SubHeader subpagi={subPagination} />

      {IsLoading && (
        <div>
          <CustomLoader />
        </div>
      )}
      <Container fluid>
        <Container>
          <Row className="justify-content-md-center ">
            <Col className="mb-3" sm={12}>
              <div className="moreInfoSec formContent boxShaddow viewProfileBox">
                <h1>{isUpdateProfilem === true ? 'Edit Profile' : 'Account Details'}</h1>
                <Row>
                  <Col lg={4}>
                    {imgPreview.length > 0 ? (
                      <>
                        <div className="photoUpload" style={{cursor: "default"}}>
                          <div className="imgpreview" >
                            <img src={imgPreview} className="img-fluid" alt="" />
                            <button disabled={isUpdateProfilem === true ? false : true} onClick={() => { removeAssets() }} className="closeBtnimg" type="button">
                              {isUpdateProfilem && <img src="/images/svg/CloseWindow.svg" alt="" />}
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="photoUpload" style={{cursor: "default"}}>
                          <input disabled={isUpdateProfilem === true ? false : true} type="file" id="profilephoto" onChange={async (e) => await uploadProfile(e)} />
                          <label htmlFor="profilephoto" style={!isUpdateProfilem ? {cursor: "default"} : {}}>
                            {isUpdateProfilem && <img src={"/images/svg/UploadImage.svg"} alt="upload" />}
                            {!isUpdateProfilem && <img style={{ width: '200px', height: '200px' }} src={"/images/svg/profilePhoto.svg"} alt="upload" />}
                            {isUpdateProfilem && <h3>Upload Profile Image</h3>}
                          </label>
                        </div>
                      </>
                    )}

                  </Col>
                  <Col lg={8}>
                    <div className="accountDetails">
                      <div className="d-flex align-items-center gap-3 mb-4">
                        <button className="typebtn" type="button" style={{ cursor: "default" }}>
                          <span>User Type</span>
                          {formData.user_type}
                        </button>
                        <button className="typebtn" type="button" style={{ cursor: "default" }}>
                          <span>User Role</span>
                          {localStorage.getItem('userType') == 'producer' ? 'Supplier' : 'Buyer'}
                        </button>
                      </div>
                      <Form>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className="mb-3" >
                              <Form.Label>Name</Form.Label>
                              <Form.Control type="text" disabled={isUpdateProfilem === true ? false : true} placeholder="Enter the name"
                                onBlur={validate}
                                onChange={(e) => {
                                  updateFormData('name', e.target.value)
                                  validate()
                                }}
                                value={formData.name} />
                              {(isUpdateProfilem && errors.name) && <span style={{ "color": "red", "alignItems": "left" }}>*{errors.name}</span>}
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3" >
                              <Form.Label>Mobile Number</Form.Label>
                              <Form.Control type="text" disabled={isUpdateProfilem === true ? false : true} placeholder="Enter Mobile Number"

                                onBlur={validate}
                                onKeyUp={(e) => {
                                  updateFormData('phone', e.target.value)
                                  validate()
                                }}
                                onChange={(e) => {
                                  updateFormData('phone', e.target.value)
                                  validate()
                                }} value={formData.phone} />

                              {(isUpdateProfilem && errors.phone) && <span style={{ "color": "red", "alignItems": "left" }}>*{errors.phone}</span>}
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3" >
                              <Form.Label>Email</Form.Label>
                              <Form.Control type="email" disabled={true} placeholder="" value={formData.email} />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3" >
                              <Form.Label>Phone Number</Form.Label>
                              <Form.Control type="text" disabled={isUpdateProfilem === true ? false : true} placeholder="Enter Phone Number" value={formData.mobile}

                                onBlur={validate}
                                onKeyUp={(e) => {
                                  updateFormData('mobile', e.target.value)
                                  validate()
                                }}
                                onChange={(e) => {
                                  updateFormData('mobile', e.target.value)
                                  validate()
                                }} />

                              {(isUpdateProfilem && errors.mobile) && <span style={{ "color": "red", "alignItems": "left" }}>*{errors.mobile}</span>}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </div>

                  </Col>
                  <Col lg={12}>
                    <h3></h3>
                  </Col>
                  <Col lg={12}>
                    {isUpdateProfilem === false ? (
                      <><button className="  secondaryBtn   btn w-auto ms-auto" onClick={() => unlockEdit()}>Edit Profile</button></>
                    ) : <><button className=" secondaryBtn   btn w-auto  ms-auto" onClick={async () => editProfile()}>Update</button></>}

                  </Col>
                </Row>
              </div>
            </Col>
            <Col sm={12}>
              <div className="moreInfoSec formContent boxShaddow viewProfileBox">
                <h1>Change Password</h1>
                <Row>
                  <Col lg={6}>
                    <Form autoComplete="false">

                      <Form.Group className="mb-3" >
                        <Form.Label>Old Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control type={oldPass} id="oldpassword" value={passwordFormData.password} onChange={async (e) => {
                            updatePasswordFormData('password', e.target.value)
                          }}
                            placeholder="Enter Old Password" />
                          <button type="button" className="btn passwordSHow" value={passwordFormData.newPassword} onClick={() => showPassword('oldpassword')}><i class={"fa-regular " + (oldPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
                        </div>
                        {passwordErrors.oldPassword && <p style={{ "color": "red", "text-align": "left" }}>*{passwordErrors.oldPassword}</p>}


                      </Form.Group>
                      <Form.Group className="mb-3" >
                        <Form.Label>New Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control type={showPass} id="password" placeholder="Enter New Password" onChange={async (e) => {
                            updatePasswordFormData('newPassword', e.target.value)
                          }} />
                          <button type="button" className="btn passwordSHow" value={passwordFormData.newPassword} onClick={() => showPassword('password')}><i class={"fa-regular " + (showPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>

                        </div>

                        {passwordErrors.password && <p style={{ "color": "red", "text-align": "left" }}>*{passwordErrors.password}</p>}
                      </Form.Group>
                      <Form.Group className="mb-0" >
                        <Form.Label>Confirm Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control type={confshowPass} id="Confirmpassword" placeholder="Enter Confirm Password" onChange={async (e) => {
                            updatePasswordFormData('reNewPassword', e.target.value)
                          }} />
                          <button type="button" className="btn passwordSHow" value={passwordFormData.reNewPassword} onClick={() => showPassword('Confirmpassword')}><i class={"fa-regular  " + (confshowPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
                        </div>

                        {passwordErrors.confirmPassword && <p style={{ "color": "red", "text-align": "left" }}>*{passwordErrors.confirmPassword}</p>}
                      </Form.Group>
                    </Form>

                    <button className=" secondaryBtn   btn mt-4  ms-auto" onClick={submitPasswordForm}>Change Password</button>


                  </Col>
                </Row>
              </div>

            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default ViewProfile;
