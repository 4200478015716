import React,{useEffect} from 'react';
import { Container, Row, Col, Carousel, Image, Form, Button, Card } from 'react-bootstrap';

const Explore = ({ history }) => {


	/* Api Calling */
	useEffect(() => {},[]);

	return (
		<div>
			
			<Container>
				
				<Row>
					<Image src="assets/img/home.png" rounded />
				</Row>

			</Container>
			
		</div>
	);
};

export default Explore;
