import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Subheader from "../component/Subheader";
import BASE_URL from "../utils/appUrls";
import { memo } from "react";
import moment from 'moment';
import {
  createDelist,
  createResell,
  deListBlockChainMetaData,
  getCtnftList,
  getNFTList,
  getPendingList,
  saveTransactionHash,
} from "../action/Api.action";
import NoRecordsFound from "../component/NoRecordFound";
import styles from "../common/WithdrawList/order.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ProducerStatus from "../common/modal/producerStatus";
import Pagination from "../common/pagination";
import axios from "axios";
import fileDownload from "js-file-download";
import { setDapp, setResubmission } from "../slices/home";
import { BASE_CURRENCY, NAME } from "../constant/constant";
import { createResellTransaction } from "../component/walletConnect";
import { useLoader } from "../context/Loader/useLoader";
import { useToast } from "../context/Toast/useToast";
import { getListedNft } from "../action/walletApiAction";
import { useLocation, Link } from 'react-router-dom';
import CustomLoader from "../common/customLoader";

const ApprovedCTNFT = ({ history, status, location }) => {
  const itemsPerPage = process.env.REACT_APP_ITEMPERPAGE;
  const [ctnftList, setCTNFTList] = useState([]);
  const [timeZone, setTimeZone] = useState('')
  const [paging, setPaging] = useState({
    count: 0,
    totalCount: 0,
  });
  const [viewMore, setViewMore] = useState(false);
  const [cardOpen, setCardOpen] = useState();
  const [width, setWidth] = useState();
  const userData = useSelector((state) => state?.auth?.profileData);
  const [CurrentStep, setCurrentStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReformatCertificates, setReformatCertificates] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);
  const [listedNfts, setListedNfts] = useState([])
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { addToast } = useToast();
  const [subPagination, SetPagination] = useState()
  const { state } = useLocation();
  const [IsLoading,setIsLoading] = useState(false)
  useEffect(() => {
    SetPagination(state)
  }, [state])
 
  useEffect(() => {
    setWidth(window.innerWidth);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone)

    // const convertedDateTime = 
  }, []);

  /*const getUserProductList = async()=>{
    let resp = await getUserProductListAction({});

    if(resp.code === 200){
      setCTNFTList(resp.data);
    }
  }*/

  const fetchCtnftList = async () => {
    setIsLoading(true)
     console.log("Ashmin test category :",status )
      let searchObj = {
        "user_id" : localStorage.getItem('userId'),
        "nft_type" : status,
        "wallet_address" : localStorage.getItem('wallet_address')

      }
      console.log("ashmin test :",searchObj)
      const resp = await getNFTList(searchObj);
      console.log("ashmin test response :", resp)
      if (resp?.status) {
        resp?.data &&
          resp.data.map((nft) => {
            if (nft.state == "resubmission") {
              addToast({
                message: "CNFT Documents Rejected, Please Resubmit Documents",
                type: "error",
                duration: 3000,
              });
              return;
            }
          });
        setCTNFTList(resp?.NFT_list);
      }
      setIsLoading(false)
  };

  /* Api Calling */
  useEffect(() => {
    //getUserProductList();
    fetchCtnftList();
  }, []);

  useEffect(() => {
    // const partner_type = userData?.partner_type;
    // const partner_state = userData?.partner_state;

    // if (partner_type !== "producer") {
    //   history.push(`/edit-profile`);
    // }
    const partner_type = localStorage.getItem('userType');

    const partner_state = "approved"; // fetch this state from the backend first to proceed with the dashboard rendering


    //use this part to check for proper entries of supplier/producer

    if (partner_state !== "approved" && partner_type === "producer") {
      setModalOpen(true);
      setTimeout(() => {
        history.push(`/edit-profile`);
        setModalOpen(false);
      }, 5000);
    }
  }, []);
  const handleClose = () => {
    setModalOpen(false);
    history.push(`/edit-profile`);
  };

  // const handleDeList = async (id) => {
  //   console.log(id);

  //   let certificate = [];
  //   certificate.push(id.certificate_id);
  //   let body = {
  //     certificates: certificate,
  //   };
  //   const res = await deListBlockChainMetaData(body);
  //   setLoading(
  //     true,
  //     "Please accept the transaction on your Phaeton Wallet for Delist"
  //   );

  //   if (res.status) {
  //     const resp = await createResellTransaction(res?.data);

  //     if (resp.status) {
  //       setLoading(true, "Creating Delisting Request");
  //       addToast({
  //         message: resp?.message,
  //         type: "success",
  //         duration: 3000,
  //       });

  //       const response = await createDelist(body);
  //       if (response.status) {
  //         addToast({
  //           message: "Certificate Delisted Successfull.",
  //           type: "success",
  //           duration: 3000,
  //         });
  //         // addToast({
  //         //   message: response?.message,
  //         //   type: "success",
  //         //   duration: 3000,
  //         // });
  //         let infos = {
  //           txn_type: "withdraw_relist",
  //           object_id: response?.object_id,
  //           transaction: resp?.transactionHash,
  //         };
  //         const result = await saveTransactionHash(infos);
  //         setLoading(true, "Saving Transaction Hash");
  //         if (result.status) {
  //           history.push("/resell");
  //           addToast({
  //             message: result?.message,
  //             type: "success",
  //             duration: 3000,
  //           });
  //           setLoading(false);
  //         }
  //       } else {
  //         addToast({
  //           message: response?.message,
  //           type: "error",
  //           duration: 3000,
  //         });
  //         setLoading(false);
  //       }
  //     } else {
  //       addToast({
  //         message: resp?.message,
  //         type: "error",
  //         duration: 3000,
  //       });
  //       setLoading(false);
  //     }
  //   } else {
  //     addToast({
  //       message: res?.message,
  //       type: "error",
  //       duration: 3000,
  //     });
  //     setLoading(false);
  //   }
  // };

  // const handleDocPagination = (page) => {
  //   if (page) {
  //     let startPage = page * itemsPerPage;
  //     let endPage = page + 1 * itemsPerPage;
  //     let nextPrevData = [];
  //     allCertificates.forEach((data, index) => {
  //       if (index + 1 >= startPage && index + 1 < endPage) {
  //         nextPrevData.push(data);
  //       }
  //     });
  //     setReformatCertificates(nextPrevData);
  //     //window.scrollTo({ top: 0 });
  //     setCurrentPage(page);
  //   }
  // };
  const handlePagination = (page) => {
    if (page) {
      // window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    if (currentPage) {
      fetchCtnftList();
    }
  }, [currentPage]);

  // const handleDownload = (url, filename) => {
  //   axios
  //     .get(url, {
  //       responseType: "blob",
  //     })
  //     .then((res) => {
  //       fileDownload(res.data, filename);
  //     });
  // };

  // const handleEdit = (dapp) => {
  //   dispatch(setDapp(dapp.dapp_id));
  //   history.push("/edit-ctnft");
  // };
  // function capitalizeFirstLetter(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // }
  // const shortHeader = (name) => {
  //   if (name.length > 8) {
  //     let shortName = name?.substr(0, 8);
  //     return shortName + "...";
  //   } else {
  //     return name;
  //   }
  // };
  // const mintNft = (accu) => {
  //   history.push(`/mintCNFT/${accu}`);
  // }

 
  
  // const viewCTNFT = (e) => {
  //   history.push(`/viewctnft/${e}`)
  // }

  
  const retireNft = (accu) =>{
  
    history.push({pathname:`/viewctnft/${accu}`, state:{"view":status}});
  }
  return (
    <div>
      <Subheader subpagi={subPagination} />
      {IsLoading && (
        <div>
          <CustomLoader />
        </div>
      )}
      <Container className='bg-white' fluid>
        <Row>
          <Col lg={12}>

            <div className='table-resposive tableBox'>
              <table>
                <thead>
                  <tr>
                    <td>Sr. No</td>
                    <td>Project Title</td>
                    <td>Txn. Hash</td>
                    <td>Registration No.</td>
                    <td>Asset ID</td>
                    <td>
                          {status == 'listed' &&  "Type"}
                    </td>
                    <td>Date </td>
                    <td>NFT cost</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {ctnftList && ctnftList?.length > 0 ? (
                    ctnftList?.map((item, i) => {
                      return (<>
                        <tr>
                          {/* <td>{res?.accu}</td>
                                    <td>{res?.authority_id}</td>
                                    <td>{res?.price}</td>
                                    <td>{capitalizeFirstLetter(res?.state)}</td> */}
                          <td>{i + 1}</td>
                          <td>{item?.project_title}</td>
                          <td>
                          {item?.transaction_hash}
                            </td>
                          <td>{item?.registration_number}</td>
                          <td>{item?.serial_number}</td>
                          <td>
                          {status == 'listed' &&  item?.nft_type}
                    </td>
                          <td>{item?.date}</td>
                          <td>
                          <img width={15} src="/images/svg/usdcIcon.svg" alt="" />
                          {item?.price}</td>
                          <td>
                            {viewMore &&
                              width <= 768 &&
                              cardOpen === i ? (
                              <></>
                            ) : (
                              <div className="btnbox">

                                {/* <button className="btn py-0"
                                  onClick={() => viewCTNFT(item?.id)}>
                                  <i class="fa-regular fa-eye"></i>
                                </button> */}
                                {status !== 'bought' && <Link className="btn py-0" to={{pathname:`/viewctnft/${item?.nft_id}`, state:{"view": status},sellType : item?.sell_status}}><i class="fa-regular fa-eye"></i></Link>}
                                {status === 'bought' && <button className="btn btn-danger text-white" onClick={()=>retireNft(item?.nft_id)}>Retire</button>}

                                {/* <button
                                              onClick={() => handleDeList(res)}>
                                              De-List
                                            </button> */}
                              </div>
                            )}
                          </td>
                        </tr>

                      </>)
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>No Records Found.</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>


          </Col>
          <Col className="text-end" lg={12}>
            {listedNfts && paging?.totalCount > 0 ? (
              <Pagination
                itemsCount={paging?.totalCount || 0}
                itemsPerPage={paging?.count || 9}
                currentPage={currentPage}
                setCurrentPage={(e) => handlePagination(e)}
                alwaysShown={false}
              />
            ) : null}
          </Col>
        </Row>
      </Container>

      {modalOpen && (
        <ProducerStatus
          handleClose={handleClose}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
};

export default memo(ApprovedCTNFT);
