import { Container,Row,Col,Button,Form} from 'react-bootstrap';
import './styles.css'
import {Link} from 'react-router-dom'
import { Label } from '@blueprintjs/core';

const Contactnew = ({ data }) => {

    const images = ["/images/png/RegisterHow.png","/images/png/VerificationHow.png"]
    return (
       
        <div className="featureSec container-fluid position-relative contactNewSec"  >
            
            <img className='contactBgImg' src='/images/png/ContactBgImg.png'></img>

               <div className='pt-5'>
              
                <Row className='mt-5' >
               
                <Col className='featureText col-md-6 my-4'>
               
                <div className='contactTitles'>
                <p className='aboutHead text-left'>Contact Us</p>

                <h1 className='contactTitle'>Reach out to us</h1>
                <p className='contactSubTitle'>Let’s schedule a catch up over a coffee, beer or online to discover how we can help you</p>
                <Link to="/contactus"><Button className="primaryBtn contactBtn newsletterEmail " variant="" type="submit" >
               Contact Us
              </Button></Link>
                </div>
            
       
                
               
                </Col>
             
               
                </Row>
                <Row>
                    <div className='newsLetterPanel mt-5'>
                    <div className="container infoPanel my-5">
                  <div className="row mt-2">
                    <img className="imgNewsletter" src="/images/png/rafiki1.png" style={{height : '100%' , width : '30%',marginLeft : '5%', transform: 'translateY(-40%)'}}></img>

                
                <h2 className='mt-5' ><b>Subscribe to our Newsletter</b></h2>
                <p className='text-black text-left'>Stay updated with the latest news, updates, and exclusive offers from EcoCredit. Get insights on our projects, sustainability tips, and ways to make a positive impact.</p>
              
              <Form className='newsLetterForm'>
                <Row>
                  <Col md={5}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                   <Form.Control
                className=' newsEmail'

                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  //onChange={(e) => handleChange(e)}
                />              </Form.Group> 

                  </Col>
                  <Col md={7}>
                  <Button className="primaryBtn contactBtn newsletterEmail mx-4" variant="" type="submit" style={{display :'inline'}}>
               Subscribe
              </Button>
                  </Col>
                  </Row> 
               

            
            </Form>
             
                    
                  </div>
                  </div>
                    </div>
                </Row>
                </div>
        </div>
    );
};

export default Contactnew;
