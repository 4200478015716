import React from "react";
import styles from "./style.module.scss";

function CustomSelect({
  items,
  label,
  important = false,
  selectedValue,
  onChange,
  error,
  readonly,
}) {
  return (
    <div className={styles.SelectContainer}>
      <div className={styles.SelectWrapper}>
        {/* {label && (
          <label className={styles.Label}>
            {label}
            {important && <b className={styles.SelectStar}>*</b>}
          </label>
        )} */}
        <select 
          className={'form-select form-control'}
          value={selectedValue}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          disabled={readonly}>
          <option key={-1} value="">
            Please Select {label ? label : ""}
          </option>
          {items.map((item, index) => {
            return (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

export default CustomSelect;
